import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/models/User';
import Swal from 'sweetalert2';
import { UtilsService } from 'src/app/services/core/utils.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-usersneverlogin',
  templateUrl: './usersneverlogin.component.html',
  styleUrls: ['./usersneverlogin.component.css']
})
export class UsersneverloginComponent implements OnInit {
  users: any[];
  constructor(
    private title: Title,
    private utils: UtilsService,
    private userService: UserService
  ) {
    this.loadPage();
    this.title.setTitle('Usuario que nunca logaram | Qisar');
  }

  loadPage() {
    this.utils.running();
    this.userService
      .neverLoginUsers()
      .then((users) => {
        this.users = users;
        this.utils.finish();
      })
      .catch(() => {
        Swal.fire('Qisar', 'Não foi possivel recuperar os dados do relatorio', 'error');
        this.utils.finish();
      });
  }

  ngOnInit() {
  }

}
