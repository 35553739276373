import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition, AnimationEvent } from '@angular/animations';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { CompanyInvoice } from 'src/app/models/companyInvoice.model';

@Component({
    selector: 'invoice-detail',
    templateUrl: './invoicedetail.component.html',
    styleUrls: ['./invoicedetail.component.css'],
    animations: [
        trigger('openClose', [
            state('show', style({
                opacity: 1,
            })),
            state('hide', style({
                opacity: 0.1,
                height: '0px',
                display: 'none'
            })),
            transition('show => hide', [
                animate('0.15s')
            ]),
            transition('hide => show', [
                animate('0.15s')
            ])
        ]),
    ]
})
export class InvoiceDetailComponent implements OnInit {
    public isVisible : boolean;
    public companyInvoice : CompanyInvoice;
    @ViewChild('container', { read: ElementRef }) $container: ElementRef;

    private _lastInvoiceLoaded : number;

    constructor(
        private _paymentService: PaymentService
    ){
        this.isVisible = false;
    }

    ngOnInit(): void {

    }

    loadInvoice(invoiceId : number){

        if(this._lastInvoiceLoaded === invoiceId)
        {
            this.isVisible = true;
            return;
        }

        this._lastInvoiceLoaded = invoiceId;
        this.isVisible = false;

        this._paymentService.getInvoiceDetail(invoiceId)
            .then(data => {
                this.companyInvoice = data;
                this.isVisible = true;
            });
    }

    close(){
        this.isVisible = false;
    }

    openCloseAnimationStart(event : AnimationEvent){
        if(event.fromState === 'hide' && event.toState === 'show'){
            this.$container.nativeElement.style.display = 'block';
        }
    }

    openCloseAnimationDone(event : AnimationEvent){
        if(event.fromState === 'show' && event.toState === 'hide'){
            this.$container.nativeElement.style.display = 'none';
        }
    }
}
