import { Injectable, EventEmitter } from '@angular/core';
import { UtilsService } from './core/utils.service';
import { User } from '../models/User';
import { ChatmessageService } from './chat/chatmessage.service';
import { ProposalService } from './proposal/proposal.service';
import { ChatService } from './chat/chat.service';
import { SaleService } from './sale/sale.service';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  onOpen: EventEmitter<void> = new EventEmitter();
  currentUser: User;
  chatNotificationList = [];
  proposalNotificationList = [];
  requestNotificationList = [];
  saleNotificationList = [];
  declineNotificationList = [];
  requestCancelNotificationList = [];

  constructor(
    private utilsService: UtilsService,
    private proposalService: ProposalService,
    private chatService: ChatService,
    private saleService: SaleService,
    private chatMessageService: ChatmessageService

  ) {
  }

  /** inicia as consultas */
  start() {
    this.loadChatNotifications();
    this.loadProposalNotifications();
    this.loadRequestNotifications();
    this.loadSalesNotifications();
    this.loadRequestCancelNotifications();
    this.loadDeclineNotifications();
  }

  /** chama api para retornar lista de notificações */
  loadChatNotifications(): void {
    this.utilsService.getUserFromSession().then(user => {
      this.currentUser = user;
      this.chatMessageService
        .unreadMessages(user.userId)
        .then((list: any) => {
          this.chatNotificationList = list;
        });
    });
  }

  /** recarrega lista de chats */
  onChatReload() {
    this.loadChatNotifications();
  }

  loadProposalNotifications(): void {
    this.proposalService.getProposalsNotRead()
      .then((data: any) => {
        this.proposalNotificationList = data;
      });
  }

  onProposalReload() {
    this.loadProposalNotifications();
  }

  loadRequestNotifications(): void {
    this.chatService.getNotReadRequestByUserId()
      .then((data: any) => {
        this.requestNotificationList = data;
      });
  }

  onRequestReload() {
    this.loadRequestNotifications();
  }

  loadSalesNotifications(): void {
    this.saleService.getNotReadSalesByUserId()
      .then((data: any) => {
        this.saleNotificationList = data;
      });
  }

  onSalesReload() {
    this.loadSalesNotifications();
  }

  loadDeclineNotifications(): void {
    this.chatService.getNotReadDeclineFromCustomer()
      .then((data: any) => {
        this.declineNotificationList = data;
      });
  }

  onDeclineReload() {
    this.loadDeclineNotifications();
  }

  loadRequestCancelNotifications(): void {
    this.chatService.getNotReadCancelRequestsFromSaler()
      .then((data: any) => {
        this.requestCancelNotificationList = data;
      });
  }

  onRequestCancelReload() {
    this.loadRequestCancelNotifications();
  }
}
