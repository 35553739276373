import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import { UtilsService } from 'src/app/services/core/utils.service';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-request-report',
  templateUrl: './request-report.component.html',
  styleUrls: ['./request-report.component.css']
})
export class RequestReportComponent implements OnInit {

  constructor(
    private service: RequestService,
    private util: UtilsService,
    private formBuilder: FormBuilder,
    private title: Title) {
    this.title.setTitle('Requisições enviadas');
    const initial = new Date(new Date().setDate(new Date().getDate() - 1));
    this.form = this.formBuilder.group({
      from: [initial],
      to: [new Date()]
    });
  }

  form: FormGroup;
  list: any;

  pt = {
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Feveiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
  };

  ngOnInit() {
    this.loadPage();
  }

  loadPage() {
    this.util.running();
    const from = new Date(this.form.value.from);
    const to = new Date(this.form.value.to);
    this.service.report(from, to)
      .then((data: any) => {
        this.list = data;
        this.util.finish();
      });
  }

  download() {
    const headerLine = ['Controle Interno',
      'Req.',
      'Data da Requisição',
      'Limite para Resposta',
      'Id do Comprador',
      'Comprador',
      'Ativo na Plataforma',
      'Id do Status',
      'Status da Requisição',
      'Segmento',
      'Endereço de Entrega',
      'Fornecedor Homologado',
      'Fornecedor',
      'Plano escolhido',
      'Fornecedor Ativo',
      'Fornecedor Aceitou a Plataforma',
      'Telefone do Fornecedor',
      'Email',
      'Fornecedor Enviou Proposta',
      'Data da última Proposta do Fornecedor',
      'Valor da Proposta',
      'Proposta Escolhida',
      'Motivo da Compra'
    ];
    const replacer = (key, value) => value === null ? '' : value;
    const header = Object.keys(this.list[0]);
    const csv = this.list.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
    csv.unshift(headerLine.join(';'));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob(['\ufeff', csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'relatorio.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.util.finish();

  }

}
