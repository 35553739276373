export class FileHelper {

    public static convertDataUrlToFile(dataUrl: string, fileName: string): File {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    }

    // public static getBase64FromFile(file: File): Promise<string> {
    //     return new Promise(resolve => {
    //       if (!file) {
    //         resolve(null);
    //       } else {
    //         let fileReader = new FileReader();
    //         fileReader.onloadend = event => {
    //           resolve(fileReader.result);
    //         }
    //         fileReader.readAsDataURL(file);
    //       }
    //     })
    //   }

    public static getFileListFromInputEvent(event): Array<File> {
        return event.target.files;
    }

    public static getFileFromInputEvent(event): File {
        const files: Array<File> = FileHelper.getFileListFromInputEvent(event);
        if (files.length > 0) {
            return event.target.files[0];
        }
        return null
    }

    public static validadeFilEextension(file: File, validFileExtensions: Array<string>): boolean {
        let fileNameParts: Array<string> = file.name.split(".")
        if (fileNameParts.length > 1) {
            let fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
            for (let validExtension of validFileExtensions) {
                validExtension = validExtension.toLowerCase();
                if (validExtension == fileExtension) {
                    return true;
                }
            }
            return false;
        }
        else {
            return false;
        }
    }

    public static validateFileSize(file: File, sizeInKiloBytes: number): boolean {
        let sizeInBytes: number = sizeInKiloBytes * 1024;
        if (file.size > sizeInBytes) {
            return false
        }
        return true
    }

}