import { Injectable } from '@angular/core';
import { PaymentPlanAcceptMonthApi } from 'src/app/core/http/entidades/PaymentPlanAcceptMonthApi';
import { PaymentPlanAcceptMonth } from 'src/app/models/PaymentPlanAcceptMonth';

@Injectable({
  providedIn: 'root'
})
export class PaymentPlanAcceptMonthService {

  constructor(private api: PaymentPlanAcceptMonthApi) {
    this.api.baseName = 'paymentplanacceptmonth';
   }

   getByCompanyid(): any {
    return new Promise((resolve, reject) => {
      this.api.get('/companyitens/')
        .then((data: PaymentPlanAcceptMonth) => resolve(data))
        .catch(error => reject(error));
    });
  }

  getByCompanyidDateRange(initialDate: Date, finalDate: Date): any {
    return new Promise((resolve, reject) => {
      this.api.get('/companyitensDateRange/'+ initialDate.toISOString()  + '/' + finalDate.toISOString())
        .then((data: PaymentPlanAcceptMonth) => resolve(data))
        .catch(error => reject(error));
    });
  }

   /** Salvar */
   save(paymentPlanAcceptMonth: PaymentPlanAcceptMonth): any {
    return new Promise((resolve, reject) => {
        this.api.post(paymentPlanAcceptMonth)
          .then((data: PaymentPlanAcceptMonth) => resolve(data))
          .catch(() => {
            reject();
          });
    });
  }

}