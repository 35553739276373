import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company/company.service';

@Component({
  selector: 'app-notrustcommission',
  templateUrl: './notrustcommission.component.html',
  styleUrls: ['./notrustcommission.component.css']
})
export class NotrustcommissionComponent implements OnInit {

  items = [];
  constructor(private companyService: CompanyService) { }

  ngOnInit() {
    this.companyService.getBasicNoTrustCommissions().then((items: []) => this.items = items);
  }


}
