import { Injectable } from '@angular/core';
import { LeadApi } from 'src/app/core/http/entidades/LeadApi';
import { Lead } from 'src/app/models/Lead';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  constructor(private api: LeadApi) {
    this.api.baseName = 'lead';
  }

  save(lead: Lead): any {
    return new Promise((resolve, reject) => {
      this.api
        .post(lead)
        .then((data: any) => resolve(data))
        .catch((error: any) => reject(error));
    });
  }

  get(): any {
    return new Promise((resolve, reject) => {
      this.api
        .get()
        .then((data: any) => resolve(data))
        .catch((error: any) => reject(error));
    });
  }

}