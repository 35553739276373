import { RequestItem } from './RequestItem';
import { ProposalHistory } from './ProposalHistory';
import { Proposal } from './Proposal';

export class ProposalRequestItem {
  proposalRequestItemId: number;
  proposalId: number;
  requestItemId: number;
  cost: number;
  bestPrice: boolean;
  icms: number;
  icmsPercent: number;
  pisCofins: number;
  pisCofinsPercent: number;
  ipi: number;
  ipiPercent: number;
  iss: number;
  issPercent: number;
  requestItem: RequestItem;
  proposal: Proposal;
  sold?: boolean;
}
