import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { OffersService } from 'src/app/services/offers/offers.service';
import { Router } from '@angular/router';
import { SelectedFile } from 'src/app/components/file-picker/file-picker.component';
import { ArrayHelper } from 'src/app/utils/array-helper';
import { AzureBlobUploaderService } from 'src/app/services/azure-blob-uploader.service';
import { OfferphotosService } from 'src/app/services/offers/offerphotos.service';
import { DialogHelperService } from 'src/app/services/dialog-helper.service';

@Component({
  selector: 'app-offernew',
  templateUrl: './offernew.component.html',
  styleUrls: ['./offernew.component.css']
})
export class OffernewComponent implements OnInit {
  form: FormGroup;
  selectedFileList: Array<SelectedFile> = [];

  constructor(
    private formBuilder: FormBuilder,
    private offerService: OffersService,
    private offerPhotoService: OfferphotosService,
    private router: Router,
    public azureBlobUploaderService: AzureBlobUploaderService,
    private notificationService: DialogHelperService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      quantity: [null, Validators.compose([Validators.required, Validators.min(1)])],
      cost: [null, Validators.compose([Validators.required, Validators.min(0.01)])]
    });
  }

  changeNegotiation() {
    this.form.controls.cost.setValue(0.001);
  }

  saveOffer() {
    if (this.form.valid) {
      const offer = {
        offerId: 0,
        name: this.form.value.name,
        cost: Number(this.form.value.cost),
        quantity: Number(this.form.value.quantity),
        description: this.form.value.description
      };
      this.offerService.save(offer)
        .then((data: any) => {
          const uploadIdList = ArrayHelper.getPropertyList(this.selectedFileList, 'id');
          this.azureBlobUploaderService.checkUploadIsComplete(uploadIdList).then(urlList => {
            for (const url of urlList) {
              this.offerPhotoService.save({
                offerPhotoId: 0,
                offerId: data.offerId,
                uRI: url
              });
            }
            this.notificationService.showSuccessNotification('oferta ' + data.offerId.toString() +
              ' criada com sucesso');
            this.router.navigateByUrl('/offer/list');
          });
        })
        .catch(() => this.notificationService.showErrorNotification('Ocorreu um erro ao criar sua oferta'));
    } else {
      Swal.fire('Qisar Oportunidades', 'Preencha todos os campos', 'info');
    }
  }

  disabled() {
    if (this.form.valid) {
      return '';
    } else {
      return 'disabled';
    }
  }

  onFileListChange(selectedFileList: Array<SelectedFile>) {
    this.selectedFileList = selectedFileList;
  }
}
