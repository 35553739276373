import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import { UtilsService } from 'src/app/services/core/utils.service';
import { Request } from 'src/app/models/Request';
import { OverlayPanel } from 'primeng/overlaypanel';
import { SelectItem } from 'primeng/api';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ObjectMap } from 'src/app/utils/object-map';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ActiveCompanyService } from 'src/app/services/active-company.service';
import { ActivatedRoute } from '@angular/router';
import { QisarService } from 'src/app/services/hubs/qisar.service';
import { DialogHelperService } from 'src/app/services/dialog-helper.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ChatService } from 'src/app/services/chat/chat.service';

@Component({
  selector: 'app-provider-request-list-page',
  templateUrl: './provider-request-list-page.component.html',
  styleUrls: ['./provider-request-list-page.component.css']
})
export class ProviderRequestListPageComponent implements OnInit {

  @ViewChild('addressOverlayPanel') addressOverlayPanel: OverlayPanel;
  @ViewChild('atachmentsOverlayPanel') atachmentsOverlayPanel: OverlayPanel;
  @ViewChild('productOverlayPanel') productOverlayPanel: OverlayPanel;

  requestList: Array<Request>;
  requestMap: ObjectMap<Request> = new ObjectMap('requestId');
  isRequestApiBusy = false;

  selectedRequestToShowAddress: Request = null;
  selectedRequestToShowAtachments: Request = null;
  selectedRequestToShowProduct: Request = null;

  requestIdFilterCurrentValue : string = '';

  filterForm: FormGroup;

  isTermsDone = false;
  seeStatusFilter = true;
  icon = 'fas fa-list';
  pageTitle = 'Todas as Requisições Recebidas';

  filterFormDateRangeOptionList: Array<SelectItem> = [
    {
      label: 'Hoje',
      value: 'today'
    },
    {
      label: 'Última semana',
      value: 'last-7-days'
    },
    {
      label: 'Últimos 15 dias',
      value: 'last-15-days'
    },
    {
      label: 'Último mês',
      value: 'last-30-days'
    },
    {
      label: 'Desde o início',
      value: 'all'
    }
  ];
  filterFormSegmentIdOptionList: Array<SelectItem> = [];
  filterFormStatusIdOptionList: Array<SelectItem> = [
    {
      label: 'Todos',
      value: null
    },
    {
      label: 'Em andamento',
      value: 1
    },
    {
      label: 'Encerrada',
      value: 3
    },
    {
      label: 'Aguardando Aprovação',
      value: 5
    },
    {
      label: 'Cancelada',
      value: 2
    },
  ];

  statusColorMap = {
    1: '#ffc107',
    2: '#b71c1c',
    3: '#64dd17',
    5: '#1976d2',
  };

  constructor(
    private formBuilder: FormBuilder,
    private requestService: RequestService,
    private route: ActivatedRoute,
    private segmentService: SegmentService,
    private utils: UtilsService,
    private title: Title,
    private activeCompanyService: ActiveCompanyService,
    private dialogHelperService: DialogHelperService,
    private hub: QisarService,
    private notificationService: NotificationService
  ) {
    this.utils.running();
  }

  ngOnInit() {
    let statusId = null;
    let gettingNewRequests = false;
    if (this.route.snapshot.paramMap.get('status')) {
      this.seeStatusFilter = false;
      switch (this.route.snapshot.paramMap.get('status')) {
        case 'open':
          statusId = 1;
          this.icon = 'fas fa-lock-open';
          this.pageTitle = 'Requisições Em Andamento';
          break;
        case 'cancel':
          statusId = 2;
          this.icon = 'fas fa-ban';
          this.pageTitle = 'Requisições Canceladas';
          break;
        case 'close':
          statusId = 3;
          this.icon = 'fas fa-lock';
          this.pageTitle = 'Requisições Encerradas';
          break;
      }
    }
    this.title.setTitle(this.pageTitle + ' | ' + environment.appTitle);
    this.filterForm = this.formBuilder.group({
      segmentId: [null, Validators.compose([])],
      dateRange: ['last-7-days', Validators.compose([])],
      awnserDate: [null, Validators.compose([])],
      requestId: [null, Validators.compose([])],
      statusId: [statusId, Validators.compose([])],
    });
    this.loadPage();

    this.hub.onChageRequestStatus.subscribe(() => {
      this.notificationService.onSalesReload();
    });

    this.hub.onCancelRequest.subscribe(() => {
      this.notificationService.onRequestCancelReload();
      this.notificationService.onRequestReload();
    });

    this.hub.onRequestRecieved.subscribe((response: any) => {
      this.notificationService.onRequestReload();
      if (!gettingNewRequests) {
        gettingNewRequests = true;
        let rangeDateStart: Date = null;
        let rangeDateEnd: Date = null;
        const filterFormDateRange: string = this.filterForm.value.dateRange;
        if (filterFormDateRange === 'today') {
          rangeDateStart = new Date(moment().format('YYYY-MM-DD') + ' 00:00:00');
          rangeDateEnd = new Date(moment().format('YYYY-MM-DD') + ' 23:59:59');
        }
        if (filterFormDateRange === 'last-7-days') {
          rangeDateStart = new Date(moment().subtract(7, 'days').format('YYYY-MM-DD') + ' 00:00:00');
          rangeDateEnd = new Date(moment().format('YYYY-MM-DD') + ' 23:59:59');
        }
        if (filterFormDateRange === 'last-15-days') {
          rangeDateStart = new Date(moment().subtract(15, 'days').format('YYYY-MM-DD') + ' 00:00:00');
          rangeDateEnd = new Date(moment().format('YYYY-MM-DD') + ' 23:59:59');
        }
        if (filterFormDateRange === 'last-30-days') {
          rangeDateStart = new Date(moment().subtract(30, 'days').format('YYYY-MM-DD') + ' 00:00:00');
          rangeDateEnd = new Date(moment().format('YYYY-MM-DD') + ' 23:59:59');
        }
        this.requestService
          .searchList('requestor',
            response.requestId,
            false,
            this.activeCompanyService.activeCompanyId,
            this.filterForm.value.segmentId,
            this.filterForm.value.statusId,
            rangeDateStart, rangeDateEnd,
            this.filterForm.value.awnserDate,
            this.filterForm.value.requestId,
            false,
            false)
          .then((request: Array<Request>) => {
            if (request[0]) {
              this.requestList.unshift(request[0]);
              this.requestMap.add(request[0]);
              gettingNewRequests = false;
            }
          });
      }
    });

  }

  loadPage() {
    this.loadAuxData().then(() => {
      this.loadList().then(() => {
        this.utils.finish();
      });
    });
  }

  loadList(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.utils.running();
      this.isRequestApiBusy = true;
      let rangeDateStart: Date = null;
      let rangeDateEnd: Date = null;

      const filterFormDateRange: string = this.filterForm.value.dateRange;
      if (filterFormDateRange === 'today') {
        rangeDateStart = new Date(moment().format('YYYY-MM-DD') + ' 00:00:00');
        rangeDateEnd = new Date(moment().format('YYYY-MM-DD') + ' 23:59:59');
      }
      if (filterFormDateRange === 'last-7-days') {
        rangeDateStart = new Date(moment().subtract(7, 'days').format('YYYY-MM-DD') + ' 00:00:00');
        rangeDateEnd = new Date(moment().format('YYYY-MM-DD') + ' 23:59:59');
      }
      if (filterFormDateRange === 'last-15-days') {
        rangeDateStart = new Date(moment().subtract(15, 'days').format('YYYY-MM-DD') + ' 00:00:00');
        rangeDateEnd = new Date(moment().format('YYYY-MM-DD') + ' 23:59:59');
      }
      if (filterFormDateRange === 'last-30-days') {
        rangeDateStart = new Date(moment().subtract(30, 'days').format('YYYY-MM-DD') + ' 00:00:00');
        rangeDateEnd = new Date(moment().format('YYYY-MM-DD') + ' 23:59:59');
      }

      this.requestService
        .searchList('requestor',
          0,
          false,
          this.activeCompanyService.activeCompanyId,
          this.filterForm.value.segmentId,
          this.filterForm.value.statusId,
          rangeDateStart, rangeDateEnd,
          this.filterForm.value.awnserDate,
          this.filterForm.value.requestId,
          false,
          false)
        .then((response: Array<Request>) => {
          this.requestList = response;
          this.requestMap.addList(response);
          this.isRequestApiBusy = false;
          resolve();
          this.utils.finish();
        });
    });
  }

  loadAuxData(): Promise<void> {
    return new Promise((resolve, reject) => {
      const promiseList: Array<Promise<any>> = [];
      promiseList.push(
        new Promise((res, rej) => {
          this.segmentService.getall().then(response => {
            this.filterFormSegmentIdOptionList.push({
              label: 'Todos',
              value: null
            });
            for (const segment of response) {
              this.filterFormSegmentIdOptionList.push({
                label: segment.name,
                value: segment.segmentId
              });
            }
            res();
          });
        })
      );
      Promise.all(promiseList).then(() => {
        resolve();
      });
    });
  }

  getDisplayItemsList(request: Request): string {
    let str = '';
    let index = 0;
    request.requestItems.forEach(item => {
      if (str.length > 0) {
        if (index === request.requestItems.length - 1) {
          str += ' e ';
        } else {
          str += ', ';
        }
      }
      str += item.description + ' (' + item.quantity + ' ' + item.measure + ')';
      index++;
    });
    return str;
  }

  getDisplayProductList(request: Request): string {
    let str = '';
    let index = 0;
    request.requestProducts.forEach(product => {
      if (str.length > 0) {
        if (index === request.requestProducts.length - 1) {
          str += ' e ';
        } else {
          str += ', ';
        }
      }
      str += product.product.name + ' (' + product.qtd + ' ' + product.measure + ')';
      index++;
    });
    return str;
  }

  openAdressOverlayPanel(event: any, request: Request) {
    this.selectedRequestToShowAddress = request;
    if (this.addressOverlayPanel.visible) {
      this.addressOverlayPanel.hide();
    }
    setTimeout(() => {
      this.addressOverlayPanel.show(event);
    }, 250);
  }

  openAtachmentsOverlayPanel(event: any, request: Request) {
    this.selectedRequestToShowAtachments = request;
    if (this.atachmentsOverlayPanel.visible) {
      this.atachmentsOverlayPanel.hide();
    }
    setTimeout(() => {
      this.atachmentsOverlayPanel.show(event);
    }, 250);
  }

  openProductOverlayPanel(event: any, request: Request) {
    this.selectedRequestToShowProduct = request;
    if (this.productOverlayPanel.visible) {
      this.productOverlayPanel.hide();
    }
    setTimeout(() => {
      this.productOverlayPanel.show(event);
    }, 250);
  }

  onAcceptProviderTermsOfUse() {
    this.loadList();
    this.isTermsDone = true;
  }

  getDisplayProductText(request: Request): string {
    if (request.requestProducts) {
      if (request.requestProducts.length > 0) {
        return this.getDisplayProductList(request);
      }
    }
    if (request.requestItems) {
      if (request.requestItems.length > 0) {
        return this.getDisplayItemsList(request);
      }
    }
    if (request.productDescription) {
      return request.productDescription + ' (' + request.quantity + ' ' + request.measure + ')';
    } else {
      return '';
    }
  }

  requestIdFilterChanged(e: any){
    var value = e.target.value;

    if(this.requestIdFilterCurrentValue !== value)
    {
      this.requestIdFilterCurrentValue = value;
      this.loadList();
    }
  }

}
