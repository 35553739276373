import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { User } from 'src/app/models/User';
import { UtilsService } from 'src/app/services/core/utils.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DialogHelperService } from 'src/app/services/dialog-helper.service';
import { ProposalService } from 'src/app/services/proposal.service';
import { Proposal } from 'src/app/models/Proposal';
import { ChatService } from 'src/app/services/chat/chat.service';
import { AzureStorageBlobHelperService } from 'src/app/services/azure-storage-blob-helper.service';
import { ProposalAttachmentService } from 'src/app/services/proposalAttachment/proposal-attachment.service';
import { Company } from 'src/app/models/Company';
import { ActiveCompanyService } from 'src/app/services/active-company.service';
import * as moment from 'moment';
import { SelectedFile } from 'src/app/components/file-picker/file-picker.component';
import { ArrayHelper } from 'src/app/utils/array-helper';
import { ProposalrequestitemService } from 'src/app/services/proposal/proposalrequestitem.service';
import { ProposalshippingService } from 'src/app/services/proposal/shipping/proposalshipping.service';
import Swal from 'sweetalert2';
import { QisarService } from 'src/app/services/hubs/qisar.service';
import { ChatmessageService } from 'src/app/services/chat/chatmessage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SaleService } from 'src/app/services/sale/sale.service';
import { ShippingtypeService } from 'src/app/services/shippingtype/shippingtype.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ProposalRequestItem } from 'src/app/models/ProposalRequestItem';
import { PaymentPlanAcceptMonthService } from 'src/app/services/paymentplanacceptmonth/paymentplanacceptmonth.service';
import { PaymentPlanAcceptMonth } from 'src/app/models/PaymentPlanAcceptMonth';


@Component({
  selector: 'app-provider-request-detail-view',
  templateUrl: './provider-request-detail-view.component.html',
  styleUrls: ['./provider-request-detail-view.component.css']
})
export class ProviderRequestDetailViewComponent implements OnInit, OnDestroy {

  @Input() request: any;
  @Input() requestId: number;
  @Input() requestUserId: number;
  @Input() requestNoTaxes: boolean;
  @Input() isTermsDone = false;
  @Output() onacceptProviderTermsOfUse = new EventEmitter<void>();
  @Output() onsendProposal = new EventEmitter<void>();


  @ViewChild('nativeFileInputElement') nativeFileInputElement: ElementRef;
  @ViewChild('messageScrollDiv')
  messageScrollDiv: ElementRef;

  companyProposalList: Proposal[];
  companyPaymentPlanAcceptMonth: PaymentPlanAcceptMonth[];
  acceptedPlans = 0;
  companyProposals = 0;
  requestDateLimit: Date;
  chat: any;
  currentUser: User;
  messageList = [];
  messageIdMap: any = {};
  messageText: string;
  isSending = false;
  declineForm: FormGroup;
  proposalForm: FormGroup;
  proposalFormFileList: Array<File> = [];
  lastMessageDate: Date;
  isProposalSaving = false;
  totalProposalSaveSteps: number;
  completedProposalSaveSteps: number;
  selectedProposalAtachments: any;
  sale: any;
  itemsCost = 0;
  pt = {
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'eg', 'ter', 'qua', 'qui', 'sex', 'sab'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Feveiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
  };
  minDate = new Date();
  maxiDate: any;
  haveItems = false;
  proposalModal: Proposal;
  Id = 0;
  searchNewChats = false;
  messsageSending = false;
  shippingTypes = [];
  chargesByItem: boolean = false;

  constructor(
    private utils: UtilsService,
    private ngxSmartModalService: NgxSmartModalService,
    private formBuilder: FormBuilder,
    private dialogHelperService: DialogHelperService,
    private proposalService: ProposalService,
    private proposalRequestItemService: ProposalrequestitemService,
    private chatService: ChatService,
    private azureStorageBlobHelperService: AzureStorageBlobHelperService,
    private proposalAttachmentService: ProposalAttachmentService,
    private proposalshippingService: ProposalshippingService,
    private chatMessageService: ChatmessageService,
    private hub: QisarService,
    private activeCompanyService: ActiveCompanyService,
    private notificationService: NotificationService,
    private saleService: SaleService,
    private shippingTypeServices: ShippingtypeService,
    private paymentPlanAcceptMonthService: PaymentPlanAcceptMonthService
  ) { }

  /** se compra coletiva, recuperar valores */
  getPreProposalValue(id, items): any {
    const item = items.find(i => i.preRequestItemId === id);
    return item.quantity;
  }

  /** carregando a pagina */
  pageLoad() {
    this.shippingTypeServices.getall()
      .then((data: []) => this.shippingTypes = data);
    this.saleService.readSaleByRequest(this.requestId).then(() =>
      this.notificationService.loadSalesNotifications()
    );
    if (this.request.maxDeliveredDate) {
      this.maxiDate = new Date(this.request.maxDeliveredDate);
    }
    if (!this.requestNoTaxes) {
      this.requestNoTaxes = false;
    }
    if (this.request.requestItems) {
      if (this.request.requestItems.length > 1) {
        console.log(this.request.requestItems)
        this.haveItems = true;
      }
    }
    this.proposalForm = null;
    this.requestDateLimit = moment(this.request.awnserDate + ' ' + this.request.awnserHour, 'DD/MM/YYYY HH:mm:ss').toDate();
    this.utils.getUserFromSession().then(response => {
      this.currentUser = response;

      this.chatService.setReadCancelRequest(this.currentUser.userId, this.requestId)
        .then(() => {
          this.notificationService.onRequestCancelReload();
        });

      this.loadChat().then(() => {
        this.chatService.readRequestByRequest(this.chat.chatId)
          .then(() => {
            this.notificationService.onRequestReload();
          });
        if (this.currentUser.company.termsStatus === 3) {
          this.isTermsDone = true;
        } else {
          if (this.chat.trustCompany) {
            if (this.currentUser.company.termsStatus >= 1) {
              this.isTermsDone = true;
            } else {
              this.isTermsDone = false;
            }
          } else {
            if (this.currentUser.company.termsStatus >= 2) {
              this.isTermsDone = true;
            } else {
              this.isTermsDone = false;
            }
          }
        }

        this.buildDeclineForm();
        this.buildProposalForm();

        // marcar mensagens dessa requisição como lidas
        this.chatMessageService
          .SetMessagesRead(this.currentUser.userId, this.requestId)
          .then(() => this.notificationService.onChatReload());
        // buscar o chat

        this.loadMessageList(this.chat.chatMessages)
          .then(() => {
            let qtd = 0;
            const i = setInterval(() => {
              qtd++;
              if (qtd > 5) {
                clearInterval(i);
              }
              this.scrollMessageList();
            }, 800);
          });

      });

      this.searchNewChats = true;
      this.hub.onMessageRecieved.subscribe((message: any) => {
        if (this.searchNewChats) {
          if (message.requestId === this.requestId) {
            if (message.to === this.currentUser.userId) {
              if (!this.messageList.includes(message)) {
                this.messageList.push(message);
              }
            }
            let qtd = 0;
            const i = setInterval(() => {
              qtd++;
              if (qtd > 5) {
                clearInterval(i);
              }
              this.scrollMessageList();
            }, 800);

            this.chatMessageService
              .SetMessagesRead(this.currentUser.userId, this.requestId)
              .then(() => {
                this.notificationService.onChatReload();
              });
          }
        }
      });
    });


    this.hub.onChageRequestStatus.subscribe((requestId: any) => {
      if (this.requestId === requestId) {
        this.notificationService.onSalesReload();
        this.onsendProposal.emit();
      }
    });
  }

  private buildDeclineForm() {
    this.declineForm = this.formBuilder.group({
      declineReason: [null, Validators.compose([Validators.required])]
    });
  }

  private buildProposalForm() {
    this.proposalForm = this.formBuilder.group({
      conditions: [null, Validators.compose([Validators.required])],
      shipping_estimate: [null, Validators.compose([Validators.required])],
      shipping: [null, Validators.compose([Validators.required, Validators.min(0)])],
      shippingTypeId: [null, Validators.compose([Validators.required])],
      date: [null, Validators.compose([Validators.required])],


    });

    if (this.haveItems) {
      this.proposalForm.addControl('minPurchase',
        new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])))

      this.request.requestItems.forEach(item => {
        let itemFormName = `un_${item.requestItemId}`;

        this.proposalForm.addControl(itemFormName,
          new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])));

        this.proposalForm.addControl(item.requestItemId.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])));

        if (!this.requestNoTaxes) {
          let icmsFormName = `un_${item.requestItemId}_icms`;
          let icmsPercentFormName = `${icmsFormName}_percent`;
          let pisCofinsFormName = `un_${item.requestItemId}_piscofins`;
          let pisCofinsPercentFormName = `${pisCofinsFormName}_percent`;
          let ipiFormName = `un_${item.requestItemId}_ipi`;
          let ipiPercentFormName = `${ipiFormName}_percent`;
          let issFormName = `un_${item.requestItemId}_iss`;
          let issPercentFormName = `${issFormName}_percent`;
          let totalChargesFormName = `cost_${item.requestItemId}_with_charges`;

          this.proposalForm.addControl(icmsFormName,
            new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

          this.proposalForm.addControl(icmsPercentFormName,
            new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

          this.proposalForm.addControl(pisCofinsFormName,
            new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

          this.proposalForm.addControl(pisCofinsPercentFormName,
            new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

          this.proposalForm.addControl(ipiFormName,
            new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

          this.proposalForm.addControl(ipiPercentFormName,
            new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

          this.proposalForm.addControl(issFormName,
            new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

          this.proposalForm.addControl(issPercentFormName,
            new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

          this.proposalForm.addControl(totalChargesFormName,
            new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));



          this.setPercentChargesFormValueWatchers([icmsFormName, pisCofinsFormName, ipiFormName, issFormName], `${item.requestItemId}`, totalChargesFormName);
        }
      });
    }
    else {
      this.proposalForm.addControl('un_cost',
        new FormControl(null, Validators.compose([Validators.required, Validators.min(0.01)])));

      this.proposalForm.addControl('cost',
        new FormControl(null, Validators.compose([Validators.required, Validators.min(0.01)])));

      if (!this.requestNoTaxes) {
        this.proposalForm.addControl('iCMS',
          new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

        this.proposalForm.addControl('iCMS_percent',
          new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

        this.proposalForm.addControl('pisCofins',
          new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

        this.proposalForm.addControl('pisCofins_percent',
          new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

        this.proposalForm.addControl('iPI',
          new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

        this.proposalForm.addControl('iPI_percent',
          new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

        this.proposalForm.addControl('iSS',
          new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

        this.proposalForm.addControl('iSS_percent',
          new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])));

        this.proposalForm.addControl('cost_with_charges',
          new FormControl(null, Validators.compose([Validators.required, Validators.min(0.01)])));


        this.setPercentChargesFormValueWatchers(['iCMS', 'pisCofins', 'iPI', 'iSS'], 'cost', 'cost_with_charges');
      }
    }

    if (this.request.preRequest) {
      this.request.preRequest.preProposals.forEach(item => {
        this.proposalForm.addControl('shipping_' + item.preProposalId.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])));
      });
    }
  }

  private setPercentChargesFormValueWatchers(chargeControlNames: string[], valueControlName: string, totalControlName: string): void {
    let chargeControls = chargeControlNames.map(x => this.proposalForm.get(`${x}_percent`));
    let valueControl = this.proposalForm.get(valueControlName);
    let totalControl = this.proposalForm.get(totalControlName);

    valueControl.valueChanges
      .subscribe(x => this.caculateItemChargesByPercent(chargeControlNames, valueControl, totalControl));

    chargeControls.forEach(x => {
      x.valueChanges
        .subscribe(x => this.caculateItemChargesByPercent(chargeControlNames, valueControl, totalControl));
    });
  }

  /** inicia a pagina */
  ngOnInit() {
    this.pageLoad();



  }

  /** sai da pagina */
  ngOnDestroy(): void {
    this.searchNewChats = false;
  }

  /** formulario de proposta preenchido */
  isInvalidProposalForm(): boolean {
    let cost = this.haveItems ? this.itemsCost : this.proposalForm.value.cost;
    cost = Number(cost);
    if (cost === 0) {
      return true;
    } else {
      return !this.proposalForm.valid;
    }
  }

  /** busca o chat */
  loadChat(): Promise<void> {
    this.utils.running();
    return new Promise((resolve) => {
      this.chatService.findByRequest(this.requestId).then(response => {
        this.chat = response[0];
        if (this.chat) {
          for (const proposal of this.chat.proposalHistories) {
            if (proposal.proposal) {
              if (proposal.proposal.sales.length > 0) {
                this.sale = proposal.proposal.sales[0];
              }
            }
          }
        }
        this.utils.finish();
        resolve();
      });
    });
  }

  /** Envio de mensagem do chat */
  sendMessage() {
    if (this.messageText && this.messageText !== '' && !this.messsageSending) {
      this.messsageSending = true;
      const chatMessage = {
        chatId: this.chat.chatId,
        from: this.currentUser.userId,
        to: this.requestUserId,
        fromName: this.currentUser.name,
        message: this.messageText,
        requestId: this.requestId,
        date: new Date()
      };
      this.hub.setChatMessage(chatMessage);
      this.chatMessageService
        .create(chatMessage)
        .then(() => {
          this.messsageSending = false;
          this.messageList.push(chatMessage);
          this.scrollMessageList();
        });
      this.messageText = '';
    }
  }

  /** carregar lista de mensagens */
  loadMessageList(messages: any = []): Promise<void> {
    return new Promise((resolve) => {
      if (messages) {
        this.orderByMessages(messages)
          .then((newList) => {
            this.messageList = newList;
            resolve();
          });
      }
    });
  }

  /** ordena lista de mensagem por data */
  orderByMessages(list: any): Promise<any> {
    return new Promise((resolve) => {
      const newList = list.sort((a: any, b: any) => {
        return +new Date(a.date) - +new Date(b.date);
      });
      resolve(newList);
    });
  }

  /** scroll do campo de mensagens do chat */
  scrollMessageList() {
    if (this.messageScrollDiv) {
      this.messageScrollDiv.nativeElement.scrollTop = this.messageScrollDiv.nativeElement.scrollHeight;
    }
  }

  /** abre o formulario de Declinar da Requisição */
  openDeclineModal() {
    const modalName = 'provider-request-detail-view-decline_' + this.requestId;
    this.declineForm.reset();
    this.ngxSmartModalService.getModal(modalName).open();
  }

  /** abre o formulario para enviar proposta */
  async openProposalModal() {
    // this.proposalForm.reset();
    const proposalCreation = await this.proposalQtyValidation();
    if (proposalCreation == true) {
      this.proposalService.LastProposalFromRequestAndCompany(this.requestId, this.currentUser.companyId)
        .then((data: any) => {
          if (data) {
            const formdate = new Date(data.date);
            if (!this.haveItems) {
              this.proposalForm.controls.cost.setValue(data.cost);
            } else {
              if (data.proposalRequestItems) {
                data.proposalRequestItems.forEach(item => {
                  let itemm = item as ProposalRequestItem;

                  let icmsFormName = `un_${item.requestItemId}_icms`;
                  let icmsPercentFormName = `${icmsFormName}_percent`;
                  let pisCofinsFormName = `un_${item.requestItemId}_piscofins`;
                  let pisCofinsPercentFormName = `${pisCofinsFormName}_percent`;
                  let ipiFormName = `un_${item.requestItemId}_ipi`;
                  let ipiPercentFormName = `${ipiFormName}_percent`;
                  let issFormName = `un_${item.requestItemId}_iss`;
                  let issPercentFormName = `${issFormName}_percent`;
                  let totalChargesFormName = `cost_${item.requestItemId}_with_charges`;


                  if (item.cost > 0) {
                    this.proposalForm.controls['un_' + item.requestItemId.toString()].setValue(item.cost / item.requestItem.quantity);
                  } else {
                    this.proposalForm.controls['un_' + item.requestItemId.toString()].setValue(0);
                  }

                  if (!this.requestNoTaxes && this.haveItems) {
                    this.proposalForm.controls[item.requestItemId.toString()].setValue(item.cost);

                    //this.proposalForm.controls[icmsFormName].setValue(item.iCMS);
                    this.proposalForm.controls[icmsPercentFormName].setValue(item.icmsPercent);

                    //this.proposalForm.controls[pisCofinsFormName].setValue(item.pisCofins);
                    this.proposalForm.controls[pisCofinsPercentFormName].setValue(item.pisCofinsPercent);

                    //this.proposalForm.controls[ipiFormName].setValue(item.iPI);
                    this.proposalForm.controls[ipiPercentFormName].setValue(item.ipiPercent);

                    //this.proposalForm.controls[issFormName].setValue(item.iSS);
                    this.proposalForm.controls[issPercentFormName].setValue(item.issPercent);

                    this.caculateItemChargesByPercent(
                      [icmsFormName, pisCofinsFormName, ipiFormName, issFormName],
                      this.proposalForm.get(`${item.requestItemId}`),
                      this.proposalForm.get(totalChargesFormName));
                  }
                });
              }
            }
            this.proposalForm.controls.date.setValue(formdate);
            this.proposalForm.controls.conditions.setValue(data.conditions);
            if (!this.requestNoTaxes && !this.haveItems) {
              this.proposalForm.controls.iCMS.setValue(data.icms);
              this.proposalForm.controls.iPI.setValue(data.ipi);
              this.proposalForm.controls.iSS.setValue(data.iss);
              this.proposalForm.controls.pisCofins.setValue(data.pisCofins);
            }
            this.proposalForm.controls.shipping_estimate.setValue(data.shippingEstimate);
            this.proposalForm.controls.shipping.setValue(data.shipping);
          }
        });
      this.proposalForm.patchValue({
        paymentMethod: 'in-cash'
      });
      const modalName = 'provider-request-detail-view-proposal_' + this.requestId;
      this.ngxSmartModalService.getModal(modalName).open();
    }
    else {
      const modalName = 'provider-request-detail-view-acceptPlan-Level1';
      this.ngxSmartModalService.getModal(modalName).open();
    }
  }

  /** validar quantidade de requisições feitas x planos aceitos */
  async proposalQtyValidation() {
    this.companyProposalList = await this.proposalService.getMonthItensByCompanyId();

    this.companyPaymentPlanAcceptMonth = await this.paymentPlanAcceptMonthService.getByCompanyid();

    let limit = 0;

    if (this.companyPaymentPlanAcceptMonth.length) {
      this.acceptedPlans = Number(this.companyPaymentPlanAcceptMonth.length);
      limit = this.companyPaymentPlanAcceptMonth[0].limit;
    }
    if (this.companyProposalList.length) {
      this.companyProposals = Number(this.companyProposalList.length);
    }

    if (this.companyProposals < limit || this.companyProposals == 0) {
      return true;
    }

    return false;
  }

  /** aceitar os termos */
  continueModalAccept() {

    let limit = 0;
    switch (this.acceptedPlans) {
      case 0:
        limit = 5;
        break;
      case 1:
        limit = 10;
        break;
      case 2:
        limit = 999999;
        break;
      default:
        limit = 1;
        break;
    }

    const newPaymentPlanAcceptMonth: PaymentPlanAcceptMonth = {
      companyId: this.currentUser.companyId,
      userId: this.currentUser.userId,
      limit: limit,
      paymentPlanAcceptMonthId: 0,
      userip: '',
      createdAt: new Date(),
      user: ''
    }

    this.paymentPlanAcceptMonthService.save(newPaymentPlanAcceptMonth).then(() => {
      const modalName = 'provider-request-detail-view-acceptPlan-Level1';
      this.ngxSmartModalService.getModal(modalName).close();
      this.openProposalModal();
    })
      .catch(() => Swal.fire('Qisar', 'Erro ao aceitar os termos', 'error'));
  }

  /** somar valores por itens */
  sumItemValue(requestItemId: any, quantity: any) {
    const value = this.proposalForm.controls['un_' + requestItemId.toString()].value;
    if (value === '') {
      this.proposalForm.controls[requestItemId.toString()].setValue(0.001);
    } else {
      if (isNaN(value)) {
        this.proposalForm.controls[requestItemId.toString()].setValue(0.001);
      } else {
        if (value > 0) {
          const sum = Number(value) * quantity;
          this.proposalForm.controls[requestItemId.toString()].setValue(sum);
        } else {
          this.proposalForm.controls[requestItemId.toString()].setValue(0.001);
        }
      }
    }
  }

  sumValue() {
    const value = this.proposalForm.value.un_cost;
    if (value === '') {
      this.proposalForm.controls.cost.setValue(0.001);
    } else {
      if (isNaN(value)) {
        this.proposalForm.controls.cost.setValue(0.001);
      } else {
        if (value > 0) {
          const sum = Number(value) * this.request.quantity;
          this.proposalForm.controls.cost.setValue(sum);
        } else {
          this.proposalForm.controls.cost.setValue(0.001);
        }
      }
    }
  }

  /** retorna id da requisição */
  getId() {
    return this.requestId;
  }

  /** declina a requisição */
  decline() {
    const modalName = 'provider-request-detail-view-decline_' + this.requestId;
    if (this.declineForm.valid) {
      this.dialogHelperService.askConfirmation('Você realmente quer declinar essa requisição? Essa ação não pode ser desfeita.')
        .then(confirmation => {
          if (confirmation) {
            this.chat.cancelReasor = this.declineForm.value.declineReason;
            this.chatService.requestorDecline(this.chat).then(() => {
              this.dialogHelperService.showSuccessNotification('A requisição foi declinada com sucesso.');
              this.ngxSmartModalService.getModal(modalName).close();
              this.hub.informeSalerCancelRequest();
            });
          }
        });
    } else {
      const formKeyList: Array<string> = Object.keys(this.declineForm.controls);
      for (const key of formKeyList) {
        this.declineForm.controls[key].markAsTouched();
      }
    }
  }

  /** ordena itens da requisição */
  sortData(Items: any): any {
    return Items.sort((a, b) => {
      return (new Date(b.proposalHistoryId) as any) - (new Date(a.proposalHistoryId) as any);
    });
  }

  /** enviar nova proposta */
  saveProposal() {
    const modalName = 'provider-request-detail-view-proposal_' + this.requestId;



    const cost = this.haveItems ? this.itemsCost : this.proposalForm.value.cost;


    if (this.proposalForm.valid) {
      this.dialogHelperService.askConfirmation('Você quer mesmo enviar essa proposta?')
        .then(confirmation => {
          if (confirmation) {
            this.utils.running();
            this.ngxSmartModalService.getModal(modalName).close();
            const proposal = new Proposal();
            proposal.proposalId = 0;
            proposal.companyId = this.request.companyId;
            proposal.requestId = this.requestId;
            proposal.chatId = this.chat.chatId;
            proposal.cost = cost;
            proposal.date = this.proposalForm.value.date;

            //Se não for pedido com mais de um item
            if ((this.request.requestItems || 0).length === 0) {
              proposal.icms = this.requestNoTaxes ? 0 : this.proposalForm.value.iCMS;
              proposal.icmsPercent = this.requestNoTaxes ? 0 : this.proposalForm.value.iCMS_percent;
              proposal.ipi = this.requestNoTaxes ? 0 : this.proposalForm.value.iPI;
              proposal.ipiPercent = this.requestNoTaxes ? 0 : this.proposalForm.value.iPI_percent;
              proposal.iss = this.requestNoTaxes ? 0 : this.proposalForm.value.iSS;
              proposal.issPercent = this.requestNoTaxes ? 0 : this.proposalForm.value.iSS_percent;
              proposal.pisCofins = this.requestNoTaxes ? 0 : this.proposalForm.value.pisCofins;
              proposal.pisCofinsPercent = this.requestNoTaxes ? 0 : this.proposalForm.value.pisCofins_percent;


            }
            else if (this.request.requestItems.length > 0) {
              proposal.icms = this.request.requestItems
                .map(item => this.proposalForm.value[`un_${item.requestItemId}_icms`] || 0)
                .reduce((a, b) => a + b);
              proposal.icmsPercent = 0;
              proposal.ipi = this.request.requestItems
                .map(item => this.proposalForm.value[`un_${item.requestItemId}_ipi`] || 0)
                .reduce((a, b) => a + b);
              proposal.ipiPercent = 0;
              proposal.iss = this.request.requestItems
                .map(item => this.proposalForm.value[`un_${item.requestItemId}_iss`] || 0)
                .reduce((a, b) => a + b);
              proposal.issPercent = 0;
              proposal.pisCofins = this.request.requestItems
                .map(item => this.proposalForm.value[`un_${item.requestItemId}_piscofins`] || 0)
                .reduce((a, b) => a + b);
              proposal.pisCofinsPercent = 0;
            }

            proposal.shipping = this.proposalForm.value.shipping;
            proposal.conditions = this.proposalForm.value.conditions;
            proposal.shippingTypeId = this.proposalForm.value.shippingTypeId;
            proposal.shippingEstimate = this.proposalForm.value.shipping_estimate;
            proposal.minPurchase = this.haveItems ? this.proposalForm.value.minPurchase : 0;

            if (this.activeCompanyService.activeCompanyId) {
              proposal.companyId = this.activeCompanyService.activeCompanyId;
            }
            this.proposalService.save(proposal)
              .then((response: any) => {
                const proposalId = response.proposalId;
                proposal.proposalId = response.proposalId;
                const itemListAddPromiseList: Array<Promise<any>> = [];
                if (this.request.requestItems && this.haveItems) {
                  console.log("nao vai entrar")
                  if (this.request.requestItems.length > 0) {
                    for (const item of this.request.requestItems) {
                      itemListAddPromiseList.push(
                        new Promise((resolve, reject) => {
                          let proposalRequestItem = new ProposalRequestItem();

                          proposalRequestItem.proposalRequestItemId = 0;
                          proposalRequestItem.proposalId = proposalId;
                          proposalRequestItem.requestItemId = item.requestItemId;
                          proposalRequestItem.cost = this.proposalForm.value[item.requestItemId];
                          proposalRequestItem.icms = this.proposalForm.value[`un_${item.requestItemId}_icms`] || 0;
                          proposalRequestItem.icmsPercent = this.proposalForm.value[`un_${item.requestItemId}_icms_percent`] || 0;
                          proposalRequestItem.pisCofins = this.proposalForm.value[`un_${item.requestItemId}_piscofins`] || 0;
                          proposalRequestItem.pisCofinsPercent = this.proposalForm.value[`un_${item.requestItemId}_piscofins_percent`] || 0;
                          proposalRequestItem.ipi = this.proposalForm.value[`un_${item.requestItemId}_ipi`] || 0;
                          proposalRequestItem.ipiPercent = this.proposalForm.value[`un_${item.requestItemId}_ipi_percent`] || 0;
                          proposalRequestItem.iss = this.proposalForm.value[`un_${item.requestItemId}_iss`] || 0;
                          proposalRequestItem.issPercent = this.proposalForm.value[`un_${item.requestItemId}_iss_percent`] || 0;
                          proposalRequestItem.bestPrice = false;
                          proposalRequestItem.requestItem = null;
                          proposalRequestItem.proposal = null;

                          this.proposalRequestItemService.save(proposalRequestItem)
                            .then((savedItem: any) => {
                              resolve(null);
                            });
                        })
                      );
                    }
                  }
                }
                else {
                  console.log("entrou no else")
                  let proposalRequestItem: ProposalRequestItem = new ProposalRequestItem();
                  proposalRequestItem.proposalRequestItemId = 0;
                  proposalRequestItem.requestItemId = this.request.requestItems[0].requestItemId;
                  proposalRequestItem.proposalId = response.proposalId;
                  proposalRequestItem.icms = this.proposalForm.value.iCMS;
                  proposalRequestItem.icmsPercent = this.proposalForm.value.iCMS_percent;
                  proposalRequestItem.ipi = this.proposalForm.value.iPI;
                  proposalRequestItem.ipiPercent = this.proposalForm.value.iPI_percent;
                  proposalRequestItem.iss = this.proposalForm.value.iSS;
                  proposalRequestItem.issPercent = this.proposalForm.value.iSS_percent;
                  proposalRequestItem.pisCofins = this.proposalForm.value.pisCofins;
                  proposalRequestItem.pisCofinsPercent = this.proposalForm.value.pisCofins_percent;
                  proposalRequestItem.bestPrice = false;
                  proposalRequestItem.cost = cost;
                  proposalRequestItem.requestItem = null;
                  proposalRequestItem.proposal = null;
                  this.proposalRequestItemService.save(proposalRequestItem)
                    .then((savedItem: any) => {
                    });

                }
                if (this.request.preRequest) {
                  this.request.preRequest.preProposals.forEach(item => {
                    const value = Number(this.proposalForm.value['shipping_' + item.preProposalId]);
                    const data = {
                      proposalId,
                      cost: value
                    };
                    this.proposalshippingService.save(data);
                  });
                }
                const fileListAddPromiseList: Array<Promise<any>> = [];
                this.totalProposalSaveSteps = this.proposalFormFileList.length * 2;
                for (const file of this.proposalFormFileList) {
                  fileListAddPromiseList.push(
                    new Promise((resolve, reject) => {
                      this.azureStorageBlobHelperService.uploadFile(file, 'requestfiles').then(fileUrl => {
                        this.completedProposalSaveSteps += 1;
                        this.proposalAttachmentService.save({
                          proposalId,
                          url: fileUrl
                        }).then(() => {
                          this.completedProposalSaveSteps += 1;
                          this.isProposalSaving = false;
                          resolve(null);
                        });
                      });
                    })
                  );
                }
                Promise.all(itemListAddPromiseList).then(() => {
                  this.proposalRequestItemService.setBestPricesByRequestId(this.requestId);
                  Promise.all(fileListAddPromiseList).then(() => {
                    if (response.statusId === 3) {
                      Swal.fire('Qisar', 'Parabéns, sua proposta foi aceita automáticamente pelo sistema', 'info')
                        .then(() => {
                          this.onsendProposal.emit();
                        });
                    } else {
                      this.loadChat();
                      this.dialogHelperService.showSuccessNotification('A proposta foi enviada com sucesso.');
                      this.hub.informeSendNewProposal(this.request.userId, this.requestId);
                    }
                    this.utils.finish();

                  });
                });
              });
          }
        });
    } else {
      const formKeyList: Array<string> = Object.keys(this.proposalForm.controls);
      for (const key of formKeyList) {
        this.proposalForm.controls[key].markAsTouched();
      }
    }
  }

  private getProposalItemsCost() {
    let cost = this.proposalForm.value.cost;
    if (!cost) {
      cost = 0;
    }
    cost = Number(cost);
    if (this.haveItems) {
      cost = 0;
      this.itemsCost = 0;
      this.request.requestItems.forEach(item => {
        let itemValue = this.proposalForm.value[item.requestItemId];
        if (!itemValue) {
          itemValue = 0;
        }
        itemValue = Number(itemValue);
        cost += itemValue;
        this.itemsCost += itemValue;
      });
    }

    return cost;
  }

  private caculateItemChargesByPercent(chargeControlNames: string[], valueControl: AbstractControl, totalControl: AbstractControl): void {
    let total: number = 0;
    let itemValue = Number(valueControl.value || 0);

    chargeControlNames
      .forEach(fieldName => {
        let field = this.proposalForm.get(fieldName);
        let percentField = this.proposalForm.get(`${fieldName}_percent`);
        let percentValue = Number(percentField.value || 0);
        let value = itemValue * (percentValue / 100);

        field.setValue(value);
        total += value;
      });

    totalControl.setValue(itemValue + (total === 0 ? 0.001 : total));
  }

  /** calcula o valor dos itens da proposta */
  getProposalFormFinalCost(): number {
    let cost = this.getProposalItemsCost();

    let shipping = this.proposalForm.value.shipping;

    if (!shipping) {
      shipping = 0;
    }

    shipping = Number(shipping);

    if (this.request.preRequest) {
      if (this.request.preRequest.preProposals) {
        this.request.preRequest.preProposals.forEach(item => {
          if (this.proposalForm.value['shipping_' + item.preProposalId]) {
            const value = Number(this.proposalForm.value['shipping_' + item.preProposalId]);
            shipping += value;
          }
        });
      }
    }

    shipping = Number(shipping);

    if (!this.requestNoTaxes) {
      let icmsItemsTotal = 0, pisCofinsItemsTotal = 0, issItemsTotal = 0, ipiItemsTotal = 0;

      if ((this.request.requestItems || 0).length > 0) {
        icmsItemsTotal = this.request.requestItems
          .map(item => this.proposalForm.value[`un_${item.requestItemId}_icms`] || 0)
          .reduce((a, b) => a + b);

        ipiItemsTotal = this.request.requestItems
          .map(item => this.proposalForm.value[`un_${item.requestItemId}_ipi`] || 0)
          .reduce((a, b) => a + b);

        issItemsTotal = this.request.requestItems
          .map(item => this.proposalForm.value[`un_${item.requestItemId}_iss`] || 0)
          .reduce((a, b) => a + b);

        pisCofinsItemsTotal = this.request.requestItems
          .map(item => this.proposalForm.value[`un_${item.requestItemId}_piscofins`] || 0)
          .reduce((a, b) => a + b);
      }

      let iCMS = this.proposalForm.value.iCMS;
      if (!iCMS) {
        iCMS = 0;
      }
      iCMS = Number(iCMS + icmsItemsTotal);
      let pisCofins: number = this.proposalForm.value.pisCofins;
      if (!pisCofins) {
        pisCofins = 0;
      }
      pisCofins = Number(pisCofins + pisCofinsItemsTotal);

      let iPI: number = this.proposalForm.value.iPI;
      if (!iPI) {
        iPI = 0;
      }
      iPI = Number(iPI + ipiItemsTotal);
      let iSS: number = this.proposalForm.value.iSS;
      if (!iSS) {
        iSS = 0;
      }
      iSS = Number(iSS + issItemsTotal);
      return (cost + shipping + iCMS + pisCofins + iPI + iSS);
    }

    return (cost + shipping);
  }

  /** carrega os anexos da proposta */
  onProposalFormFileListChange(selectedFileList: Array<SelectedFile>) {
    const fileList = ArrayHelper.getPropertyList(selectedFileList, 'file');
    this.proposalFormFileList = fileList;
  }

  /** abre modal de encio de anexo */
  openProposalAtachmentsModal(proposal: any) {
    const modalName = 'provider-request-detail-view-proposal_' + this.requestId + '-atachments';
    this.selectedProposalAtachments = proposal;
    this.ngxSmartModalService.getModal(modalName).open();
  }

  /** marca termos de uso */
  checkTermsOfUse(): boolean {
    if (!this.chat) {
      return false;
    }
    if (!this.currentUser) {
      return false;
    }
    if (this.chat.trustCompany) {
      return true;
    }
    if (this.currentUser.company.termsStatus > 0) {
      return true;
    }
    return false;
  }

  /** quando aceita os termos */
  onAcceptTermsOfUse(company: Company) {
    this.currentUser.company = company;
    this.isTermsDone = true;
    this.onacceptProviderTermsOfUse.emit();
  }

  /** valor da data de hj */
  getNow(): Date {
    return new Date();
  }

  /** css do item da proposta */
  getClassProposalItem(proposal: any, itemId: number): string {
    if (!proposal.proposalRequestItems) {
      return '';
    }
    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);
    if (!item) {
      return '';
    }
    if (item.bestPrice === true) {
      return 'bestPrice';
    }
    return '';
  }

  /** valor do item da proposta */
  getCostProposalItem(proposal: any, itemId: number): string {
    if (!proposal.proposalRequestItems) {
      return '';
    }
    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);
    if (!item) {
      return '';
    }
    return item.cost.toString();
  }

  getProposalItem(proposal: any, itemId: number): any {
    if (!proposal.proposalRequestItems)
      return {};

    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);

    if (!item)
      return {};

    return item;
  }

  /** unidade do item da proposta */
  getCostProposalItemUnity(proposal: any, quantity: any, itemId: number): string {
    if (!proposal.proposalRequestItems) {
      return '';
    }
    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);
    if (!item) {
      return '';
    }
    const itemUnity = item.cost / quantity;
    return itemUnity.toString();
  }

  /** detalhes do item da proposta */
  openModalProposalItemsDetails(proposal: Proposal) {
    const modalName = 'provider-proposal-items-details_' + this.requestId;
    this.utils.running();
    this.proposalModal = proposal;
    this.ngxSmartModalService.getModal(modalName).open();
    this.utils.finish();
  }

  /** css da proposta */
  getClassProposal(proposal: any, itemId: number): string {
    if (!proposal.proposalRequestItems) {
      return '';
    }
    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);
    if (!item) {
      return '';
    }
    if (item.cost === 0) {
      return 'noPrice';
    }
    return '';
  }

 
}
