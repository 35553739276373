import { Injectable } from "@angular/core";
import { ImportFileApi } from 'src/app/core/http/entidades/ImportFileApi';
import { ImportFile } from 'src/app/models/ImporFile';


@Injectable({
  providedIn: "root",
})
export class ImportFileService {
  constructor(private api: ImportFileApi) { 
    this.api.baseName = 'importfile';
  }

  /**
   * Grava o arquivo no disco
   * @param importFile 
   */
  save(importFile: ImportFile): any {
    return new Promise((resolve, reject) => {
      this.api
        .post(importFile)
        .then((data: any) => resolve(data))
        .catch((error: any) => reject(error));
    });
  }

  get(): any {
    return new Promise((resolve, reject) => {
      this.api
        .get()
        .then((data: any) => resolve(data))
        .catch((error: any) => reject(error));
    });

  }
}