import { Component, ViewChild, ElementRef, Renderer, Input, Output, EventEmitter } from '@angular/core';
import { FileHelper } from 'src/app/utils/file-helper';
import { DialogHelperService } from 'src/app/services/dialog-helper.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AzureBlobUploaderService } from 'src/app/services/azure-blob-uploader.service';
import { ObjectMap } from 'src/app/utils/object-map';
import * as UUID from 'uuid-js';
export interface SelectedFile {
  file: File;
  id?: string;
}
@Component({
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.css']
})
export class FilePickerComponent {

  @ViewChild('nativeFileInputElement') nativeFileInputElement: ElementRef;

  @Output() onchange: EventEmitter<Array<SelectedFile>> = new EventEmitter();

  @Input() autoAzureBlobUpload = false;
  @Input() showInline = true;

  @Input() maxFileSize: number;
  @Input() validExtensions: Array<string>;
  @Input() limit = 1;

  selectedFileIdList: Array<string> = [];
  selectedFileMap: ObjectMap<SelectedFile> = new ObjectMap('id');

  selectedFileListModalId = 'file-picker-selected-file-list';

  constructor(
    // tslint:disable-next-line: deprecation
    private renderer: Renderer,
    private dialogHelperService: DialogHelperService,
    public ngxSmartModalService: NgxSmartModalService,
    public azureBlobUploaderService: AzureBlobUploaderService,
  ) { }

  askFiles() {
    const event = new MouseEvent('click', { bubbles: true });
    this.renderer.invokeElementMethod(this.nativeFileInputElement.nativeElement, 'dispatchEvent', [event]);
  }

  onInputChange(event) {
    const inputFileList: Array<File> = FileHelper.getFileListFromInputEvent(event);
    if (this.selectedFileIdList.length + inputFileList.length > this.limit) {
      this.dialogHelperService
        .showErrorAlert('O limite de arquivos, que é ' +
          this.limit +
          ', já foi atingido. Remova algum dos arquivos para adicionar outro.', 'Limite de arquivos atingido.');
      return false;
    }
    for (const inputFile of inputFileList) {
      this.addFile(inputFile);
    }
    this.emitOnChangeEvent();
  }

  removeFile(id: string) {
    const index: number = this.selectedFileIdList.indexOf(id);
    this.selectedFileIdList.splice(index, 1);
    this.emitOnChangeEvent();
  }

  private addFile(file: File) {
    let id: string = UUID.create().toString();
    if (this.autoAzureBlobUpload) {
      id = this.azureBlobUploaderService.uploadFile(file);
    }
    this.selectedFileMap.add({
      file,
      id,
    });
    this.selectedFileIdList.push(id);
  }

  private emitOnChangeEvent() {
    const selectedFileList: Array<SelectedFile> = [];
    for (const selectedFileId of this.selectedFileIdList) {
      selectedFileList.push(this.selectedFileMap.get(selectedFileId));
    }
    this.onchange.emit(selectedFileList);
  }

  getValidExtensionsDisplay() {
    let text = '';
    let index = 0;
    this.validExtensions.forEach(extension => {
      text += extension;
      index++;
      if ((index + 2) < this.validExtensions.length) {
        text += ', ';
      } else {
        if ((index + 2) === this.validExtensions.length) {
          text += ' e ';
        }
      }
    });


    if (text === '') {
      return 'nenhuma.';
    }
    return text;
  }

  openFileListModal(): void {
    this.ngxSmartModalService.getModal(this.selectedFileListModalId).open();
  }
}
