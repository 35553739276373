import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
//import { ChartsModule } from 'ng2-charts';
import { HighchartsChartModule } from 'highcharts-angular';
import { LocationStrategy, PathLocationStrategy, registerLocaleData, DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultThemeComponent } from './@layout/themes/default-theme/default-theme.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { LostPasswordComponent } from './pages/auth/lost-password/lost-password.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { UserProfileComponent } from './pages/user/user-profile/user-profile.component';
import { RequestComponent } from './pages/request/request.component';
import { RetailComponent } from './pages/retail/retail.component';
import { CompanyInvoicesComponent } from './pages/company/invoices/invoices.component';
import { CompanyExtractComponent } from './pages/company/extract/extract.component';
import { AllInvoicesComponent } from './pages/financial/invoices/invoices.component';
import { UserRegisterComponent } from './pages/user/user-register/user-register.component';
import { CompanyRegisterComponent } from './pages/company/company-register/company-register.component';
import { SegmentRegisterComponent } from './pages/segment/segment-register/segment-register.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { UsetermsComponent } from './pages/useterms/useterms.component';
import { NgxMaskModule } from 'ngx-mask-2';
import { NewrequestComponent } from './pages/request/newrequest/newrequest.component';
import { CustomerRequestAddPageComponent } from './pages/customer-request-add-page/customer-request-add-page.component';
import { CustomerRequestListPageComponent } from './pages/customer-request-list-page/customer-request-list-page.component';
import { CustomerRequestDetailViewComponent } from './views/customer-request-detail-view/customer-request-detail-view.component';
import { ConfigurationSystemComponent } from './pages/configuration/system/configuration-system.component';
import { ConfigurationPaymentComponent } from './pages/configuration/payment/configuration-payment.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { AutoCompleteModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StepsModule } from 'primeng/steps';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { TabViewModule } from 'primeng/tabview';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputMaskModule } from 'primeng/inputmask';
import { AccordionModule } from 'primeng/accordion';
import { CardModule } from 'primeng/card';
import { EditorModule } from 'primeng/editor';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormControlErrorsComponent } from './components/form-control-errors/form-control-errors.component';
import { FilePickerComponent } from './components/file-picker/file-picker.component';
import { AddressTextComponent } from './components/address-text/address-text.component';
import { ProcessingComponent } from './components/processing/processing.component';
import { AzureBlobUrlPipe } from './pipes/azure-blob-url.pipe';
import { BlobModule, BlobService } from 'angular-azure-blob-service';
import { ProviderRequestListPageComponent } from './pages/provider-request-list-page/provider-request-list-page.component';
import { ProviderRequestDetailViewComponent } from './views/provider-request-detail-view/provider-request-detail-view.component';
import { UsersneverloginComponent } from './pages/reports/usersneverlogin/usersneverlogin.component';
import { environment } from 'src/environments/environment';
import { CustomerFirstAddPageComponent } from './pages/customer-first-add-page/customer-first-add-page.component';
import ptBr from '@angular/common/locales/pt';
import { TermsOfUseViewComponent } from './views/terms-of-use-view/terms-of-use-view.component';
import { ImagePickerAndCropperComponent } from './components/image-picker-and-cropper/image-picker-and-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RequestPageComponent } from './pages/request-page/request-page.component';
import { NotificationListViewComponent } from './views/notification-list-view/notification-list-view.component';
import { ReportbysegmentsComponent } from './pages/company/reportbysegments/reportbysegments.component';
import { CustomerProposalListPageComponent } from './pages/customer-proposal-list-page/customer-proposal-list-page.component';
import { InvoiceDetailComponent } from './pages/company/invoices/detail/invoicedetail.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ShippingFilesImportComponent } from './pages/financial/shippingfiles/import/shippingfiles-import.component';
import { ShippingFilesExportComponent } from './pages/financial/shippingfiles/export/shippingfiles-export.component';
import { MailListViewComponent } from './views/mail-list-view/mail-list-view.component';
import { SummitComponent } from './pages/summit/summit.component';
import { StarRatingModule } from 'angular-star-rating';
import { PushNotificationsModule } from 'ng-push';
import { QisarcurrencymaskDirective } from './qisarcurrencymask.directive';
import { CompanyAddComponent } from './pages/company/company-add/company-add.component';
import { RequestReportComponent } from './pages/report/request-report/request-report.component';
import { CustomerPrerequestListPageComponent } from './pages/customer-prerequest-list-page/customer-prerequest-list-page.component';
import { CustomerPrerequestAddPageComponent } from './pages/customer-prerequest-add-page/customer-prerequest-add-page.component';
import { CustomerPrerequestDatailViewComponent } from './views/customer-prerequest-datail-view/customer-prerequest-datail-view.component';
import { DashBoardProviderComponent } from './pages/dash-board-provider/dash-board-provider.component';
import { DashBoardProviderPerformaceComponent } from './pages/dash-board-provider-performace/dash-board-provider-performace.component';
import { DashBoardCustomerPerformaceComponent } from './pages/dash-board-customer-performace/dash-board-customer-performace.component';
import { FlexformActiveComponent } from './pages/reports/flexform-active/flexform-active.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { UsersActiveComponent } from './pages/users-active/users-active.component';
import { LatePaymentsComponent } from './pages/late-payments/late-payments.component';
import { PlazaActiveComponent } from './pages/reports/plaza-active/plaza-active.component';
import { PlansComponent } from './pages/reports/company/plans/plans.component';
import { TermsComponent } from './pages/legal/terms/terms.component';
import { ShippingtypeComponent } from './pages/shippingtype/shippingtype.component';
import { PartnercusrtomersComponent } from './pages/partners/partnercusrtomers/partnercusrtomers.component';
import { BasicplanComponent } from './pages/company/commissions/basicplan/basicplan.component';
import { NotrustcommissionComponent } from './pages/company/commissions/notrustcommission/notrustcommission.component';
import { OfferlistComponent } from './pages/offers/offerlist/offerlist.component';
import { OffernewComponent } from './pages/offers/offernew/offernew.component';
import { OfferdetailsComponent } from './pages/offers/offerdetails/offerdetails.component';
import { OfferbuyComponent } from './pages/offers/offerbuy/offerbuy.component';
import { OffermybuiesComponent } from './pages/offers/offermybuies/offermybuies.component';
import { OffermysalersComponent } from './pages/offers/offermysalers/offermysalers.component';
import { OffernegotiationComponent } from './pages/offers/offernegotiation/offernegotiation.component';
import { CarouselModule } from 'primeng/carousel';
import { OffereditComponent } from './pages/offers/offeredit/offeredit.component';
import { CustomerGeralComponent } from './pages/reports/company/customer-geral/customer-geral.component';
import { CustomerTrustSalersComponent } from './pages/reports/company/customer-trustsalers/customer-trustsalers.component';
import { Igaratiba } from './pages/reports/igaratiba/igaratiba.component';
import { CovidComponent } from './pages/covid/covid.component';
import { CovidAdminComponent } from './pages/covid-admin/covid-admin.component';
import { ImportRequestComponent } from './pages/import-request/import-request.component';


registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    DefaultThemeComponent,
    LoginComponent,
    ResetPasswordComponent,
    LostPasswordComponent,
    RegisterComponent,
    UserProfileComponent,
    RequestComponent,
    RetailComponent,
    CompanyInvoicesComponent,
    CompanyExtractComponent,
    AllInvoicesComponent,
    UserRegisterComponent,
    CompanyRegisterComponent,
    SegmentRegisterComponent,
    UsetermsComponent,
    NewrequestComponent,
    CustomerRequestAddPageComponent,
    CustomerRequestListPageComponent,
    CustomerRequestDetailViewComponent,
    FormControlErrorsComponent,
    FilePickerComponent,
    AddressTextComponent,
    ProcessingComponent,
    AzureBlobUrlPipe,
    ProviderRequestListPageComponent,
    ProviderRequestDetailViewComponent,
    UsersneverloginComponent,
    CustomerFirstAddPageComponent,
    TermsOfUseViewComponent,
    ImagePickerAndCropperComponent,
    RequestPageComponent,
    NotificationListViewComponent,
    ReportbysegmentsComponent,
    CustomerProposalListPageComponent,
    InvoiceDetailComponent,
    TruncatePipe,
    MailListViewComponent,
    ConfigurationSystemComponent,
    ConfigurationPaymentComponent,
    ShippingFilesImportComponent,
    ShippingFilesExportComponent,
    TruncatePipe,
    SummitComponent,
    QisarcurrencymaskDirective,
    CompanyAddComponent,
    RequestReportComponent,
    CustomerPrerequestListPageComponent,
    CustomerPrerequestAddPageComponent,
    CustomerPrerequestDatailViewComponent,
    DashBoardProviderComponent,
    DashBoardProviderPerformaceComponent,
    DashBoardCustomerPerformaceComponent,
    FlexformActiveComponent,
    TopMenuComponent,
    SideMenuComponent,
    UsersActiveComponent,
    LatePaymentsComponent,
    PlazaActiveComponent,
    PlansComponent,
    TermsComponent,
    ShippingtypeComponent,
    PartnercusrtomersComponent,
    BasicplanComponent,
    NotrustcommissionComponent,
    OfferlistComponent,
    OffernewComponent,
    OfferdetailsComponent,
    OfferbuyComponent,
    OffermybuiesComponent,
    OffermysalersComponent,
    OffernegotiationComponent,
    OffereditComponent,
    CustomerGeralComponent,
    CustomerTrustSalersComponent,
    Igaratiba,
    CovidComponent,
    CovidAdminComponent,
    ImportRequestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
   // ChartsModule,
    HighchartsChartModule,
    FormsModule,
    ReactiveFormsModule,
    InputMaskModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    NgxMaskModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxChartsModule,
    PaginatorModule,
    AutoCompleteModule,
    TableModule,
    CardModule,
    InputTextModule,
    DropdownModule,
    InputSwitchModule,
    StepsModule,
    CalendarModule,
    InputTextareaModule,
    ProgressBarModule,
    TabViewModule,
    OverlayPanelModule,
    AccordionModule,
    EditorModule,
    CarouselModule,
    FontAwesomeModule,
    SimpleNotificationsModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    ImageCropperModule,
    BlobModule,
    StarRatingModule.forRoot(),
    PushNotificationsModule

  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    BlobService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    library.add(far as any);
    library.add(fas as any);
  }
}
