export class CompanyPaymentDetail{
    public companiesPaymentDetailId: number;
    public companyId: number;
    public requestId: number;
    public name: string;
    public cost: number;
    public taxes: number;
    public total: number;
    public createdAt: any;
    public sale: number;
    public saleCommission: number;
}