import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UserProfileComponent } from './pages/user/user-profile/user-profile.component';
import { CompanyRegisterComponent } from './pages/company/company-register/company-register.component';
import { UserRegisterComponent } from './pages/user/user-register/user-register.component';
import { SegmentRegisterComponent } from './pages/segment/segment-register/segment-register.component';
import { CustomerRequestListPageComponent } from './pages/customer-request-list-page/customer-request-list-page.component';
import { CustomerRequestAddPageComponent } from './pages/customer-request-add-page/customer-request-add-page.component';
import { ProviderRequestListPageComponent } from './pages/provider-request-list-page/provider-request-list-page.component';
import { UsersneverloginComponent } from './pages/reports/usersneverlogin/usersneverlogin.component';
import { CustomerFirstAddPageComponent } from './pages/customer-first-add-page/customer-first-add-page.component';
import { AllInvoicesComponent } from './pages/financial/invoices/invoices.component';
import { RequestPageComponent } from './pages/request-page/request-page.component';
import { ReportbysegmentsComponent } from './pages/company/reportbysegments/reportbysegments.component';
import { CustomerProposalListPageComponent } from './pages/customer-proposal-list-page/customer-proposal-list-page.component';
import { CompanyInvoicesComponent } from './pages/company/invoices/invoices.component';
import { CompanyExtractComponent } from './pages/company/extract/extract.component';
import { ConfigurationSystemComponent } from './pages/configuration/system/configuration-system.component';
import { ConfigurationPaymentComponent } from './pages/configuration/payment/configuration-payment.component';
import { ShippingFilesImportComponent } from './pages/financial/shippingfiles/import/shippingfiles-import.component';
import { ShippingFilesExportComponent } from './pages/financial/shippingfiles/export/shippingfiles-export.component';
import { CompanyAddComponent } from './pages/company/company-add/company-add.component';
import { CustomerPrerequestListPageComponent } from './pages/customer-prerequest-list-page/customer-prerequest-list-page.component';
import { CustomerPrerequestAddPageComponent } from './pages/customer-prerequest-add-page/customer-prerequest-add-page.component';
import { DashBoardProviderComponent } from './pages/dash-board-provider/dash-board-provider.component';
import { DashBoardProviderPerformaceComponent } from './pages/dash-board-provider-performace/dash-board-provider-performace.component';
import { DashBoardCustomerPerformaceComponent } from './pages/dash-board-customer-performace/dash-board-customer-performace.component';
import { UsersActiveComponent } from './pages/users-active/users-active.component';
import { LatePaymentsComponent } from './pages/late-payments/late-payments.component';
import { PlansComponent } from './pages/reports/company/plans/plans.component';
import { TermsComponent } from './pages/legal/terms/terms.component';
import { ShippingtypeComponent } from './pages/shippingtype/shippingtype.component';
import { PartnercusrtomersComponent } from './pages/partners/partnercusrtomers/partnercusrtomers.component';
import { BasicplanComponent } from './pages/company/commissions/basicplan/basicplan.component';
import { NotrustcommissionComponent } from './pages/company/commissions/notrustcommission/notrustcommission.component';
import { OfferlistComponent } from './pages/offers/offerlist/offerlist.component';
import { OffernewComponent } from './pages/offers/offernew/offernew.component';
import { OfferbuyComponent } from './pages/offers/offerbuy/offerbuy.component';
import { OfferdetailsComponent } from './pages/offers/offerdetails/offerdetails.component';
import { OffermybuiesComponent } from './pages/offers/offermybuies/offermybuies.component';
import { OffermysalersComponent } from './pages/offers/offermysalers/offermysalers.component';
import { OffernegotiationComponent } from './pages/offers/offernegotiation/offernegotiation.component';
import { OffereditComponent } from './pages/offers/offeredit/offeredit.component';
import { CustomerGeralComponent } from './pages/reports/company/customer-geral/customer-geral.component';
import { CustomerTrustSalersComponent } from './pages/reports/company/customer-trustsalers/customer-trustsalers.component';
import { Igaratiba } from './pages/reports/igaratiba/igaratiba.component';
import { CovidComponent } from './pages/covid/covid.component';
import { CovidAdminComponent } from './pages/covid-admin/covid-admin.component';
import { ImportRequestComponent } from './pages/import-request/import-request.component';


const routes: Routes = [
  { path: 'customer/requests/report', component: CustomerGeralComponent },
  { path: 'customer/salers/report', component: CustomerTrustSalersComponent },
  { path: 'users/online', component: UsersActiveComponent },
  { path: 'customer/resume', component: DashboardComponent },
  { path: 'customer/resume/performance', component: DashBoardCustomerPerformaceComponent },
  { path: 'provider/resume', component: DashBoardProviderComponent },
  { path: 'provider/resume/performance', component: DashBoardProviderPerformaceComponent },
  { path: 'user/profile', component: UserProfileComponent },
  { path: 'company/register', component: CompanyRegisterComponent },
  { path: 'user/register', component: UserRegisterComponent },
  { path: 'segment/register', component: SegmentRegisterComponent },
  { path: 'company/reportbysegments', component: ReportbysegmentsComponent },
  { path: 'request/:requestId', component: RequestPageComponent },
  { path: 'customer/request/list', component: CustomerRequestListPageComponent },
  { path: 'customer/request/list/:status', component: CustomerRequestListPageComponent },
  { path: 'customer/request/forapproved', component: CustomerRequestListPageComponent },
  { path: 'customer/request/add', component: CustomerRequestAddPageComponent },
  { path: 'customer/request/copy/:requestId', component: CustomerRequestAddPageComponent },
  { path: 'customer/automatic/request/list', component: CustomerRequestListPageComponent },
  { path: 'customer/automatic/request/list/:status', component: CustomerRequestListPageComponent },
  { path: 'customer/automatic/request/add', component: CustomerRequestAddPageComponent },
  { path: 'customer/schedulle/request/list', component: CustomerRequestListPageComponent },
  { path: 'customer/schedulle/request/list/:status', component: CustomerRequestListPageComponent },
  { path: 'customer/schedulle/request/add', component: CustomerRequestAddPageComponent },
  { path: 'customer/prerequest/list/:status', component: CustomerPrerequestListPageComponent },
  { path: 'customer/prerequest/list', component: CustomerPrerequestListPageComponent },
  { path: 'customer/prerequest/add', component: CustomerPrerequestAddPageComponent },
  { path: 'company/add', component: CompanyAddComponent },
  { path: 'customer/proposal/list', component: CustomerProposalListPageComponent },
  { path: 'provider/request/list/:status', component: ProviderRequestListPageComponent },
  { path: 'provider/request/list', component: ProviderRequestListPageComponent },
  { path: 'report/user/nevelogin', component: UsersneverloginComponent },
  { path: 'customer/first/add', component: CustomerFirstAddPageComponent },
  { path: 'conasi/customer/add', component: CustomerFirstAddPageComponent },
  { path: 'radarindustrial/customer/add', component: CustomerFirstAddPageComponent },
  { path: 'habitacional/customer/add', component: CustomerFirstAddPageComponent },
  { path: 'company/invoices', component: CompanyInvoicesComponent },
  { path: 'company/extract', component: CompanyExtractComponent },
  { path: 'financial/invoices', component: AllInvoicesComponent },
  { path: 'configuration/system', component: ConfigurationSystemComponent },
  { path: 'configuration/payment', component: ConfigurationPaymentComponent },
  { path: 'financial/shippingfiles/import', component: ShippingFilesImportComponent },
  { path: 'financial/shippingfiles/export', component: ShippingFilesExportComponent },
  { path: 'campaigns/summit', component: CustomerFirstAddPageComponent },
  { path: 'latepayments', component: LatePaymentsComponent },
  { path: 'report/plans', component: PlansComponent },
  { path: 'legal/terms', component: TermsComponent },
  { path: 'shipping/types', component: ShippingtypeComponent },
  { path: 'partner/customers', component: PartnercusrtomersComponent },
  { path: 'financial/commission/basic', component: BasicplanComponent },
  { path: 'financial/commission/notrust', component: NotrustcommissionComponent },
  { path: 'offer/list', component: OfferlistComponent },
  { path: 'offer/new', component: OffernewComponent },
  { path: 'offer/buy/:offerId', component: OfferbuyComponent },
  { path: 'offer/itemsbuy', component: OffermybuiesComponent },
  { path: 'offer/itemssalers', component: OffermysalersComponent },
  { path: 'offer/negotiation', component: OffernegotiationComponent },
  { path: 'offer/details/:offerId', component: OfferdetailsComponent },
  { path: 'offer/edit/:offerId', component: OffereditComponent },
  { path: 'igaratiba', component: Igaratiba },
  { path: 'campanha-covid', component: CovidComponent },
  { path: 'admin/campanha-covid', component: CovidAdminComponent },
  { path: 'request-import', component: ImportRequestComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
