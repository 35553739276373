import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import {
  HubConnection,
  HubConnectionState,
  HubConnectionBuilder,
} from "@aspnet/signalr";
import { User } from "src/app/models/User";
import { EventEmitter } from "@angular/core";
import { NotificationService } from "../notification.service";

@Injectable({
  providedIn: "root",
})
/** classe de comunicação com a API via SignalR (Hub QisarHub) */
export class QisarService {
  connectionId = "";

  onlineUsers = [];
  onMessageRecieved: EventEmitter<any> = new EventEmitter();
  onRequestRecieved: EventEmitter<any> = new EventEmitter();
  onMessageNewProposal: EventEmitter<void> = new EventEmitter();
  onMessageRead: EventEmitter<any> = new EventEmitter();
  onChageRequestStatus: EventEmitter<any> = new EventEmitter();
  onDeclineRequest: EventEmitter<any> = new EventEmitter();
  onCancelRequest: EventEmitter<any> = new EventEmitter();
  onChangeUsers: EventEmitter<any> = new EventEmitter();

  private hubConnection: HubConnection;
  constructor(private notificationService: NotificationService) {}

  /** Abre nova conexão */
  async startConnection(user: User) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.apiUri + "qisarhub")
      .build();

    await this.hubConnection
      .start()
      .then(() => {
        if (this.connectionId != "") {
          console.log('Limpando conexão antiga [' + this.connectionId + ']');
          this.hubConnection.invoke(
            "DisconnectByConnectionId",
            this.connectionId
          );
        }
        console.log("Conectado ao Hub da Qisar");
        this.hubConnection.invoke("LinkUserToConnection", user);
      })
      .catch((err) => console.log("Erro ao conectar com o hub: " + err));

    // callback da associação do user com a conexão do Hub
    this.hubConnection.on("linkUserToConnectionSuccessful", (connectionId) => {
      this.connectionId = connectionId;
      console.log("Usuario Associado à Conexão " + connectionId);
    });

    this.hubConnection.on("InformeOnlineUsers", (users) => {
      this.onlineUsers = users;
      this.onChangeUsers.emit();
    });

    // retorn as mensagens para mim
    this.hubConnection.on("getChatMessgeToMe", (chatMessage: any) => {
      this.onMessageRecieved.emit(chatMessage);
      this.notificationService.onChatReload();
    });

    // retorn o status da mensagem para mim
    this.hubConnection.on("informeChatMessageReadToMe", (chatMessage: any) => {
      this.onMessageRead.emit(chatMessage);
    });

    /** recebe novas propostas
    this.hubConnection.on('InformeSendNewProposalSucessful', (RequestId: any) => {
      this.onMessageNewProposal.emit(RequestId);
    });*/

    /** recebe mudanças de status de requisições
    this.hubConnection.on('sendRequestStatusChangeSucessful', (requestId) => {
      this.onChageRequestStatus.emit(requestId);
    });*/

    /** recebe novas requisições
    this.hubConnection.on('informeNewRequetSucessful', (requestId, segmentId) => {
      const response = { requestId, segmentId };
      this.onRequestRecieved.emit(response);
    });*/

    /** recebe cabcelamento de requisições
    this.hubConnection.on('InformeCancelRequestSucessful', () => {
      this.onCancelRequest.emit();
    });*/

    /** recebe declinio de requisições
    this.hubConnection.on('InformeSalerCancelRequestSucessful', () => {
      this.onDeclineRequest.emit();
    });*/
  }

  /** Fecha conexão */
  closeConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop().then(() => console.log("Conexão Encerrada"));
    }
  }

  /** Verifica se está online no HUB */
  isOnline(): boolean {
    if (this.hubConnection) {
      return this.hubConnection.state === HubConnectionState.Connected;
    } else {
      return false;
    }
  }

  /** Envia mensagem de chat para um usuario */
  setChatMessage(chatMessage: any) {
    this.hubConnection.invoke("SetChatMessage", chatMessage);
  }

  /** informa que enviou nova proposta */
  informeSendNewProposal(to: string, requestId: number) {
    this.hubConnection.invoke("InformeSendNewProposal", to, requestId);
  }

  /** Envia confirmação de leitura de uma mensagem */
  confirmChatMessageRead(chatMessage: any) {
    this.hubConnection.invoke("confirmChatMessagesRead", chatMessage);
  }

  /** comunica mudança de status da requisição */
  sendRequestStatusChange(requestId: any) {
    this.hubConnection.invoke("sendRequestStatusChange", requestId);
  }

  /** comunica a criacao de nova requisicao */
  informeNewRequet(requestId: any, segmentId: any) {
    this.hubConnection.invoke("informeNewRequet", requestId, segmentId);
  }

  /** comunica o cancelamento de uma requisição */
  informeCancelRequest() {
    this.hubConnection.invoke("InformeCancelRequest");
  }

  /** comunica o declinio da requisição */
  informeSalerCancelRequest() {
    this.hubConnection.invoke("InformeSalerCancelRequest");
  }
}
