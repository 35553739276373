import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { UtilsService } from "src/app/services/core/utils.service";
import { SaleService } from "src/app/services/sale/sale.service";
import { RequestService } from "src/app/services/request/request.service";
import { ProposalService } from "src/app/services/proposal/proposal.service";
import { ActiveCompanyService } from "src/app/services/active-company.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as Highcharts from "highcharts";
import { UserService } from "src/app/services/user/user.service";
import Drilldown from "highcharts/modules/drilldown";
import { SegmentService } from "src/app/services/segment/segment.service";
import { SelectItem } from "primeng/api";
import { Subscription } from 'rxjs';
import { User } from "src/app/models/User";

declare var require: any;
const Boost = require("highcharts/modules/boost");
const noData = require("highcharts/modules/no-data-to-display");
const More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
Drilldown(Highcharts);

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit, OnDestroy {
  total = "0";
  propostas = "";
  form: FormGroup;
  itemsProductType = [];
  currentUser: User;

  pt = {
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sab"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Feveiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
  };

  optionsTop5: any = {};
  optionsRequests: any = {};
  filterFormSegmentIdOptionList: Array<SelectItem> = [];
  filterFormUserIdOptionList: Array<SelectItem> = [];
  loadTop5 = false;
  loadRequest = false;
  loadSales = false;
  loadProposals = false;
  loadType = false;
  subscribeCompany: Subscription;
  currentUserId : number;
  userIsManager : boolean = false;

  constructor(
    private title: Title,
    private router: Router,
    private saleService: SaleService,
    private requestService: RequestService,
    private proposalService: ProposalService,
    private activeCompany: ActiveCompanyService,
    private formBuilder: FormBuilder,
    private segmentService: SegmentService,
    private userService: UserService,
    private util: UtilsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.title.setTitle("Resumo | Qisar");
    Highcharts.setOptions({
      lang: {
        drillUpText: "Carregar Todos",
        noData: "Nenhum dado encontrado",
      },
    });
  }

  ngOnDestroy(): void {
    if (this.subscribeCompany) {
      this.subscribeCompany.unsubscribe();
    }
  }

  /**
   * Limpa os gráficos
   */
  cleanCharts() {
    this.loadTop5 = false;
    this.loadRequest = false;
    this.loadSales = false;
    this.loadProposals = false;
    this.loadType = false;

    this.propostas = "";
    this.total = "";
    this.itemsProductType = [];
    this.optionsRequests = {
      chart: {
        height: 280,
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
    };
    Highcharts.chart("requests", this.optionsRequests);

    this.optionsTop5 = {
      chart: {
        height: 280,
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
    };
    Highcharts.chart("top5", this.optionsTop5);
  }

  /**
   * Carrega a lista de segmentos
   */
  loadSegments() {
    this.filterFormSegmentIdOptionList = [];
    this.filterFormSegmentIdOptionList.push({
      label: "Todos",
      value: 0,
    });
    this.segmentService.getall().then((response) => {
      for (const segment of response) {
        this.filterFormSegmentIdOptionList.push({
          label: segment.name,
          value: segment.segmentId,
        });
      }
    });
  }
  /*
   * carrega lista de usuários
   */

  async loadUsers() {
    this.filterFormUserIdOptionList = [];
    this.filterFormUserIdOptionList.push({
      label: "Todos",
      value: 0,
    });

    let userList: User[];
    userList = await this.userService.listWithOutSegments(this.currentUser.companyId);

    for (const user of userList) {
      this.filterFormUserIdOptionList.push({
        label: user.name,
        value: user.userId,
      });
    };
  }

  /**
   * Gráfico top 5 segmentos
   */
  loadTop5Segments() {
    this.requestService
      .topFiveSegments(
        this.activeCompany.activeCompanyId,
        this.form.value.segmentId,
        this.form.value.to,
        this.form.value.from,
        this.userIsManager ? this.form.value.userId : this.currentUserId
      )
      .then((FiveSegments: any) => {
        const top5data: Array<any> = [];
        FiveSegments.forEach((s) => {
          top5data.push({
            name: s.name,
            y: s.count,
          });
        });
        this.optionsTop5 = {
          chart: {
            type: "pie",
            height: 280,
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          tooltip: {
            pointFormat: "<b>{point.percentage:.1f}</b>%",
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b><br /> {point.y}",
              },
            },
          },
          series: [
            {
              name: "Segmentos",
              colorByPoint: true,
              data: top5data,
            },
          ],
        };
        Highcharts.chart("top5", this.optionsTop5);
        this.loadTop5 = true;
        if (
          this.loadTop5 &&
          this.loadRequest &&
          this.loadSales &&
          this.loadProposals &&
          this.loadType
        ) {
          this.util.finish();
        }
      });
  }

  /**
   * Total Comprado
   */
  loadTotalBuy() {
    this.saleService
      .TotalBuy(
        this.activeCompany.activeCompanyId,
        this.form.value.segmentId,
        this.form.value.to,
        this.form.value.from,
        this.userIsManager ? this.form.value.userId : this.currentUserId
      )
      .then((Sales: any) => {
        console.log(Sales.total)
        this.total = Sales.total;
        this.loadSales = true;
        if (
          this.loadTop5 &&
          this.loadRequest &&
          this.loadSales &&
          this.loadProposals &&
          this.loadType
        ) {
          this.util.finish();
        }
      });
  }

  /**
   * quantidade de propostas recebidas
   */
  loadReceivedProposals() {
    this.proposalService
      .totalReceived(
        this.activeCompany.activeCompanyId,
        this.form.value.segmentId,
        this.form.value.to,
        this.form.value.from,
        this.userIsManager ? this.form.value.userId : this.currentUserId 
      )
      .then((TotalProposal: any) => {
        this.propostas = TotalProposal;
        this.loadProposals = true;
        if (
          this.loadTop5 &&
          this.loadRequest &&
          this.loadSales &&
          this.loadProposals &&
          this.loadType
        ) {
          this.util.finish();
        }
      });
  }

  /**
   * Grafico de requisições por tipo de produto
   */
  loadProductType() {
    this.requestService
      .byProductType(
        this.activeCompany.activeCompanyId,
        this.form.value.segmentId,
        this.form.value.to,
        this.form.value.from,
        this.userIsManager ? this.form.value.userId : this.currentUserId
      )
      .then((ProductType: any) => {
        this.itemsProductType = ProductType;
        this.loadType = true;
        if (
          this.loadTop5 &&
          this.loadRequest &&
          this.loadSales &&
          this.loadProposals &&
          this.loadType
        ) {
          this.util.finish();
        }
      });
  }

  /**
   * Requisição por Status
   */
  loadRequestStatus() {
    this.requestService
      .requestByStatus(
        this.activeCompany.activeCompanyId,
        this.form.value.segmentId,
        this.form.value.to,
        this.form.value.from,
        this.userIsManager ? this.form.value.userId : this.currentUserId
      )
      .then((Requests: any) => {
        const drillDown = [];
        Requests.drillDown.forEach((item: any) => {
          const drillDownData = [];
          item.data.forEach((itemData: any) => {
            drillDownData.push([itemData.item1, itemData.item2]);
          });
          drillDown.push({
            name: item.name,
            id: item.id,
            data: drillDownData,
          });
        });

        this.optionsRequests = {
          chart: {
            type: "column",
            height: 280,
          },
          title: {
            text: "",
          },
          credits: {
            enabled: false,
          },
          xAxis: {
            type: "category",
          },
          yAxis: {
            title: {
              text: "Requisições",
            },
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            series: {
              borderWidth: 0,
            },
          },
          tooltip: {
            headerFormat:
              '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat:
              '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>',
          },
          series: [
            {
              name: "Status",
              data: Requests.items,
            },
          ],
          drilldown: {
            series: drillDown,
          },
        };
        Highcharts.chart("requests", this.optionsRequests);
        this.loadRequest = true;
        if (
          this.loadTop5 &&
          this.loadRequest &&
          this.loadSales &&
          this.loadProposals &&
          this.loadType
        ) {
          this.util.finish();
        }
      });
  }

  /***
   * Carrega as páginas
   */
  loadPage() {
    this.util.running();
    this.cleanCharts();
    // carrega total em compras
    this.loadTotalBuy();
    // carrega grafico dos top 5 segmentos
    this.loadTop5Segments();
    // quantidade de propostas recebidas
    this.loadReceivedProposals();
    // tipo de produto
    this.loadProductType();
    // requisições por status
    this.loadRequestStatus();
    // carrega os usuários
  }

  /**
   * metodo init
   */
  async ngOnInit() {
    // Troca de empresa
    this.subscribeCompany = this.activeCompany.onActiveCompanyChange.subscribe(() => {
      this.loadPage();
    });
    // Formulario de busca
    const initial = new Date(new Date().setMonth(new Date().getMonth() - 1));
    this.form = this.formBuilder.group({
      to: [initial],
      from: [new Date()],
      segmentId: [0],
      userId: [0]
    });
    this.loadSegments();
    
    await this.util.getUserFromSession()
      .then(user => {
          this.userIsManager = user.companyManager || false;
          this.currentUserId = user.userId;
          this.currentUser = user;

          this.loadPage();
      });

      await this.loadUsers();
  }

  private getCurrentUserInfo() : Promise<any>{
    return new Promise(() => {
      this.util.getUserFromSession();
    });
  }
}
