import { Injectable } from '@angular/core';
import { CompanyTrustRequestorApi } from 'src/app/core/http/entidades/CompanyTrustRequestorApi';
import { CompanyTrustRequestor } from 'src/app/models/CompanyTrustRequestor';

@Injectable({
  providedIn: 'root'
})
export class CompanyTrustRequestoService {

  constructor(private api: CompanyTrustRequestorApi) {
    this.api.baseName = 'companytrustrequestor';
  }

  /** buscar todos os registros */
  getall(): any {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um registro pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** Salvar */
  save(entity: CompanyTrustRequestor): any {
    return new Promise((resolve, reject) => {
      // se empresa nova, insere
      if (entity.companyTrustRequestorId === 0) {
        this.api.post(entity)
          .then(data => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(entity)
          .then(data => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api.delete(id)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  isTrustRequestorForSomeCompany(): any {
    return new Promise((result, reject) => {
      this.api.getOne('', '/IsTrustRequestorForSomeCompany')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
}
