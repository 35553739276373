import { Injectable } from '@angular/core';
import { UserSegmentApi } from 'src/app/core/http/entidades/UserSegmentApi';
import { UserSegment } from 'src/app/models/UserSegment';

@Injectable({
  providedIn: 'root'
})
export class UsersegmentService {

  constructor(private api: UserSegmentApi) {
    this.api.baseName = 'usersegment';
  }

  /** buscar todas os segmentos */
  getall(): any {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um segmento pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** Salvar */
  save(userSegment: UserSegment): any {
    return new Promise((resolve, reject) => {
      // se segmento novo, insere
      if (userSegment.userSegmentId === 0) {
        this.api.post(userSegment)
          .then((data: UserSegment) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(userSegment)
          .then((data: UserSegment) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api.delete(id)
        .then(() => {
          resolve(null);
        })
        .catch(() => {
          reject();
        });
    });
  }
}
