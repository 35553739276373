import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/services/core/utils.service';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/models/User';
import Swal from 'sweetalert2';
import { UserPhone } from 'src/app/models/UserPhone';
import { UserphoneService } from 'src/app/services/userphone/userphone.service';
import { TokenModel } from 'src/app/models/TokenModel';
import { AzureStorageBlobHelperService } from 'src/app/services/azure-storage-blob-helper.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  user: User;
  actualPassword: string;
  newPassword: string;
  confirmPassword: string;
  phone: UserPhone;
  tokenSessao: TokenModel;
  selectedAvatarBase64: string;

  constructor(
    private azureStorageBlobHelperService: AzureStorageBlobHelperService,
    private ngxSmartModalService: NgxSmartModalService,
    private title: Title,
    private utils: UtilsService,
    private router: Router,
    private userPhoneService: UserphoneService,
    private userService: UserService) {
    this.title.setTitle('Perfil do Usuário | Qisar');
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.pageLoad();
  }
  /** carregar a pagina */
  pageLoad() {
    this.tokenSessao = JSON.parse(sessionStorage.getItem('Token'));
    if (this.tokenSessao.resetPassword === true) {
      Swal.fire('Qisar', 'Senha expirada, por favor informe uma nova', 'info').then(() => {
        this.openModaPassword();
      });
    }
    this.utils.running();
    this.userService.getUserFromToken()
      .then((data: User) => {
        this.user = data;
        this.phoneClean();
        this.utils.finish();
      })
      .catch(() => {
        Swal.fire('Qisar', 'Erro ao Recuperar o usuário', 'error');
      });
  }
  ngOnInit() {
  }
  /** abrir modal telefone */
  newPhone() {
    this.phoneClean();
    this.ngxSmartModalService.getModal('modalPhone').open();
  }
  /** salvar nova senha */
  changePassWord() {
    this.utils.running();
    if (this.newPassword === this.confirmPassword) {
      this.userService.confirmPassword(this.actualPassword)
        .then((data: boolean) => {
          if (data) {
            this.userService.changePassword(this.newPassword)
              .then(() => {
                Swal.fire('Qisar', 'Senha alterada com sucesso', 'success')
                  .then(() => {
                    this.userService.logout();
                    this.utils.redirect('');
                  });
              })
              .catch((err) => {
                Swal.fire('Qisar', err.error, 'error');
                this.utils.finish();
              });
          } else {
            Swal.fire('Qisar', 'Senha atual não confere', 'error');
            this.utils.finish();
          }
        })
        .catch(() => {
          Swal.fire('Qisar', 'Erro ao verificar a senha');
          this.utils.finish();
        });
    } else {
      Swal.fire('Qisar', 'A nova senha não é igual à confirmação', 'error');
      this.utils.finish();
    }
  }
  /* Fecha Modal */
  closeModalPhone() {
    this.userService.getUserFromToken()
      .then((user: User) => {
        this.user = user;
      });
    this.ngxSmartModalService.getModal('modalPhone').close();
  }
  /** abrir modal de nova senha */
  openModaPassword() {
    this.actualPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
    this.ngxSmartModalService.getModal('modalPassword').open();
  }
  /** fechar modal senha */
  closeModalPassword() {
    this.ngxSmartModalService.getModal('modalPassword').close();
  }
  /** salvar usuario */
  saveUser() {
    this.uploadAvatar().then(avatarUrl => {
      this.user.avatar = avatarUrl;
      this.userService.save(this.user).then((user: User) => {
        this.user = user;
        Swal.fire('Qisar', 'Dados alterados com sucesso', 'success');
        this.utils.updateSessionUser();
        this.userService.reactiveLogin(this.user);
      });
    });
  }
  /** telefone limpo */
  phoneClean() {
    this.phone = new UserPhone();
    this.phone.userPhoneId = 0;
    this.phone.userId = this.user.userId;
    this.phone.number = '';
    this.phone.label = '';
    this.phone.default = false;
    this.phone.actived = true;
  }
  /** editar um fone */
  editPhone(phone: UserPhone) {
    this.phone = phone;
    this.ngxSmartModalService.getModal('modalPhone').open();
  }
  deletePhone(phone: UserPhone) {
    Swal.fire({
      title: 'Qisar',
      type: 'question',
      html:
        'Deseja excluir o telefone',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmo'
    }).then((data) => {
      this.userPhoneService.delete(phone.userPhoneId)
        .then(() => {
          const idx = this.user.userPhones.findIndex(p => p.userPhoneId === phone.userPhoneId);
          this.user.userPhones.splice(idx, 1);
          const session: TokenModel = JSON.parse(sessionStorage.getItem('Token'));
          sessionStorage.removeItem('Token');
          session.user = this.user;
          sessionStorage.setItem('Token', JSON.stringify(session));
        })
        .catch(() => {
          Swal.fire('Qisar', 'Erro ao desabilitar a empresa', 'error');
        });
    });
  }
  /** salvar telefone */
  savePhone() {
    this.phone.userId = this.user.userId;
    this.phone.actived = true;
    this.userPhoneService.save(this.phone)
      .then((data: UserPhone) => {
        if (this.phone.userPhoneId === 0) {
          if (data) {
            this.user.userPhones.push(data);
          }
        }
        this.ngxSmartModalService.getModal('modalPhone').close();
        Swal.fire('Qisar', 'Telefone salvo com sucesso', 'success');
      });
  }

  onAvatarChange(imageBase64: string) {
    this.selectedAvatarBase64 = imageBase64;
  }

  uploadAvatar(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.selectedAvatarBase64) {
        this.azureStorageBlobHelperService.uploadDataUrl(this.selectedAvatarBase64, 'requestfiles').then(url => {
          resolve(url);
        });
      } else {
        resolve(this.user.avatar);
      }
    });
  }
}
