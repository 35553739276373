import { Injectable } from '@angular/core';
import { OfferApi } from 'src/app/core/http/entidades/OfferApi';
@Injectable({
  providedIn: 'root'
})
export class OffersService {

  constructor(private api: OfferApi) {
    this.api.baseName = 'offer';
  }

  getOffersList() {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  save(offer: any) {
    return new Promise((resolve, reject) => {
      if (offer.offerId === 0) {
        this.api.post(offer)
          .then(data => resolve(data))
          .catch(error => reject(error));
      } else {
        this.api.put(offer)
          .then(data => resolve(data))
          .catch(error => reject(error));
      }
    });
  }

  get(id: number) {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }


  sale(sale: any ){
    return new Promise((resolve, reject) => {
        this.api.post(sale, '/sales')
          .then(data => resolve(data))
          .catch(error => reject(error));
      });
  }

  cancelSale(Sale: any) {
    return new Promise((resolve, reject) => {
      this.api.post(Sale, '/cancelSale')
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  cancelBuy(Sale: any) {
    return new Promise((resolve, reject) => {
      this.api.post(Sale, '/cancelBuy')
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  offersByCompanyAndUser(companyId: number, userId: number) {
    return new Promise((resolve, reject) => {
      this.api.get('/offersByCompanyAndUser/' + companyId + '/' + userId)
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  salesByCompanyAndUser(companyId: number, userId: number) {
    return new Promise((resolve, reject) => {
      this.api.get('/salesByCompanyAndUser/' + companyId + '/' + userId)
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  shoppingByCompanyAndUser(companyId: number, userId: number) {
    return new Promise((resolve, reject) => {
      this.api.get('/shoppingByCompanyAndUser/' + companyId + '/' + userId)
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  remove(offer: any) {
    return new Promise((resolve, reject) => {
        this.api.delete(offer.offerId)
          .then(data => resolve(data))
          .catch(error => reject(error));
    });
  }
}
