import { Address } from './Address';
import { CompanySegment } from './CompanySegment';
import { User } from './User';
import { CompanyTrustRequestor } from './CompanyTrustRequestor';

export class Company {
  companyId: number;
  cnpj: string;
  stateCode: string;
  name: string;
  nickName: string;
  effectiveDate: any;
  phoneOffice: string;
  webSite: string;
  active: boolean;
  isCustomer: boolean;
  isRequestor: boolean;
  isPartner: boolean;
  ratesAverage: number;
  termsStatus: number;
  enableToSaleAcceptDate: string;
  companyTrustRequestors: CompanyTrustRequestor[];
  companySegments: CompanySegment[];
  addresses: Address[];
  users: User[];
  logo: string;
  monthlyPayment: number;
  monthlyPaymentDays: number;
  paymentBlock: boolean;
  paymentPlanId: number;
  partnerId: number;
  noChargeUntil: any;
  hasImportFile?: boolean;
  hasCompanyWork?: boolean;
  pagCompras?: boolean;
}
