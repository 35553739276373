import { Injectable } from '@angular/core';
import { ProposalApi } from '../core/http/entidades/ProposalApi';
import { Proposal } from '../models/Proposal';


@Injectable({
  providedIn: 'root'
})
export class ProposalService {
  constructor(
    private api: ProposalApi,
  ) {
    this.api.baseName = 'proposal';
  }

  approval(proposalId: number, userId: number) {
    return this.api.post({ proposalId, userId }, '/approval');
  }

  decline(proposalId: number, userId: number, cancelReason: string) {
    return this.api.post({ proposalId, userId, cancelReason }, '/decline');
  }

  save(proposal: Proposal) {
    return new Promise((resolve, reject) => {
      if (proposal.proposalId === 0) {
        this.api.post(proposal)
          .then(data => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(proposal)
          .then(data => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }

  findByRequest(requestId: number): any {
    return new Promise((result, reject) => {
      this.api.get('/findbyrequest/' + requestId.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }


  gethistory(requestId: number, companyId: number): any {
    return new Promise((result, reject) => {
      this.api.get('/gethistory/' + requestId.toString() + '/' + companyId.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  LastProposalFromRequestAndCompany(requestId: any, companyId: any) {
    return this.api.getOne('', '/lastproposal/' + requestId + '/' + companyId);
  }

  /** buscar propostas não lidas */
  getProposalsNotRead(): any {
    return new Promise((result, reject) => {
      this.api.get('/GetProposalsNotRead')
        .then(data => result(data))
        .catch(error => reject(error));
    });

  }

  /** marcar proposta como lida */
  readProposalsByRequest(requestId: any): any {
    return new Promise((resolve, reject) => {
      this.api.post(requestId, '/readProposalsByRequest')
        .then(() => resolve(null))
        .catch(() => {
          reject();
        });
    });
  }

    /** buscar propostas do mês */
    getMonthItensByCompanyId(): any {
      return new Promise((result, reject) => {
        this.api.get('/GetMonthItensByCompanyId')
          .then(data => result(data))
          .catch(error => reject(error));
      });
  
    }

     /** buscar propostas por data selecionada */
     GetMonthItensByCompanyIdRangeDate(initialDate: Date, finalDate: Date): any {
      return new Promise((result, reject) => {
        this.api.get('/GetMonthItensByCompanyIdRangeDate/'+ initialDate + '/' + finalDate)
          .then(data => result(data))
          .catch(error => reject(error));
      });
  
    }
}
