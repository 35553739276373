import { Component, OnInit } from "@angular/core";
import { ActiveCompanyService } from "src/app/services/active-company.service";
import { Title } from "@angular/platform-browser";
import { RequestService } from "src/app/services/request/request.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import * as Highcharts from "highcharts";
import { SaleService } from "src/app/services/sale/sale.service";
import { UtilsService } from "src/app/services/core/utils.service";
import { CompanyService } from "src/app/services/company/company.service";
import * as _ from "lodash";
import { SelectItem } from 'primeng/api';
import { UserService } from 'src/app/services/user/user.service';
declare var require: any;
const Boost = require("highcharts/modules/boost");
const noData = require("highcharts/modules/no-data-to-display");
const More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);


@Component({
  templateUrl: "./igaratiba.component.html",
  styleUrls: ["./igaratiba.component.css"],
})
export class Igaratiba implements OnInit {
  form: FormGroup;
  QisarValueSaving = 0;
  ValueSaving = 0;
  TotalCompras = "0";
  savingTable = [];

  segmentsChart = [];

  IgaratibaData = [];
  SavingIgaratiba = 0;
  IgaratibaSalesWithProducts = 0;
  IgaratibaSalesWithOutProducts = 0;

  LoadSaving = false;
  LoadCompras = false;
  LoadIgaratiba = false;

  userIsManager : boolean = false;
  currentUserId : number;
  filterFormUserIdOptionList: Array<SelectItem> = [];

  options = {};

  pt = {
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sab"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Feveiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
  };

  constructor(
    private activeCompany: ActiveCompanyService,
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private requestService: RequestService,
    private utils: UtilsService,
    private saleService: SaleService,
    private userService: UserService,
    private title: Title
  ) {}

  loadOk() {
    if (this.LoadSaving && this.LoadCompras && this.LoadIgaratiba) {
      this.utils.finish();
    }
  }

  ngOnInit(): void {
    this.title.setTitle("Igaratiba");
    const initial = new Date(2019,0,1);
    this.form = this.formBuilder.group({
      to: [initial],
      from: [new Date()],
      segmentId: [0],
      userId: [0],
    });

    this.utils.getUserFromSession()
      .then(user => {
        this.userIsManager = user.companyManager || false;
        this.currentUserId = user.userId;
        this.loadPage();
      });

    this.loadUsers();
  }

  loadPage() {
    this.utils.running();
    this.loadIgaratibaSavings();
    this.loadSaving();
    this.loadTotalBuy();
  }

  loadIgaratibaSavings() {
    this.companyService
      .getIgaratibaSaving(
        this.form.value.segmentId,
        this.form.value.to,
        this.form.value.from,
        this.userIsManager ? this.form.value.userId : this.currentUserId
      )
      .then((data: any) => {
        this.savingTable = data;
        this.SavingIgaratiba = this.calcSaving(data);
        this.IgaratibaSalesWithProducts = this.calcTotalProduct(data);
        this.IgaratibaSalesWithOutProducts = this.calcTotalNoProduct(data);
        this.loadChart(data);
        this.LoadIgaratiba = true;
      })
      .catch((e) => console.log(e));
  }

  loadTotalBuy() {
    this.saleService
      .TotalBuy(
        this.activeCompany.activeCompanyId,
        this.form.value.segmentId,
        this.form.value.to,
        this.form.value.from,
        this.userIsManager ? this.form.value.userId : this.currentUserId
      )
      .then((Sales: any) => {
        this.TotalCompras = Sales.total;
        this.LoadCompras = true;
        this.loadOk();
      });
  }

  calcSaving(array = []): number {
    let total = 0;
    array.forEach((element) => {
      total = total + element.saving;
    });

    return total;
  }

  calcTotalProduct(array = []): number {
    let total = 0;
    array.forEach((element) => {
      if (element.costIgaratiba > 0) total = total + 1;
    });

    return total;
  }

  calcTotalNoProduct(array = []): number {
    let total = 0;
    array.forEach((element) => {
      if (element.costIgaratiba === 0) total = total + 1;
    });

    return total;
  }

  loadChart(array = []) {
    this.segmentsChart = [];
    const grp = _.mapValues(_.groupBy(array, "segmentName"), (clist) =>
      clist.map(() => _.omit(array, "segmentName"))
    );

    const segments = Object.keys(grp);
    segments.forEach(element => {
      this.segmentsChart.push({
        name: element,
        y: grp[element].length
      })
    });

    this.options = {
      chart: {
        type: "pie",
        height: 276,

      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormat: "<b>{point.percentage:.1f}</b>%",
      },
      plotOptions: {
        pie: {
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "{point.name}",
          },
        },
      },
      series: [
        {
          innerSize: "50%",
          name: "Fornecedores",
          colorByPoint: true,
          data: this.segmentsChart,
        },
      ],
    };

    Highcharts.chart("chart", this.options);
  }

  loadSaving() {
    this.requestService
      .GetDashBoarbPerformaceCustromerSavingValue(
        this.activeCompany.activeCompanyId,
        this.form.value.to,
        this.form.value.from,
        this.form.value.segmentId,
        this.userIsManager ? this.form.value.userId : this.currentUserId
      )
      .then((data: any) => {
        this.QisarValueSaving = data.sum;
        this.ValueSaving = data.myCustomerRequestsSum;
        this.LoadSaving = true;
        this.loadOk();
      });
  }

  /*
   * carrega lista de usuários
   */

  loadUsers() {
    this.filterFormUserIdOptionList = [];
    this.filterFormUserIdOptionList.push({
      label: "Todos",
      value: 0,
    });
    this.userService.list().then((users) => {
      for (const user of users) {
        this.filterFormUserIdOptionList.push({
          label: user.name,
          value: user.userId,
        });
      }
    });
  }
}
