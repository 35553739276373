import { Component, OnInit, Input, ElementRef, ViewChild, Renderer, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ObjectMap } from 'src/app/utils/object-map';
import { FileHelper } from 'src/app/utils/file-helper';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/user/user.service';
import { UtilsService } from 'src/app/services/core/utils.service';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/services/chat/chat.service';
import { ArrayHelper } from 'src/app/utils/array-helper';
import { ProposalService } from 'src/app/services/proposal.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DialogHelperService } from 'src/app/services/dialog-helper.service';
import { RequestService } from 'src/app/services/request/request.service';
import { SaleService } from 'src/app/services/sale/sale.service';
import { AzureStorageBlobHelperService } from 'src/app/services/azure-storage-blob-helper.service';
import { SelectedFile } from 'src/app/components/file-picker/file-picker.component';
import { Proposal } from 'src/app/models/Proposal';
import { QisarService } from 'src/app/services/hubs/qisar.service';
import { ChatmessageService } from 'src/app/services/chat/chatmessage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { ActiveCompanyService } from 'src/app/services/active-company.service';
import { RequestItem } from 'src/app/models/RequestItem';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Sale } from 'src/app/models/Sale';
import { some } from 'lodash';
import { Company } from 'src/app/models/Company';
import { ProposalRequestItem } from 'src/app/models/ProposalRequestItem';
import { ProposalrequestitemService } from 'src/app/services/proposal/proposalrequestitem.service';
import { utils, writeFile } from 'xlsx';


@Component({
  selector: 'app-customer-request-detail-view',
  templateUrl: './customer-request-detail-view.component.html',
  styleUrls: ['./customer-request-detail-view.component.css']
})
export class CustomerRequestDetailViewComponent implements OnInit, OnDestroy {
  @ViewChild('nativeFileInputElement') nativeFileInputElement: ElementRef;
  @ViewChild('messageScrollDiv') messageScrollDiv: ElementRef;
  @ViewChild('propChoiceReasonOverlayPanel') propChoiceReasonOverlayPanel: OverlayPanel;

  @Input() request: any;
  @Input() requestId: number;
  @Input() requestUserId: number;
  @Input() companyId: number;
  @Input() toAproved: boolean;
  @Input() requestNoTaxes = false;
  @Input() showProposalChoiceReason: boolean = false;
  @Output() onbuyProposal = new EventEmitter<number>();
  @Output() onapprovedOrReject = new EventEmitter<number>();
  @Output() oncancel = new EventEmitter<void>();

  cancelForm: FormGroup;
  buyProposalForm: FormGroup;
  sale = false;
  chatMap: ObjectMap<any> = new ObjectMap('chatId');
  chatIdList: Array<string>;
  chatIdListToDisplay: Array<string> = [];
  checkStatus = false;
  rating = 0;
  proposalToReject: Proposal;
  arrOnlineUserId = [];
  userMap: ObjectMap<User> = new ObjectMap('userId');
  currentUser: User;
  selectedChatId: string;
  chatMessageListMap: any = {};
  messageIdMap: any = {};
  lastMessageDate: Date;
  chatNewMessageMap: any = {};
  messageSubscription: Subscription;
  selectedProposalAtachments: any;
  proposalList: Array<any>;
  messageFile: File;
  messageText: string;
  userIdChatId: any = {};
  chatIdUserId: any = {};
  chatWithInteractionMap: any = {};
  qisarRequestLastMessageFirstSignal = false;
  buyProposalFormFile: File;
  canSeeMessages = false;
  haveItems = false;
  haveProposals = false;
  proposalModal: Proposal;
  rejectProposalForm: FormGroup;
  searchNewNotifications = false;
  showingNotification = false;
  selectedProposalSaleToShowChoiceReason: Sale;
  selectedVendor: Company;
  proposalRequestItem: ProposalRequestItem[] = [];
  selectedItem: string;

  constructor(
    private userService: UserService,
    // tslint:disable-next-line: deprecation
    private renderer: Renderer,
    public authService: AuthService,
    public utils: UtilsService,
    private chatService: ChatService,
    private proposalService: ProposalService,
    public ngxSmartModalService: NgxSmartModalService,
    private formBuilder: FormBuilder,
    private dialogHelperService: DialogHelperService,
    private requestService: RequestService,
    private saleService: SaleService,
    private hub: QisarService,
    private proposalRequestItemService: ProposalrequestitemService,
    private companyService: CompanyService,
    private activeCompanyService: ActiveCompanyService,
    private chatMessageService: ChatmessageService,
    private azureStorageBlobHelperService: AzureStorageBlobHelperService,
    private notificationService: NotificationService
  ) { }

  getPreProposalValue(id, items): any {
    const item = items.find(i => i.preRequestItemId === id);
    return item.quantity;
  }

  loadPage() {
    this.setOnlineStatus();
    this.searchNewNotifications = true;
    this.rejectProposalForm = this.formBuilder.group({
      reason: [null, Validators.compose([Validators.required])],
    });
    this.proposalService.readProposalsByRequest(this.requestId)
      .then(() => {
        this.notificationService.onProposalReload();
      });
    this.chatService.setReadDeclineRequest(this.requestId)
      .then(() => {
        this.notificationService.onDeclineReload();
      });

    this.utils.running();
    if (!this.requestNoTaxes) {
      this.requestNoTaxes = false;
    }
    if (this.request.requestItems) {
      if (this.request.requestItems.length > 1) {
        this.getLastPrice(this.request);
        this.haveItems = true;
      }
    }
    this.utils.getUserFromSession().then(response => {
      this.currentUser = response;
      this.canSeeMessages = (!this.currentUser.master && !this.currentUser.operator && this.request.userId === this.currentUser.userId);
      if (this.canSeeMessages) {
        this.canSeeMessages = !this.toAproved;
      }
      this.cancelForm = this.formBuilder.group({
        reason: [null, Validators.compose([Validators.required])],
      });
      this.buyProposalForm = this.formBuilder.group({
        reason: [null, Validators.compose([Validators.required])],
        proposalId: [null, Validators.compose([Validators.required])]
      });
      this.utils.finish();
      this.loadChatList().then(() => {
        this.loadProposalList();
        this.loadMessageList();
      });
    });
    this.hub.onMessageRecieved.subscribe((message: any) => {
      if (this.searchNewNotifications) {
        if (message.requestId === this.requestId) {
          this.chatIdList.forEach(id => {
            if (this.chatMap.get(id).userId === message.from) {
              if (!this.chatMessageListMap[id].includes(message)) {
                this.chatMessageListMap[id].push(message);
              }
              if (!this.chatIdListToDisplay.includes(id)) {
                this.chatIdListToDisplay.push(id);
              }
            }
          });
          let qtdDeLoops = 0;
          const intervalo = setInterval(() => {
            qtdDeLoops++;
            if (qtdDeLoops > 5) {
              clearInterval(intervalo);
            }
            this.scrollMessageList();
          }, 800);
          this.chatMessageService
            .SetMessagesRead(this.currentUser.userId, this.requestId)
            .then(() => {
              this.notificationService.onChatReload();
            });
        }
      }
    });

    this.hub.onMessageNewProposal.subscribe((RequestId: any) => {
      if (this.searchNewNotifications && !this.showingNotification) {
        this.showingNotification = true;
        if (RequestId === this.requestId) {
          this.dialogHelperService.showSuccessNotification('Nova proposta recebida', 'Requisição #' + this.requestId);
          this.showingNotification = false;
          this.proposalService.readProposalsByRequest(this.requestId);
        }
        this.loadPage();
      }
    });
  }

  getLastPrice(request: any): any {
    var requestItems = request.requestItems;
    if (requestItems) {
      if (requestItems.length > 0) {
        requestItems.forEach((item: any) => {
          this.companyService.getCompanyProductsByName(item.description, request.companyId)
            .then((product: any) => {
              if (product) {
                item.product = product;
              }
            });
        });
      }
    }
  }

  ngOnInit() {
    this.loadPage();
  }

  approvalProposal(proposal: any) {
    this.dialogHelperService
      .askConfirmation('Você realmente quer aprovar essa compra? Essa ação não pode ser desfeita.')
      .then(confirmation => {
        if (confirmation) {
          this.proposalService.approval(proposal.proposalId, this.currentUser.userId).then(() => {
            this.dialogHelperService.showSuccessAlert('A sua compra foi aprovada.');
            this.hub.sendRequestStatusChange(this.requestId);
            this.onapprovedOrReject.emit();
          });
        }
      });
  }

  rejectProposal(proposal: Proposal) {
    const modalName = 'customer-proposal-list-page-reject-proposal_' + this.requestId;
    this.rejectProposal = null;
    this.proposalToReject = proposal;
    this.ngxSmartModalService.getModal(modalName).open();
  }

  confirmRejectProposal() {
    const modalName = 'customer-proposal-list-page-reject-proposal_' + this.requestId;
    if (this.rejectProposalForm.valid) {
      this.dialogHelperService
        .askConfirmation('Você realmente quer reprovar essa proposta? Essa ação não pode ser desfeita.')
        .then(confirmation => {
          if (confirmation) {
            const proposal = this.proposalToReject;
            this.proposalService.decline(proposal.proposalId, this.currentUser.userId, this.rejectProposalForm.value.reason).then(() => {
              this.dialogHelperService.showSuccessAlert('A sua compra foi reprovada.');
              this.hub.sendRequestStatusChange(this.requestId);
              this.ngxSmartModalService.getModal(modalName).close();
              this.onapprovedOrReject.emit();
            });
          }
        });
    }
  }

  selectStar(evt) {
    this.rating = evt.rating;
  }

  ngOnDestroy(): void {
    this.searchNewNotifications = false;

  }

  setOnlineStatus() {
    this.hub.onlineUsers.forEach(item => {
      this.arrOnlineUserId.push(item.userid);
    });
    this.hub.onChangeUsers.subscribe((users: []) => {
      this.arrOnlineUserId = [];
      this.hub.onlineUsers.forEach(item => {
        this.arrOnlineUserId.push(item.userid);
      });
    });
  }

  loadProposalList(): Promise<void> {
    return new Promise((resolve) => {
      this.proposalService.findByRequest(this.requestId)
        .then((proposals: any) => {
          proposals.forEach(proposal => {
            this.haveProposals = true;
            this.chatWithInteractionMap[proposal.chatId] = true;
            if (!this.chatIdListToDisplay.includes(proposal.chatId)) {
              this.chatIdListToDisplay.push(proposal.chatId);
            }
            if (!this.selectedChatId) {
              if (this.chatIdListToDisplay) {
                if (this.chatIdListToDisplay.length > 0) {
                  this.selectVendor(this.chatIdListToDisplay[0]);
                }
              }
            }
          });
          this.proposalList = proposals;
          resolve();
        });
    });
  }

  loadChatList(): Promise<void> {
    return new Promise((resolve) => {
      this.utils.running();
      this.chatService.findByRequest(this.requestId).then(response => {
        this.chatMap.addList(response);
        for (const chat of response) {
          this.userIdChatId[chat.userId] = chat.chatId;
          this.chatIdUserId[chat.chatId] = chat.userId;
        }
        this.chatIdList = ArrayHelper.getPropertyList(response, 'chatId');
        this.utils.finish();
        resolve();
      });
    });
  }

  selectChat(chatId: string) {
    this.chatNewMessageMap[chatId] = false;
    this.selectedChatId = chatId;
    let qtd = 0;
    const i = setInterval(() => {
      qtd++;
      if (qtd > 5) {
        clearInterval(i);
      }
      this.scrollMessageList();
    }, 800);

    this.chatMessageService
      .SetMessagesRead(this.currentUser.userId, this.requestId)
      .then(() => {
        this.notificationService.onChatReload();
      });
  }

  selectVendor(chatId: string) {
    this.selectChat(chatId);
    this.selectedVendor = this.chatMap.get(chatId).company;
  }

  chatWithSale(chatId: string) {
    const chat = this.chatMap.get(chatId);
    if (!chat) {
      return false;
    }

    if (!chat.proposalHistories) {
      return false;
    } else {
      for (const item of chat.proposalHistories) {
        if (item.statusId === 3) {
          return true;
        }
      }
    }
    return false;
  }

  scrollMessageList() {
    if (this.messageScrollDiv) {
      this.messageScrollDiv.nativeElement.scrollTop = this.messageScrollDiv.nativeElement.scrollHeight;
    }
  }

  orderByMessages(list): Promise<any> {
    return new Promise((resolve) => {
      if (list) {
        const newList = list.sort((a: any, b: any) => {
          return +new Date(a.date) - +new Date(b.date);
        });
        resolve(newList);
      } else {
        resolve([]);
      }
    });
  }

  loadMessageList() {
    for (const chatId of this.chatIdList) {
      let haveMessages = false;
      this.chatMessageListMap[chatId] = [];
      this.orderByMessages(this.chatMap.get(chatId).chatMessages)
        .then((messages) => {
          if (messages) {
            messages.forEach((message: any) => {
              this.chatMessageListMap[chatId].push(message);
              haveMessages = true;
            });
          }
          if (haveMessages) {
            if (!this.chatIdListToDisplay.includes(chatId)) {
              this.chatIdListToDisplay.push(chatId);
            }
          }
        });
    }
  }

  sendMessage() {
    if (this.messageText && this.messageText !== '') {
      const chatMessage = {
        chatId: this.selectedChatId,
        from: this.currentUser.userId,
        to: this.chatIdUserId[this.selectedChatId],
        fromName: this.currentUser.name,
        message: this.messageText,
        requestId: this.requestId,
        date: new Date()
      };
      this.hub.setChatMessage(chatMessage);
      this.chatMessageService
        .create(chatMessage)
        .then(() => this.scrollMessageList());
      this.chatMessageListMap[this.selectedChatId].push(chatMessage);
      this.messageText = '';
    }
  }

  askFile() {
    const event = new MouseEvent('click', { bubbles: true });
    this.renderer.invokeElementMethod(this.nativeFileInputElement.nativeElement, 'dispatchEvent', [event]);
  }

  onInputChange(event) {
    const file: File = FileHelper.getFileFromInputEvent(event);
    if (file) {
      this.messageFile = file;
    }
  }

  removeFile() {
    this.messageFile = null;
  }

  randomIntFromInterval(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  openCancelModal() {
    const modalName = 'customer-request-cancel_' + this.requestId;
    this.cancelForm.reset();
    this.ngxSmartModalService.getModal(modalName).open();
  }

  selectProposalToBuy(proposalId: number) {
    const modalName = 'customer-request-detail-view-buy-proposal_' + this.requestId;
    this.buyProposalForm.reset();
    this.buyProposalForm.controls.proposalId.setValue(proposalId);

    this.ngxSmartModalService.getModal(modalName).open();
  }

  sortData(items): any {
    return items.sort((a, b) => {
      return (new Date(b.proposalHistoryId) as any) - (new Date(a.proposalHistoryId) as any);
    });
  }

  getProposalTotal(proposal: Proposal): string {
    return proposal.cost.toString();
  }

  getProposalTotalWithChargesAndShipping(proposal: any): string {
    return (proposal.cost + proposal.icms + proposal.pisCofins + proposal.ipi + proposal.iss + proposal.shipping).toString();
  }

  getClassProposalItem(proposal: any, itemId: number): string {
    if (!proposal.proposalRequestItems) {
      return '';
    }
    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);
    if (!item) {
      return '';
    }
    if (item.cost === 0) {
      return 'noPrice';
    }
    if (item.bestPrice === true) {
      return 'bestPrice';
    }
    return '';
  }


  getProposalItem(proposal: any, itemId: number): any {
    if (!proposal.proposalRequestItems)
      return {};

    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);

    if (!item)
      return {};

    return item;
  }

  getItemCharges(proposal: any, itemId: number): string {
    if (!proposal.proposalRequestItems) {
      return '';
    }
    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);
    if (!item) {
      return '';
    }

    var charges = item.icms + item.pisCofins + item.ipi + item.iss;

    return charges.toString();
  }

  getCostProposalItemUnity(proposal: any, quantity: any, itemId: number): string {
    if (!proposal.proposalRequestItems) {
      return '';
    }
    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);
    if (!item) {
      return '';
    }
    const itemUnity = item.cost / quantity;
    return itemUnity.toString();
  }

  getClassProposal(proposal: any, itemId: number): string {
    if (!proposal.proposalRequestItems) {
      return '';
    }
    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);
    if (!item) {
      return '';
    }
    if (item.cost === 0) {
      return 'noPrice';
    }
    return '';
  }

  getCostProposalItem(proposal: any, itemId: number): string {
    if (!proposal.proposalRequestItems) {
      return '';
    }
    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);
    if (!item) {
      return '';
    }
    return item.cost.toString();
  }


  createRequest() {
    this.dialogHelperService
      .askConfirmation('Confirma a criação dessa requisição? ')
      .then(confirmation => {
        if (confirmation) {
          this.request.statusId = 1;
          this.requestService.save(this.request).then(() => {
            this.dialogHelperService.showSuccessNotification('A requisição foi criada com sucesso.');
            this.hub.informeCancelRequest();
            this.oncancel.emit();
          });
        }
      });
  }
  cancel() {
    const modalName = 'customer-request-cancel_' + this.requestId;
    if (this.cancelForm.valid) {
      this.dialogHelperService
        .askConfirmation('Você realmente quer cancelar essa requisição? Essa ação não pode ser desfeita.')
        .then(confirmation => {
          if (confirmation) {
            this.request.statusId = 2;
            this.request.cancelReason = this.cancelForm.value.reason;
            this.requestService.save(this.request).then(() => {
              this.dialogHelperService.showSuccessNotification('A requisição foi cancelada com sucesso.');
              this.ngxSmartModalService.getModal(modalName).close();
              this.hub.informeCancelRequest();
              this.oncancel.emit();
            });
          }
        });
    } else {
      const formKeyList: Array<string> = Object.keys(this.cancelForm.controls);
      for (const key of formKeyList) {
        this.cancelForm.controls[key].markAsTouched();
      }
    }
  }

  async buyProposal() {
    const modalName = 'customer-request-detail-view-buy-proposal_' + this.requestId;
    this.dialogHelperService.askConfirmation('Você realmente quer comprar essa proposta?').then(async confirmation => {
      if (confirmation) {
        let proposal = this.proposalList.find(proposal => proposal.proposalId);
        this.hub.sendRequestStatusChange(this.requestId);
        this.utils.running();
        this.ngxSmartModalService.getModal(modalName).close();
        // this.uploadBuyProposalFile().then(fileUrl => {
        const data = this.buyProposalForm.value;
        // data.attachment = fileUrl;
        data.rate = this.rating;
        this.saleService.save(data).then(async (sale: any) => {
          if (sale) {
            if (sale.statusId === 5) {
              this.dialogHelperService.showWarningNotification('A sua compra está pendente de aprovação.');
            } else {
              this.dialogHelperService.showSuccessNotification('Sua compra foi executada com sucesso.');
              for (let proposalItem in proposal.proposalRequestItems) {
                let proposalItemToEdit = proposal.proposalRequestItems[proposalItem];
                proposalItemToEdit.sold = true;
                await this.proposalRequestItemService.save(proposalItemToEdit).then(res => { })
              }

            }
          }
          this.onbuyProposal.emit(this.requestId);
          this.buyProposalFormFile = null;
          this.utils.finish();
        });
        // });
      }
    });
  }

  openProposalAtachmentsModal(proposal: any) {
    const modalName = 'customer-request-detail-view-proposal-atachments_' + this.requestId;
    this.selectedProposalAtachments = proposal;
    this.ngxSmartModalService.getModal(modalName).open();
  }

  onBuyProposalFormFileListChange(selectedFileList: Array<SelectedFile>) {
    this.buyProposalFormFile = null;
    if (selectedFileList) {
      if (selectedFileList.length > 0) {
        this.buyProposalFormFile = selectedFileList[0].file;
      }
    }
  }

  uploadBuyProposalFile(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.buyProposalFormFile) {
        this.azureStorageBlobHelperService.uploadFile(this.buyProposalFormFile, 'requestfiles').then(fileUrl => {
          resolve(fileUrl);
        });
      } else {
        resolve(null);
      }
    });
  }


  openModalProposalItemsDetails(proposal: Proposal) {
    const modalName = 'provider-proposal-items-details_' + this.requestId;
    this.utils.running();
    this.proposalModal = proposal;
    this.ngxSmartModalService.getModal(modalName).open();
    this.utils.finish();
  }

  openModalProposalCompare() {

    const modalName = 'customer-proposal-items-compare_' + this.requestId;
    this.utils.running();
    this.ngxSmartModalService.getModal(modalName).open();
    if (this.request.statusId == 3) {

      this.proposalList.forEach((proposal: Proposal) => {
        proposal.proposalRequestItems.forEach(proposalItem => {
          if (proposalItem.sold == true) {
            this.proposalRequestItem.push(proposalItem)
          }
        })
      })
    }
    this.utils.finish();
  }

  getSaleFromProposal(proposal: Proposal): Sale {
    return proposal.sales ? proposal.sales.find(x => x.statusId == 5) : undefined;
  }

  openPropChoiceReasonOverlayPanel(event: any, proposal: Proposal) {
    this.selectedProposalSaleToShowChoiceReason = this.getSaleFromProposal(proposal);

    if (this.propChoiceReasonOverlayPanel.visible) {
      this.propChoiceReasonOverlayPanel.hide();
    }
    setTimeout(() => {
      this.propChoiceReasonOverlayPanel.show(event);
    }, 250);
  }

  joinProposals(proposal: Proposal, requestItemId: number) {

    if (this.proposalRequestItem.length > 0) {
      let sameProposal = this.proposalRequestItem.find(p => p.requestItemId == requestItemId && p.proposalId == proposal.proposalId)
      if (typeof sameProposal == "undefined") {
        this.proposalRequestItem = this.proposalRequestItem.filter(p => p.requestItemId != requestItemId)
        this.proposalRequestItem.push(proposal.proposalRequestItems.find(pri => pri.requestItemId == requestItemId))
        let proposalItem = this.proposalRequestItem.find(p => p.requestItemId == requestItemId);
        proposalItem.sold = true;
      } else {
        this.proposalRequestItem = this.proposalRequestItem.filter(p => p.requestItemId != requestItemId)
      }
    } else {
      this.proposalRequestItem.push(proposal.proposalRequestItems.find(pri => pri.requestItemId == requestItemId))
      let proposalItem = this.proposalRequestItem.find(p => p.requestItemId == requestItemId);
      proposalItem.sold = true;
    }


  }

  removeFromJoinedProposal(index: number) {
    this.proposalRequestItem.splice(index, 1)
  }
  getCostProposalItemUnityByItem(proposalItem: ProposalRequestItem, quantity: any, itemId: number): string {
    let proposal = this.proposalList.find(p => p.proposalRequestItems[0].proposalId == proposalItem.proposalId)
    if (!proposal.proposalRequestItems) {
      return '';
    }
    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);
    if (!item) {
      return '';
    }
    const itemUnity = item.cost / quantity;
    return itemUnity.toString();
  }
  getCostProposalItemByItem(proposalItem: ProposalRequestItem, itemId: number): string {
    let proposal = this.proposalList.find(p => p.proposalRequestItems[0].proposalId == proposalItem.proposalId)
    if (!proposal.proposalRequestItems) {
      return '';
    }
    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);
    if (!item) {
      return '';
    }
    return item.cost.toString();
  }
  getItemChargesByItem(proposalItem: ProposalRequestItem, itemId: number): string {
    let proposal = this.proposalList.find(p => p.proposalRequestItems[0].proposalId == proposalItem.proposalId)
    if (!proposal.proposalRequestItems) {
      return '';
    }
    const item = proposal.proposalRequestItems.find(p => p.requestItemId === itemId);
    if (!item) {
      return '';
    }

    var charges = item.icms + item.pisCofins + item.ipi + item.iss;

    return charges.toString();
  }

  getClassForSelectedItem(proposal: Proposal, requestItemId: number) {
    let proposalloc = this.proposalRequestItem.find(p => p.requestItemId == requestItemId && p.proposalId == proposal.proposalId)
    if (typeof proposalloc != "undefined") {
      if (proposalloc.sold == true) {
        return 'fractioned'
      }
    }
    return ""
  }

  fractionedTotal(proposal: Proposal) {
    let totalValue = 0;
    this.proposalRequestItem.filter(p => p.proposalId == proposal.proposalId).forEach(p => {
      if (p.sold == true) {
        totalValue += p.cost
      }
    })

    return totalValue;

  }
  fractionedTotalWithChargesAndShip(proposal: Proposal) {
    let totalValue = 0;
    this.proposalRequestItem.filter(p => p.proposalId == proposal.proposalId).forEach(p => {
      if (p.sold == true) {
        totalValue += p.cost + p.ipi + p.iss + p.pisCofins + p.icms;
      }
    })
    return totalValue + proposal.shipping;
  }

  saveProposalitens() {

    this.dialogHelperService.askConfirmation('Você realmente quer comprar de mais de uma proposta?').then(confirmation => {
      if (confirmation) {
        this.hub.sendRequestStatusChange(this.requestId);
        this.utils.running();
        this.dialogHelperService.showSuccessNotification('Sua compra foi executada com sucesso.');
        this.onbuyProposal.emit(this.requestId);
        this.buyProposalFormFile = null;
        this.utils.finish()

      }
    })
  }

  async saveProp() {
    let lastproposal;
    let AllowSell = true;
    let proposalIds: number[] = [];
    let totalBySeller;
    this.proposalRequestItem.forEach(pri => {
      totalBySeller = 0;
      this.proposalRequestItem.filter(i => i.sold == true && i.proposalId == pri.proposalId).forEach(item => {
        if (item.sold == true) {
          totalBySeller += item.cost + item.ipi + item.iss + item.pisCofins;
        }
      })
      let proposal: Proposal = this.proposalList.find(p => p.proposalId == pri.proposalId);
      let shipping = proposal.shipping > 0 ? proposal.shipping : 0;
      totalBySeller += shipping;
      if (totalBySeller < proposal.minPurchase) {
        AllowSell = false;
      }

      if (typeof proposalIds.find(num => num == pri.proposalId) == "undefined" && AllowSell) {
        proposalIds.push(pri.proposalId);
      }
    })
    if (!AllowSell) {
      this.dialogHelperService.showErrorAlert("Uma das compras não atende os valores mínimos de venda do fornecedor!", "Abaixo do Valor Minimo")
      return;
    }
    this.dialogHelperService.askConfirmation('Você realmente quer comprar essa proposta?').then(async confirmation => {
      if (confirmation) {
        this.hub.sendRequestStatusChange(this.requestId);
        this.utils.running();
        //aqui
        for (const proposalItem in this.proposalRequestItem) {
          await this.proposalRequestItemService.save(this.proposalRequestItem[proposalItem]).then(res => { })
        }

        for (const proposalid in proposalIds) {
          let data = this.buyProposalForm.value;
          data.proposalId = proposalIds[proposalid];
          if (lastproposal != proposalIds[proposalid]) {
            await this.saleService.save(data).then((sale: Sale) => {
              lastproposal = proposalIds[proposalid];
            });
          }

        }
        this.onbuyProposal.emit(this.requestId);
        this.buyProposalFormFile = null;
        this.utils.finish();
        this.dialogHelperService.showSuccessNotification("Compra Fracionada feita com sucesso", "Compra")

      }
    });
  }

  costValue(proposal: Proposal) {
    if (!this.haveItems || typeof proposal.proposalRequestItems.find(element => element.sold == true) == "undefined") {

      return proposal.cost;
    }
    let cost = 0;

    proposal.proposalRequestItems.forEach(proposalItem => {
      cost += proposalItem.sold == true ? proposalItem.cost : 0;
    })

    return cost;

  }
  ipiValue(proposal: Proposal) {
    if (!this.haveItems || typeof proposal.proposalRequestItems.find(element => element.sold == true) == "undefined")
      return proposal.ipi;
    let ipi = 0;
    proposal.proposalRequestItems.forEach(proposalItem => {
      ipi += proposalItem.sold == true ? proposalItem.ipi : 0;
    })
    return ipi;

  }
  pisCofinsValue(proposal: Proposal) {
    if (!this.haveItems || typeof proposal.proposalRequestItems.find(element => element.sold == true) == "undefined")
      return proposal.pisCofins;
    let pisCofins = 0;
    proposal.proposalRequestItems.forEach(proposalItem => {
      pisCofins += proposalItem.sold == true ? proposalItem.pisCofins : 0;
    })
    return pisCofins;

  }
  issValue(proposal: Proposal) {
    if (!this.haveItems || typeof proposal.proposalRequestItems.find(element => element.sold == true) == "undefined")
      return proposal.iss;
    let iss = 0;
    proposal.proposalRequestItems.forEach(proposalItem => {
      iss += proposalItem.sold == true ? proposalItem.iss : 0;
    })
    return iss;

  }
  icmsValue(proposal: Proposal) {
    if (!this.haveItems || typeof proposal.proposalRequestItems.find(element => element.sold == true) == "undefined")
      return proposal.icms;

    let icms = 0;
    proposal.proposalRequestItems.forEach(proposalItem => {
      icms += proposalItem.sold == true ? proposalItem.icms : 0;
    })
    return icms;

  }

  enablePurchase() {

    if (typeof this.proposalList.find(proposal => proposal.statusId == 3) == "undefined") {
      return true
    }
    return false

  }


  minPurchase(proposal: Proposal) {

    if (proposal.minPurchase == null) {
      return 0;
    }
    return proposal.minPurchase;
  }

  totalWithTaxandFreight(proposal: Proposal) {

    if (!this.haveItems) {

      return (proposal.cost + proposal.icms + proposal.pisCofins + proposal.ipi + proposal.iss + proposal.shipping)
    }
    return this.costValue(proposal) + this.icmsValue(proposal) + this.pisCofinsValue(proposal) + this.ipiValue(proposal) + this.issValue(proposal) + proposal.shipping
  }

  resetValue() {
    this.proposalRequestItem = [];
    this.loadPage()

  }



  exportSales() {
    type MyArrayType = Array<{ descricao: string, quantidade: string, unidademedida: string, ultimovalor: string }>;

    var arr: any[] = [];

    for (var item of this.request.requestItems) {

      let arrLocal;
      arrLocal = {
        Descricao: item.description,
        Quantidade: item.quantity,
        UnidadeMedida: item.measure,
        UltimoValor: item.product ? item.product.lastBuyValue : '-'
      }

      for (var proposal of this.proposalList) {
        var provider = proposal.company.name + ' - Valor Total: R$:';
        var costItemUnity = this.getCostProposalItemUnity(proposal, item.quantity, item.requestItemId);
        var costTotal = this.getCostProposalItem(proposal, item.requestItemId);
        
        arrLocal[provider] = costTotal;


      }
      arr.push(arrLocal);
    }


    const defaultName = "ComparativoRequisicao" + this.request.requestId;

    const wb = utils.book_new();
    const ws = utils.json_to_sheet(arr);
    utils.book_append_sheet(wb, ws, defaultName);
    writeFile(wb, `${defaultName}.xlsx`);
  };



}

