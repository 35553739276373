import { Injectable } from '@angular/core';
import { UserApi } from 'src/app/core/http/entidades/UserApi';
import { User } from 'src/app/models/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private api: UserApi) {
    this.api.baseName = 'user';
  }
  /** Retorna todos os usuarios */
  getAll(): any {
    return new Promise((result, reject) => {
      this.api.get()
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** Retorna todos os usuarios */
  list(companyId = 0): any {
    return new Promise((result, reject) => {
      if (companyId === 0) {
        this.api.get('/list')
          .then(data => result(data))
          .catch(error => reject(error));
      } else {
        this.api.get('/list/' + companyId)
          .then(data => result(data))
          .catch(error => reject(error));
      }
    });
  }
  listWithOutSegments(companyId = 0): any {
    return new Promise((result, reject) => {
      if (companyId === 0) {
        this.api.get('/listwithoutsegments')
          .then(data => result(data))
          .catch(error => reject(error));
      } else {
        this.api.get('/listwithoutsegments/' + companyId)
          .then(data => result(data))
          .catch(error => reject(error));
      }
    });
  }
  /** buscar um usuario pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um usuario pelo nome */
  getByName(name: string): any {
    return new Promise((result, reject) => {
      this.api.getOne(name, '/findbyname')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  neverLoginUsers(): any {
    return new Promise((result, reject) => {
      this.api.get('/neverlogin')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um usuario pelo nome */
  getByEmail(email: string): any {
    return new Promise((result, reject) => {
      this.api.getOne(email, '/findbyemail')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
   /** buscar um usuario pelo email sem necessidade de token */
   getByEmailWithoutTiken(email: string): any {
    return new Promise((result, reject) => {
      this.api.getOne(email, '/findbyemailwithouttoken')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** usuario do token */
  getUserFromToken(): any {
    return new Promise((result, reject) => {
      this.api.get('/userfromtoken')
        .then(data => result(data))
        .catch(error => reject(error));
    });

  }
  /** Chamar a API de login */
  login(user: User): any {
    return new Promise((resolve, reject) => {
      this.api.post(user, '/login')
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /** Chamar a API de login */
  reactiveLogin(user: User): any {
    return new Promise((resolve, reject) => {
      this.api.post(user, '/reactiveLogin')
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /** aceitar termos de uso */
  setUseTerms(): any {
    return new Promise((result, reject) => {
      this.api.post(null, '/useterms')
        .then(() => {
          result(null);
        })
        .catch(() => reject());
    }
    );
  }
  /** Salvar */
  save(user: User): any {
    return new Promise((resolve, reject) => {
      // se usuario novo, insere
      if (user.userId === 0) {
        this.api.post(user)
          .then(data => {
            this.lostPassword(user);
            resolve(data);
          })
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(user)
          .then(data => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
  /** verificar a senha */
  confirmPassword(password: string): any {
    return new Promise((resolve, reject) => {
      this.api.post(password, '/confirmPassword')
        .then(data => resolve(data))
        .catch(() => {
          reject();
        });
    });
  }
  /** mudar a senha */
  changePassword(password: string): any {
    return new Promise((resolve, reject) => {
      this.api.post(password, '/changePassword')
        .then(() => resolve(null))
        .catch((err) => {
          reject(err);
        });
    });
  }
  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api.delete(id)
        .then(() => {
          resolve(null);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /** Sair do Sistema */
  logout() {
    sessionStorage.clear();
  }
  lostPassword(user: User): void {
    this.api.post(user, '/LostPassword');
  }
  /** usuario do token */
  GetUsersActive(id: any): any {
    return new Promise((result, reject) => {
      this.api.get('/GetUsersActive/' + id)
        .then(data => result(data))
        .catch(error => reject(error));
    });

  }
}
