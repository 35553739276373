import { Injectable } from '@angular/core';
import { ChatApi } from 'src/app/core/http/entidades/ChatApi';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private api: ChatApi) {
    this.api.baseName = 'chat';
  }
  getall(): any {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um segmento pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um segmento pelo Id */
  findByRequest(requestId: number): any {
    return new Promise((result, reject) => {
      this.api.get('/findbyrequest/' + requestId.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** Salvar */
  save(chat: any): any {
    return new Promise((resolve, reject) => {
      if (chat.chatId === 0) { // se segmento novo, insere
        this.api.post(chat)
          .then((data: any) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(chat)
          .then((data: any) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
  /** Fornecedor Declinou */
  requestorDecline(chat: any): any {
    return new Promise((resolve, reject) => {
      chat.statusId = 4;
      this.api.post(chat, '/requestorDecline')
        .then((data: any) => resolve(data))
        .catch(() => {
          reject();
        });
    });
  }
  /** buscar requisições não lidas */
  getNotReadRequestByUserId(): any {
    return new Promise((result, reject) => {
      this.api.get('/GetNotReadRequestByUserId')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** marcar requisição como lida */
  readRequestByRequest(chatId: any): any {
    return new Promise((resolve, reject) => {
      this.api.post(chatId, '/ReadRequestByRequest')
        .then(() => {
          resolve();
        })
        .catch(() => reject());
    });
  }
  /** buscar requisições  declinadas não lidas */
  getNotReadDeclineFromCustomer(): any {
    return new Promise((result, reject) => {
      this.api.get('/GetNotReadDeclineFromCustomer')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** marcar requisição declinada como lida */
  setReadDeclineRequest(requestId: any): any {
    return new Promise((resolve, reject) => {
      this.api.post(requestId, '/SetReadDeclineRequest')
        .then(() => {
          resolve();
        })
        .catch(() => reject());
    });
  }
  /** buscar requisições  canceladas não lidas */
  getNotReadCancelRequestsFromSaler(): any {
    return new Promise((result, reject) => {
      this.api.get('/GetNotReadCancelRequestsFromSaler')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** marcar requisição canceladas como lida */
  setReadCancelRequest(salerId: any, requestId: any): any {
    const readCancelRequestModel = { salerId, requestId };
    return new Promise((resolve, reject) => {
      this.api.post(readCancelRequestModel, '/SetReadCancelRequest')
        .then(() => {
          resolve();
        })
        .catch(() => reject());
    });
  }
}
