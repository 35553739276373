import { Injectable } from '@angular/core';
import { PreChatApi } from 'src/app/core/http/entidades/PreChatApi';

@Injectable({
  providedIn: 'root'
})
export class PrechatService {

  constructor(private api: PreChatApi) {
    this.api.baseName = 'prechat';
  }

  /** buscar um segmento pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** buscar um segmento pelo Id */
  findByRequest(requestId: number): any {
    return new Promise((result, reject) => {
      this.api.get('/findbyrequest/' + requestId.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** Salvar */
  save(chat: any): any {
    return new Promise((resolve, reject) => {
      if (!chat.preChatId) {
        this.api.post(chat)
          .then((data: any) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(chat)
          .then((data: any) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
}
