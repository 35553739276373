import { Component, Input } from '@angular/core';
import { Address } from 'src/app/models/Address';

@Component({
  selector: 'app-address-text',
  templateUrl: './address-text.component.html',
  styleUrls: ['./address-text.component.css']
})
export class AddressTextComponent {

  @Input() address: Address;
  @Input() inline = true;

  constructor() { }

}
