import { Injectable } from '@angular/core';
import { PreProposalApi } from 'src/app/core/http/entidades/PreProposalApi';

@Injectable({
  providedIn: 'root'
})
export class PrepoposalService {

  constructor(private api: PreProposalApi) {
    this.api.baseName = 'preproposal';
  }

  /** Salvar */
  save(entity: any): any {
    return new Promise((resolve, reject) => {
      this.api.post(entity)
        .then((data: any) => resolve(data))
        .catch(() => {
          reject();
        });
    });
  }
}
