import { Injectable } from '@angular/core';
import { Segment } from 'src/app/models/Segment';
import { SegmentApi } from 'src/app/core/http/entidades/SegmentApi';

@Injectable({
  providedIn: 'root'
})
export class SegmentService {

  constructor(private api: SegmentApi) {
    this.api.baseName = 'segment';
  }
  /** buscar todas os segmentos */
  getall(): any {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
  /** buscar todas os segmentos */
  list(): any {
    return new Promise((resolve, reject) => {
      this.api.get('/list')
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um segmento pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
    /** buscar um segmento pelo Id */
  getFromUser(id: number): any {
    return new Promise((result, reject) => {
      this.api.get('/user/' + id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  companyList(id: number): any {
    return new Promise((result, reject) => {
      this.api.get('/companysegments/' + id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  companyNonList(id: number): any {
    return new Promise((result, reject) => {
      this.api.get('/companynonsegments/' + id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um segmento pelo Id */
  getNotUserFromUser(id: number, company: number): any {
    return new Promise((result, reject) => {
      this.api.get('/segmentsnotuse/' + id.toString() + '/' + company.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** buscar um segmento pelo Id */
  getForRequest(companyId: number): any {
    return new Promise((result, reject) => {
      this.api.get('/forrequest/' + companyId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** Salvar */
  save(segment: Segment): any {
    return new Promise((resolve, reject) => {
      // se segmento novo, insere
      if (segment.segmentId === 0) {
        this.api.post(segment)
          .then((data: Segment) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(segment)
          .then((data: Segment) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api.delete(id)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
}
