import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import { Title } from '@angular/platform-browser';
import { UtilsService } from 'src/app/services/core/utils.service';

@Component({
  selector: 'app-plaza-active',
  templateUrl: './plaza-active.component.html',
  styleUrls: ['./plaza-active.component.css']
})
export class PlazaActiveComponent implements OnInit {
  items = [];
  constructor(
    private requestService: RequestService,
    private title: Title,
    private utils: UtilsService) { }

  ngOnInit() {
    this.title.setTitle('Relatório de Adesão Condominio Plaza');
    this.loadPage();
  }

  loadPage() {
    this.utils.running();
    this.requestService.getPlazaCompaniesActives()
      .then((data: []) => {
        this.items = data;
        this.utils.finish();
      });
  }

  download() {
    const headerLine = [
      'Id.',
      'Empresa',
      'Valor',
    ];
    const replacer = (key, value) => value === null ? '' : value;
    const header = Object.keys(this.items[0]);
    const csv = this.items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
    csv.unshift(headerLine.join(';'));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob(['\ufeff', csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'relatorio.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.utils.finish();
  }

}
