import { Component, OnInit, ViewChild, Output, OnDestroy } from "@angular/core";
import { UtilsService } from "src/app/services/core/utils.service";
import { SelectItem } from "primeng/api";
import { OverlayPanel } from "primeng/overlaypanel";
import { InputMask } from "primeng/inputmask";
import { ObjectMap } from "src/app/utils/object-map";
import { DialogHelperService } from "src/app/services/dialog-helper.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SegmentService } from "src/app/services/segment/segment.service";
import * as moment from "moment";
import { Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { ActiveCompanyService } from "src/app/services/active-company.service";
import { User } from "src/app/models/User";
import { PrerequestService } from "src/app/services/prerequest/prerequest.service";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-customer-prerequest-list-page",
  templateUrl: "./customer-prerequest-list-page.component.html",
  styleUrls: ["./customer-prerequest-list-page.component.css"],
})
export class CustomerPrerequestListPageComponent implements OnInit, OnDestroy {
  @ViewChild("dateTimeEditOverlayPanel") dateTimeEditOverlayPanel: OverlayPanel;
  @ViewChild("addressOverlayPanel") addressOverlayPanel: OverlayPanel;
  @ViewChild("productOverlayPanel") productOverlayPanel: OverlayPanel;
  @ViewChild("dateTimeEditInputMask") dateTimeEditInputMask: InputMask;
  @ViewChild("atachmentsOverlayPanel") atachmentsOverlayPanel: OverlayPanel;

  companyId: any;
  requestList: Array<any>;
  requestMap: ObjectMap<any> = new ObjectMap("preRequestId");
  isRequestApiBusy = false;
  isMaster = false;
  filterForm: FormGroup;
  icon = "fas fa-list";
  pageTitle = "Todas as Compras Coletivas Enviadas";

  seeStatusFilter = true;

  filterFormDateRangeOptionList: Array<SelectItem> = [
    {
      label: "Hoje",
      value: "today",
    },
    {
      label: "Última semana",
      value: "last-7-days",
    },
    {
      label: "Últimos 15 dias",
      value: "last-15-days",
    },
    {
      label: "Último mês",
      value: "last-30-days",
    },
    {
      label: "Desde o início",
      value: "all",
    },
  ];
  filterFormSegmentIdOptionList: Array<SelectItem> = [];
  filterFormStatusIdOptionList: Array<SelectItem> = [
    {
      label: "Todos",
      value: null,
    },
    {
      label: "Em andamento",
      value: 1,
    },
    {
      label: "Encerrada",
      value: 3,
    },
    {
      label: "Cancelada",
      value: 2,
    },
  ];

  statusColorMap = {
    1: "#ffc107",
    2: "#b71c1c",
    3: "#64dd17",
    5: "#1976d2",
  };

  dateTimeEditValue = "";
  selectedRequestIdToDateTimeEdit: number = null;
  selectedRequestToShowAddress: any = null;
  selectedRequestToShowProduct: any = null;
  selectedRequestToShowAtachments: any = null;
  subscribeCompany: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private requestService: PrerequestService,
    private route: ActivatedRoute,
    private segmentService: SegmentService,
    private dialogHelperService: DialogHelperService,
    private utils: UtilsService,
    private title: Title,
    private activeCompanyService: ActiveCompanyService
  ) {
    this.utils.running();
  }
  ngOnDestroy(): void {
    if (this.subscribeCompany) {
      this.subscribeCompany.unsubscribe();
    }
  }

  ngOnInit() {
    this.utils.getUserFromSession().then((user: User) => {
      if (user.master || user.operator) {
        this.isMaster = true;
      }
    });
    let statusId = null;
    if (this.route.snapshot.paramMap.get("status")) {
      this.seeStatusFilter = false;
      switch (this.route.snapshot.paramMap.get("status")) {
        case "open":
          statusId = 1;
          this.icon = "fas fa-lock-open";
          this.pageTitle = "Compras Coletivas Em Andamento";
          break;
        case "cancel":
          statusId = 2;
          this.icon = "fas fa-ban";
          this.pageTitle = "Compras Coletivas Canceladas";
          break;
        case "close":
          statusId = 3;
          this.icon = "fas fa-lock";
          this.pageTitle = "Compras Coletivas Encerradas";
          break;
      }
    }
    this.title.setTitle("Compras Coletivas enviadas | " + environment.appTitle);
    this.filterForm = this.formBuilder.group({
      segmentId: [null, Validators.compose([])],
      dateRange: ["last-7-days", Validators.compose([])],
      statusId: [statusId, Validators.compose([])],
      awnserDate: [null, Validators.compose([])],
      requestId: [null, Validators.compose([])],
    });

    this.loadAuxData().then(() => {
      this.loadList().then(() => {
        this.utils.finish();
      });
    });
    this.subscribeCompany = this.activeCompanyService.onActiveCompanyChange.subscribe(
      () => {
        this.loadList().then(() => {
          this.utils.finish();
        });
      }
    );
  }

  reloadItem(preRequestId: number) {
    if (this.seeStatusFilter) {
      this.requestService
        .searchList(
          "customer",
          this.activeCompanyService.activeCompanyId,
          this.filterForm.value.segmentId,
          this.filterForm.value.statusId,
          null,
          null,
          this.filterForm.value.awnserDate,
          this.filterForm.value.preRequestId
        )
        .then((response: Array<any>) => {
          const idx = this.requestList.findIndex(
            (r) => r.preRequestId === preRequestId
          );
          this.requestList[idx] = response.find(
            (r) => r.preRequestId === preRequestId
          );
        });
    } else {
      this.loadList();
    }
  }

  loadList(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.companyId = this.activeCompanyService.activeCompanyId;
      this.requestList = [];
      this.utils.running();
      this.isRequestApiBusy = true;
      let rangeDateStart: Date = null;
      let rangeDateEnd: Date = null;

      const filterFormDateRange: string = this.filterForm.value.dateRange;
      if (filterFormDateRange === "today") {
        rangeDateStart = new Date(moment().format("YYYY-MM-DD") + " 00:00:00");
        rangeDateEnd = new Date(moment().format("YYYY-MM-DD") + " 23:59:59");
      }
      if (filterFormDateRange === "last-7-days") {
        rangeDateStart = new Date(
          moment().subtract(7, "days").format("YYYY-MM-DD") + " 00:00:00"
        );
        rangeDateEnd = new Date(moment().format("YYYY-MM-DD") + " 23:59:59");
      }
      if (filterFormDateRange === "last-15-days") {
        rangeDateStart = new Date(
          moment().subtract(15, "days").format("YYYY-MM-DD") + " 00:00:00"
        );
        rangeDateEnd = new Date(moment().format("YYYY-MM-DD") + " 23:59:59");
      }
      if (filterFormDateRange === "last-30-days") {
        rangeDateStart = new Date(
          moment().subtract(30, "days").format("YYYY-MM-DD") + " 00:00:00"
        );
        rangeDateEnd = new Date(moment().format("YYYY-MM-DD") + " 23:59:59");
      }

      this.requestService
        .searchList(
          "customer",
          this.activeCompanyService.activeCompanyId,
          this.filterForm.value.segmentId,
          this.filterForm.value.statusId,
          rangeDateStart,
          rangeDateEnd,
          this.filterForm.value.awnserDate,
          this.filterForm.value.preRequestId
        )
        .then((response: Array<any>) => {
          this.requestList = response;
          this.requestMap.addList(response);
          this.isRequestApiBusy = false;
          this.utils.finish();
          resolve();
        });
    });
  }

  loadAuxData(): Promise<void> {
    return new Promise((resolve, reject) => {
      const promiseList: Array<Promise<any>> = [];
      promiseList.push(
        new Promise((res, rj) => {
          this.segmentService.getall().then((response) => {
            this.filterFormSegmentIdOptionList.push({
              label: "Todos",
              value: null,
            });
            for (const segment of response) {
              this.filterFormSegmentIdOptionList.push({
                label: segment.name,
                value: segment.segmentId,
              });
            }
            res(null);
          });
        })
      );
      Promise.all(promiseList).then(() => {
        resolve();
      });
    });
  }

  getDisplayItemsList(request: any): string {
    let str = "";
    let index = 0;
    request.preRequestItems.forEach((item) => {
      if (str.length > 0) {
        if (index === request.preRequestItems.length - 1) {
          str += " e ";
        } else {
          str += ", ";
        }
      }
      str += item.description + " (" + item.quantity + " " + item.measure + ")";
      index++;
    });
    return str;
  }

  getDisplayProductList(request: any): string {
    let str = "";
    let index = 0;
    request.preRequestProducts.forEach((product) => {
      if (str.length > 0) {
        if (index === request.preRequestProducts.length - 1) {
          str += " e ";
        } else {
          str += ", ";
        }
      }
      str +=
        product.product.name + " (" + product.qtd + " " + product.measure + ")";
      index++;
    });
    return str;
  }

  getDisplayProductText(request: any): string {
    if (request.preRequestProducts) {
      if (request.preRequestProducts.length > 0) {
        return this.getDisplayProductList(request);
      }
    }
    if (request.preRequestItems) {
      if (request.preRequestItems.length > 0) {
        return this.getDisplayItemsList(request);
      }
    }
    if (request.productDescription) {
      return (
        request.productDescription +
        " (" +
        request.quantity +
        " " +
        request.measure +
        ")"
      );
    } else {
      return "";
    }
  }

  openDateTimeEditOverlayPanel(event: any, request: any) {
    this.selectedRequestIdToDateTimeEdit = request.preRequestId;
    if (this.dateTimeEditOverlayPanel.visible) {
      this.dateTimeEditOverlayPanel.hide();
    }
  }

  openAdressOverlayPanel(event: any, request: any) {
    this.selectedRequestToShowAddress = request;
    if (this.addressOverlayPanel.visible) {
      this.addressOverlayPanel.hide();
    }
  }

  openProductOverlayPanel(event: any, request: any) {
    this.selectedRequestToShowProduct = request;
    if (this.productOverlayPanel.visible) {
      this.productOverlayPanel.hide();
    }
  }

  onDateTimeEditInputMaskComplete() {
    const value: string = this.dateTimeEditInputMask.value;
    const request = this.requestMap.get(
      this.selectedRequestIdToDateTimeEdit.toString()
    );
    this.dialogHelperService
      .askConfirmation(
        "Alterar data e hora limite da requisição " +
          "#" +
          request.preRequestId +
          " para " +
          value +
          "."
      )
      .then((confirmation) => {
        this.dateTimeEditOverlayPanel.hide();
        if (confirmation) {
          const valueParts = value.split(" ");
          request.awnserDate = valueParts[0];
          request.awnserHour = valueParts[1];
          this.requestService.save(request).then(() => {
            this.dialogHelperService.showSuccessNotification(
              "Data limite alterada com sucesso!"
            );
            this.loadList().then(() => {
              this.utils.finish();
            });
          });
        }
      });
  }

  openAtachmentsOverlayPanel(event: any, request: any) {
    this.selectedRequestToShowAtachments = request;
    if (this.atachmentsOverlayPanel.visible) {
      this.atachmentsOverlayPanel.hide();
    }
  }
}
