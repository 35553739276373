import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UtilsService } from 'src/app/services/core/utils.service';
import { Segment } from 'src/app/models/Segment';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Company } from 'src/app/models/Company';
import { User } from 'src/app/models/User';
import { CompanySegment } from 'src/app/models/CompanySegment';
import { UserSegment } from 'src/app/models/UserSegment';
import { CompanyService } from 'src/app/services/company/company.service';
import { UserService } from 'src/app/services/user/user.service';
import { Address } from 'src/app/models/Address';
import { AddressService } from 'src/app/services/address/address.service';
import { UsersegmentService } from 'src/app/services/usersegment/usersegment.service';
import { CompanyTrustRequestor } from 'src/app/models/CompanyTrustRequestor';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-company-add',
  templateUrl: './company-add.component.html',
  styleUrls: ['./company-add.component.css']
})
export class CompanyAddComponent implements OnInit {

  form: FormGroup;
  company: any;
  user: any;
  address: any;
  segments = [];
  dropdownList = [];
  dropdownSettings = {};
  dropdownSettingsCustomer = {};
  dropdownListCustomer = [];

  arrSelectedItemsBuy = [];
  arrSelectedItemsSale = [];
  arrSelectedItemsCompanies = [];

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private utils: UtilsService,
    private http: HttpClient,
    private companyService: CompanyService,
    private userService: UserService,
    private addressService: AddressService,
    private userSegmentsService: UsersegmentService,
    private router: Router,
    private segmentsService: SegmentService
  ) {
    this.title.setTitle('Bem vindo | Qisar');
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'segmentId',
      textField: 'name',
      selectAllText: 'Selecione Tudo',
      unSelectAllText: 'Desmarque Tudo',
      allowSearchFilter: true,
      searchPlaceholderText: 'Pesquisar'

    };
    this.dropdownSettingsCustomer = {
      singleSelection: false,
      idField: 'companyId',
      textField: 'name',
      selectAllText: 'Selecione Tudo',
      unSelectAllText: 'Desmarque Tudo',
      allowSearchFilter: true,
      searchPlaceholderText: 'Pesquisar'

    };

    this.utils.running();
    this.segmentsService.getall()
      .then((s) => {
        this.dropdownList = s;
        this.utils.finish();
      })
      .catch(() => {
        this.utils.finish();
      });
    this.companyService.getallcustomers()
      .then((s) => {
        this.dropdownListCustomer = s;
        this.utils.finish();
      })
      .catch(() => {
        this.utils.finish();
      });
  }

  isInvalidForm(): boolean {
    return !this.form.valid;
  }

  onItemSelectBuy(item: any) {
    this.arrSelectedItemsBuy.push(item);
  }
  onSelectAllBuy(items: any) {
    this.arrSelectedItemsBuy = items;
  }
  onDeSelectBuy(item: any) {
    const idx = this.arrSelectedItemsBuy.findIndex(s => s.segmentId === item.segmentId);
    this.arrSelectedItemsBuy.splice(idx, 1);
  }

  onDeSelectAllBuy(item: any) {
    this.arrSelectedItemsBuy = [];
  }


  onItemSelectSale(item: any) {
    this.arrSelectedItemsSale.push(item);
  }
  onSelectAllSale(items: any) {
    this.arrSelectedItemsSale = items;
  }

  onDeSelectSale(item: any) {
    const idx = this.arrSelectedItemsSale.findIndex(s => s.segmentId === item.segmentId);
    this.arrSelectedItemsSale.splice(idx, 1);
  }

  onDeSelectAllSale(item: any) {
    this.arrSelectedItemsSale = [];
  }

  onItemSelectCompany(item: any) {
    this.arrSelectedItemsCompanies.push(item);
  }
  onSelectAllCompany(items: any) {
    this.arrSelectedItemsCompanies = items;
  }

  onDeSelectCompany(item: any) {
    const idx = this.arrSelectedItemsCompanies.findIndex(s => s.segmentId === item.segmentId);
    this.arrSelectedItemsCompanies.splice(idx, 1);
  }

  onDeSelectAllCompany(item: any) {
    this.arrSelectedItemsCompanies = [];
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      companyName: [null, Validators.compose([Validators.required])],
      companyCNPJ: [null, Validators.compose([Validators.required])],
      stateCode: [null, Validators.compose([Validators.required])],
      companyCEP: [null, Validators.compose([Validators.required])],
      companyStreet: [null, Validators.compose([Validators.required])],
      companyNumber: [null, Validators.compose([Validators.required])],
      companyNeighBorHood: [null, Validators.compose([Validators.required])],
      companyCMPL: [null, Validators.compose([])],
      companyCity: [null, Validators.compose([Validators.required])],
      companyState: [null, Validators.compose([Validators.required])],
      companyPhone: [null, Validators.compose([Validators.required])],
      userEmail: [null, Validators.compose([Validators.required])],
      userName: [null, Validators.compose([Validators.required])],
      selectedItemsBuy: [null, Validators.compose([])],
      selectedItemsSales: [null, Validators.compose([])],
      selectedCustomers: [null, Validators.compose([])],
      isBranch: [null, Validators.compose([])],
    });
  }
  setValidators(): void{
   var isBranch= this.form.value.isBranch; 
   if (isBranch){
     this.form.controls.userEmail.setValidators(Validators.compose([]));
     this.form.controls.userName.setValidators(Validators.compose([]));

     this.form.controls.userEmail.disable();
     this.form.controls.userName.disable();

     this.form.controls.userEmail.setValue("");
     this.form.controls.userName.setValue("");
   }
   else{
    this.form.controls.userEmail.setValidators(Validators.compose([Validators.required]));
    this.form.controls.userName.setValidators(Validators.compose([Validators.required]));

    this.form.controls.userEmail.enable();
    this.form.controls.userName.enable();
   }
   this.form.controls.userEmail.updateValueAndValidity();
   this.form.controls.userName.updateValueAndValidity();
  }

  /** busca o cep */
  searchCep(): void {
    if (this.form.value.companyCEP) {
      if (this.form.value.companyCEP !== '') {
        this.http.get('https://viacep.com.br/ws/' + this.form.value.companyCEP + '/json')
          .toPromise()
          .then((data: any) => {
            this.form.controls.companyStreet.setValue(data.logradouro);
            this.form.controls.companyNeighBorHood.setValue(data.bairro);
            this.form.controls.companyCity.setValue(data.localidade);
            this.form.controls.companyState.setValue(data.uf);
          });
      }
    }
  }
  validCNPJ(): void {
    if (this.form.value.companyCNPJ) {
      if (this.form.value.companyCNPJ !== '') {
        if (!this.utils.validCnpj(this.form.value.companyCNPJ)) {
          Swal.fire('Qisar', 'Ops, CNPJ invalido', 'error');
          this.form.controls.companyCNPJ.setValue('');
        } else {
          this.companyService.getByCNPJ(this.form.value.companyCNPJ)
            .then((data) => {
              if (data) {
                Swal.fire('Qisar', 'Ops, CNPJ já cadastrado', 'error');
                this.form.controls.companyCNPJ.setValue('');
              }
            });
        }
      }
    }
  }
  validEmail(): void {
    if (this.form.value.userEmail) {
      if (this.form.value.userEmail !== '') {
        // tslint:disable-next-line: max-line-length
        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (!regexp.test(this.form.value.userEmail)) {
          Swal.fire('Qisar', 'Ops, E-mail Inválido', 'error');
          this.form.controls.userEmail.setValue('');
          return;
        }
        this.userService.getByEmailWithoutTiken(this.form.value.userEmail).then(user =>{
          if(user){
            Swal.fire('Qisar', 'Ops, E-mail já cadastrado', 'error');
            this.form.controls.userEmail.setValue('');
          }
        })

      }
    }
  }

  save() {
    this.utils.running();
    this.company = new Company();
    this.company.companyId = 0;
    this.company.name = this.form.value.companyName;
    this.company.nickName = this.form.value.companyName;
    this.company.phoneOffice = this.form.value.companyPhone;
    this.company.webSite = '';
    this.company.monthlyPaymentDays = this.form.value.monthlyPaymentDays;
    this.company.monthlyPayment = this.form.value.monthlyPayment;
    this.company.cnpj = this.form.value.companyCNPJ;
    this.company.stateCode = this.form.value.stateCode;
    this.company.isRequestor = (this.arrSelectedItemsSale.length > 0);
    this.company.isCustomer = (this.arrSelectedItemsSale.length > 0);
    this.company.termsStatus = 0;
    this.company.effectiveDate = '';
    this.company.active = true;
    this.address = new Address();
    this.address.actived = true;
    this.address.city = this.form.value.companyCity;
    this.address.cmpl = this.form.value.companyCMPL;
    this.address.neighBorHood = this.form.value.companyNeighBorHood;
    this.address.default = true;
    this.address.deliveryInstruction = '';
    this.address.name = 'Principal';
    this.address.number = Number(this.form.value.companyNumber);
    this.address.state = this.form.value.companyState;
    this.address.street = this.form.value.companyStreet;
    this.address.zipCode = this.form.value.companyCEP;
    this.companyService.save(this.company)
      .then((company) => {
        this.address.addressId = 0;
        this.address.companyId = company.companyId;
        this.addressService.save(this.address);
        this.arrSelectedItemsSale.forEach((s) => {
          const companySegment = new CompanySegment();
          companySegment.companySegmentId = 0;
          companySegment.actived = true;
          companySegment.companyId = company.companyId;
          companySegment.isRequestor = true;
          companySegment.isCustomer = false;
          companySegment.segmentId = s.segmentId;
          this.companyService.addSegmentToCompany(companySegment);
        });
        this.arrSelectedItemsBuy.forEach((s) => {
          const companySegment = new CompanySegment();
          companySegment.companySegmentId = 0;
          companySegment.actived = true;
          companySegment.companyId = company.companyId;
          companySegment.isRequestor = false;
          companySegment.isCustomer = true;
          companySegment.segmentId = s.segmentId;
          this.companyService.addSegmentToCompany(companySegment);
        });

        this.arrSelectedItemsSale.forEach((s) => {
          const companyTrustRequestor = new CompanyTrustRequestor();
          companyTrustRequestor.companyTrustRequestorId = 0;
          companyTrustRequestor.companyId = s.companyId;
          companyTrustRequestor.requestorId = company.companyId;
          this.companyService.addRequestorToCompany(companyTrustRequestor);
        });
        this.user = new User();
        this.user.userId = 0;
        this.user.creationDate = new Date();
        this.user.companyMaster = true;
        this.user.email = this.form.value.userEmail;
        this.user.password = '';
        this.user.master = false;
        this.user.name = this.form.value.userName;
        this.user.useTerms = false;
        this.user.operator = false;
        this.user.companyId = company.companyId;
        this.user.actived = true;
        this.user.avatar = '';
        this.user.tokenChat = '';

        if(!this.form.value.isBranch)
        {
          this.userService.save(this.user)
          .then((us) => {
            this.arrSelectedItemsSale.forEach((sSales) => {
              const userSegment = new UserSegment();
              userSegment.userSegmentId = 0;
              userSegment.maxValue = 0;
              userSegment.userId = us.userId;
              userSegment.isRequestor = true;
              userSegment.isCustomer = false;
              userSegment.segmentId = sSales.segmentId;
              userSegment.companyId = us.companyId;
              userSegment.canCreateRequests = true;
              this.userSegmentsService.save(userSegment);
            });
            this.arrSelectedItemsBuy.forEach((sBuy) => {
              const userSegment = new UserSegment();
              userSegment.userSegmentId = 0;
              userSegment.maxValue = 0;
              userSegment.userId = us.userId;
              userSegment.isRequestor = false;
              userSegment.isCustomer = true;
              userSegment.canCreateRequests = true;
              userSegment.segmentId = sBuy.segmentId;
              userSegment.companyId = us.companyId;
              this.userSegmentsService.save(userSegment);
            });
            this.arrSelectedItemsCompanies.forEach((sCompamies) => {
              const companyTrustRequestor = new CompanyTrustRequestor();
              companyTrustRequestor.companyTrustRequestorId = 0;
              companyTrustRequestor.companyId = sCompamies.companyId;
              companyTrustRequestor.requestorId = company.companyId;
              this.companyService.addRequestorToCompany(companyTrustRequestor);
            });
            this.utils.finish();
            Swal.fire('Qisar', 'Empresa Cadastrada com sucesso', 'success');
            this.router.navigateByUrl('/company/register');
          })
          .catch(() => {
            this.utils.finish();
            Swal.fire('Qisar', 'Erro ao Cadastrar à Empresa', 'error');
          });
        }
        else{
          this.utils.finish();
            Swal.fire('Qisar', 'Empresa Cadastrada com sucesso', 'success');
            this.router.navigateByUrl('/company/register');
        }        
      });
  }

}
