import { Component, OnInit } from '@angular/core';
import { SelectedFile } from 'src/app/components/file-picker/file-picker.component';
import { UtilsService } from 'src/app/services/core/utils.service';
import { ShippingFileService } from 'src/app/services/shippingfile/shippingfile.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shippingfiles-import',
  templateUrl: './shippingfiles-import.component.html',
  styleUrls: ['./shippingfiles-import.component.css']
})
export class ShippingFilesImportComponent implements OnInit {
  constructor(
    private utils : UtilsService,
    private shippingFileService : ShippingFileService
  ) { }
  public SelectedFiles : File[] = [];
  public UploadProgress : number = 0;

  ngOnInit() {
    
  }

  send(){
    this.shippingFileService.sendFiles(
      this.SelectedFiles,
      this.uploadProgressHandle.bind(this)
    )
    .then(data => {
      if(data === true)
        Swal.fire('Qisar', 'Arquivo enviado com sucesso!', 'success');
      else
        Swal.fire('Qisar', 'Falha ao realizar o upload do arquivo de retorno, por favor, contate o suporte', 'error');

      this.clear();
    })
    .catch((err) => {
      console.log(err);
      Swal.fire('Qisar', 'Falha ao realizar o upload do arquivo de retorno, por favor, contate o suporte', 'error');
      this.clear();
    });
  }

  uploadProgressHandle(event : any){
    const percentDone = Math.round(100 * event.loaded / event.total);
    this.UploadProgress = percentDone;
  }

  onSelectFile(files : SelectedFile[]){
    this.SelectedFiles = [];
    files.forEach(file => this.SelectedFiles.push(file.file));
  }

  clear(){
    this.UploadProgress = 0;
    this.SelectedFiles = [];
  }
}
