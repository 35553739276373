import { Component, OnInit } from '@angular/core';
import { MailService } from 'src/app/services/mail.service';

@Component({
  selector: 'app-mail-list-view',
  templateUrl: './mail-list-view.component.html',
  styleUrls: ['./mail-list-view.component.css']
})
export class MailListViewComponent implements OnInit {

  

  constructor(
    public mailService: MailService
  ) { }

  ngOnInit() {
    
  }

  

}
