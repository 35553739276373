import { Component, OnInit } from '@angular/core';
import { SelectedFile } from 'src/app/components/file-picker/file-picker.component';
import { User } from 'src/app/models/User';
import { CompanyWorkService } from 'src/app/services/companywork/companywork.service';
import { ImportFileService } from 'src/app/services/importfiles/importfile.service';
import { UtilsService } from 'src/app/services/core/utils.service';
import Swal from 'sweetalert2';
import { ImportFile } from 'src/app/models/ImporFile';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AzureBlobUploaderService } from 'src/app/services/azure-blob-uploader.service';
import { Router } from '@angular/router';




@Component({
    selector: 'app-import-file',
    templateUrl: './import-request.component.html'
})
export class ImportRequestComponent implements OnInit {
    selectedFileList: Array<SelectedFile> = [];
    companyWorks = [];
    form: FormGroup;
    currentUser: User;
    files: ImportFile[];

    constructor(
        private formBuilder: FormBuilder,
        private companyWorkService: CompanyWorkService,
        private importFileService: ImportFileService,
        private router: Router,
        public azureBlobUploaderService: AzureBlobUploaderService,
        private utils: UtilsService) { }


    ngOnInit(): void {
        this.form = this.formBuilder.group({
            referenceId: new FormControl('')
        });
        this.getFiles();

        this.utils.getUserFromSession().then((user: User) => {
            this.currentUser = user;

            this
                .companyWorkService
                .getActived(user.companyId)
                .then((data: any[]) => {
                    this.companyWorks.push({
                        label: "Nenhuma",
                        value: null,
                    });
                    data.forEach(companyWork => {
                        this.companyWorks.push({
                            label: companyWork.name,
                            value: companyWork.companyWorkId,
                        });
                    });
                })
        });
    }

    onFileListChange(selectedFileList: Array<SelectedFile>) {
        this.selectedFileList = selectedFileList;
    }

    sendImportRequest() {
        this.selectedFileList.forEach(file => {
            const fileNameParts: Array<string> = file.file.name.split(".");
            let extension: string = null;
            if (fileNameParts.length > 1) {
              extension = fileNameParts[fileNameParts.length - 1]
            }
            const importFile: ImportFile = {
                ImportFileId: 0,
                companyId: this.currentUser.companyId,
                fileName: file.file.name,
                fileExtension: extension,
                uri: this.azureBlobUploaderService.getStatus(file.id).url + '?sp=r&st=2019-05-17T16:28:19Z&se=2030-05-18T16:28:00Z&sv=2018-03-28&sig=DOTEO5B6zoRGkShxcTYvz25GTmJS41DHOraCXGuvAj8%3D&sr=c',
                stateId: 1,
                companyBusinessCode: '',
                companyWorkId: 0,
                userId: this.currentUser.userId
            };
            this
                .importFileService
                .save(importFile)
                .then(() => {
                    Swal.fire('Qisar', 'Arquivo importado com sucesso', 'info');
                    this.router.navigate(['/customer/request/list/open'])   
                })
                .catch(() => Swal.fire('Qisar', 'Erro ao importar o arquivo', 'error'));
        });
    }


    getFiles() {
        this.importFileService.get().then((data: []) =>  {
            this.files = data;
            console.log(this.files);
        })
    }

    getStatus(id: number){
        switch(id){
            case 1: 
                return "Pendente";
            case 2:
                return "Processando";
            case 3:
                return "Cancelado";
            case 4:
                return "Erro ao processar";
            case 5:
                return "Processado com sucesso";
        }
    }

}