import { Component, OnInit } from '@angular/core';
import { OffersService } from 'src/app/services/offers/offers.service';
import { UtilsService } from 'src/app/services/core/utils.service';
import { requireConfirm } from 'src/app/utils/decorators/requireConfirm';
import { Router } from '@angular/router';

@Component({
  selector: 'app-offernegotiation',
  templateUrl: './offernegotiation.component.html',
  styleUrls: ['./offernegotiation.component.css']
})
export class OffernegotiationComponent implements OnInit {
  offers = [];

  constructor(
    private offerServices: OffersService,
    private router: Router,
    private utils: UtilsService,
  ) { }

  edit(Offer: any) {
    this.router.navigateByUrl('/offer/edit/' + Offer.offerId);
  }

  @requireConfirm('Qisar', 'Confirma a exclusão dessa oferta?')
  delete(Offer: any) {
    this.offerServices.remove(Offer)
      .then(() => this.loadOffers())
      .catch((err) => console.error(err.error));
  }

  ngOnInit() {
    this.loadOffers();
  }

  loadOffers() {
    this.utils.getUserFromSession()
      .then(response => {
        const currentUser = response;
        this.offerServices.offersByCompanyAndUser(currentUser.companyId, currentUser.userId)
          .then((offers: []) => this.offers = offers);
      });
  }
}
