import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.css']
})
export class ProcessingComponent implements OnInit {

  @Input() totalSteps: number = 0;
  @Input() completedSteps: number = 0;

  processingText: string = "Processando..."
  completedText: string = "Pronto!"

  constructor() { }

  ngOnInit() {
  }

}
