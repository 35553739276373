import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { MenuItem, SelectItem } from "primeng/api";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DialogHelperService } from "src/app/services/dialog-helper.service";
import { SegmentService } from "src/app/services/segment/segment.service";
import { ArrayHelper } from "src/app/utils/array-helper";
import { AuthService } from "src/app/services/auth.service";
import { Segment } from "src/app/models/Segment";
import { ObjectMap } from "src/app/utils/object-map";
import { SubSegmentService } from "src/app/services/subsegment/subsegment.service";
import { ProductService } from "src/app/services/product/product.service";
import { AddressService } from "src/app/services/address/address.service";
import { Address } from "src/app/models/Address";
import { Product } from "src/app/models/Product";
import { SubSegment } from "src/app/models/SubSegment";
import { Request } from "src/app/models/Request";
import * as moment from "moment";
import { AzureStorageBlobHelperService } from "src/app/services/azure-storage-blob-helper.service";
import { UtilsService } from "src/app/services/core/utils.service";
import { User } from "src/app/models/User";
import { Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { ActiveCompanyService } from "src/app/services/active-company.service";
import { SelectedFile } from "src/app/components/file-picker/file-picker.component";
import { AzureBlobUploaderService } from "src/app/services/azure-blob-uploader.service";
import { CustomValidators } from "src/app/utils/custom-validators";
import { RequestItem } from "src/app/models/RequestItem";
import { PrerequestService } from "src/app/services/prerequest/prerequest.service";
import { PrerequestproductService } from "src/app/services/prerequest/prerequestproduct.service";
import { PrerequestitemService } from "src/app/services/prerequest/prerequestitem.service";
import { PrerequestatachmentService } from "src/app/services/prerequest/prerequestatachment.service";
import { PrerequestcompanyService } from "src/app/services/prerequest/prerequestcompany.service";
import { PrechatService } from "src/app/services/prerequest/prechat.service";
import Swal from "sweetalert2";
import { Subscription } from "rxjs";


interface ProductItem {
  productId: number;
  qtd: number;
  measure: string;
}

@Component({
  selector: "app-customer-prerequest-add-page",
  templateUrl: "./customer-prerequest-add-page.component.html",
  styleUrls: ["./customer-prerequest-add-page.component.css"],
})
export class CustomerPrerequestAddPageComponent implements OnInit, OnDestroy {
  dropdownList = [];
  dropdownSettings = {};
  selected = [];

  stepList: Array<MenuItem> = [
    {
      label: "Segmento",
    },
    {
      label: "Produto",
    },
    {
      label: "Entrega",
    },
    {
      label: "Prazo de Resposta",
    },
  ];
  stepsActiveIndex = 0;
  pt = {
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "eg", "ter", "qua", "qui", "sex", "sab"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Feveiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
  };
  minDate = new Date();
  form: FormGroup;
  productForm: FormGroup;
  itemsForm: FormGroup;

  productSelectionType: string; // "one", "many" or "text"

  productTypeList = [
    {
      productTypeId: 1,
      name: "Ativo",
    },
    {
      productTypeId: 2,
      name: "Consumo",
    },
    {
      productTypeId: 3,
      name: "Industrializado",
    },
    {
      productTypeId: 4,
      name: "Outros",
    },
  ];

  segmentOptionList: Array<SelectItem> = [];
  subSegmentOptionList: Array<SelectItem> = [];
  addressOptionList: Array<SelectItem> = [];
  productObjectOptionList: Array<SelectItem> = [];
  productTypeOptionList: Array<SelectItem> = [];
  timeOptionList: Array<SelectItem> = [
    {
      label: "",
      value: null,
    },
    {
      label: "00:00",
      value: "00:00:00",
    },
    {
      label: "01:00",
      value: "01:00:00",
    },
    {
      label: "02:00",
      value: "02:00:00",
    },
    {
      label: "03:00",
      value: "03:00:00",
    },
    {
      label: "04:00",
      value: "04:00:00",
    },
    {
      label: "05:00",
      value: "05:00:00",
    },
    {
      label: "06:00",
      value: "06:00:00",
    },
    {
      label: "07:00",
      value: "07:00:00",
    },
    {
      label: "08:00",
      value: "08:00:00",
    },
    {
      label: "09:00",
      value: "09:00:00",
    },
    {
      label: "10:00",
      value: "10:00:00",
    },
    {
      label: "11:00",
      value: "11:00:00",
    },
    {
      label: "12:00",
      value: "12:00:00",
    },
    {
      label: "13:00",
      value: "13:00:00",
    },
    {
      label: "14:00",
      value: "14:00:00",
    },
    {
      label: "15:00",
      value: "15:00:00",
    },
    {
      label: "16:00",
      value: "16:00:00",
    },
    {
      label: "17:00",
      value: "17:00:00",
    },
    {
      label: "18:00",
      value: "18:00:00",
    },
    {
      label: "19:00",
      value: "19:00:00",
    },
    {
      label: "20:00",
      value: "20:00:00",
    },
    {
      label: "21:00",
      value: "21:00:00",
    },
    {
      label: "22:00",
      value: "22:00:00",
    },
    {
      label: "23:00",
      value: "23:00:00",
    },
  ];

  segmentMap: ObjectMap<Segment> = new ObjectMap("segmentId");
  subSegmentMap: ObjectMap<SubSegment> = new ObjectMap("subSegmentId");
  productMap: ObjectMap<Product> = new ObjectMap("productId");
  addressMap: ObjectMap<Address> = new ObjectMap("addressId");
  productTypeMap: ObjectMap<any> = new ObjectMap("productTypeId");

  selectedProductList: Array<any> = [];
  selectedItemList: Array<any> = [];
  selectedFileList: Array<SelectedFile> = [];
  selectedSubSegmentMeasure: string;

  isSaving = false;

  manyItems = false;

  currentUser: User;
  subscribeCompany: Subscription;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private dialogHelperService: DialogHelperService,
    private segmentService: SegmentService,
    private subSegmentService: SubSegmentService,
    private productService: ProductService,
    private addressService: AddressService,
    private requestService: PrerequestService,
    private preChat: PrechatService,
    private requestProductService: PrerequestproductService,
    private requestItemService: PrerequestitemService,
    private requestAttachmentService: PrerequestatachmentService,
    private requestCompanies: PrerequestcompanyService,
    private utils: UtilsService,
    private title: Title,
    private activeCompanyService: ActiveCompanyService,
    public azureBlobUploaderService: AzureBlobUploaderService
    
  ) {}
  ngOnDestroy(): void {
    if (this.subscribeCompany) {
      this.subscribeCompany.unsubscribe();
    }
  }

  changeMultiItems() {
    this.selectedItemList = [];
    this.manyItems = !this.manyItems;
  }

  ngOnInit() {
    this.title.setTitle("Nova compra coletiva | " + environment.appTitle);

    this.utils.getUserFromSession().then((user) => {
      let hasManyCompanies = true;
      if (!user.userCompanies) {
        hasManyCompanies = false;
      } else {
        if (user.userCompanies.length === 0) {
          hasManyCompanies = false;
        }
      }
      if (hasManyCompanies) {
        for (const userCampany of user.userCompanies) {
          if (
            userCampany.company.companyId !==
            this.activeCompanyService.activeCompanyId
          ) {
            this.dropdownList.push(userCampany.company);
          }
        }
      } else {
        if (user.company) {
          this.dropdownList.push(user.company);
        }
      }
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: "companyId",
      textField: "name",
      selectAllText: "Selecione Tudo",
      unSelectAllText: "Desmarque Tudo",
      allowSearchFilter: false,
    };
    this.utils.getUserFromSession().then((response) => {
      this.currentUser = response;
      this.form = this.formBuilder.group({
        segmentId: [null, Validators.compose([Validators.required])],
        subSegmentId: [null, Validators.compose([Validators.required])],
        productTypeId: [null, Validators.compose([Validators.required])],
        product: [null, Validators.compose([])],
        quantity: [null, Validators.compose([])],
        noTaxes: [null, Validators.compose([])],
        manyItems: [null, Validators.compose([])],
        productDescription: [null, Validators.compose([])],
        addressId: [null, Validators.compose([Validators.required])],
        deliveryInstructions: [null, Validators.compose([Validators.required])],
        awnserDate: [
          null,
          Validators.compose([
            Validators.required,
            CustomValidators.minDate(new Date()),
          ]),
        ],
        awnserHour: [null, Validators.compose([Validators.required])],
        measure: [null, Validators.compose([])],
      });
      this.productForm = this.formBuilder.group({
        product: [null, Validators.compose([Validators.required])],
        qtd: [null, Validators.compose([Validators.required])],
        measure: [null, Validators.compose([])],
      });
      this.itemsForm = this.formBuilder.group({
        product: [null, Validators.compose([Validators.required])],
        qtd: [null, Validators.compose([Validators.required])],
        measure: [null, Validators.compose([Validators.required])],
      });
      this.loadAuxData();
      this.utils.finish();
    });
    this.subscribeCompany = this.subscribeCompany = this.activeCompanyService.onActiveCompanyChange.subscribe(
      () => {
        this.loadAuxData();
        this.clearFormSegmentIdChildData();
        this.stepsActiveIndex = 0;
      }
    );
  }

  getParticipantes(): string {
    let str = "";
    let index = 0;
    this.selected.forEach((company) => {
      if (str.length > 0) {
        if (index === this.selected.length - 1) {
          str += " e ";
        } else {
          str += ", ";
        }
      }
      str += company.name;
      index++;
    });
    return str;
  }

  loadAuxData(): Promise<void> {
    return new Promise((rs, rj) => {
      this.authService.waitAuth().then(() => {
        const promiseList: Array<Promise<any>> = [];
        promiseList.push(
          new Promise((resolve, reject) => {
            this.segmentService
              .getForRequest(this.activeCompanyService.activeCompanyId)
              .then((response) => {
                this.segmentMap.addList(response);
                this.segmentOptionList = ArrayHelper.toOptionList(
                  response,
                  "segmentId",
                  "name"
                );
                resolve();
              });
          })
        );

        promiseList.push(
          new Promise((resolve, reject) => {
            this.addressService
              .findByCompany(this.activeCompanyService.activeCompanyId)
              .then((response) => {
                this.addressOptionList = ArrayHelper.toOptionList(
                  response,
                  "addressId",
                  "name"
                );
                this.addressMap.addList(response);
                resolve();
              });
          })
        );

        this.productTypeMap.addList(this.productTypeList);
        this.productTypeOptionList = ArrayHelper.toOptionList(
          this.productTypeList,
          "productTypeId",
          "name"
        );

        Promise.all(promiseList).then(() => {
          rs();
        });
      });
    });
  }

  checkActiveStepValidation(): boolean {
    if (!this.form) {
      return false;
    }
    if (this.stepsActiveIndex === 0) {
      if (!this.form.controls.segmentId.valid) {
        return false;
      }
      if (!this.form.controls.subSegmentId.valid) {
        return false;
      }
    }
    if (this.stepsActiveIndex === 1) {
      if (!this.form.controls.productTypeId.valid) {
        return false;
      }
      if (!this.form.controls.product.valid) {
        return this.manyItems ? this.selectedItemList.length > 0 : false;
      }
      if (!this.form.controls.productDescription.valid) {
        return this.manyItems ? this.selectedItemList.length > 0 : false;
      }
      if (!this.form.controls.quantity.valid) {
        return this.manyItems ? this.selectedItemList.length > 0 : false;
      }
      if (this.productSelectionType === "many") {
        if (this.selectedProductList.length === 0) {
          return false;
        }
      }
    }
    if (this.stepsActiveIndex === 2) {
      if (!this.form.controls.addressId.valid) {
        return false;
      }
      if (!this.form.controls.deliveryInstructions.valid) {
        return false;
      }
    }
    if (this.stepsActiveIndex === 3) {
      if (!this.form.controls.awnserDate.valid) {
        return false;
      }
      if (!this.form.controls.awnserHour.valid) {
        return false;
      }
    }

    return true;
  }

  goNextStep() {
    this.stepsActiveIndex += 1;
  }

  goPrevStep() {
    this.stepsActiveIndex -= 1;
  }

  onFileListChange(selectedFileList: Array<SelectedFile>) {
    this.selectedFileList = selectedFileList;
  }

  onFormSegmentIdChange() {
    this.clearFormSegmentIdChildData();
    const segmentId: number = this.form.value.segmentId;
    if (segmentId) {
      this.subSegmentService.getFromSegment(segmentId).then((response) => {
        this.subSegmentOptionList = ArrayHelper.toOptionList(
          response,
          "subSegmentId",
          "name"
        );
        this.subSegmentMap.addList(response);
      });
    }
  }

  onFormSubSegmentIdChange() {
    this.clearFormSubSegmentIdChildData();
    const subSegmentId: number = this.form.value.subSegmentId;
    const segmentId: number = this.form.value.segmentId;
    const subSegment = this.subSegmentMap.get(subSegmentId.toString());
    let isManySelectionType: boolean;
    if (subSegment.requestHasManyProducts) {
      isManySelectionType = true;
    }
    if (segmentId) {
      let productList: Array<Product> = [];
      this.productService.getFromSegment(segmentId).then((response) => {
        productList = response;
        this.productService
          .getFromSubSegment(subSegmentId)
          .then((response2) => {
            if (response2.length > 0) {
              productList = response;
            }
            if (!isManySelectionType) {
              if (productList.length > 0) {
                this.productSelectionType = "one";
                this.form.controls.product.setValidators([Validators.required]);
                this.form.controls.productDescription.setValidators([]);
                this.form.controls.quantity.setValidators([
                  Validators.required,
                  Validators.min(1),
                ]);
              } else {
                this.productSelectionType = "text";
                this.form.controls.product.setValidators([]);
                this.form.controls.productDescription.setValidators([
                  Validators.required,
                ]);
                this.form.controls.quantity.setValidators([
                  Validators.required,
                  Validators.min(1),
                ]);
              }
            } else {
              this.productSelectionType = "many";
              this.form.controls.product.setValidators([]);
              this.form.controls.productDescription.setValidators([]);
              this.form.controls.quantity.setValidators([]);
            }

            let measure: string = this.subSegmentMap.get(
              this.form.value.subSegmentId
            ).measure;
            if (measure === "") {
              measure = null;
            }
            this.selectedSubSegmentMeasure = measure;

            if (this.selectedSubSegmentMeasure) {
              this.form.controls.measure.setValidators([]);
            } else {
              this.form.controls.measure.setValidators([Validators.required]);
            }
            this.productMap.addList(productList);
            this.productObjectOptionList = ArrayHelper.toObjectOptionList(
              productList,
              "name"
            );
          });
      });
    }
  }

  onFormAddressIdChange() {
    this.form.controls.deliveryInstructions.setValue(null);
    const addressId: number = this.form.value.addressId;
    if (addressId) {
      const address: Address = this.addressMap.get(addressId.toString());
      this.form.controls.deliveryInstructions.setValue(
        address.deliveryInstruction
      );
    }
  }

  save() {
    this.dialogHelperService
      .askConfirmation("Você deseja enviar essa requisição?")
      .then((confirmation) => {
        if (confirmation) {
          this.utils.running();
          const requestData: any = this.form.value;
          requestData.userId = this.currentUser.userId;
          requestData.awnserDate = moment(requestData.awnserDate).format(
            "DD/MM/YYYY"
          );
          requestData.companyId = this.currentUser.companyId;
          requestData.statusId = 1;

          requestData.preRequestId = 0;

          if (!requestData.quantity) {
            requestData.quantity = 0;
          }

          let measure: string = this.selectedSubSegmentMeasure;
          if (!measure) {
            measure = this.form.value.measure;
          }
          requestData.measure = measure;

          if (this.activeCompanyService.activeCompanyId) {
            requestData.companyId = this.activeCompanyService.activeCompanyId;
          }

          this.requestService.save(requestData).then((response: any) => {
            const requestId: number = response.preRequestId;
            this.selected.forEach((company) => {
              const preRequestCompany: any = {
                companyId: company.companyId,
                statusId: 1,
                preRequestId: requestId,
                preRequestCompanyId: 0,
              };
              this.requestCompanies.save(preRequestCompany);

              const preChat: any = {
                companyId: company.companyId,
                trustCompany: true,
                statusId: 1,
                preRequestId: requestId,
              };
              this.preChat.save(preChat);
            });
            const productListAddPromiseList: Array<Promise<any>> = [];
            const itemListAddPromiseList: Array<Promise<any>> = [];
            const fileListAddPromiseList: Array<Promise<any>> = [];
            if (this.form.value.product) {
              let measureValue = this.selectedSubSegmentMeasure;
              if (!measureValue) {
                measureValue = this.form.value.measure;
              }
              this.selectedProductList.push({
                productId: this.form.value.product.productId,
                qtd: this.form.value.quantity,
                measure: measureValue,
              });
            }
            this.isSaving = true;
            for (const product of this.selectedProductList) {
              productListAddPromiseList.push(
                new Promise((resolve, reject) => {
                  this.requestProductService
                    .save({
                      preRequestId: requestId,
                      preRequestProductId: 0,
                      productId: product.productId,
                      qtd: product.qtd,
                      measure: product.measure,
                    })
                    .then(() => {
                      resolve();
                    });
                })
              );
            }
            for (const item of this.selectedItemList) {
              itemListAddPromiseList.push(
                new Promise((resolve, reject) => {
                  item.preRequestId = requestId;
                  item.preRequestItemId = 0;
                  this.requestItemService.save(item).then(() => {
                    resolve();
                  });
                })
              );
            }
            const uploadIdList: Array<string> = ArrayHelper.getPropertyList(
              this.selectedFileList,
              "id"
            );
            this.azureBlobUploaderService
              .checkUploadIsComplete(uploadIdList)
              .then((urlList) => {
                for (const url of urlList) {
                  fileListAddPromiseList.push(
                    new Promise((resolve, reject) => {
                      this.requestAttachmentService
                        .save({
                          requestId,
                          uri: url,
                        })
                        .then(() => {
                          resolve();
                        });
                    })
                  );
                }
              });
            Promise.all(itemListAddPromiseList).then(() => {
              Promise.all(productListAddPromiseList).then(() => {
                Promise.all(fileListAddPromiseList).then(() => {});
              });
            });
            this.dialogHelperService.showSuccessNotification(
              "A compra coletiva #" + requestId + " foi criada."
            );
            this.utils.finish();
            this.router.navigateByUrl("/customer/prerequest/list/open");
          });
        }
      });
  }
  removeItem(i) {
    this.dialogHelperService
      .askConfirmation("Deseja remover esse item?")
      .then((confirmation) => {
        if (confirmation) {
          this.selectedItemList.splice(i, 1);
        }
      });
  }
  addItem() {
    if (this.itemsForm.valid) {
      const requestItem: RequestItem = {
        description: this.itemsForm.value.product,
        quantity: this.itemsForm.value.qtd,
        measure: this.itemsForm.value.measure,
        requestId: 0,
        requestItemId: 0,
        request: null,
      };
      this.selectedItemList.push(requestItem);
      this.itemsForm.reset();
    } else {
      const formKeyList: Array<string> = Object.keys(this.itemsForm.controls);
      for (const key of formKeyList) {
        this.itemsForm.controls[key].markAsTouched();
      }
    }
  }

  addProduct() {
    if (this.productForm.valid) {
      if (
        this.selectedProductList.some(
          (item) => item.productId === this.productForm.value.product.productId
        )
      ) {
        this.dialogHelperService.showErrorNotification(
          "O produto que você escolheu já foi adicionado.",
          "Produto já adicionado!"
        );
      } else {
        const productItem: ProductItem = {
          productId: this.productForm.value.product.productId,
          qtd: this.productForm.value.qtd,
          measure: this.productForm.value.measure,
        };
        if (!productItem.measure) {
          productItem.measure = this.selectedSubSegmentMeasure;
        }
        this.selectedProductList.push(productItem);
        this.productForm.reset();
      }
    } else {
      const formKeyList: Array<string> = Object.keys(this.productForm.controls);
      for (const key of formKeyList) {
        this.productForm.controls[key].markAsTouched();
      }
    }
  }

  removeProduct(index: number) {
    this.selectedProductList.splice(index, 1);
  }

  clearFormSegmentIdChildData() {
    this.clearFormSubSegmentIdChildData();
    this.form.controls.subSegmentId.reset();
    this.subSegmentOptionList = [];
  }

  clearFormSubSegmentIdChildData() {
    this.productSelectionType = null;
    this.form.controls.product.reset();
    this.form.controls.productDescription.reset();
    this.form.controls.quantity.reset();
    this.selectedProductList = [];
    this.selectedFileList = [];
    this.productObjectOptionList = [];
    this.productForm.reset();
  }

  openFile(file: File) {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      window.open(fileReader.result.toString(), "blank");
    };
    fileReader.readAsDataURL(file);
  }

}
