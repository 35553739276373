import { Injectable } from '@angular/core';
import { OfferPhotoApi } from 'src/app/core/http/entidades/OfferPhotoApi';

@Injectable({
  providedIn: 'root'
})
export class OfferphotosService {

  constructor(private api: OfferPhotoApi) {
    this.api.baseName = 'offerphoto';
  }

  save(offer: any) {
    return new Promise((resolve, reject) => {
      if (offer.offerPhotoId === 0) {
        this.api.post(offer)
          .then(data => resolve(data))
          .catch(error => reject(error));
      } else {
        this.api.put(offer)
          .then(data => resolve(data))
          .catch(error => reject(error));
      }
    });
  }
}
