import { Injectable } from "@angular/core";
import { CompanyApi } from "src/app/core/http/entidades/CompanyApi";
import { Company } from "src/app/models/Company";
import { CompanySegment } from "src/app/models/CompanySegment";
import { CompanySegmentApi } from "src/app/core/http/entidades/CompanySegmentApi";
import { SummitCompany } from "src/app/models/SummitCompany";
import { CompanyTrustRequestor } from "src/app/models/CompanyTrustRequestor";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  companyUFs = [];

  constructor(
    private api: CompanyApi,
    private apiCompanySegment: CompanySegmentApi
  ) {
    this.api.baseName = "company";
    this.apiCompanySegment.baseName = "companysegment";
  }
  /** buscar todas as empresas */
  getall(): any {
    return new Promise((resolve, reject) => {
      this.api
        .get()
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }
  /** buscar todas as empresas */
  getallcustomers(): any {
    return new Promise((resolve, reject) => {
      this.api
        .get("/customers/list")
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }
  /** buscar todas as empresas */
  list(companyId = 0): any {
    if (companyId > 0) {
      return new Promise((resolve, reject) => {
        this.api
          .get("/list/" + companyId)
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    } else {
      return new Promise((resolve, reject) => {
        this.api
          .get("/list")
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    }
  }
  /** buscar uma empresa pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api
        .getOne(id.toString())
        .then((data) => result(data))
        .catch((error) => reject(error));
    });
  }
  /** buscar uma empresa pelo nome */
  getByName(name: string): any {
    return new Promise((result, reject) => {
      this.api
        .getOne(name, "/findbyname")
        .then((data) => result(data))
        .catch((error) => reject(error));
    });
  }
  /** buscar uma empresa pelo cnpj */
  getByCNPJ(cnpj: string): any {
    return new Promise((result, reject) => {
      this.api
        .getOne(cnpj, "/findbycnpj")
        .then((data) => result(data))
        .catch((error) => reject(error));
    });
  }
  /** Salvar */
  save(company: Company): any {
    return new Promise((resolve, reject) => {
      // se empresa nova, insere
      if (company.companyId === 0) {
        this.api
          .post(company)
          .then((data) => resolve(data))
          .catch(() => {
            reject();
          });
      } else {
        // senão atualiza
        this.api
          .put(company, "/UpdateAndAcceptTerm")
          .then((data) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
  /** Salvar */
  summit(company: Company): any {
    return new Promise((resolve, reject) => {
      this.api
        .post(company, "/AcceptSummit")
        .then((data) => resolve(data))
        .catch(() => {
          reject();
        });
    });
  }
  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api
        .delete(id)
        .then(() => {
          resolve(null);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /** adicionar segmento à uma empresa */
  addSegmentToCompany(companySegment: CompanySegment): any {
    return new Promise((resolve, reject) => {
      if (companySegment.companySegmentId === 0) {
        return this.apiCompanySegment
          .post(companySegment)
          .then((data: CompanySegment) => resolve(data))
          .catch(() => reject());
      } else {
        return this.apiCompanySegment
          .put(companySegment)
          .then((data: CompanySegment) => resolve(data))
          .catch(() => reject());
      }
    });
  }
  /** adicionar segmento à uma empresa */
  addRequestorToCompany(companyTrustRequestor: CompanyTrustRequestor): any {
    return new Promise((resolve, reject) => {
      return this.api
        .post(companyTrustRequestor, "/AddRequestorToCompany")
        .then((data: CompanySegment) => resolve(data))
        .catch(() => reject());
    });
  }
  /** remover um segmento de uma empresa */
  removeSegmentFromCompany(companySegment: CompanySegment) {
    return new Promise((resolve, reject) => {
      return this.apiCompanySegment
        .delete(companySegment.companySegmentId)
        .then(() => resolve(null))
        .catch(() => reject());
    });
  }

  /**
   * Segmentos por Vendedores
   */
  ReportSegmentRequestors(): any {
    return new Promise((resolve, reject) => {
      this.api
        .get("/ReportSegmentRequestors")
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  /** Busca Fornecedor */
  getVendors(filter: string): Promise<Company[]> {
    return new Promise((resolve, reject) => {
      this.api
        .get(`/vendors/${filter}`)
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  /** Busca Empresas Summit */
  getSummit(): Promise<SummitCompany[]> {
    return new Promise((resolve, reject) => {
      this.api
        .get(`/summit`)
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  /** Busca Boletos Atrasados */
  LatePayments(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api
        .get("/LatePayments")
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  AddRequestorToCustomerTrust(customerid, requestorid): Promise<any> {
    return new Promise((resolve, reject) => {
      const obj = { customerid, requestorid };
      this.api
        .post(obj, "/AddRequestorToCustomerTrust")
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  CancelCompanyInvoice(invoice): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api
        .post(invoice, "/CancelCompanyInvoice")
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }
  DownCompanyInvoice(invoice): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api
        .post(invoice, "/DownCompanyInvoice")
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  /** buscar planos das empresas */
  getCompanyPlansByCustomerId(CustomerId: number): any {
    return new Promise((resolve, reject) => {
      this.api
        .get("/GetCompanyPlansByCustomerId/" + CustomerId)
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  getCompanyProducts(companyId: number): any {
    return new Promise((resolve, reject) => {
      this.api
        .get("/GetCompanyProducts/" + companyId)
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  getCompanyProductsByName(itemDescription: string, companyId: number) {
    return new Promise((resolve, reject) => {
      this.api
        .get("/GetCompanyProductsByName/" + itemDescription + "/" + companyId)
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  getCompanyProductsSearch(companyId: number, name: string) {
    return new Promise((resolve, reject) => {
      this.api
        .get("/GetCompanyProductsSearch/" + companyId + "/" + name)
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  getSituationsFromCustomer(iddoparceiro: number) {
    return new Promise((resolve, reject) => {
      this.api
        .get("/GetSituationsFromCustomer/" + iddoparceiro)
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  getBasicPlanSaleCommissions() {
    return new Promise((resolve, reject) => {
      this.api
        .get("/getBasicPlanSaleCommissions")
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  getBasicNoTrustCommissions() {
    return new Promise((resolve, reject) => {
      this.api
        .get("/getBasicNoTrustCommissions")
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  getSalers(companyId: number) {
    return new Promise((resolve, reject) => {
      this.api
        .get("/companytrustssalers/" + companyId)
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  getIgaratibaSaving(segmentId: number, from: any, to: any, userId : number) {
    return new Promise((resolve, reject) => {
      this.api
        .get(
          "/getIgaratibaSavings/" +
            segmentId +
            "/" +
            from.toISOString() +
            "/" +
            to.toISOString() +
            "/" +
            userId
        )
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }
}
