import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { MenuItem, SelectItem } from "primeng/api";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators, Form } from "@angular/forms";
import { DialogHelperService } from "src/app/services/dialog-helper.service";
import { SegmentService } from "src/app/services/segment/segment.service";
import { ArrayHelper } from "src/app/utils/array-helper";
import { AuthService } from "src/app/services/auth.service";
import { Segment } from "src/app/models/Segment";
import { ObjectMap } from "src/app/utils/object-map";
import { SubSegmentService } from "src/app/services/subsegment/subsegment.service";
import { ProductService } from "src/app/services/product/product.service";
import { AddressService } from "src/app/services/address/address.service";
import { Address } from "src/app/models/Address";
import { Product } from "src/app/models/Product";
import { SubSegment } from "src/app/models/SubSegment";
import { Request } from "src/app/models/Request";
import { RequestService } from "src/app/services/request/request.service";
import * as moment from "moment";
import { RequestProductService } from "src/app/services/requestProduct/requestProduct.service";
import { RequestAttachmentService } from "src/app/services/requestAttachment/requestAttachment.service";
import { UtilsService } from "src/app/services/core/utils.service";
import { User } from "src/app/models/User";
import { Title } from "@angular/platform-browser";
import { ActiveCompanyService } from "src/app/services/active-company.service";
import { SelectedFile } from "src/app/components/file-picker/file-picker.component";
import { AzureBlobUploaderService } from "src/app/services/azure-blob-uploader.service";
import { CustomValidators } from "src/app/utils/custom-validators";
import { RequestItem } from "src/app/models/RequestItem";
import { RequestitemService } from "src/app/services/request/requestitem.service";
import { QisarService } from "src/app/services/hubs/qisar.service";
import { CompanyService } from "src/app/services/company/company.service";
import { Subscription } from "rxjs";
import { NgxSmartModalService } from "ngx-smart-modal";
import { HttpClient } from "@angular/common/http";
import Swal from "sweetalert2";
import { Company } from "src/app/models/Company";



interface ProductItem {
  productId: number;
  qtd: number;
  measure: string;
}

@Component({
  selector: "app-customer-request-add-page",
  templateUrl: "./customer-request-add-page.component.html",
  styleUrls: ["./customer-request-add-page.component.css"],
})
export class CustomerRequestAddPageComponent implements OnInit, OnDestroy {
  stepList: Array<MenuItem> = [
    {
      label: "Segmento",
    },
    {
      label: "Produto",
    },
    {
      label: "Entrega",
    },
    {
      label: "Observação"
    },
    {
      label: "Prazo de Resposta",
    },
  ];
  stepsActiveIndex = 0;
  pt = {
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "eg", "ter", "qua", "qui", "sex", "sab"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Feveiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
  };
  minDate = new Date();
  form: FormGroup;
  productForm: FormGroup;
  itemsForm: FormGroup;
  companyProducts = [];
  companyProductsFilter = [];
  lastBuy = 0;
  lastMeasure = "";
  lastBuyDate : Date = null;
  btnprimary = "btn-primary";
  cssPage = 'app-page-body';

  productSelectionType: string; // "one", "many" or "text"

  productTypeList = [
    {
      productTypeId: 1,
      name: "Ativo",
    },
    {
      productTypeId: 2,
      name: "Consumo",
    },
    {
      productTypeId: 3,
      name: "Industrializado",
    },
    {
      productTypeId: 4,
      name: "Outros",
    },
  ];

  segmentOptionList: Array<SelectItem> = [];
  subSegmentOptionList: Array<SelectItem> = [];
  addressOptionList: Array<SelectItem> = [];
  productObjectOptionList: Array<SelectItem> = [];
  productTypeOptionList: Array<SelectItem> = [];
  timeOptionList: Array<SelectItem> = [
    {
      label: "",
      value: null,
    },
    {
      label: "00:00",
      value: "00:00:00",
    },
    {
      label: "01:00",
      value: "01:00:00",
    },
    {
      label: "02:00",
      value: "02:00:00",
    },
    {
      label: "03:00",
      value: "03:00:00",
    },
    {
      label: "04:00",
      value: "04:00:00",
    },
    {
      label: "05:00",
      value: "05:00:00",
    },
    {
      label: "06:00",
      value: "06:00:00",
    },
    {
      label: "07:00",
      value: "07:00:00",
    },
    {
      label: "08:00",
      value: "08:00:00",
    },
    {
      label: "09:00",
      value: "09:00:00",
    },
    {
      label: "10:00",
      value: "10:00:00",
    },
    {
      label: "11:00",
      value: "11:00:00",
    },
    {
      label: "12:00",
      value: "12:00:00",
    },
    {
      label: "13:00",
      value: "13:00:00",
    },
    {
      label: "14:00",
      value: "14:00:00",
    },
    {
      label: "15:00",
      value: "15:00:00",
    },
    {
      label: "16:00",
      value: "16:00:00",
    },
    {
      label: "17:00",
      value: "17:00:00",
    },
    {
      label: "18:00",
      value: "18:00:00",
    },
    {
      label: "19:00",
      value: "19:00:00",
    },
    {
      label: "20:00",
      value: "20:00:00",
    },
    {
      label: "21:00",
      value: "21:00:00",
    },
    {
      label: "22:00",
      value: "22:00:00",
    },
    {
      label: "23:00",
      value: "23:00:00",
    },
  ];
  address : any;
  companyId : any;
  companies: Company[];
  companyFilter: any[];
  segmentMap: ObjectMap<Segment> = new ObjectMap("segmentId");
  subSegmentMap: ObjectMap<SubSegment> = new ObjectMap("subSegmentId");
  productMap: ObjectMap<Product> = new ObjectMap("productId");
  addressMap: ObjectMap<Address> = new ObjectMap("addressId");
  productTypeMap: ObjectMap<any> = new ObjectMap("productTypeId");

  selectedProductList: Array<ProductItem> = [];
  selectedItemList: Array<RequestItem> = [];
  selectedFileList: Array<SelectedFile> = [];
  selectedSubSegmentMeasure: string;

  isSaving = false;

  manyItems = false;
  automaticApproved = false;
  haveSchedulle = false;
  currentUser: User;
  cssValue = "hide-element";
  pageTitle = "Nova requisição de Compra";
  haveValue = false;
  subscribeCompany: Subscription;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private dialogHelperService: DialogHelperService,
    private segmentService: SegmentService,
    private subSegmentService: SubSegmentService,
    private productService: ProductService,
    private companyService: CompanyService,
    private addressService: AddressService,
    private requestService: RequestService,
    private requestProductService: RequestProductService,
    private requestItemService: RequestitemService,
    private requestAttachmentService: RequestAttachmentService,
    private utils: UtilsService,
    private title: Title,
    private route: ActivatedRoute,
    private activeCompanyService: ActiveCompanyService,
    public azureBlobUploaderService: AzureBlobUploaderService,
    public hub: QisarService,
    private ngxSmartModalService: NgxSmartModalService,
    private http : HttpClient
  ) {

    if (sessionStorage.getItem('whitelabel')) {
      this.btnprimary = this.btnprimary + sessionStorage.getItem('whitelabel');
      this.cssPage += "-" + sessionStorage.getItem('whitelabel');
    }
  }
  ngOnDestroy(): void {
    if (this.subscribeCompany) {
      this.subscribeCompany.unsubscribe();
    }
  }

  setValueFocus() {
    this.haveValue = true;
  }
  changeMultiItems() {
    this.selectedItemList = [];
    this.itemsForm.reset();
    this.form.controls.quantity.setValue(null);
    this.form.controls.productDescription.setValue(null);
    this.form.controls.measure.setValue(null);
    this.lastBuy = 0;
    this.lastMeasure = "";
    this.lastBuyDate = null;

    this.manyItems = !this.manyItems;
  }

  ngOnInit() {
    this.address = new Address();
    this.companyFilter = [];
    this.title.setTitle("Nova requisição ");
    if (window.location.href.indexOf("customer/automatic/request/add") > 0) {
      this.automaticApproved = true;
      this.pageTitle = "Nova requisição de Compra Automatizada";
    }
    if (window.location.href.indexOf("customer/schedulle/request/add") > 0) {
      this.haveSchedulle = true;
      this.pageTitle = "Nova requisição de Compra Agendada";
    }

    this.utils.getUserFromSession().then((response) => {
      this.currentUser = response;

      this.form = this.formBuilder.group({
        segmentId: [null, Validators.compose([Validators.required])],
        subSegmentId: [null, Validators.compose([Validators.required])],
        productTypeId: [null, Validators.compose([Validators.required])],
        product: [null, Validators.compose([])],
        quantity: [null, Validators.compose([])],
        noTaxes: [null, Validators.compose([])],
        manyItems: [null, Validators.compose([])],
        productDescription: [null, Validators.compose([])],
        addressId: [null, Validators.compose([Validators.required])],
        deliveryInstructions: [null, Validators.compose([Validators.required])],
        awnserDate: [
          null,
          Validators.compose([
            Validators.required,
            CustomValidators.minDate(new Date()),
          ]),
        ],
        awnserHour: [null, Validators.compose([Validators.required])],
        measure: [null, Validators.compose([])],
        automaticApprovedValue: [null, Validators.compose([])],
        schedulleShipping: [null, Validators.compose([])],
        maxDeliveredDate: [null, Validators.compose([])],
        companyProductId: [null],
        spot: [null],
        note: [null]
      });

      this.productForm = this.formBuilder.group({
        product: [null, Validators.compose([Validators.required])],
        qtd: [null, Validators.compose([Validators.required])],
        measure: [null, Validators.compose([])],
      });
      this.itemsForm = this.formBuilder.group({
        product: [null, Validators.compose([Validators.required])],
        companyProductId: [null],
        qtd: [null, Validators.compose([Validators.required])],
        measure: [null, Validators.compose([Validators.required])],
      });
      this.loadAuxData().then(() => {
        if (window.location.href.indexOf("customer/request/copy") > 0) {
          this.utils.running();
          const param = this.route.snapshot.paramMap.get("requestId");
          const requestId = Number(param);
          this.loadRequest(requestId);
          this.pageTitle = "Copiar requisição " + requestId.toString();
        }
        this.utils.finish();
      });
    });

    this.subscribeCompany = this.activeCompanyService.onActiveCompanyChange.subscribe(
      () => {
        this.loadAuxData();
        this.clearFormSegmentIdChildData();
        this.stepsActiveIndex = 0;
      }
    );

    // this.companyService.getCompanyProducts(this.activeCompanyService.activeCompanyId)
    //  .then((data: []) => this.companyProducts = data);
    this.companyId = this.activeCompanyService.activeCompanyId;
    this.loadCompanyList().then(() => {
    });
    
    
  }

  loadRequest(requestId: number) {
    this.utils.getUserFromSession().then((user: User) => {
      this.requestService
        .searchList(
          "customer",
          user.userId,
          false,
          this.activeCompanyService.activeCompanyId,
          null,
          null,
          null,
          null,
          null,
          requestId,
          false,
          false,
          false
        )
        .then((response: Array<Request>) => {
          const request = response[0];
          this.loadCopyValues(request);
          this.utils.finish();
        });
    });
    
  }

  selectProduct(event, form) {
    this.lastBuy = 0;
    this.lastMeasure = "";
    this.lastBuyDate = null;

    this.companyProducts.forEach((product) => {
      if (product.description.toLowerCase().indexOf(event.toLowerCase()) > -1) {
        form.controls.measure.setValue(product.measure);
        if (product.lastBuyValue && product.quantity) {
          this.lastBuy = product.lastBuyValue / product.quantity;
          this.lastBuyDate = new Date(product.lastBuyDate);
          this.lastMeasure = product.measure;
        } else {
          if (product.lastBuyValue) {
            this.lastBuy = product.lastBuyValue;
            this.lastBuyDate = new Date(product.lastBuyDate);
            this.lastMeasure = "";
          }
        }
      }
    });
  }

  filterProduct(event: any) {
    const filtered: any[] = [];
    /*this.companyProducts.forEach(product => {
      if (product.description.toLowerCase().indexOf(event.query.toLowerCase()) > -1) {
        filtered.push(product.description);
      }
    });*/
    this.companyProducts = [];
    this.companyService
      .getCompanyProductsSearch(
        this.activeCompanyService.activeCompanyId,
        event.query
      )
      .then((data: []) => {
        this.companyProducts = data;
        data.forEach((product: any) => {
          filtered.push(product.description);
        });
        this.companyProductsFilter = filtered;
      });
  }

  loadCopyValues(request: any) {
    this.form.controls.segmentId.setValue(request.subSegment.segmentId);
    this.clearFormSegmentIdChildData();
    this.subSegmentService
      .getFromSegment(request.subSegment.segmentId)
      .then((response) => {
        this.subSegmentOptionList = ArrayHelper.toOptionList(
          response,
          "subSegmentId",
          "name"
        );
        this.subSegmentMap.addList(response);
        this.form.controls.subSegmentId.setValue(request.subSegmentId);
        this.onFormSubSegmentIdChange();
      });
    this.form.controls.productTypeId.setValue(request.productTypeId);
    this.form.controls.noTaxes.setValue(request.noTaxes);
    const manyItems = request.requestItems && request.requestItems.length > 0;
    this.form.controls.manyItems.setValue(manyItems);
    this.manyItems = manyItems;
    if (manyItems) {
      request.requestItems.forEach((item) => {
        const requestItem: RequestItem = {
          description: item.description,
          quantity: item.quantity,
          measure: item.measure,
          requestId: 0,
          requestItemId: 0,
          request: null,
        };
        this.selectedItemList.push(requestItem);
        this.lastBuy = 0;
        this.lastMeasure = "";
        this.lastBuyDate = null;
      });
    }
    this.form.controls.addressId.setValue(request.addressId);
    this.onFormAddressIdChange();

    this.form.controls.quantity.setValue(request.quantity);
    this.form.controls.productDescription.setValue(request.productDescription);
    this.form.controls.measure.setValue(request.measure);
  }

  loadAuxData(): Promise<void> {
    return new Promise((rs, rj) => {
      this.authService.waitAuth().then(() => {
        const promiseList: Array<Promise<any>> = [];
        promiseList.push(
          new Promise((resolve, reject) => {
            this.segmentService
              .getForRequest(this.activeCompanyService.activeCompanyId)
              .then((response) => {
                this.segmentMap.addList(response);
                this.segmentOptionList = ArrayHelper.toOptionList(
                  response,
                  "segmentId",
                  "name"
                );
                resolve();
              });
          })
        );

        promiseList.push(
          new Promise((resolve, reject) => {
            this.addressService
              .findByCompany(this.activeCompanyService.activeCompanyId)
              .then((response) => {
                this.addressOptionList = ArrayHelper.toOptionList(
                  response,
                  "addressId",
                  "name"
                );
                this.addressMap.addList(response);
                resolve();
              });
          })
        );

        this.productTypeMap.addList(this.productTypeList);
        this.productTypeOptionList = ArrayHelper.toOptionList(
          this.productTypeList,
          "productTypeId",
          "name"
        );

        Promise.all(promiseList).then(() => {
          rs();
        });
      });
    });
  }

  checkActiveStepValidation(): boolean {
    if (!this.form) {
      return false;
    }
    if (this.stepsActiveIndex === 0) {
      if (!this.form.controls.segmentId.valid) {
        return false;
      }
      if (!this.form.controls.subSegmentId.valid) {
        return false;
      }
    }
    if (this.stepsActiveIndex === 1) {
      if (this.automaticApproved && this.haveValue) {
        const value = this.form.controls.automaticApprovedValue.value;

        if (value === "") {
          this.cssValue = "form-text ng-star-inserted error-text";
          return false;
        } else if (Number(value) === 0) {
          this.cssValue = "form-text ng-star-inserted error-text";
          return false;
        } else {
          this.cssValue = "hide-element";
        }
      }
      if (!this.form.controls.productTypeId.valid) {
        return false;
      }
      if (!this.form.controls.product.valid) {
        return this.manyItems ? this.selectedItemList.length > 0 : false;
      }
      if (!this.form.controls.productDescription.valid) {
        return this.manyItems ? this.selectedItemList.length > 0 : false;
      }
      if (!this.form.controls.quantity.valid) {
        return this.manyItems ? this.selectedItemList.length > 0 : false;
      }
      if (this.productSelectionType === "many") {
        if (this.selectedProductList.length === 0) {
          return false;
        }
      }
    }
    if (this.stepsActiveIndex === 2) {
      if (!this.form.controls.addressId.valid) {
        return false;
      }
      if (!this.form.controls.deliveryInstructions.valid) {
        return false;
      }
    }
    if(this.stepsActiveIndex === 3){
      if(this.form.controls.note.invalid)
        return false;
    }
    if (this.stepsActiveIndex === 4) {
      if (!this.form.controls.awnserDate.valid) {
        return false;
      }
      if (!this.form.controls.awnserHour.valid) {
        return false;
      }
    }

    return true;
  }

  goNextStep() {
    this.stepsActiveIndex += 1;
  }

  goPrevStep() {
    this.stepsActiveIndex -= 1;
  }

  onFileListChange(selectedFileList: Array<SelectedFile>) {
    this.selectedFileList = selectedFileList;
  }

  onFormSegmentIdChange() {
    this.clearFormSegmentIdChildData();

    const segmentId: number = this.form.value.segmentId;
    if (segmentId) {
      this.subSegmentService.getFromSegment(segmentId).then((response) => {
        this.subSegmentOptionList = ArrayHelper.toOptionList(
          response,
          "subSegmentId",
          "name"
        );
        this.subSegmentMap.addList(response);
      });
    }
  }

  onFormSubSegmentIdChange() {
    this.clearFormSubSegmentIdChildData();
    const subSegmentId: number = this.form.value.subSegmentId;
    const segmentId: number = this.form.value.segmentId;

    const subSegment = this.subSegmentMap.get(subSegmentId.toString());
    let isManySelectionType: boolean;
    if (subSegment.requestHasManyProducts) {
      isManySelectionType = true;
    }
    if (segmentId) {
      let productList: Array<Product> = [];
      this.productService.getFromSegment(segmentId).then((response) => {
        productList = response;
        this.productService
          .getFromSubSegment(subSegmentId)
          .then((response2) => {
            if (response2.length > 0) {
              productList = response;
            }
            if (!isManySelectionType) {
              if (productList.length > 0) {
                this.productSelectionType = "one";
                this.form.controls.product.setValidators([Validators.required]);
                this.form.controls.productDescription.setValidators([]);
                this.form.controls.quantity.setValidators([
                  Validators.required,
                  Validators.min(1),
                ]);
              } else {
                this.productSelectionType = "text";
                this.form.controls.product.setValidators([]);
                this.form.controls.productDescription.setValidators([
                  Validators.required,
                ]);
                this.form.controls.quantity.setValidators([
                  Validators.required,
                  Validators.min(1),
                ]);
              }
            } else {
              this.productSelectionType = "many";
              this.form.controls.product.setValidators([]);
              this.form.controls.productDescription.setValidators([]);
              this.form.controls.quantity.setValidators([]);
            }

            let measure: string = this.subSegmentMap.get(
              this.form.value.subSegmentId
            ).measure;
            if (measure === "") {
              measure = null;
            }
            this.selectedSubSegmentMeasure = measure;

            if (this.selectedSubSegmentMeasure) {
              this.form.controls.measure.setValidators([]);
            } else {
              this.form.controls.measure.setValidators([Validators.required]);
            }
            this.productMap.addList(productList);
            this.productObjectOptionList = ArrayHelper.toObjectOptionList(
              productList,
              "name"
            );
          });
      });
    }
  }

  onFormAddressIdChange() {
    this.form.controls.deliveryInstructions.setValue(null);
    const addressId: number = this.form.value.addressId;
    if (addressId) {
      const address: Address = this.addressMap.get(addressId.toString());
      this.form.controls.deliveryInstructions.setValue(
        address.deliveryInstruction
      );
    }
  }

  save() {
    const requestItem: RequestItem = {
      description: this.form.value.productDescription,
      quantity: this.form.value.quantity,
      measure:  this.form.value.measure,
      requestId: 0,
      requestItemId: 0,
      request: null,
    };
    if(this.selectedItemList.length < 1){
      this.selectedItemList.push(requestItem);
    }
    
    this.dialogHelperService
      .askConfirmation("Você deseja enviar essa requisição?")
      .then((confirmation) => {
        if (confirmation) {
          this.utils.running();
          const requestData: Request = this.form.value;
          requestData.userId = this.currentUser.userId;
          requestData.awnserDate = moment(requestData.awnserDate).format(
            "DD/MM/YYYY"
          );
          requestData.companyId = this.currentUser.companyId;
          requestData.statusId = 1;

          requestData.requestId = 0;
          if (!requestData.quantity) {
            requestData.quantity = 0;
          }

          let measure: string = this.selectedSubSegmentMeasure;
          if (!measure) {
            measure = this.form.value.measure;
          }
          requestData.measure = measure;

          if (this.activeCompanyService.activeCompanyId) {
            requestData.companyId = this.activeCompanyService.activeCompanyId;
          }

        requestData.note = this.form.value.note;

          this.requestService.save(requestData).then((response: Request) => {
            const requestId: number = response.requestId;
            this.hub.informeNewRequet(requestId, this.form.value.segmentId);
            this.requestService.SendRequestEmails(response);
            const productListAddPromiseList: Array<Promise<any>> = [];
            const itemListAddPromiseList: Array<Promise<any>> = [];
            const fileListAddPromiseList: Array<Promise<any>> = [];
            if (this.form.value.product) {
              let measureValue = this.selectedSubSegmentMeasure;
              if (!measureValue) {
                measureValue = this.form.value.measure;
              }
              this.selectedProductList.push({
                productId: this.form.value.product.productId,
                qtd: this.form.value.quantity,
                measure: measureValue,
              });
            }
            this.isSaving = true;
            for (const product of this.selectedProductList) {
              productListAddPromiseList.push(
                new Promise((resolve, reject) => {
                  this.requestProductService
                    .save({
                      requestId,
                      productId: product.productId,
                      qtd: product.qtd,
                      measure: product.measure,
                    })
                    .then(() => {
                      resolve();
                    });
                })
              );
            }
            for (const item of this.selectedItemList) {
              itemListAddPromiseList.push(
                new Promise((resolve, reject) => {
                  item.requestId = requestId;
                  this.requestItemService.save(item).then(() => {
                    resolve();
                  });
                })
              );
            }
            const uploadIdList = ArrayHelper.getPropertyList(
              this.selectedFileList,
              "id"
            );
            this.azureBlobUploaderService
              .checkUploadIsComplete(uploadIdList)
              .then((urlList) => {
                for (const url of urlList) {
                  fileListAddPromiseList.push(
                    new Promise((resolve, reject) => {
                      this.requestAttachmentService
                        .save({
                          requestId,
                          uri: url,
                        })
                        .then(() => {
                          resolve();
                        });
                    })
                  );
                }
              });
            Promise.all(itemListAddPromiseList).then(() => {
              Promise.all(productListAddPromiseList).then(() => {
                Promise.all(fileListAddPromiseList).then(() => {});
              });
            });
            if (response.statusId === 1) {
              this.dialogHelperService.showSuccessNotification(
                "A requisição #" + requestId + " foi criada."
              );
            } else if (response.statusId === 6) {
              this.dialogHelperService.showSuccessNotification(
                "A requisição #" +
                  requestId +
                  " está pendende de aprovação do gestor."
              );
            }
            this.utils.finish();
            if (this.automaticApproved) {
              this.router.navigateByUrl(
                "/customer/automatic/request/list/open"
              );
            } else if (this.haveSchedulle) {
              this.router.navigateByUrl(
                "/customer/schedulle/request/list/open"
              );
            } else {
              this.router.navigateByUrl("/customer/request/list/open");
            }
          });
        }
      });
  }
  updateItemDescription(item, idx) {
    this.selectedItemList[idx].description = item.target.value;
  }

  updateItemQuantity(item, idx) {
    this.selectedItemList[idx].quantity = item.target.value;
  }

  updateItemMeasure(item, idx) {
    this.selectedItemList[idx].measure = item.target.value;
  }

  removeItem(i) {
    this.dialogHelperService
      .askConfirmation("Deseja remover esse item?")
      .then((confirmation) => {
        if (confirmation) {
          this.selectedItemList.splice(i, 1);
        }
      });
  }
  addItem() {
    if (this.itemsForm.valid) {
      const requestItem: RequestItem = {
        description: this.itemsForm.value.product,
        quantity: this.itemsForm.value.qtd,
        measure: this.itemsForm.value.measure,
        requestId: 0,
        requestItemId: 0,
        request: null,
      };
      this.selectedItemList.push(requestItem);
      this.lastBuy = 0;
      this.lastMeasure = "";
      this.lastBuyDate = null;
      this.itemsForm.reset();
    } else {
      const formKeyList: Array<string> = Object.keys(this.itemsForm.controls);
      for (const key of formKeyList) {
        this.itemsForm.controls[key].markAsTouched();
      }
    }
  }

  addProduct() {
    if (this.productForm.valid) {
      if (
        this.selectedProductList.some(
          (item) => item.productId === this.productForm.value.product.productId
        )
      ) {
        this.dialogHelperService.showErrorNotification(
          "O produto que você escolheu já foi adicionado.",
          "Produto já adicionado!"
        );
      } else {
        const productItem: ProductItem = {
          productId: this.productForm.value.product.productId,
          qtd: this.productForm.value.qtd,
          measure: this.productForm.value.measure,
        };
        if (!productItem.measure) {
          productItem.measure = this.selectedSubSegmentMeasure;
        }
        this.selectedProductList.push(productItem);
        this.productForm.reset();
      }
    } else {
      const formKeyList: Array<string> = Object.keys(this.productForm.controls);
      for (const key of formKeyList) {
        this.productForm.controls[key].markAsTouched();
      }
    }
  }

  removeProduct(index: number) {
    this.selectedProductList.splice(index, 1);
  }

  clearFormSegmentIdChildData() {
    this.clearFormSubSegmentIdChildData();
    this.form.controls.subSegmentId.reset();
    this.subSegmentOptionList = [];
  }

  clearFormSubSegmentIdChildData() {
    // this.productSelectionType = null;
    // this.form.controls.product.reset();
    // this.form.controls.productDescription.reset();
    // this.form.controls.quantity.reset();
    // this.selectedProductList = [];
    // this.selectedFileList = [];
    // this.productObjectOptionList = [];
    // this.productForm.reset();
  }

  openFile(file: File) {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      window.open(fileReader.result.toString(), "blank");
    };
    fileReader.readAsDataURL(file);
  }
  newAddress(companyId: number) {
    this.cleanAddress(companyId);
    this.ngxSmartModalService.getModal("modalCompanyAddress").open();
  }

  cleanAddress(companyId: number) {
    this.address = new Address();
    this.address.addressId = 0;
    this.address.companyId = companyId;
    this.address.actived = true;
    this.address.city = "";
    this.address.default = false;
    this.address.state = "";
    this.address.street = "";
    this.address.zipCode = "";
    this.address.deliveryInstruction = "";
    this.address.neighBorHood = "";
    this.address.number = 0;
    this.address.cmpl = "";
  }
  onKeyZipCodeInput(event: any) {
    if (event.key === "Enter") {
      this.searchCep();
    }
  }
  searchCep() {
    this.utils.running();
    this.http
      .get("https://viacep.com.br/ws/" + this.address.zipCode + "/json")
      .toPromise()
      .then((data: any) => {
        this.address.street = data.logradouro;
        this.address.neighBorHood = data.bairro;
        this.address.city = data.localidade;
        this.address.state = data.uf;
        this.address.zipCode = data.cep;
        this.address.number = 0;
        this.address.cmpl = "";
        this.utils.finish();
      })
      .catch(() => {
        Swal.fire("Qisar", "Erro ao buscar o CEP", "error");
        this.utils.finish();
      });
  }
   preSaveAddress() {
    this.saveAddress();
    this.ngxSmartModalService.getModal("modalCompanyAddress").close();
    
  }
  saveAddress() {
    console.log(this.address)
    this.utils.running();
    this.addressService
      .save(this.address)
      .then((data: Address) => {
        Swal.fire("Qisar", "Endereço cadastrado com sucesso", "success");
        // if (this.address.addressId === 0) {
        //   const company = this.companies.find(
        //     (c) => c.companyId === this.address.companyId
        //   );
        //   company.addresses.push(data);
        // }
        this.addressService
              .findByCompany(this.activeCompanyService.activeCompanyId)
              .then((response) => {
                this.addressOptionList = ArrayHelper.toOptionList(
                  response,
                  "addressId",
                  "name"
                );
                this.addressMap.addList(response);
              });
        this.utils.finish();
      })
      .catch((e) => {
        Swal.fire("Qisar", "Erro ao salvar o endereço", "error");
       
        this.utils.finish();
      });
  }
  loadCompanyList(): Promise<void> {
    this.companyFilter.push({
      label: "",
      value: 0,
    });
    return new Promise((resolve, reject) => {
      this.companyService
        .list(this.activeCompanyService.activeCompanyId)
        .then((companies: []) => {
          this.companies = ArrayHelper.orderArrayByProperty(
            companies,
            "nickName"
          );
          companies.forEach((item: any) => {
            this.companyFilter.push({
              label: item.name,
              value: item.companyId,
            });
          });
          resolve();
        })
        .catch(() => {
          Swal.fire("Qisar", "Erro ao Trazer a lista de Empresas", "error");
          reject();
        });
    });
  }
  
}
