import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
//import { Color, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { Payment } from '../../../models/payment-model';
import { PaymentState } from '../../../models/paymentState.enum.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { ChartCompanyInvoicesStatus } from 'src/app/models/ChartCompanyInvoiceStatus';
import { ChartCompanyInvoiceStatusSummary } from 'src/app/models/ChartCompanyInvoiceStatusSummary';
import { Pagination } from 'src/app/models/pagination.model';
import { CompanyInvoice } from 'src/app/models/companyInvoice.model';
import { InvoiceState } from 'src/app/models/invoiceState.enum.model';
import { ChartCompanyInvoiceStatusSummaryItem } from 'src/app/models/ChartCompanyInvoiceStatusSummaryItem';
import { Title } from '@angular/platform-browser';
import { CompanyPaymentItem } from 'src/app/models/CompanyPaymentItem';

@Component({
  selector: 'app-allinvoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class AllInvoicesComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private title: Title,
    private paymentService: PaymentService) {
    this.Paginator = new Pagination();
    this.initilizeChartSummary();
  }

  Payments: Array<Payment>;

  InitialDate: Date = new Date();
  EndDate: Date = new Date();
  form: FormGroup;

  pt = {
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Feveiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
  };


  OverviewChartData: ChartDataSets[] = [{ data: [], label: '' }];
  //OverviewChartLabels: Label[] = [];
  OverviewChartOptions: ChartOptions;
  //OverviewChartColors: Color[];
  OverviewChartLegend: boolean;
  OverviewChartType: string;
  OverviewChartPlugins: any[];
  OverviewChartSummary: ChartCompanyInvoiceStatusSummary;

  Paginator: Pagination<CompanyPaymentItem[]>;
  LastSearchInvoiceState: InvoiceState;

  ngOnInit() {
    this.buildChart();
    this.createForm();
    this.buildPaginator();
    this.title.setTitle('Gerenciar Faturas');
  }

  createForm() {
    this.form = this.formBuilder.group({
      initialDate: [null, Validators.compose([Validators.required])],
      endDate: [null, Validators.compose([Validators.required])],
    });

    this.initializeForm();
  }

  initializeForm() {
    const initial = moment().subtract(1, 'months').toDate();
    const end = moment().toDate();
    this.form.setValue({ initialDate: initial, endDate: end });

    this.loadChart();
  }

  loadChart() {
    this.paymentService.getInvoicePaymentOverview(
      this.form.value.initialDate,
      this.form.value.endDate,
    )
      .then(this.bindChartData.bind(this))
      .catch(error => console.log(JSON.stringify(error)));
  }

  bindChartData(res: ChartCompanyInvoicesStatus[]) {
    //this.OverviewChartLabels = [];
    this.OverviewChartData = res.map(item => {
      let statusLabel: string;

      const data = item.months.map(month => {
      //  if (this.OverviewChartLabels.length < item.months.length) {
        //  this.OverviewChartLabels.push(`${month.referenceMonth}/${month.referenceYear}`);
        //}
        return month.quantity;
      });

      const summaryItem = new ChartCompanyInvoiceStatusSummaryItem();
      summaryItem.quantity = data.reduce((accumulator: any, current: any) => accumulator += current, 0);
      summaryItem.total = item.total;

      switch (item.invoiceState) {
        case 1:
          statusLabel = 'Pendente';
          this.OverviewChartSummary.pending = summaryItem;
          break;
        case 2:
          statusLabel = 'Pago';
          this.OverviewChartSummary.payed = summaryItem;
          break;
        case 3:
          statusLabel = 'Em Atraso';
          this.OverviewChartSummary.lateBill = summaryItem;
          break;
      }

      return { data, label: statusLabel } as ChartDataSets;
    });
  }

  initilizeChartSummary() {
    this.OverviewChartSummary = new ChartCompanyInvoiceStatusSummary();

    this.OverviewChartSummary.pending = new ChartCompanyInvoiceStatusSummaryItem();
    this.OverviewChartSummary.pending.quantity = 0;
    this.OverviewChartSummary.pending.total = 0;

    this.OverviewChartSummary.payed = new ChartCompanyInvoiceStatusSummaryItem();
    this.OverviewChartSummary.payed.quantity = 0;
    this.OverviewChartSummary.payed.total = 0;

    this.OverviewChartSummary.lateBill = new ChartCompanyInvoiceStatusSummaryItem();
    this.OverviewChartSummary.lateBill.quantity = 0;
    this.OverviewChartSummary.lateBill.total = 0;
  }

  buildChart() {
    this.OverviewChartLegend = true;
    this.OverviewChartType = 'bar';
    this.OverviewChartPlugins = [pluginAnnotations];
    this.OverviewChartOptions = {
      responsive: true,
      elements: {
        line: {
          fill: false
        }
      }
    } as ChartOptions;

    // this.OverviewChartColors = [
    //   {
    //     backgroundColor: '#FFDC85',
    //     borderColor: '#FFDC85'
    //   },
    //   {
    //     backgroundColor: '#7AE2E2',
    //     borderColor: '#7AE2E2'
    //   },
    //   {
    //     backgroundColor: '#FF8BA4',
    //     borderColor: '#FF8BA4'
    //   },
    // ];
  }

  getInvoicesByState(state: InvoiceState, newSearch: boolean) {
    this.LastSearchInvoiceState = state;

    if (newSearch) {
      this.Paginator.currentPage = 1;
    }
    this.paymentService.getCompanyInvoicesPagingByStatus(
      state,
      this.Paginator.itemsPerPage,
      this.Paginator.currentPage,
      this.form.value.initialDate,
      this.form.value.endDate)
      .then((result: Pagination<Array<CompanyPaymentItem>>) => {
        result.data.forEach(item => {
          const referenceDate = moment(item.referenceDate);
          item.paymentDate = this.getPaymentDate(item);
          item.referenceMonth = referenceDate.format('MM');
          item.referenceYear = referenceDate.format('YYYY');
        });
        this.Paginator = result;
      });
  }

  buildPaginator() {
    this.Paginator.itemsPerPage = 10;
    this.Paginator.currentPage = 1;
    this.Paginator.totalRecords = 0;
  }

  paginate(event) {
    this.Paginator.itemsPerPage = 10;
    this.Paginator.currentPage = (event.page + 1);
    this.getInvoicesByState(this.LastSearchInvoiceState, false);
  }

  getPaymentDate(companyInvoice: CompanyPaymentItem): Date {
    if (companyInvoice.payments) {
      const lastPayment = companyInvoice.payments.filter(item => item.isCurrent);
      if (lastPayment.length > 0 && lastPayment[0].state === PaymentState.success) {
        return lastPayment[0].paymentDate;
      }
    }
    return null;
  }
}
