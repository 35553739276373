import { Injectable } from '@angular/core';
import { RequestApi } from 'src/app/core/http/entidades/RequestApi';
import { Request } from 'src/app/models/Request';
import * as moment from 'moment';
import 'src/app/utils/extensions';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private api: RequestApi) {
    this.api.baseName = 'request';
  }
  /** buscar todas os segmentos */
  getall(): any {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
  /** query do relatorio */
  report(From: any, To: any): any {
    return new Promise((result, reject) => {
      this.api.get('/report/' + From.toISOString() + '/' + To.toISOString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um segmento pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um segmento pelo Id */
  findByCompany(companyId: number): any {
    return new Promise((result, reject) => {
      this.api.get('/findByCompany/' + companyId.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  getCustomerList(): any {
    return new Promise((result, reject) => {
      this.api.get('/forlist/customer')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  getProviderList(): any {
    return new Promise((result, reject) => {
      this.api.get('/forlist/provider')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** buscar um segmento pelo Id */
  getForRequest(): any {
    return new Promise((result, reject) => {
      this.api.get('/forrequest')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** Salvar */
  save(request: Request): any {
    return new Promise((resolve, reject) => {
      // se segmento novo, insere
      if (request.requestId === 0) {
        this.api.post(request)
          .then((data: Request) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(request)
          .then((data: Request) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }

  SendRequestEmails(request: Request): any {
    console.log('chamando api de envio de email para requisição ' + request.requestId.toString());
    return new Promise((resolve, reject) => {
      this.api.post(request, '/SendRequestEmails')
        .then((data: Request) => {
          resolve(data);
        })
        .catch(() => {
          reject();
        });
    });
  }

  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api.delete(id)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  searchList(
    profile: string,
    userId: number,
    managerQuery: boolean,
    companyId: number,
    segmentId: number,
    statusId: number,
    createdAtStart: Date,
    createdEnd: Date,
    awnserDate: Date,
    requestId: number,
    automatic: boolean = false,
    schedulle: boolean = false,
    allStatuses: boolean = false,
    companyBusinessCode: string = '',
    companyWorkId: number = 0
  ): Promise<Array<any>> {
    const searchRequestData = {
      profile,
      userId,
      managerQuery,
      companyId,
      segmentId,
      statusId,
      createdAtStart: (createdAtStart != null ? createdAtStart.toISOUTCBrasiliaString() : null),
      createdEnd: (createdEnd != null ? createdEnd.toISOUTCBrasiliaString() : null),
      requestId,
      companyBusinessCode,
      companyWorkId,
      awnserDate: null,
      automatic,
      schedulle,
      allStatuses
    };
    if (awnserDate) {
      searchRequestData.awnserDate = moment(awnserDate).format('DD/MM/YYYY');
    }
    return this.api.post(searchRequestData, '/search');
  }

  GetForAproved(
    companyId: number,
    segmentId: number,
    requestId: number,
    userId: number,
    companyWorkId: number,
    companyBusinessCode: string,
    createdAtStart: Date,
    createdEnd: Date,
  ): Promise<Array<any>> {
    const searchRequestData = {
      companyId,
      segmentId,
      requestId,
      userId,
      companyWorkId,
      companyBusinessCode,
      createdAtStart: (createdAtStart != null ? createdAtStart.toISOUTCBrasiliaString() : null),
      createdEnd: (createdEnd != null ? createdEnd.toISOUTCBrasiliaString() : null),
    };

    return this.api.post(searchRequestData, '/GetForAproved');
  }
  requestByStatus(companyId: number, segmentId: number, initialDate: any, endDate: any, userId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/requestbystatus/' +
        companyId + '/'
        + segmentId + '/'
        + initialDate.toISOString() + '/'
        + endDate.toISOString() + '/' + userId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }


  reportRequestsWithProposals(companyId: number, segmentId: number, initialDate: Date, endDate: Date, userId: number): any {
    return new Promise((result, reject) => {
      this.api.get(
        '/reportRequestsChatsAndProposals/' +
        companyId + '/' +
        initialDate.toBeginDate().toISOUTCBrasiliaString() + '/' +
        endDate.toEndDate().toISOUTCBrasiliaString() + '/' +
        segmentId + '/' +
        userId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }


  reportRequestsWithChats(companyId: number, segmentId: number, initialDate: Date, endDate: Date, userId: number): any {
    return new Promise((result, reject) => {
      this.api.get(
        '/reportRequestsWithChats/' +
        companyId + '/' +
        initialDate.toBeginDate().toISOUTCBrasiliaString() + '/' +
        endDate.toEndDate().toISOUTCBrasiliaString() + '/' +
        segmentId + '/' +
        userId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }


  providerRequestByStatus(companyId: number, segmentId: number, initialDate: any, endDate: any): any {
    return new Promise((result, reject) => {
      this.api.get(
        '/provider/requestbystatus/' +
        companyId + '/' +
        segmentId + '/' +
        initialDate.toISOString() + '/' +
        endDate.toISOString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  topFiveSegments(companyId: number, segmentId: number, initialDate: any, endDate: any, userId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/topfivesegments/'
        + companyId + '/'
        + segmentId + '/'
        + initialDate.toISOString() + '/'
        + endDate.toISOString() + '/' + userId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  providerTopFiveSegments(companyId: number, segmentId: number, initialDate: any, endDate: any): any {
    return new Promise((result, reject) => {
      this.api.get(
        '/provider/topfivesegments/' +
        companyId + '/' +
        segmentId + '/' +
        initialDate.toISOString() + '/' +
        endDate.toISOString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  byProductType(companyId: number, segmentId: number, initialDate: any, endDate: any, userId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/reportByProductType/'
        + companyId + '/'
        + segmentId + '/'
        + initialDate.toISOString() + '/'
        + endDate.toISOString() + '/' + userId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  providerByProductType(companyId: number, segmentId: number, initialDate: any, endDate: any): any {
    return new Promise((result, reject) => {
      this.api.get(
        '/provider/reportByProductType/' +
        companyId + '/' +
        segmentId + '/' +
        initialDate.toISOString() + '/' +
        endDate.toISOString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  getFlexFormCompaniesActives(): any {
    return new Promise((result, reject) => {
      this.api.get('/getFlexFormCompaniesActives')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  getPlazaCompaniesActives(): any {
    return new Promise((result, reject) => {
      this.api.get('/getPlazaCompaniesActives')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  GetDashBoarbPerformaceRequestorRequestsSumaries(companyId: any, startDate: any, endDate: any, segmentId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/GetDashBoarbPerformaceRequestorRequestsSumaries/'
        + companyId + '/'
        + startDate.toISOString() + '/'
        + endDate.toISOString()
        + '/' + segmentId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  GetDashBoarbPerformaceRequestorProposalsSend(companyId: any, startDate: any, endDate: any, segmentId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/GetDashBoarbPerformaceRequestorProposalsSend/'
        + companyId + '/'
        + startDate.toISOString() + '/'
        + endDate.toISOString()
        + '/' + segmentId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  GetDashBoarbPerformaceRequestorSalesValue(companyId: any, startDate: any, endDate: any, segmentId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/GetDashBoarbPerformaceRequestorSalesValue/'
        + companyId + '/'
        + startDate.toISOString() + '/'
        + endDate.toISOString()
        + '/' + segmentId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  GetDashBoarbPerformaceRequestorSales(companyId: any, startDate: any, endDate: any, segmentId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/GetDashBoarbPerformaceRequestorSales/'
        + companyId + '/'
        + startDate.toISOString() + '/'
        + endDate.toISOString()
        + '/' + segmentId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  GetDashBoarbPerformaceOtherRequestorSales(companyId: any, startDate: any, endDate: any, segmentId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/GetDashBoarbPerformaceOtherRequestorSales/'
        + companyId + '/'
        + startDate.toISOString() + '/'
        + endDate.toISOString()
        + '/' + segmentId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  GetDashBoarbPerformaceOtherRequestorSalesDetails(companyId: any, startDate: any, endDate: any, segmentId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/GetDashBoarbPerformaceOtherRequestorSalesDetails/'
        + companyId + '/'
        + startDate.toISOString() + '/'
        + endDate.toISOString()
        + '/' + segmentId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  GetDashBoarbPerformaceCustomerRequestsSumaries(companyId: any, startDate: any, endDate: any, segmentId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/GetDashBoarbPerformaceCustomerRequestsSumaries/'
        + companyId + '/'
        + startDate.toISOString() + '/'
        + endDate.toISOString()
        + '/' + segmentId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  GetDashBoarbPerformaceCustromerProposalsGet(companyId: any, startDate: any, endDate: any, segmentId: any, userId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/GetDashBoarbPerformaceCustromerProposalsGet/'
        + companyId + '/'
        + startDate.toISOString() + '/'
        + endDate.toISOString()
        + '/' + segmentId + '/' + userId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  GetDashBoarbPerformaceCustromerSales(companyId: any, startDate: any, endDate: any, segmentId: any, userId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/GetDashBoarbPerformaceCustromerSales/'
        + companyId + '/'
        + startDate.toISOString() + '/'
        + endDate.toISOString()
        + '/' + segmentId + '/' + userId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  GetDashBoarbPerformaceCustromerSalesValue(companyId: any, startDate: any, endDate: any, segmentId: any, userId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/GetDashBoarbPerformaceCustromerSalesValue/'
        + companyId + '/'
        + startDate.toISOString() + '/'
        + endDate.toISOString()
        + '/' + segmentId + '/' + userId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  GetDashBoarbPerformaceCustromerSalesTopRequestors(companyId: any, startDate: any, endDate: any, segmentId: any, userId: any): any {
   
    return new Promise((result, reject) => {
      this.api.get('/GetDashBoarbPerformaceCustromerSalesTopRequestors/'
        + companyId + '/'
        + startDate.toISOString() + '/'
        + endDate.toISOString()
        + '/' + segmentId + '/' + userId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  GetDashBoarbPerformaceCustromerSavingValue(companyId: any, startDate: any, endDate: any, segmentId: any, userId: any): any {
    console.log('/GetDashBoarbPerformaceCustromerSalesTopRequestors/'
    + companyId + '/'
    + startDate.toISOString() + '/'
    + endDate.toISOString()
    + '/' + segmentId + '/' + userId)
    return new Promise((result, reject) => {
      this.api.get('/GetDashBoarbPerformaceCustromerSavingValue/'
        + companyId + '/'
        + startDate.toISOString() + '/'
        + endDate.toISOString()
        + '/' + segmentId + '/' + userId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
}

