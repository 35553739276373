import { Injectable } from '@angular/core';
import { Segment } from 'src/app/models/Segment';
import { SubSegmentApi } from 'src/app/core/http/entidades/SubSegmentApi';
import { SubSegment } from 'src/app/models/SubSegment';

@Injectable({
  providedIn: 'root'
})
export class SubSegmentService {

  constructor(private api: SubSegmentApi) {
    this.api.baseName = 'subsegment';
   }
  /** buscar todas os segmentos */
  getall(): any {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um segmento pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** buscar um segmento pelo Id */
  getFromSegment(segmentId: number): any {
    return new Promise((result, reject) => {
      this.api.get('/fromsegment/' + segmentId.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** buscar um segmento pelo Id */
  getForRequest(): any {
    return new Promise((result, reject) => {
      this.api.get('/forrequest')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** Salvar */
  save(subSegment: SubSegment): any {
    return new Promise((resolve, reject) => {
      // se segmento novo, insere
      if (subSegment.subSegmentId === 0) {
        this.api.post(subSegment)
          .then((data: SubSegment) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(subSegment)
          .then((data: SubSegment) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api.delete(id)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
}
