import { Injectable } from '@angular/core';
import { ProposalRequestItemApi } from 'src/app/core/http/entidades/ProposalRequestItemApi';
import { ProposalRequestItem } from 'src/app/models/ProposalRequestItem';

@Injectable({
  providedIn: 'root'
})
export class ProposalrequestitemService {

  constructor(private api: ProposalRequestItemApi) {
    this.api.baseName = 'proposalrequestitem';
  }

  save(item: ProposalRequestItem): any {
    return new Promise((resolve, reject) => {
      // se segmento novo, insere
      if (item.proposalRequestItemId === 0) {
        this.api.post(item)
          .then((data: ProposalRequestItem) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(item)
          .then((data: ProposalRequestItem) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }

  saveSelectedItens(itens: ProposalRequestItem[]): any {
    return new Promise((resolve, reject) => {
      this.api.put(itens)
        .then((data: ProposalRequestItem) => resolve(data))
        .catch(() => {
          reject();
        });
    })
}

setBestPricesByRequestId(requestId: number): any {
  return new Promise((resolve, reject) => {
    // se segmento novo, insere
    this.api.post(requestId, '/SetBestPricesByRequestId')
      .then((data: ProposalRequestItem) => resolve(data))
      .catch(() => {
        reject();
      });
  });
}
}
