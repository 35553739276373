import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'azureBlobUrl'
})
export class AzureBlobUrlPipe implements PipeTransform {

  transform(url: string, args?: any): any {
    if (!url) {
      return null;
    }
    return url + environment.azureBlobSasTokenForDownload;
  }

}
