import { Component } from '@angular/core';
import { UtilsService } from './services/core/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public isLoggedIn = false;
  public isPublicPage = false;
  public resetPassword = false;
  public newUser = false;
  public conasiUser = false;
  public radarDigitalUser = false;
  public summitCampaing = false;
  public summitCampaingClose = false;
  public lostPassword = false;
  public registerUser = false;
  public useTerms = false;
  public Covid = false;

  constructor(private util: UtilsService) {
    if (this.util.urlPagSeguro()) {
      sessionStorage.setItem('whitelabel', 'pagseguro');
    } else {
      sessionStorage.removeItem('whitelabel');
    }

    if (sessionStorage.getItem('Token')) {
      this.isLoggedIn = true;
    } else {
      if ((window.location.search.indexOf('action=lost-password') > 0)) {
        this.isPublicPage = true;
        this.lostPassword = true;
      }
      if ((window.location.search.indexOf('action=reset-password') > 0)) {
        this.isPublicPage = true;
        this.resetPassword = true;
      }
      if ((window.location.search.indexOf('action=register') > 0)) {
        this.isPublicPage = true;
        this.registerUser = true;
      }
      if ((window.location.href.indexOf('use-terms') > 0)) {
        this.useTerms = true;
      }
      if ((window.location.href.indexOf('customer/first/add') > 0)) {
        this.newUser = true;
      }
      if ((window.location.href.indexOf('conasi/customer/add') > 0)) {
        this.conasiUser = true;
      }
      if ((window.location.href.indexOf('radarindustrial/customer/add') > 0)) {
        this.radarDigitalUser = true;
      }
      if ((window.location.href.indexOf('habitacional/customer/add') > 0)) {
        this.newUser = true;
      }

      if ((window.location.href.indexOf('covid') > 0)) {
        this.Covid = true;
      }
      if ((window.location.href.indexOf('campaigns/summit') > 0)) {
        this.summitCampaing = true;
      }
      if ((window.location.href.indexOf('close/campaigns') > 0)) {
        this.summitCampaingClose = true;
      }

    }
  }
}
