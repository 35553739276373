import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { User } from 'src/app/models/User';
import { UtilsService } from 'src/app/services/core/utils.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActiveCompanyService } from 'src/app/services/active-company.service';
import { QisarService } from 'src/app/services/hubs/qisar.service';
import { TokenModel } from 'src/app/models/TokenModel';

@Component({
  selector: 'app-default-theme',
  templateUrl: './default-theme.component.html',
  styleUrls: ['./default-theme.component.css'],
})
export class DefaultThemeComponent implements OnInit {
  public session: any;
  @Input() isLoggedIn: boolean;
  @Input() lostPassword: boolean;
  @Input() resetPassword: boolean;
  @Input() registerUser: boolean;
  @Input() conasiUser: boolean;
  @Input() radarDigitalUser: boolean;
  @Input() Covid: boolean;
  @Input() newUser: boolean;

  // MainMenuCss = 'sideMenu';
  MainMenuCss = 'sideMenu';
  MainContainerCss = 'app-container';
  user: User;
  sessionOff = false;
  width: any;
  company: any;
  companyList = [];
  userOnline = true;
  firstAccess = true;
  whiteLabel = '';

  constructor(
    private userService: UserService,
    private router: Router,
    private utils: UtilsService,
    private ngxSmartModalService: NgxSmartModalService,
    private hub: QisarService,
    public activeCompanyService: ActiveCompanyService
  ) { }

  ngOnInit() {
    this.session = null;
    if (sessionStorage.getItem('whitelabel')) {
      this.whiteLabel = sessionStorage.getItem('whitelabel');
    }

    this.MainMenuCss = this.MainMenuCss + this.whiteLabel;
    if (this.isLoggedIn) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      if (sessionStorage.getItem('Token')) {
        this.utils
          .getUserFromSession()
          .then((user: User) => {
            if (user.email === 'integration') {
              this.userService.logout();
              this.utils.redirect('');
            }
            this.user = user;
            this.statusSignalR(user);
            setInterval(() => {
              this.statusSignalR(user);
            }, 5000);
          })
          .catch(() => {
            Swal.fire(
              'Qisar',
              'Não foi possivel carregar as informações do usuario',
              'error'
            );
            this.userService.logout();
            this.utils.redirect('');
          });
      }

      this.utils.getUserFromSession().then((user) => {
        if (user.company) {
          let hasManyCompanies = true;
          if (!user.userCompanies) {
            hasManyCompanies = false;
          } else {
            if (user.userCompanies.length === 0) {
              hasManyCompanies = false;
            }
          }
          if (hasManyCompanies) {
            for (const userCampany of user.userCompanies) {
              this.companyList.push(userCampany.company);
            }
          } else {
            if (user.company) {
              this.companyList.push(user.company);
            }
          }

          this.companyList.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          
          this.activeCompanyService.setOptionList(this.companyList);
          if (user.companyId) {
            this.company = this.activeCompanyService.companyMap.get(
              user.companyId.toString()
            );
          }
        }
      });

      this.utils.onUserDataChange.subscribe((user) => {
        this.user = user;
      });
    }
  }

  async statusSignalR(user: any) {
    if (this.firstAccess) {
      this.userOnline = true;
      this.firstAccess = false;
      await this.hub.startConnection(user);
    } else {
      if (!this.hub.isOnline()) {
        await this.hub.startConnection(user);
        this.reactiveSession().then(() => {
          this.userOnline = this.hub.isOnline();
        });
      } else {
        this.userOnline = true;
      }
    }
  }

  async reactiveSession() {
    if (!this.sessionOff) {
      if (this.isLoggedIn) {
        console.log('Reativando a Sessão');
        this.sessionOff = true;
        await this.userService
          .reactiveLogin(this.user)
          .then((token: TokenModel) => {
            console.log('Sessão Reativa');
            this.userService.logout();
            sessionStorage.setItem('Token', JSON.stringify(token));
            this.sessionOff = false;
          });
      }
    }
  }

  /** Ocultar / Exibir Menu */
  toogleMenu() {
    if (this.MainMenuCss === 'sideMenu-close') {
      this.MainMenuCss = "sideMenu" + this.whiteLabel;
      this.MainContainerCss = 'app-container';
    } else {
      this.MainMenuCss = 'sideMenu-close';
      this.MainContainerCss = 'app-container-full';
    }
  }

  /** caso mobile / fechar menu */
  menuClick() {
    if (window.innerWidth < 768) {
      this.MainMenuCss = 'sideMenu';
      this.MainContainerCss = 'app-container';
    }
  }

  onActiveCampanyChange() {
    this.activeCompanyService.onActiveCompanyChange.emit(
      this.activeCompanyService.activeCompanyId
    );
  }

  openCampanySelectionModal() {
    this.ngxSmartModalService.getModal('user-company-list').open();
  }

  selectCompany(companyId: number) {
    this.activeCompanyService.selectCompany(companyId);
    this.company = this.activeCompanyService.companyMap.get(
      companyId.toString()
    );
    this.ngxSmartModalService.getModal('user-company-list').close();
  }

  logout() {
    if (this.hub.isOnline()) {
      this.hub.closeConnection();
    } else {
      console.log('Não conectado ao Hub');
    }
    this.userService.logout();
    this.utils.redirect('');
  }
}
