import { Injectable } from '@angular/core';
import { CompanyPaymentDetailApi } from 'src/app/core/http/entidades/CompanyPaymentDetailApi';
import { CompanyPaymentDetail } from 'src/app/models/CompanyPaymentDetail';

@Injectable({
  providedIn: 'root'
})
export class CompanyPaymentDetailService {

  constructor(private api: CompanyPaymentDetailApi) {
    this.api.baseName = 'companypaymentdetail';
   }

    /** buscar por data selecionada */
    GetCompanyPaymentDetailByDate(initialDate: Date, finalDate: Date): any {
        return new Promise((result, reject) => {
        this.api.get('/CompanyPaymentDetailByDate/'+ initialDate.toISOString()  + '/' + finalDate.toISOString())
            .then((data: CompanyPaymentDetail) => result(data))
            .catch(error => reject(error));
        });
    }

}