import { Injectable } from '@angular/core';
import { UserCompanyApi } from 'src/app/core/http/entidades/UserCompanyApi';
import { UserCompany } from 'src/app/models/UserCompany';

@Injectable({
  providedIn: 'root'
})
export class UserCompanyService{

  constructor(private api: UserCompanyApi) {
      this.api.baseName = 'usercompany';
  }

  getByUserId(userId: number): any {
    return new Promise((result, reject) => {
      this.api.get('/getbyuserid/' + userId)
        .then((data: UserCompany[]) => result(data))
        .catch(error => reject(error));
    });
  }
  save(userCompany: UserCompany): any {
    console.log(userCompany);
    return new Promise((resolve, reject) => {
        this.api.post(userCompany)
          .then(data => {
            resolve(data);
          })
          .catch(() => {
            reject();
          });
    });
  }
  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api.delete(id)
        .then(() => {
          resolve(null);
        })
        .catch(() => {
          reject();
        });
    });
  }
}