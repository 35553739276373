import { Injectable } from '@angular/core';
import { ShippingFileInfo } from 'src/app/models/ShippingFileInfo';
import { ShippingFileApi } from 'src/app/core/http/entidades/ShippingFile';
import { Pagination } from 'src/app/models/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class ShippingFileService {

  constructor(private api: ShippingFileApi) {
    this.api.baseName = 'shippingfile';
  }

  getOutgoingFilesPaging(paginator : Pagination<ShippingFileInfo[]>) : Promise<Pagination<ShippingFileInfo[]>>{
    return new Promise<Pagination<ShippingFileInfo[]>>((result, reject) => {
      this.api.getAllPaging(paginator, null, 'outgoing/')
        .then(data => result(data))
        .catch(error => reject(error))
    });
  }
  
  downloadOutgoingFile(fileId : number, beforeStart : Function = null, onComplete: Function = null) : void{
    this.api.getFile(`/outgoing/download/${fileId}`, 'text/plain', beforeStart, onComplete);
  }

  sendFiles(files: File[], uploadProgressEvent : Function = null) : Promise<boolean>{
    return new Promise<boolean>((result, reject) => {
      this.api.sendFiles('incoming/upload', files, uploadProgressEvent)
        .then(() => result(true))
        .catch((error) => {
          console.log(error);
          result(false);
        });
    })
  }
}
