import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { User } from "src/app/models/User";
import { TokenModel } from "src/app/models/TokenModel";
import { UserService } from "src/app/services/user/user.service";
import { UtilsService } from "src/app/services/core/utils.service";
import Swal from "sweetalert2";
import { TermService } from "src/app/services/term/term.service";
import { CompanyTrustRequestoService } from "src/app/services/companytrustrequestor/companytrusterequestor.service";
import { CompanyService } from "src/app/services/company/company.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { PagSeguroConnect } from "src/app/services/pagSeguro/pagSeguro";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  @ViewChild("terms") dataContainer: ElementRef;

  public monthlyPayment = 0;
  public monthlyPaymentDays = "Grátis por 60 dias partir da data de adesão.";
  loginResponse: any;
  url = "";
  today = new Date();
  nextSixMonth = new Date(new Date().setMonth(new Date().getMonth() + 6));
  nextYear = new Date(new Date().setMonth(new Date().getMonth() + 12));
  term1 = false;
  termPla1 = false;
  termPla2 = false;
  termPla3 = false;
  termPla4 = false;
  term2 = false;
  isTrust = false;
  isTerm1Accept = false;
  isTerm2Accept = false;
  form: FormGroup;
  imageLogo = '/assets/images/logo';
  loginHeader = 'login-header';
  btnPrimary = 'btn-primary';
  linkHome = "linkHome";
  cssPage = "page";
  opac = "opac";
  code = "";
  state = "";


  constructor(
    private formBuilder: FormBuilder,
    private title: Title,
    private termService: TermService,
    private userService: UserService,
    private companyTrustService: CompanyTrustRequestoService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    // private PagSeguroConnect: PagSeguroConnect,
    public utils: UtilsService
  ) {
    this.title.setTitle("Login | Qisar");
    if (sessionStorage.getItem('whitelabel')) {
      this.imageLogo += '-' + sessionStorage.getItem('whitelabel');
      this.loginHeader += sessionStorage.getItem('whitelabel');
      this.btnPrimary += sessionStorage.getItem('whitelabel');
      this.linkHome += sessionStorage.getItem('whitelabel');
      this.cssPage += '-' + sessionStorage.getItem('whitelabel');
      this.opac += '-' + sessionStorage.getItem('whitelabel');
    }
    this.utils.finish();
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
      this.state = params['state']
  });
  

    
    this.form = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
    });

    this.imageLogo += '.png';

    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
      this.state = params['state'];
  
  });
  }
  isInvalidForm(): boolean {
    return !this.form.valid;
  }
  
  getTokenFromPagSeguro(){

  }

  changePlan(plan: number) {
    this.termPla1 = false;
    this.termPla2 = false;
    this.termPla3 = false;
    this.termPla4 = false;
    switch (plan) {
      case 1:
        this.termPla1 = true;
        break;
      case 2:
        this.termPla2 = true;
        break;
      case 3:
        this.termPla3 = true;
        break;
      case 4:
        this.termPla4 = true;
        break;
    }

    this.term1 =
      this.termPla1 || this.termPla2 || this.termPla3 || this.termPla4;
  }
  /** Logar */
  login() {
    if (this.form.valid) {
      this.loginResponse = new TokenModel();
      this.utils.running();
      const user = new User();
      user.email = this.form.value.email;
      user.password = this.form.value.password;
      this.userService
        .login(user)
        .then((res: TokenModel) => {        

          sessionStorage.setItem("Token", JSON.stringify(res));                        
          this.termService.useTerm().then((term) => {
            this.utils.finish();
            this.dataContainer.nativeElement.innerHTML = term.html;
          });

          this.loginResponse = res;
          if (res.token) {
            const ruser = res.user;
            if (res.resetPassword === true) {
              this.url = "/user/profile";
            } else {
              if (ruser.companyId) {
                this.url = "/provider/request/list/open";
                if (ruser.company.isCustomer) {
                  this.url = "/customer/request/list/open";
                }
                if (ruser.company.isPartner) {
                  this.url = "/partner/customers";
                }
              } else {
                this.url = "/offer/list";
              }
            }
            if (res.userTerms === true) {
              this.utils.finish();
              sessionStorage.setItem("Token", JSON.stringify(res));
              this.utils.getUserFromSession().then((userSession: User) => {
                if (userSession.company) {
                  if (
                    userSession.company.isRequestor &&
                    userSession.company.termsStatus < 3
                  ) {
                    this.companyTrustService
                      .isTrustRequestorForSomeCompany()
                      .then((data) => {
                        if (userSession.company.termsStatus === 1) {
                          this.isTerm1Accept = true;
                        }
                        if (userSession.company.termsStatus === 2) {
                          this.isTerm2Accept = true;
                        }
                        if (userSession.company.monthlyPayment) {
                          this.monthlyPayment =
                            userSession.company.monthlyPayment;
                        }
                        if (userSession.company.monthlyPaymentDays) {
                          // tslint:disable-next-line: max-line-length
                          this.monthlyPaymentDays =
                            "Gratis por " +
                            userSession.company.monthlyPaymentDays.toString() +
                            " dias à partir da data de adesão";
                        } else {
                          if (data) {
                            this.monthlyPaymentDays =
                              "Gratis por 30 dias à partir da data de adesão";
                          }
                        }
                        this.utils.finish();
                        this.isTrust = data;
                        if (!this.isTerm1Accept) {
                          this.utils.openModal("modalAccept");
                        } else {
                          this.utils.redirect(this.url);
                        }
                      });
                  } else {
                    this.utils.finish();
                    this.utils.redirect(this.url);
                  }
                } else {
                  this.utils.finish();
                  this.utils.redirect(this.url);
                }
              });
            } else {
              this.utils.openModal("modalTerms");
            }
          } else {
            this.utils.finish();
            Swal.fire("Qisar", "Usuário ou Senha invalidos", "warning");
          }
        })
        .catch((err: any) => {
          this.utils.finish();
          switch (err.status) {
            case 404:
              Swal.fire("Qisar", err.error, "warning");
              break;
            default:
              Swal.fire(
                "Qisar",
                "Sistema indisponivel, tente novamente mais tarde",
                "warning"
              );
              break;
          }
        });
    }
  }
  /** keyUp da tela de login */
  onKey(event: any) {
    if (event.key === "Enter") {
      this.login();
    }
  }
  ngOnInit() { }
  saveTerms() {
    this.utils.running();
    sessionStorage.setItem("Token", JSON.stringify(this.loginResponse));
    this.userService.setUseTerms().then(() => {
      this.utils.getUserFromSession().then((user: User) => {
        if (user.companyId) {
          if (user.company.isRequestor && user.company.termsStatus < 3) {
            this.companyTrustService
              .isTrustRequestorForSomeCompany()
              .then((data) => {
                if (user.company.termsStatus === 1) {
                  this.isTerm1Accept = true;
                }
                if (user.company.termsStatus === 2) {
                  this.isTerm2Accept = true;
                }
                if (user.company.monthlyPayment) {
                  this.monthlyPayment = user.company.monthlyPayment;
                }
                if (user.company.monthlyPaymentDays) {
                  this.monthlyPaymentDays =
                    "Grátis por " +
                    user.company.monthlyPaymentDays.toString() +
                    " dias";
                }
                this.utils.finish();
                this.utils.closeModal("modalTerms");
                this.isTrust = data;
                if (
                  !this.isTerm1Accept //||
                  //(!this.isTrust && !this.isTerm2Accept)
                ) {
                  this.utils.openModal("modalAccept");
                } else {
                  this.utils.redirect(this.url);
                }
              });
          } else {
            this.utils.finish();
            this.utils.closeModal("modalTerms");
            this.utils.redirect(this.url);
          }
        } else {
          this.utils.finish();
          this.utils.closeModal("modalTerms");
          this.utils.redirect(this.url);
        }
      });
    });
  }
  continueModalAccept() {
    let TrustStatus = 0;
    this.utils.running();
    this.term1 =
      this.termPla1 || this.termPla2 || this.termPla3 || this.termPla4;
    if (!this.term1) {
      Swal.fire("Qisar", "Por favor escolha um plano para continuar", "info");
    } else {
      let planTilte = "";
      if (this.termPla1) {
        planTilte = "Plano Basic: R$ 199,00 por mês";
      }
      if (this.termPla2) {
        planTilte = "Plano Basic: R$ 1.150,00 semestral";
      }
      if (this.termPla3) {
        planTilte = "Plano Basic: R$ 2.000,00 anual";
      }
      if (this.termPla4) {
        // tslint:disable-next-line: max-line-length
        planTilte =
          "Plano Performace: R$ 99,00 manutenção por ano à vista + 0,8% calculado sobre as vendas brutas apuradas mensalmente cuja soma dos valores seja superior à R$ 1.000, 00";
      }

      Swal.fire({
        title: "Qisar",
        text: "Você está prestes à escolher o plano " + planTilte,
        type: "question",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Não estou de acordo",
        confirmButtonText: "Confirmo à adesão ao Plano de Pagamento",
      }).then((confirm) => {
        if (confirm.value) {
          this.utils.closeModal("modalAccept");
          // tslint:disable-next-line: max-line-length
          Swal.fire(
            "Qisar",
            "Sua fatura já está disponivel, acesse ela pelo menu FINANCEIRO e se atente à data de vencimento",
            "info"
          ).then(() => {
            this.url = "/company/invoices";
            if (this.term1) {
              TrustStatus += 1;
            }
            if (this.term2 && !this.isTrust && !this.isTerm2Accept) {
              TrustStatus += 2;
            }

            if (TrustStatus > 0) {
              const tokenStr: TokenModel = JSON.parse(
                sessionStorage.getItem("Token")
              );
              let Plan = 0;
              if (this.termPla1) {
                Plan = 1;
              }
              if (this.termPla2) {
                Plan = 2;
              }
              if (this.termPla3) {
                Plan = 3;
              }
              if (this.termPla4) {
                Plan = 4;
              }
              tokenStr.user.company.termsStatus = TrustStatus;
              tokenStr.user.company.paymentPlanId = Plan;
              this.companyService.save(tokenStr.user.company).then(() => {
                sessionStorage.clear();
                sessionStorage.setItem("Token", JSON.stringify(tokenStr));
                this.utils.running();
                this.utils.redirect(this.url);
              });
            } else {
              this.utils.redirect(this.url);
            }
          });
        }
      });
    }
  }
  closeTerms() {
    this.utils.finish();
    this.userService.logout();
    this.loginResponse = new TokenModel();
    this.utils.closeModal("modalTerms");
  }

  getPagSeguroToken(){
    // this.PagSeguroConnect.getToken(this.code).then( res =>{


    // })
  }
}
