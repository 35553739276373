import { Api } from '../base/Api';
import { Injectable } from '@angular/core';
import { ProposalRequestItem } from 'src/app/models/ProposalRequestItem';


@Injectable({
    providedIn: 'root'
})
export class ProposalRequestItemApi extends Api<ProposalRequestItem> {
}
