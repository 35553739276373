import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { SelectItem } from "primeng/api";
import { ActiveCompanyService } from "src/app/services/active-company.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserService } from "src/app/services/user/user.service";
import { SegmentService } from "src/app/services/segment/segment.service";
import { UtilsService } from "src/app/services/core/utils.service";
import { Title } from "@angular/platform-browser";
import * as Highcharts from "highcharts";
import highcharts3D from "highcharts/highcharts-3d.src";
import { RequestService } from "src/app/services/request/request.service";
import { Subscription } from 'rxjs';

declare var require: any;
const Boost = require("highcharts/modules/boost");
const noData = require("highcharts/modules/no-data-to-display");
const More = require("highcharts/highcharts-more");

highcharts3D(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: "app-dash-board-provider-performace",
  templateUrl: "./dash-board-provider-performace.component.html",
  styleUrls: ["./dash-board-provider-performace.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class DashBoardProviderPerformaceComponent implements OnInit, OnDestroy {
  filterFormSegmentIdOptionList: Array<SelectItem> = [];
  filterFormUserIdOptionList: Array<SelectItem> = [];
  form: FormGroup;

  optionsLostSales = {};
  optionsSales = {};
  pt = {
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sab"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Feveiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
  };

  ProspectsRequestsReceived = 0;
  ClientsRequestsReceived = 0;
  TotalRequestsReceived = 0;

  ProspectsSentProposals = 0;
  ClientSentProposals = 0;
  TotalSentProposals = 0;

  ProspectsSalesMade = 0;
  ClientsSalesMade = 0;
  TotalSalesMade = 0;

  ProspectsUnrealizedSales = 0;
  ClientsUnrealizedSales = 0;
  TotalUnrealizedSales = 0;

  ProspectsValueInSalesMade = 0;
  ClientsValueInSalesMade = 0;
  TotalValueInSalesMade = 0;

  loadRequestsRecievedFinish = false;
  loadProposalsSendFinish = false;
  loadSalesMadeFinish = false;
  loadValueSalesMadeFinish = false;
  loadUnrealizedSalesMadeFinish = false;
  loadUnrealizedSalesGraficoFinish = false;

  subscribeCompany: Subscription;

  constructor(
    private activeCompany: ActiveCompanyService,
    private requestService: RequestService,
    private title: Title,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private segmentService: SegmentService,
    private util: UtilsService
  ) {}
  ngOnDestroy(): void {
    if (this.subscribeCompany) {
      this.subscribeCompany.unsubscribe();
    }
  }

  ngOnInit() {
    Highcharts.setOptions({
      lang: {
        drillUpText: "Carregar Todos",
        noData: "Nenhum dado encontrado",
      },
    });

    this.title.setTitle("DashBoard Performace| Qisar");
    // Troca de empresa
    this.subscribeCompany = this.activeCompany.onActiveCompanyChange.subscribe(
      () => {
        this.loadPage();
      }
    );
    // Formulario de busca
    const initial = new Date(new Date().setMonth(new Date().getMonth() - 1));
    this.form = this.formBuilder.group({
      to: [initial],
      from: [new Date()],
      segmentId: [0],
      userId: [0],
    });
    this.loadSegments();
    this.loadUsers();
    this.loadPage();
  }

  loadRequestsRecieved() {
    this.loadRequestsRecievedFinish = false;
    this.requestService
      .GetDashBoarbPerformaceRequestorRequestsSumaries(
        this.activeCompany.activeCompanyId,
        this.form.value.to,
        this.form.value.from,
        this.form.value.segmentId
      )
      .then((data: any) => {
        this.ProspectsRequestsReceived = data.nonCustomerRequests;
        this.ClientsRequestsReceived = data.myCustomerRequests;
        this.TotalRequestsReceived = data.total;
        this.loadRequestsRecievedFinish = true;
        this.validEndAll();
      });
  }

  loadProposalSend() {
    this.loadProposalsSendFinish = false;
    this.requestService
      .GetDashBoarbPerformaceRequestorProposalsSend(
        this.activeCompany.activeCompanyId,
        this.form.value.to,
        this.form.value.from,
        this.form.value.segmentId
      )
      .then((data: any) => {
        this.ProspectsSentProposals = data.nonCustomerRequests;
        this.ClientSentProposals = data.myCustomerRequests;
        this.TotalSentProposals = data.total;
        this.loadProposalsSendFinish = true;
        this.validEndAll();
      });
  }

  loadSalesMade() {
    this.loadSalesMadeFinish = false;
    this.requestService
      .GetDashBoarbPerformaceRequestorSales(
        this.activeCompany.activeCompanyId,
        this.form.value.to,
        this.form.value.from,
        this.form.value.segmentId
      )
      .then((data: any) => {
        this.ProspectsSalesMade = data.nonCustomerRequests;
        this.ClientsSalesMade = data.myCustomerRequests;
        this.TotalSalesMade = data.total;
        this.loadSalesMadeFinish = true;
        this.loadChatSales(data.nonCustomerRequests, data.myCustomerRequests);
        this.validEndAll();
      });
  }

  loadSalesUnrealized() {
    this.loadUnrealizedSalesMadeFinish = false;
    this.requestService
      .GetDashBoarbPerformaceOtherRequestorSales(
        this.activeCompany.activeCompanyId,
        this.form.value.to,
        this.form.value.from,
        this.form.value.segmentId
      )
      .then((data: any) => {
        this.ProspectsUnrealizedSales = data.nonCustomerRequests;
        this.ClientsUnrealizedSales = data.myCustomerRequests;
        this.TotalUnrealizedSales = data.total;
        this.loadUnrealizedSalesMadeFinish = true;
        this.validEndAll();
      });
  }

  loadSalesUnrealizedDetails() {
    this.loadUnrealizedSalesGraficoFinish = false;
    this.requestService
      .GetDashBoarbPerformaceOtherRequestorSalesDetails(
        this.activeCompany.activeCompanyId,
        this.form.value.to,
        this.form.value.from,
        this.form.value.segmentId
      )
      .then((data: any) => {
        this.loadChatLostSales(
          data.lostSaleFromPrice,
          data.lostSaleFromDate,
          data.lostSaleFromDateAndPrice,
          data.lostSale
        );
        this.loadUnrealizedSalesGraficoFinish = true;
        this.validEndAll();
      });
  }

  loadValueSalesMade() {
    this.loadProposalsSendFinish = false;
    this.requestService
      .GetDashBoarbPerformaceRequestorSalesValue(
        this.activeCompany.activeCompanyId,
        this.form.value.to,
        this.form.value.from,
        this.form.value.segmentId
      )
      .then((data: any) => {
        this.ProspectsValueInSalesMade = data.nonCustomerRequestsSum;
        this.ClientsValueInSalesMade = data.myCustomerRequestsSum;
        this.TotalValueInSalesMade = data.sum;
        this.loadValueSalesMadeFinish = true;
        this.validEndAll();
      });
  }

  loadChatLostSales(Price: any, Date: any, PriceAndDate: any, Others: any) {
    const arrData = [];
    if (Price > 0) {
      arrData.push({
        name: "Preço",
        y: Price,
      });
    }

    if (Date > 0) {
      arrData.push({
        name: "Prazo",
        y: Date,
      });
    }

    if (PriceAndDate > 0) {
      arrData.push({
        name: "Preço e Prazo",
        y: PriceAndDate,
      });
    }

    if (Others > 0) {
      arrData.push({
        name: "Outros",
        y: Others,
      });
    }

    this.optionsLostSales = {
      chart: {
        type: "pie",
        height: 230,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      legend: {
        enabled: true,
        align: "left",
        layout: "vertical",
        verticalAlign: "top",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.y}</b>",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b><br /> {point.y}",
          },
          showInLegend: true,
        },
      },
      yAxis: {
        title: {
          text: "sobre a consulta",
        },
      },
      xAxis: {
        type: "category",
      },
      series: [
        {
          name: "Motivos",
          colorByPoint: true,
          data: arrData,
        },
      ],
    };
    Highcharts.chart("lost-sales", this.optionsLostSales);
  }

  loadChatSales(ProspectsSalesMade: any, ClientsSalesMade: any) {
    this.optionsSales = {
      chart: {
        type: "column",
        options3d: {
          enabled: true,
          alpha: 10,
          beta: 25,
          depth: 70,
        },
        height: 200,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.y}</b>",
      },
      plotOptions: {
        column: {
          depth: 25,
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b><br /> {point.y}",
          },
          showInLegend: true,
        },
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      xAxis: {
        type: "category",
      },
      series: [
        {
          name: "Vendas",
          colorByPoint: true,
          data: [
            {
              name: "Prospects",
              y: ProspectsSalesMade,
            },
            {
              name: "Clientes",
              y: ClientsSalesMade,
            },
          ],
        },
      ],
    };
    Highcharts.chart("chat-sales", this.optionsSales);
  }

  loadPage() {
    this.util.running();
    this.loadRequestsRecieved();
    this.loadProposalSend();
    this.loadSalesMade();
    this.loadValueSalesMade();
    this.loadSalesUnrealized();
    this.loadSalesUnrealizedDetails();
  }

  loadSegments() {
    this.filterFormSegmentIdOptionList = [];
    this.filterFormSegmentIdOptionList.push({
      label: "Todos",
      value: 0,
    });
    this.segmentService.getall().then((response) => {
      for (const segment of response) {
        this.filterFormSegmentIdOptionList.push({
          label: segment.name,
          value: segment.segmentId,
        });
      }
    });
  }

  loadUsers() {
    this.filterFormUserIdOptionList = [];
    this.filterFormUserIdOptionList.push({
      label: "Todos",
      value: 0,
    });
    this.userService.list().then((users) => {
      for (const user of users) {
        this.filterFormUserIdOptionList.push({
          label: user.name,
          value: user.userId,
        });
      }
    });
  }

  validEndAll() {
    if (
      this.loadRequestsRecievedFinish &&
      this.loadProposalsSendFinish &&
      this.loadSalesMadeFinish &&
      this.loadUnrealizedSalesMadeFinish &&
      this.loadUnrealizedSalesGraficoFinish &&
      this.loadValueSalesMadeFinish
    ) {
      this.util.finish();
    }
  }
}
