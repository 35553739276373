import { Company } from './Company';

export class Address {
  public addressId: number;
  public name: string;
  public street: string;
  public number: number;
  public cmpl: string;
  public neighBorHood: string;
  public city: string;
  public state: string;
  public zipCode: string;
  public default: boolean;
  public actived: boolean;
  public companyId: number;
  public deliveryInstruction: string;
  public company: Company;
}
