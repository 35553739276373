import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company/company.service';

@Component({
  selector: 'app-basicplan',
  templateUrl: './basicplan.component.html',
  styleUrls: ['./basicplan.component.css']
})
export class BasicplanComponent implements OnInit {
  items = [];
  constructor(private companyService: CompanyService) { }

  ngOnInit() {
    this.companyService.getBasicPlanSaleCommissions().then((items: []) => this.items = items);
  }

}
