import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UtilsService } from 'src/app/services/core/utils.service';
import { User } from 'src/app/models/User';
import { ProposalService } from 'src/app/services/proposal/proposal.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DialogHelperService } from 'src/app/services/dialog-helper.service';
import { ActiveCompanyService } from 'src/app/services/active-company.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-proposal-list-page',
  templateUrl: './customer-proposal-list-page.component.html',
  styleUrls: ['./customer-proposal-list-page.component.css']
})
export class CustomerProposalListPageComponent implements OnInit, OnDestroy {

  @ViewChild('atachmentsOverlayPanel') atachmentsOverlayPanel: OverlayPanel;

  currentUser: User;

  proposalList: Array<any>;

  someDate: Date = new Date();

  selectedProposalToShowAtachments: any;
  selectedProposalToReject: any;
  selectedProposalToShowDetails: any;
  selectedRequestToShowDetails: any;
  selectedSaleToShowDetails: any;

  rejectProposalForm: FormGroup;

  subscribeCompany: Subscription;

  constructor(
    private utilsService: UtilsService,
    private proposalService: ProposalService,
    private formBuilder: FormBuilder,
    public ngxSmartModalService: NgxSmartModalService,
    private dialogHelperService: DialogHelperService,
    public activeCompanyService: ActiveCompanyService,
  ) { }

  ngOnDestroy(): void {
    if(this.subscribeCompany){
      this.subscribeCompany.unsubscribe();
    }
  }

  ngOnInit() {
    this.rejectProposalForm = this.formBuilder.group({
      reason: [null, Validators.compose([Validators.required])],
    });
    this.utilsService.getUserFromSession().then(user => {
      this.currentUser = user;
      if (this.activeCompanyService.activeCompanyId) {
        this.loadList();
      }
    });
    this.subscribeCompany = this.activeCompanyService.onActiveCompanyChange.subscribe(() => {
      this.loadList();
    });
  }

  loadList() {
    return new Promise((resolve, reject) => {
      this.proposalService.itensfromaapproval().then(response => {
        this.proposalList = response;
      });
    });
  }

  openRejectProposalModal(proposal) {
    this.selectedProposalToReject = proposal;
    this.rejectProposalForm.reset();
    this.ngxSmartModalService.getModal('customer-proposal-list-page-reject-proposal').open();
  }

  openProposalDetailsModal(row: any) {
    this.selectedProposalToShowDetails = row.proposal;
    this.selectedRequestToShowDetails = row.request;
    this.selectedSaleToShowDetails = null;
    if (this.selectedProposalToShowDetails.sales.length > 0) {
      this.selectedSaleToShowDetails = this.selectedProposalToShowDetails.sales[0];
    }
    this.ngxSmartModalService.getModal('customer-proposal-list-page-proposal-details').open();
  }


  openAtachmentsOverlayPanel(event: any, proposal: Request) {
    this.selectedProposalToShowAtachments = proposal;
    if (this.atachmentsOverlayPanel.visible) {
      this.atachmentsOverlayPanel.hide();
    }
  }

  approveProposal(proposal: any) {
    this.dialogHelperService
      .askConfirmation('Você realmente quer aprovar essa proposta? Essa ação não pode ser desfeita.')
      .then(confirmation => {
        if (confirmation) {
          this.proposalService.approval(proposal.proposalId, this.currentUser.userId).then(() => {
            this.loadList();
            this.ngxSmartModalService.getModal('customer-proposal-list-page-proposal-details').close();
          });
        }
      });
  }

  rejectProposal() {
    if (this.rejectProposalForm.valid) {
      this.dialogHelperService
        .askConfirmation('Você realmente quer rejeitar essa proposta? Essa ação não pode ser desfeita.')
        .then(confirmation => {
          if (confirmation) {
            const reason: string = this.rejectProposalForm.value.reason;
            this.proposalService.decline(this.selectedProposalToReject.proposalId, this.currentUser.userId, reason).then(() => {
              this.loadList();
              this.ngxSmartModalService.getModal('customer-proposal-list-page-reject-proposal').close();
              this.ngxSmartModalService.getModal('customer-proposal-list-page-proposal-details').close();
            });
          }
        });
    } else {
      const formKeyList: Array<string> = Object.keys(this.rejectProposalForm.controls);
      for (const key of formKeyList) {
        this.rejectProposalForm.controls[key].markAsTouched();
      }
    }
  }

}
