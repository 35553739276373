import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UtilsService } from 'src/app/services/core/utils.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-useterms',
  templateUrl: './useterms.component.html',
  styleUrls: ['./useterms.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UsetermsComponent implements OnInit {

  constructor(
    private title: Title,
    private utils: UtilsService) {
    this.title.setTitle('Termos de Uso | Qisar');
    this.utils.running();
  }

  ngOnInit() {
    this.utils.finish();
  }

}
