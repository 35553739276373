import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company/company.service';
import { ActiveCompanyService } from 'src/app/services/active-company.service';

@Component({
  selector: 'app-partnercusrtomers',
  templateUrl: './partnercusrtomers.component.html',
  styleUrls: ['./partnercusrtomers.component.css']
})
export class PartnercusrtomersComponent implements OnInit {
  customers: [];
  constructor(
      private companyService: CompanyService,
      private activeCompany: ActiveCompanyService) { }

  ngOnInit() {
    this.companyService.getSituationsFromCustomer(this.activeCompany.activeCompanyId)
    .then((data: []) => this.customers = data);
  }

}
