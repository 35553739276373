import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UtilsService } from 'src/app/services/core/utils.service';
import { OffersService } from 'src/app/services/offers/offers.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DialogHelperService } from 'src/app/services/dialog-helper.service';

@Component({
  selector: 'app-offerdetails',
  templateUrl: './offerdetails.component.html',
  styleUrls: ['./offerdetails.component.css']
})
export class OfferdetailsComponent implements OnInit {
  form: FormGroup;
  offer: any;
  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private utils: UtilsService,
    private notificationService: DialogHelperService,
    private formBuilder: FormBuilder,
    private offerService: OffersService) { }

  ngOnInit() {
    this.title.setTitle('Detalhes da Oferta | Qisar');
    this.form = this.formBuilder.group({
      quantity: [null, Validators.compose([Validators.required, , Validators.min(1)])]
    })
    const offerId = this.route.snapshot.paramMap.get('offerId');
    this.offerService.get(Number(offerId))
      .then((data: any) => {
        this.offer = data;
      });
  }

  backToList() {
    this.router.navigateByUrl('/offer/list');
  }

  buy() {
    if (this.form.valid) {
      const total = Number(Number(this.offer.cost) * Number(this.form.value.quantity));
      Swal.fire({
        title: 'ATENÇÃO',
        html: 'Você vai comprar ' + this.form.value.quantity + ' de ' + this.offer.name +
          ' por <strong>R$ ' + total.toFixed(2).replace('.', ',') + '</strong>',
        type: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Desistir',
        confirmButtonText: 'confirmar'
      })
        .then(confirm => {
          if (confirm.value) {
            let companyId = 0;
            const tokenData = this.utils.getTokenData();
            const user = tokenData.user;
            if(user.companyId) {
              companyId = user.companyId;
            }


            var order = {
              offer: this.offer,
              userId: user.userId,
              companyId: companyId,
              quantity: this.form.value.quantity
            }
            this.offerService.sale(order).then(() => {
              this.router.navigateByUrl('/offer/list');
              this.notificationService.showSuccessNotification('Compra realizada com sucesso');
            }).catch((err: any) => {
              console.error(err);
              switch (err.status) {
                case 400:
                  Swal.fire('Oportunidades', err.error, 'warning');
                  break;
                default:
                  Swal.fire('Oportunidades', 'Ocorreu um erro ao fechar sua compra', 'error');
                  break;
              }
            });
          }
        })

    }
  }

  disabled() {
    if (this.form.valid) {
      return '';
    } else {
      return 'disabled';
    }
  }

}
