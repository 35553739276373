import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notification-list-view',
  templateUrl: './notification-list-view.component.html',
  styleUrls: ['./notification-list-view.component.css']
})
export class NotificationListViewComponent implements OnInit {

  constructor(
    public notificationService: NotificationService,
    public ngxSmartModalService: NgxSmartModalService,
    public router: Router,
  ) { }

  ngOnInit() {
  }

}
