import { Injectable } from '@angular/core';
import { PreRequestApi } from 'src/app/core/http/entidades/PreRequestApi';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PrerequestService {

  constructor(private api: PreRequestApi) {
    this.api.baseName = 'prerequest';
  }

  /** Salvar */
  save(request: any): any {
    return new Promise((resolve, reject) => {
      // se segmento novo, insere
      if (request.preRequestId === 0) {
        this.api.post(request)
          .then((data: any) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(request)
          .then((data: any) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }

  SendRequestEmails(request: any): any {
    return new Promise((resolve, reject) => {
      this.api.post(request.preRequestId, '/SendRequestEmails')
        .then((data: any) => resolve(data))
        .catch(() => {
          reject();
        });
    });
  }

  ToRequest(preRequest: any): any {
    return new Promise((resolve, reject) => {
      this.api.post(preRequest, '/ToRequest')
        .then((data: any) => resolve(data))
        .catch(() => {
          reject();
        });
    });
  }

  searchList(
    profile: string,
    companyId: number,
    segmentId: number,
    statusId: number,
    createdAtStart: Date,
    createdEnd: Date,
    awnserDate: Date,
    requestId: number
  ): Promise<Array<any>> {
    const searchRequestData = {
      profile,
      companyId,
      segmentId,
      statusId,
      createdAtStart,
      createdEnd,
      requestId,
      awnserDate: null,
    };
    if (awnserDate) {
      searchRequestData.awnserDate = moment(awnserDate).format('DD/MM/YYYY');
    }
    return this.api.post(searchRequestData, '/search');
  }

  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
}
