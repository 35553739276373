import { Injectable } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  messageList: Array<any>;

  constructor(
    private ngxSmartModalService: NgxSmartModalService,
    private router: Router,
  ) {
  }

  open(): void {
    this.ngxSmartModalService.getModal('mail-list').open();
  }

  getMessageList() {
      this.messageList = [];
  }

  getCount(): number {
    if (!this.messageList) {
      return null;
    }
    return this.messageList.length;
  }

  getMessageTitle(message: any): string {
    if (message.messageTemplateId === 1 || message.messageTemplateId === 2) {
      return 'Nova requisição: #' + message.request.requestId;
    }
    if (message.messageTemplateId === 3) {
      return 'Nova venda';
    }
    if (message.messageTemplateId === 4) {
      return 'Nova compra';
    }
    if (message.messageTemplateId === 5) {
      return 'Fornecedor declinou';
    }
    if (message.messageTemplateId === 6) {
      return 'Alteração da data de resposta da requisição';
    }
    if (message.messageTemplateId === 7) {
      return 'Nova proposta';
    }
    if (message.messageTemplateId === 8) {
      return 'Requisição #' + message.request.requestId + ' cancelada';
    }
  }

  getMessageText(message: any): string {
    if (message.messageTemplateId === 1 || message.messageTemplateId === 2) {
      return 'Você recebeu uma nova requisição (#' + message.request.requestId + ') de compra.';
    }
    if (message.messageTemplateId === 3) {
      return 'Uma proposta da requisição #' + message.proposal.requestId + ' foi vendida.';
    }
    if (message.messageTemplateId === 7) {
      return 'Você recebeu uma nova proposta na requisição #' + message.request.requestId + '.';
    }
    if (message.messageTemplateId === 8) {
      return 'A requisição de compra #' + message.request.requestId + ' foi cancelada.';
    }
    const html: string = message.messageTemplate.text;
    const div = document.createElement('div');
    div.innerHTML = html.replace(/(\r\n|\n|\r)/gm, '').replace(/<style.*?<\/style>/g, '').replace(/<[^>]*>?/gm, '');
    const text = div.textContent || div.innerText || '';
    div.remove();
    return text;
  }

  getButtonText(message: any): string {
    if (
      message.messageTemplateId === 1 ||
      message.messageTemplateId === 2 ||
      message.messageTemplateId === 4 ||
      message.messageTemplateId === 5 ||
      message.messageTemplateId === 6 ||
      message.messageTemplateId === 7 ||
      message.messageTemplateId === 8
    ) {
      return 'Ir para requisição #' + message.request.requestId;
    }
    if (
      message.messageTemplateId === 3
    ) {
      return 'Ir para requisição #' + message.proposal.requestId;
    }
    return 'Ir para';
  }

  doButtonAction(message: any) {
    if (
      message.messageTemplateId === 1 ||
      message.messageTemplateId === 2 ||
      message.messageTemplateId === 3 ||
      message.messageTemplateId === 4 ||
      message.messageTemplateId === 5 ||
      message.messageTemplateId === 6 ||
      message.messageTemplateId === 7 ||
      message.messageTemplateId === 8
    ) {
      this.router.navigateByUrl('/request/' + message.request.requestId);
    }
    if (
      message.messageTemplateId === 3
    ) {
      this.router.navigateByUrl('/request/' + message.proposal.requestId);
    }

  }
}
