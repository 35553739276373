import { Component, OnInit, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import Swal from "sweetalert2";
import { Company } from "src/app/models/Company";
import { CompanyService } from "src/app/services/company/company.service";
import { UtilsService } from "src/app/services/core/utils.service";
import { Router } from "@angular/router";
import { Address } from "src/app/models/Address";
import { User } from "src/app/models/User";
import { AddressService } from "src/app/services/address/address.service";
import { Segment } from "src/app/models/Segment";
import { SegmentService } from "src/app/services/segment/segment.service";
import { CompanySegment } from "src/app/models/CompanySegment";
import { CompanysegmentserviceService } from "src/app/services/companysegmentservice/companysegmentservice.service";
import { AzureStorageBlobHelperService } from "src/app/services/azure-storage-blob-helper.service";
import { ArrayHelper } from "src/app/utils/array-helper";
import { FormGroup, FormBuilder } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { ActiveCompanyService } from "src/app/services/active-company.service";
import { NgxSmartModalService } from "ngx-smart-modal";

import { Subscription } from "rxjs";

@Component({
  selector: "app-company-register",
  templateUrl: "./company-register.component.html",
  styleUrls: ["./company-register.component.css"],
})
export class CompanyRegisterComponent implements OnInit, OnDestroy {
  companyListToDisplay: Array<any>;
  subscribeCompany: Subscription;
  filterForm: FormGroup;

  activeFilterOptions: Array<SelectItem> = [
    {
      label: "Ativo",
      value: true,
    },
    {
      label: "Inativo",
      value: false,
    },
    {
      label: "Todos",
      value: null,
    },
  ];

  companies: Company[];
  plans: any[];
  segments: any[];
  segmentsFilter: any[];
  profileFilter: any[];
  paymentPlanFilter: any[];
  companyFilter: any[];
  company: any;
  address: any;
  user: any;
  trustCompany: any;
  companySegment: any;
  selectedLogoBase64: string;
  showFilter = false;
  pagcompras = false;

  pt = {
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "eg", "ter", "qua", "qui", "sex", "sab"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Feveiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
  };
  minDate = new Date();
  colspan = "5";
  canUpdatePlan = false;
  dateToAcceptPlan: Date = null;

  constructor(
    private title: Title,
    private ngxSmartModalService: NgxSmartModalService,
    private router: Router,
    private companySegmentService: CompanysegmentserviceService,
    private http: HttpClient,
    private companyService: CompanyService,
    private utils: UtilsService,
    private addressService: AddressService,
    private segmentService: SegmentService,
    private azureStorageBlobHelperService: AzureStorageBlobHelperService,
    private formBuilder: FormBuilder,
    private activeCompanyService: ActiveCompanyService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
  ngOnDestroy(): void {
    if(this.subscribeCompany){
      this.subscribeCompany.unsubscribe();
    }
  }

  async ngOnInit() {
    this.profileFilter = [];
    this.plans = [];

    this.plans.push({
      label: "Plano R$ 99,00",
      value: 4,
    });

    this.plans.push({
      label: "Plano R$ 199,00",
      value: 1,
    });

    this.plans.push({
      label: "Plano R$ 1.150",
      value: 2,
    });

    this.plans.push({
      label: "Plano R$ 2.000",
      value: 3,
    });

    this.paymentPlanFilter = [];
    this.companyFilter = [];
    this.title.setTitle("Cadastro de Empresas | Qisar");
    this.filterForm = this.formBuilder.group({
      nickName: null,
      cnpj: null,
      email: null,
      contato: null,
      segmentId: null,
      paymentPlanId: null,
      profileId: null,
    });
    this.profileFilter.push({
      label: "Todos",
      value: null,
    });
    this.profileFilter.push({
      label: "Comprador",
      value: 1,
    });
    this.profileFilter.push({
      label: "Fornecedor",
      value: 2,
    });
    this.paymentPlanFilter.push({
      label: "Todos",
      value: null,
    });
    this.paymentPlanFilter.push({
      label: "Sem Plano",
      value: -1,
    });
    this.paymentPlanFilter.push({
      label: "R$ 199,00",
      value: 1,
    });
    this.paymentPlanFilter.push({
      label: "R$ 1.150,00",
      value: 2,
    });
    this.paymentPlanFilter.push({
      label: "R$ 2.000,00",
      value: 3,
    });
    this.paymentPlanFilter.push({
      label: "R$ 99,00",
      value: 4,
    });
    this.loadAllsegments().then(() => {
      this.segmentsFilter = [];
      this.segmentsFilter.push({
        label: "Todos",
        value: 0,
      });
      this.segments.forEach((segment) => {
        this.segmentsFilter.push({
          label: segment.name,
          value: segment.segmentId,
        });
      });
    });
    this.address = new Address();
    this.utils.running();
    this.cleanCompany();
    await this.utils.getUserFromSession().then((user: User) => {
      this.user = user;
      this.pagcompras = user.company.pagCompras;

      if (this.user.master) {
        this.showFilter = true;
        this.colspan = "6";
      }
      this.loadCompanyList().then(() => {
        this.applyCompanyListFilter();
        this.utils.finish();
      });
    });

    this.subscribeCompany = this.activeCompanyService.onActiveCompanyChange.subscribe(() => {
      this.loadCompanyList().then(() => {
        this.applyCompanyListFilter();
        this.utils.finish();
      });
    });
  }

  validInvoices(invoices): any {
    const arr = [];
    if (invoices) {
      invoices.forEach((item) => {
        if (item.value > 0) {
          arr.push(item);
        }
      });
    }
    return arr;
  }

  unlock(company: any) {
    Swal.fire({
      title: "Qisar",
      type: "question",
      html: "Confirma o desbloqueio da empresa?",
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Confirmo",
    }).then((res) => {
      if (res.value) {
        company.paymentBlock = 0;
        this.companyService.save(company);
      }
    });
  }

  lock(company: any) {
    Swal.fire({
      title: "Qisar",
      type: "question",
      html: "Confirma o bloqueio da empresa?",
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Confirmo",
    }).then((res) => {
      if (res.value) {
        company.paymentBlock = 1;
        this.companyService.save(company);
      }
    });
  }

  cancelInvoice(invoice): any {
    Swal.fire({
      title: "Qisar",
      text: "Deseja Cancelar o Boleto",
      type: "question",
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
    }).then((res) => {
      if (res.value) {
        this.companyService.CancelCompanyInvoice(invoice).then(() => {
          invoice.state = 4;
        });
      }
    });
  }

  downInvoice(invoice): any {
    Swal.fire({
      title: "Qisar",
      text: "Deseja Baixar o Boleto",
      type: "question",
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
    }).then((res) => {
      if (res.value) {
        this.companyService.DownCompanyInvoice(invoice).then(() => {
          invoice.state = 2;
        });
      }
    });
  }

  loadCompanyList(): Promise<void> {
    this.companyFilter.push({
      label: "",
      value: 0,
    });
    return new Promise((resolve, reject) => {
      this.companyService
        .list(this.activeCompanyService.activeCompanyId)
        .then((companies: []) => {
          this.companies = ArrayHelper.orderArrayByProperty(
            companies,
            "nickName"
          );
          companies.forEach((item: any) => {
            this.companyFilter.push({
              label: item.name,
              value: item.companyId,
            });
          });
          resolve();
        })
        .catch(() => {
          Swal.fire("Qisar", "Erro ao Trazer a lista de Empresas", "error");
          reject();
        });
    });
  }

  sortCompanies(companiesTrusts) {
    if (companiesTrusts) {
      const c = companiesTrusts.sort((a, b) => {
        if (a.nickName < b.nickName) {
          return -1;
        }
        if (a.nickName > b.nickName) {
          return 1;
        }
        return 0;
      });
      return c;
    }
  }

  sortSegments(segments) {
    if (segments) {
      const c = segments.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      return c;
    }
  }

  applyCompanyListFilter(): void {
    this.utils.running();
    const companyListToDisplay: Array<Company> = [];
    let cnpj = "";
    if (this.filterForm.value.cnpj) {
      cnpj = this.filterForm.value.cnpj
        .toLowerCase()
        .replace(".", "")
        .replace(".", "")
        .replace("/", "")
        .replace("-", "");
    }
    for (const company of this.companies) {
      let addToDisplayList = false;
      if (this.filterForm.value.nickName) {
        if (
          company.nickName
            .toLowerCase()
            .indexOf(this.filterForm.value.nickName.toLowerCase()) >= 0
        ) {
          addToDisplayList = true;
        }
      } else if (cnpj !== "") {
        if (company.cnpj.toLowerCase().indexOf(cnpj) >= 0) {
          addToDisplayList = true;
        }
      } else if (this.filterForm.value.email) {
        addToDisplayList = false;
        for (const user of company.users) {
          if (user.email.indexOf(this.filterForm.value.email) >= 0) {
            addToDisplayList = true;
          }
        }
      } else if (this.filterForm.value.contato) {
        addToDisplayList = false;
        for (const user of company.users) {
          if (
            user.name
              .toLowerCase()
              .indexOf(this.filterForm.value.contato.toLowerCase()) >= 0
          ) {
            addToDisplayList = true;
          }
        }
      } else if (this.filterForm.value.segmentId) {
        addToDisplayList = false;
        for (const segment of company.companySegments) {
          if (segment.segmentId === this.filterForm.value.segmentId) {
            addToDisplayList = true;
          }
        }
      } else if (this.filterForm.value.paymentPlanId) {
        addToDisplayList = false;
        const paymentPlanId = this.filterForm.value.paymentPlanId;
        if (paymentPlanId === -1 && !company.paymentPlanId) {
          companyListToDisplay.push(company);
        } else {
          if (paymentPlanId === company.paymentPlanId) {
            companyListToDisplay.push(company);
          }
        }
      } else {
        addToDisplayList = true;
      }

      if (addToDisplayList) {
        if (this.filterForm.value.profileId) {
          const profileId = this.filterForm.value.profileId;
          if (profileId === 1 && company.isCustomer) {
            companyListToDisplay.push(company);
          } else if (profileId === 2 && company.isRequestor) {
            companyListToDisplay.push(company);
          }
        } else {
          companyListToDisplay.push(company);
        }
      }
    }
    this.companyListToDisplay = companyListToDisplay;
    this.utils.finish();
  }

  loadAllsegments(): Promise<void> {
    this.segments = [];
    return new Promise((resolve, reject) => {
      this.segmentService
        .list()
        .then((data: Segment[]) => {
          this.segments = data;
          resolve();
        })
        .catch(() => {
          Swal.fire("Qisar", "Erro ao recuperar a lista de segmentos", "error");
          reject();
        });
    });
  }

  loadSegmentList(): Promise<void> {
    this.segments = [];
    return new Promise((resolve, reject) => {
      this.segmentService
        .companyNonList(this.activeCompanyService.activeCompanyId)
        .then((data: Segment[]) => {
          this.segments = data;
          resolve();
        })
        .catch(() => {
          Swal.fire("Qisar", "Erro ao recuperar a lista de segmentos", "error");
          reject();
        });
    });
  }

  /** nova empresa */
  newCompany() {
    this.cleanCompany();
    this.router.navigateByUrl("/company/add");
  }
  /** novo endereço */
  newAddress(companyId: number) {
    this.cleanAddress(companyId);
    this.ngxSmartModalService.getModal("modalCompanyAddress").open();
  }
  /** novo segmento */
  newCompanySegment(companyId: number) {
    this.loadSegmentList();
    this.cleanComponentSegment(companyId);
    this.ngxSmartModalService.getModal("modalCompanySegments").open();
  }
  /** Editar uma Empresa */
  editCompany(company: Company) {
    this.dateToAcceptPlan = null;
    this.canUpdatePlan = false;
    this.company = company;
    var today = new Date();
    if (company.enableToSaleAcceptDate) {
      var dateAccept = new Date(company.enableToSaleAcceptDate);
      var sixMonthsBeforeenableToSaleAcceptDate = new Date(
        dateAccept.getFullYear(),
        dateAccept.getMonth() + 6,
        dateAccept.getDate()
      );
      this.dateToAcceptPlan = sixMonthsBeforeenableToSaleAcceptDate;

      if (today > sixMonthsBeforeenableToSaleAcceptDate) {
        this.canUpdatePlan = true;
        this.dateToAcceptPlan = null;
      }
    }
    this.ngxSmartModalService.getModal("modalCompany").open();
  }
  /** Editar uma Empresa */
  editAddress(address: Address) {
    this.address = address;
    this.ngxSmartModalService.getModal("modalCompanyAddress").open();
  }
  /** Editar um segmento de empresa */
  editCompanySegment(companySegment: CompanySegment) {
    this.companySegment = companySegment;
    this.ngxSmartModalService.getModal("modalCompanySegments").close();
  }
  /** fechar a modal */
  closeCompanyModal() {
    this.utils.running();
    if (this.company.companyId !== 0) {
      this.getUserByApi().then((data) => {
        if (data !== this.company) {
          const i = this.companies.findIndex(
            (cs) => cs.companyId === this.company.companyId
          );
          this.companies[i] = data;
        }
        this.utils.finish();
        this.cleanCompany();
        this.ngxSmartModalService.getModal("modalCompany").close();
      });
    } else {
      this.ngxSmartModalService.getModal("modalCompany").close();
      this.utils.finish();
    }
  }
  /** fechar modal de endereço */
  closeAdressModal() {
    if (this.address.addressId > 0) {
      this.addressService
        .get(this.address.addressId)
        .then((address: Address) => {
          const company = this.companies.find(
            (c) => c.companyId === this.address.companyId
          );
          const idx = company.addresses.findIndex(
            (a) => a.addressId === this.address.addressId
          );
          company.addresses[idx] = address;
          this.ngxSmartModalService.getModal("modalCompanyAddress").close();
        });
    } else {
      this.ngxSmartModalService.getModal("modalCompanyAddress").close();
    }
  }
  /** fechar modal de associação de segmentos */
  closeCompanySegment() {
    if (this.companySegment.companySegmentId > 0) {
      this.companySegmentService
        .get(this.companySegment.companySegmentId)
        .then((data: CompanySegment) => {
          const company = this.companies.find(
            (c) => c.companyId === this.companySegment.companyId
          );
          const idx = company.companySegments.findIndex(
            (s) => s.companySegmentId === this.companySegment.companySegmentId
          );
          this.segmentService.get(data.segmentId).then((segment: Segment) => {
            data.segment = segment;
            this.ngxSmartModalService.getModal("modalCompanySegments").close();
            company.companySegments[idx] = data;
          });
        });
    } else {
      this.ngxSmartModalService.getModal("modalCompanySegments").close();
    }
  }
  /** prepara para salvar a Empresa */
  preSaveCompany() {
    this.utils.running();
    if (this.company.companyId === 0) {
      this.findByName()
        .then((dataName) => {
          if (dataName) {
            Swal.fire(
              "Qisar",
              "Já existe outra empresa com esse nome",
              "error"
            );
          } else {
            this.findByCNPJ()
              .then((dataCNPJ) => {
                if (dataCNPJ) {
                  Swal.fire(
                    "Qisar",
                    "Já existe outra empresa com esse cnpj",
                    "error"
                  );
                } else {
                  this.saveCustomer();
                }
              })
              .catch(() => {
                Swal.fire("Qisar", "Erro ao cadastrar a empresa", "error");
                this.utils.finish();
              });
          }
        })
        .catch(() => {
          Swal.fire("Qisar", "Erro ao cadastrar a empresa", "error");
          this.utils.finish();
        });
    } else {
      this.saveCustomer();
    }
  }
  /** prepara para salvar endereço */
  preSaveAddress() {
    this.saveAddress();
    this.ngxSmartModalService.getModal("modalCompanyAddress").close();
  }
  /** prepara para salvar associação de segmentos */
  async preSaveCompanySegment() {
    this.ngxSmartModalService.getModal("modalCompanySegments").close();  
    await this.saveCompanySegment();      
  }
  /** salva a empresa */
  saveCustomer() {
    this.uploadLogo().then((logoUrl) => {
      this.company.logo = logoUrl;
      this.companyService
        .save(this.company)
        .then((data: Company) => {
          if (this.company.companyId === 0) {
            this.companies.push(data);
          }
          this.cleanCompany();
          this.utils.finish();
          this.ngxSmartModalService.getModal("modalCompany").close();
          Swal.fire("Qisar", "Empresa cadastrada com sucesso", "success");
          this.utils.updateSessionUser();
        })
        .catch(() => {
          Swal.fire("Qisar", "Erro ao cadastrar a empresa", "error");
          this.utils.finish();
        });
    });
  }
  /** salva o endereço */
  saveAddress() {
    this.utils.running();
    this.addressService
      .save(this.address)
      .then((data: Address) => {
        Swal.fire("Qisar", "Endereço cadastrado com sucesso", "success");
        if (this.address.addressId === 0) {
          const company = this.companies.find(
            (c) => c.companyId === this.address.companyId
          );
          company.addresses.push(data);
        }
        this.utils.finish();
      })
      .catch(() => {
        Swal.fire("Qisar", "Erro ao salvar o endereço", "error");
        this.utils.finish();
      });
  }
  /** salva o segmento na company */
  async saveCompanySegment() {
    this.utils.running();
    const companySegmentList: Array<CompanySegment> = [];
    
    if(this.companySegment.segmentId === 0){
      for(const segment of this.segmentsFilter) {      
        this.companySegment.actived = true;
        this.companySegment.companyId = this.companySegment.companyId;
        this.companySegment.isCustomer = this.companySegment.isCustomer;
        this.companySegment.isRequestor = this.companySegment.isRequestor;
        this.companySegment.segmentId = segment.value;
        this.companySegment.segment = null;
        this.companySegment.companySegmentId = 0;

        
        companySegmentList.push(this.companySegment);
        await this.companyService.addSegmentToCompany(this.companySegment);        
      }      

      const company = await this.companies.find(
        (c) => c.companyId === this.companySegment.companyId
      );
      for(const companySegment of companySegmentList){
        await this.segmentService.get(companySegment.segmentId).then((segment: Segment) => {
          companySegment.segment = segment;
          if (!company.companySegments) {
            company.companySegments = [];
          }
          company.companySegments.push(companySegment);
        });
      }
      this.utils.finish();
      Swal.fire("Qisar", "Segmento cadastrado com sucesso", "success");
      window.location.reload();      
    }
    else{
      this.companySegment.actived = true;
      this.companyService
        .addSegmentToCompany(this.companySegment)
        .then((data: CompanySegment) => {
          Swal.fire("Qisar", "Segmento cadastrado com sucesso", "success");            
          if (this.companySegment.companySegmentId === 0) {
            const company = this.companies.find(
              (c) => c.companyId === this.companySegment.companyId
            );
            this.segmentService.get(data.segmentId).then((segment: Segment) => {
              data.segment = segment;
              if (!company.companySegments) {
                company.companySegments = [];
              }
              company.companySegments.push(data);
              this.utils.finish();
            });
          } else {
            this.segmentService
              .get(this.companySegment.segmentId)
              .then((segment: Segment) => {
                this.companySegment.segment = segment;
                this.utils.finish();
              });
          }
        })
        .catch(() => {
          this.loadCompanyList().then(() => {
            this.applyCompanyListFilter();
            this.utils.finish();
          });
        });
    }      
  }
  /** exluir uma empresa */
  changeCustomerStatus(company: Company) {
    const text =
      "Deseja " +
      (company.active ? "desabilitar" : "habilitar") +
      " a empresa " +
      company.name +
      " ?";
    Swal.fire({
      title: "Qisar",
      type: "question",
      html: text,
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmo",
    }).then((data) => {
      if (data.value) {
        company.active = !company.active;
        this.company = company;
        this.companyService
          .save(company)
          .then(() => {
            const pos = this.companies.findIndex(
              (c) => c.companyId === company.companyId
            );
          })
          .catch(() => {
            Swal.fire("Qisar", "Erro ao desabilitar a empresa", "error");
          });
      }
    });
  }
  /** excluir um endereço */
  deleteAddress(address: Address) {
    const text = "Deseja excluir o endereço ?";
    Swal.fire({
      title: "Qisar",
      type: "question",
      html: text,
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmo",
    }).then((data) => {
      if (data.value) {
        this.addressService
          .delete(address.addressId)
          .then(() => {
            const company = this.companies.find(
              (c) => c.companyId === address.companyId
            );
            const idx = company.addresses.findIndex(
              (a) => a.addressId === address.addressId
            );
            company.addresses.splice(idx, 1);
          })
          .catch(() => {
            Swal.fire("Qisar", "Erro ao Excluir o endereço", "error");
          });
      }
    });
  }
  /** remover associação */
  deleteCompanySegment(companySegment: CompanySegment) {
    const text = "Deseja excluir a associação ?";
    Swal.fire({
      title: "Qisar",
      type: "question",
      html: text,
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmo",
    }).then((data) => {
      if (data.value) {
        this.companyService
          .removeSegmentFromCompany(companySegment)
          .then(() => {
            const company = this.companies.find(
              (c) => c.companyId === companySegment.companyId
            );
            const idx = company.companySegments.findIndex(
              (a) => a.companySegmentId === companySegment.companySegmentId
            );
            company.companySegments.splice(idx, 1);
          })
          .catch(() => {
            Swal.fire("Qisar", "Erro ao remove a associação", "error");
          });
      }
    });
  }
  /** verificar se empresa já existe */
  findByName() {
    return this.companyService.getByName(this.company.name);
  }
  /** Veifica se já existe um cadastro com esse CNPJ */
  findByCNPJ() {
    return this.companyService.getByCNPJ(this.company.cnpj);
  }

  newTrustCompany(companyId: any) {
    this.trustCompany = {};
    this.trustCompany.companyId = companyId;
    this.ngxSmartModalService.getModal("modalTrustCompany").open();
  }

  preSaveCompanyTrust() {
    this.companyService
      .AddRequestorToCustomerTrust(
        this.trustCompany.companyId,
        this.trustCompany.requestorId
      )
      .then(() => {
        this.ngxSmartModalService.getModal("modalTrustCompany").close();
        Swal.fire("Qisar", "Empresa Associada com Sucesso", "info").then(() => {
          window.location.reload();
        });
      });
  }

  validCNPJ() {
    if (this.company.cnpj !== "") {
      if (!this.utils.validCnpj(this.company.cnpj)) {
        Swal.fire("Qisar", "Ops, CNPJ invalido", "error");
        this.company.cnpj = "";
      }
    }
  }

  /** monta uma empresa limpa */
  cleanCompany() {
    this.company = new Company();
    this.company.companyId = 0;
    this.company.active = false;
    this.company.cnpj = "";
    this.company.effectiveDate = "";
    this.company.isCustomer = false;
    this.company.isRequestor = false;
    this.company.name = "";
    this.company.nickName = "";
    this.company.phoneOffice = "";
    this.company.webSite = "";
    this.company.termsStatus = 0;
    this.cleanAddress(0);
    this.cleanComponentSegment(this.activeCompanyService.activeCompanyId);
  }
  /** monta um endereço limpo */
  cleanAddress(companyId: number) {
    this.address = new Address();
    this.address.addressId = 0;
    this.address.companyId = companyId;
    this.address.actived = true;
    this.address.city = "";
    this.address.default = false;
    this.address.state = "";
    this.address.street = "";
    this.address.zipCode = "";
    this.address.deliveryInstruction = "";
    this.address.neighBorHood = "";
    this.address.number = 0;
    this.address.cmpl = "";
  }
  /** monta uma associação de segmento limpa */
  cleanComponentSegment(companyId: number) {
    this.companySegment = new CompanySegment();
    this.companySegment.companySegmentId = 0;
    this.companySegment.companyId = companyId;
    this.companySegment.actived = true;
    this.companySegment.isCustomer = false;
    this.companySegment.isRequestor = false;
    this.companySegment.segmentId = 0;
  }
  /** busca o usuario na API */
  getUserByApi(): any {
    return new Promise((Result) => {
      return this.companyService
        .get(this.company.companyId)
        .then((data: User) => {
          Result(data);
        });
    });
  }
  /**
   * Habilita ou some a Linha
   * @param id Id da Mensagem
   */
  toggleLine(area: string, id: number) {
    const line = document.getElementById(area + id);
    if (line.className === "show-line") {
      line.className = "disable-line";
    } else {
      line.className = "show-line";
    }
  }
  /** busca o cep se clicar em enter no campo */
  onKeyZipCodeInput(event: any) {
    if (event.key === "Enter") {
      this.searchCep();
    }
  }
  /** busca o cep */
  searchCep() {
    this.utils.running();
    this.http
      .get("https://viacep.com.br/ws/" + this.address.zipCode + "/json")
      .toPromise()
      .then((data: any) => {
        this.address.street = data.logradouro;
        this.address.neighBorHood = data.bairro;
        this.address.city = data.localidade;
        this.address.state = data.uf;
        this.address.zipCode = data.cep;
        this.address.number = 0;
        this.address.cmpl = "";
        this.utils.finish();
      })
      .catch(() => {
        Swal.fire("Qisar", "Erro ao buscar o CEP", "error");
        this.utils.finish();
      });
  }
  /** instruções de entrega */
  instructionsDeliveryMessage(address: Address) {
    Swal.fire("Qisar", address.deliveryInstruction, "info");
  }

  onAvatarChange(imageBase64: string) {
    this.selectedLogoBase64 = imageBase64;
  }

  uploadLogo(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.selectedLogoBase64) {
        this.azureStorageBlobHelperService
          .uploadDataUrl(this.selectedLogoBase64, "requestfiles")
          .then((url) => {
            resolve(url);
          });
      } else {
        resolve(this.company.logo);
      }
    });
  }
}
