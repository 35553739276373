import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/services/core/utils.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {
  constructor(
    private title: Title,
    private utils: UtilsService,
    private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.title.setTitle('Requisição | Qisar');
    this.loadPage();
  }
  loadPage() {
    this.utils.finish();
  }
  /**
   * Inicia a Pagina
   */
  ngOnInit() {
  }


  /**
   * Habilita ou some a Linha
   * @param id Id da Mensagem
   */
  toggleLine(id: number) {
    const button = document.getElementById(id + '-button');
    const line = document.getElementById(id + '-detail');

    if (line.className === 'disable-line') {
      line.className = '';
      button.className = 'fa fa-folder-open';
      button.style.color = 'rgb(120,10,10)';
    } else {
      line.className = 'disable-line';
      button.className = 'fa fa-folder';
      button.style.color = 'rgb(10,10,120)';
    }
  }

  /**
   * captura o tecla digitada no input de mensagem
   * @param event Evento do Clique
   * @param id Id da Mensagem
   */
  keyEventInputMessage(event: any, id: number) {
    if (event.key === 'Enter') {
      this.sendMessage(id);
    }
  }

  /**
   * Envia nova mensagem para o Chat
   * @param id Id da Mensagem
   */
  sendMessage(id: number) {
    const message = document.getElementById('message-' + id) as HTMLInputElement;
    const chat = document.getElementById('chat-' + id) as HTMLInputElement;
    const newChatMessageDiv = document.createElement('div');
    newChatMessageDiv.className = 'right-text';
    const newChatMessageDivText = document.createElement('div');
    newChatMessageDivText.className = 'text';

    if (message.value !== '') {
      const paragraphWho = document.createElement('p');
      paragraphWho.className = 'text-title';

      const paragraphMessage = document.createElement('p');
      paragraphMessage.className = '';

      const who = document.createTextNode('Eu:');
      const newChatMessage = document.createTextNode(message.value);
      paragraphWho.append(who);
      paragraphMessage.append(newChatMessage);
      newChatMessageDivText.append(paragraphWho);
      newChatMessageDivText.append(paragraphMessage);
      newChatMessageDiv.append(newChatMessageDivText);
      chat.append(newChatMessageDiv);
      message.value = '';
      this.scrollMessageDivToButtom(id);
    }
  }


  /**
   * Acerta a Posição do Scroll na area de Chat
   * @param id Id da Mensagem
   */
  scrollMessageDivToButtom(id: number) {
    const chat = document.getElementById('chat-' + id) as HTMLInputElement;
    chat.scrollTop = chat.scrollHeight;
  }

  /** Cria uma nova requisição  */
  newRequest() {
    this.router.navigate(['/request/new']);
  }
}
