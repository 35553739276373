export const environment = {
  production: false,
  evenId: 5587,
  apiUri: 'https://qisar-uat-compra.azurewebsites.net/',
  appTitle: 'Qisar',
// tslint:disable-next-line: max-line-length
  azureBlobSasTokenForUpload: '?sv=2018-03-28&ss=b&srt=o&sp=wac&se=2030-05-18T00:41:11Z&st=2019-05-17T16:41:11Z&spr=https,http&sig=olOAj60oW9jvLj2ktHxWlxlv2pXrA6qTPf%2B8pikCn2s%3D',
// tslint:disable-next-line: max-line-length
  azureBlobSasTokenForDownload: '?sp=r&st=2019-05-17T16:28:19Z&se=2030-05-18T16:28:00Z&sv=2018-03-28&sig=DOTEO5B6zoRGkShxcTYvz25GTmJS41DHOraCXGuvAj8%3D&sr=c',
  pagSeguroConnect: "https://sandbox.api.pagseguro.com/",
  pagSeguroUri : 'https://sandbox.api.pagseguro.com',
  pagSeguroCallBack:'http://localhost:4200/'

};
