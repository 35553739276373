import { Component, OnInit } from '@angular/core';
import { CompanyPaymentConfiguration } from 'src/app/models/CompanyPaymentConfiguration';
import { Pagination } from 'src/app/models/pagination.model';
import Swal from 'sweetalert2';
import { UtilsService } from 'src/app/services/core/utils.service';
// tslint:disable-next-line: max-line-length
import { CompanyPaymentConfigurationService } from 'src/app/services/companyPaymentConfigurationService/companyPaymentConfigurationService.service';
import * as lodash from 'lodash';
import { SelectItem } from 'primeng/api';
import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/Company';

@Component({
  selector: 'app-payment-configuration',
  templateUrl: './configuration-payment.component.html',
  styleUrls: ['./configuration-payment.component.css']
})
export class ConfigurationPaymentComponent implements OnInit {
  public Paginator: Pagination<CompanyPaymentConfiguration[]>;
  public Vendors: Company[] = null;
  public dueDateOptions: SelectItem[] = [
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '20', value: 20 },
  ] as SelectItem[];

  filterTimeout: any;
  loadFilter: string = null;
  editingPaymentConfiguration: CompanyPaymentConfiguration = null;
  isEditing = false;
  isCreating = false;
  selectedVendorId = 0;
  selectedVendor: Company = null;

  constructor(
    private companyPaymentConfigurationService: CompanyPaymentConfigurationService,
    private companyService: CompanyService,
    private utils: UtilsService
  ) {
    this.Paginator = new Pagination();
  }

  applyFilter(event: any) {
    if (event.target.value === '') {
      this.loadFilter = null;
    } else {
      this.loadFilter = event.target.value;
    }
    this.buildPaginator();
    this.load();
  }

  load() {
    this.utils.running();

    this.companyPaymentConfigurationService
      .getPaging(this.Paginator, this.loadFilter)
      .then(data => {
        this.Paginator = data;
        this.utils.finish();
      })
      .catch(error => {
        console.log(error);
        this.utils.finish();
      });
  }

  ngOnInit() {
    this.buildPaginator();
    this.load();
  }

  buildPaginator() {
    this.Paginator.itemsPerPage = 10;
    this.Paginator.currentPage = 1;
    this.Paginator.totalRecords = 0;
  }

  paginate(event) {
    this.Paginator.itemsPerPage = event.rows;
    this.Paginator.currentPage = (event.page + 1);
    this.load();
  }

  edit(id: number) {
    this.isEditing = true;
    this.isCreating = false;

    this.editingPaymentConfiguration = lodash.cloneDeep(this.Paginator.data.find(item => item.companyPaymentConfigurationId === id));
    this.selectedVendor = this.editingPaymentConfiguration.company;
    this.selectedVendorId = this.selectedVendor.companyId;
    this.utils.openModal('modalEdit');
  }

  new() {
    this.isEditing = false;
    this.isCreating = true;

    this.editingPaymentConfiguration = {
      companyId: 0,
      companyPaymentConfigurationId: 0,
      company: null,
      billDueDateBestDay: 15,
      monthlyPaymentValue: 0,
      taxNotThrustRequestorSell: false,
      taxThrustRequestorSell: false,
      paymentMethodIdentifier: 'BankBillet'
    } as CompanyPaymentConfiguration;
    this.utils.openModal('modalEdit');
  }

  findVendors(event: any) {
    this.companyService.getVendors(event.query)
      .then(data => {
        this.Vendors = data;
      })
      .catch(error => {
        console.log(error);
      });
  }

  selectVendor(selectedCompany: any) {
    if (selectedCompany != null && selectedCompany.companyId != null && selectedCompany.companyId > 0) {
      this.selectedVendorId = selectedCompany.companyId;
    } else {
      this.selectedVendorId = 0;
    }
    this.editingPaymentConfiguration.companyId = this.selectedVendorId;
  }

  closeEdit() {
    this.selectedVendorId = 0;
    this.selectedVendor = null;
    this.editingPaymentConfiguration = null;

    this.isEditing = false;
    this.isCreating = false;

    this.utils.closeModal('modalEdit');
  }

  saveEdit() {
    const configuration = lodash.cloneDeep(this.editingPaymentConfiguration);
    configuration.company = null;

    if (this.isEditing) {
      this.utils.running();
      this.companyPaymentConfigurationService.update(configuration)
        .then(data => {
          Swal.fire('Qisar', 'Configuração de pagamento atualizada com sucesso');
          this.utils.finish();

          this.load();
          this.closeEdit();
        })
        .catch(error => {
          console.log(error);
          Swal.fire('Qisar', 'Faha ao atualizar as configurações de pagamento');
          this.utils.finish();
        });
    } else if (this.isCreating) {
      this.utils.running();
      this.companyPaymentConfigurationService.add(configuration)
        .then(data => {
          Swal.fire('Qisar', 'Configuração de pagamento atualizada com sucesso');
          this.utils.finish();

          this.load();
          this.closeEdit();
        })
        .catch(error => {
          console.log(error);
          Swal.fire('Qisar', 'Faha ao atualizar as configurações de pagamento');
          this.utils.finish();
        });
    }
  }
}
