import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company/company.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-late-payments',
  templateUrl: './late-payments.component.html',
  styleUrls: ['./late-payments.component.css']
})
export class LatePaymentsComponent implements OnInit {

  payments: [];
  constructor(
    private companyService: CompanyService,
    private title: Title
  ) {
    this.title.setTitle('Faturas em Atraso | Qisar');
  }

  ngOnInit() {
    this.companyService.LatePayments()
      .then((data) => this.payments = data);
  }

}
