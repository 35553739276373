import { OnInit, Component, ViewChild, ViewEncapsulation, OnDestroy } from "@angular/core";
import { RequestService } from "src/app/services/request/request.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ActiveCompanyService } from "src/app/services/active-company.service";
import { UtilsService } from "src/app/services/core/utils.service";
import { SelectItem } from "primeng/api";
import { SegmentService } from "src/app/services/segment/segment.service";
import { User } from "src/app/models/User";
import { Request } from "src/app/models/Request";
import { NgxSmartModalService } from "ngx-smart-modal";
import * as Highcharts from "highcharts";
import * as _ from "lodash";
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { add, forEach } from 'lodash';

declare var require: any;
const Boost = require("highcharts/modules/boost");
const noData = require("highcharts/modules/no-data-to-display");
const More = require("highcharts/highcharts-more");
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  templateUrl: "./customer-geral.component.html",
  styleUrls: ["./customer-geral.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerGeralComponent implements OnInit, OnDestroy {
  form: FormGroup;
  filterFormSegmentIdOptionList: Array<SelectItem> = [];
  filterFormUserIdOptionList: Array<SelectItem> = [];
  companyId: number;
  userId: number;
  manager = false;
  currentUser: User;

  requestCount: Request[] = [];
  requestOpen: Request[] = [];
  requestClosed: Request[] = [];
  requestCancel: Request[] = [];
  requestWithProposals: Request[] = [];
  requestWithOutProposals: Request[] = [];

  loadRequests = false;
  loadNegotiations = false;

  requestWithProposalsWithNegotiations: Request[] = [];
  requestWithProposalsWithOutNegotiations: Request[] = [];

  modalTitle = "";
  modalItems: Request[] = [];
  subscribeCompany: Subscription;

  selectedRequestToShowProduct: Request = null;
  userIsManager : boolean = false;

  pt = {
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sab"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Feveiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
  };

  options = {};

  constructor(
    private requestService: RequestService,
    private segmentService: SegmentService,
    private activeCompany: ActiveCompanyService,
    private util: UtilsService,
    private formBuilder: FormBuilder,
    private ngxSmartModalService: NgxSmartModalService,
    private userService : UserService
  ) {}
  ngOnDestroy(): void {
    if (this.subscribeCompany) {
      this.subscribeCompany.unsubscribe();
    }
  }

  loadPage() {
    this.util.running();
    this.loadRequests = false;
    this.loadNegotiations = false;

    let queryUserid = this.userId;
    if (this.manager) {
      queryUserid = this.form.value.userId;
    }

    if (!queryUserid) {
      queryUserid = 0;
    }

    this.requestService
      .reportRequestsWithProposals(
        this.companyId,
        this.form.value.segmentId ? this.form.value.segmentId : 0,
        this.form.value.to,
        this.form.value.from,
        this.userIsManager ? this.form.value.userId : this.userId
      )
      .then((data: Request[]) => {
        this.requestCount = data;
        this.requestOpen = this.getRequestsByStatusId(data, 1);
        this.requestCancel = this.getRequestsByStatusId(data, 2);
        this.requestClosed = this.getRequestsByStatusId(data, 3);
        this.requestWithProposals = this.getRequestWithProposals(data);
        this.requestWithOutProposals = this.getRequestWithOutProposals(data);
        this.loadRequests = true;
        this.loadGraph();
      });

    this.requestService
      .reportRequestsWithChats(
        this.companyId,
        this.form.value.segmentId ? this.form.value.segmentId : 0,
        this.form.value.to,
        this.form.value.from,
        this.userIsManager ? this.form.value.userId : this.userId
      )
      .then((data: Request[]) => {
        this.requestWithProposalsWithNegotiations = this.getrequestWithProposalsWithNegotiations(
          data
        );
        this.requestWithProposalsWithOutNegotiations = this.getrequestWithProposalsWithOutNegotiations(
          data
        );
        this.loadNegotiations = true;
        this.loadGraph();
      });
  }

  getRequestsByStatusId(array: Request[], statusId: number): Request[] {
    let requests: Request[] = [];
    array.forEach((item) => {
      if (item.statusId === statusId) {
        requests.push(item);
      }
    });
    return requests;
  }

  getRequestWithProposals(array: Request[]): Request[] {
    let requests: Request[] = [];
    array.forEach((item) => {
      if (item.statusId === 1) {
        if (item.proposals) {
          if (item.proposals.length > 0) {
            requests.push(item);
          }
        }
      }
    });
    return requests;
  }

  getRequestWithOutProposals(array: Request[]): Request[] {
    let requests: Request[] = [];
    array.forEach((item) => {
      if (item.statusId === 1) {
        if (item.proposals) {
          if (item.proposals.length == 0) {
            requests.push(item);
          }
        }
      }
    });
    return requests;
  }

  getrequestWithProposalsWithNegotiations(array: Request[]): Request[] {
    let requests: Request[] = [];
    array.forEach((request) => {
      const grp = _.mapValues(
        _.groupBy(request.proposalHistories, "companyId"),
        (clist) =>
          clist.map(() => _.omit(request.proposalHistories, "companyId"))
      );
      const companies = Object.keys(grp);
      
      if (companies.some(x => grp[parseInt(x)].length > 1)) {
        requests.push(request);
      }
    });

    return requests;
  }

  getrequestWithProposalsWithOutNegotiations(array: Request[]): Request[] {
    let requests: Request[] = [];
    array.forEach((request) => {
      const grp = _.mapValues(
        _.groupBy(request.proposalHistories, "companyId"),
        (clist) =>
          clist.map(() => _.omit(request.proposalHistories, "companyId"))
      );
      const companies = Object.keys(grp);

      if (!companies.some(x => grp[parseInt(x)].length > 1)) {
        requests.push(request);
      }
    });

    return requests;
  }

  getDisplayProductList(request: Request): string {
    let str = "";
    let index = 0;
    request.requestProducts.forEach((product) => {
      if (str.length > 0) {
        if (index === request.requestProducts.length - 1) {
          str += " e ";
        } else {
          str += ", ";
        }
      }
      str +=
        product.product.name + " (" + product.qtd + " " + product.measure + ")";
      index++;
    });
    return str;
  }
  getDisplayItemsList(request: Request): string {
    let str = "";
    let index = 0;
    request.requestItems.forEach((item) => {
      if (str.length > 0) {
        if (index === request.requestItems.length - 1) {
          str += " e ";
        } else {
          str += ", ";
        }
      }
      str += item.description + " (" + item.quantity + " " + item.measure + ")";
      index++;
    });
    return str;
  }

  getDisplayProductText(request: any): string {
    if (request.requestProducts) {
      if (request.requestProducts.length > 0) {
        return this.getDisplayProductList(request);
      }
    }
    if (request.requestItems) {
      if (request.requestItems.length > 0) {
        return this.getDisplayItemsList(request);
      }
    }
    if (request.productDescription) {
      return (
        request.productDescription +
        " (" +
        request.quantity +
        " " +
        request.measure +
        ")"
      );
    } else {
      return "";
    }
  }

  getDisplayProductTotal(request: any): string {
    if (request.requestProducts) {
      if (request.requestProducts.length > 0) {
        return "[" + request.requestProducts.length + " itens ]";
      }
    }
    if (request.requestItems) {
      if (request.requestItems.length > 0) {
        return "[" + request.requestItems.length + " itens ]";
      }
    }
    if (request.productDescription) {
      return "[1 item]";
    } else {
      return "";
    }
  }

  loadSegments() {
    this.filterFormSegmentIdOptionList = [];
    this.filterFormSegmentIdOptionList.push({
      label: "Todos",
      value: null,
    });
    this.segmentService.getall().then((response) => {
      for (const segment of response) {
        this.filterFormSegmentIdOptionList.push({
          label: segment.name,
          value: segment.segmentId,
        });
      }
    });
  }

  openModalRequest(items: Request[], modalTitle: string) {
    this.modalItems = items;
    this.modalTitle = modalTitle;
    this.ngxSmartModalService.getModal("modal-requests").open();
  }

  loadGraph() {
    if (this.loadRequests && this.loadNegotiations) {
      const data = [];
      data.push({
        name: "Requisições enviadas",
        y: this.requestCount.length,
        color: "#4fd1c5",
      });

      data.push({
        name: "Requisições Abertas no Periodo",
        y: this.requestOpen.length,
        color: "#ffcc0a",
      });

      data.push({
        name: "Requisições Fechadas no Período",
        y: this.requestClosed.length,
        color: "#63b3ed",
      });

      data.push({
        name: "Requisições Canceladas no Período",
        y: this.requestCancel.length,
        color: "orangered"

      });

      data.push({
        name: "Requisições Abertas com Propostas no Período",
        y: this.requestWithProposals.length,
        color: "#7dc80b"

      });

      data.push({
        name: "Requisições Abertas sem Propostas no Período",
        y: this.requestWithOutProposals.length,
        color: "orange"
      });

      data.push({
        name: "Requisições no Período com Negociação",
        y: this.requestWithProposalsWithNegotiations.length,
        color: "#b794f4"
      });

      data.push({
        name: "Requisições no Período sem Negociação",
        y: this.requestWithProposalsWithOutNegotiations.length,
        color: "darkslategrey"
      });

      this.options = {
        chart: {
          type: "column",
          height: 200,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        xAxis: {
          type: "category",
        },
        series: [
          {
            name: "Requisições",
            colorByPoint: true,
            data: data,
          },
        ],
      };

      Highcharts.chart("chart", this.options);

      this.util.finish();
    }
  }

  /*
   * carrega lista de usuários
   */

  async loadUsers() {
    this.filterFormUserIdOptionList = [];
    this.filterFormUserIdOptionList.push({
      label: "Todos",
      value: 0,
    });

    let userList: User[];
    userList = await this.userService.listWithOutSegments(this.currentUser.companyId);

    for (const user of userList) {
      this.filterFormUserIdOptionList.push({
        label: user.name,
        value: user.userId,
      });
    };
  }

  async ngOnInit(): Promise<void> {
    Highcharts.setOptions({
      lang: {
        drillUpText: "Carregar Todos",
        noData: "Nenhum dado encontrado",
        thousandsSep: ".",
        decimalPoint: ",",
      },
    });

    this.loadSegments();
    const initial = new Date(new Date().setMonth(new Date().getMonth() - 1));
    this.form = this.formBuilder.group({
      to: [initial],
      from: [new Date()],
      segmentId: [null],
      userId: [0],
      customerId: [null],
    });

    await this.util.getUserFromSession().then((user: User) => {
      this.companyId = user.companyId;
      this.userId = user.userId;
      this.manager = user.companyManager;
      this.currentUser = user;
      this.loadPage();
    });

    this.subscribeCompany = this.activeCompany.onActiveCompanyChange.subscribe(() => {
      this.companyId = this.activeCompany.activeCompanyId;
      this.loadPage();
    });

    await this.loadUsers();
    this.getCurrentUserIsManager();
  }

  private getCurrentUserIsManager(){
    this.util.getUserFromSession()
      .then(user => this.userIsManager = user.companyManager || false);
  }
}
