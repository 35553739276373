import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

  @Input() MainMenuCss: any;
  @Input() MainContainerCss: any;
  @Input() User: any;
  @Input() Company: any;
  @Input() CompanyList = [];
  @Output() onclickMenu: EventEmitter<void> = new EventEmitter();
  @Output() onopenCampanySelectionModal: EventEmitter<void> = new EventEmitter();



  customerMenuDashBoardItemsCss = 'menu_items_close';
  customerImportCss = 'menu_items_close';
  offerCss = 'menu_items_close';
  customerRequestItemsCss = 'menu_items';
  customerPreRequestItemsCss = 'menu_items_close';
  customerAutomaticRequestItemsCss = 'menu_items_close';
  customerSchedulleRequestItemsCss = 'menu_items_close';
  customerRequestWait = 'menu_items_close';
  providerMenuDashBoardItemsCss = 'menu_items_close';
  providerRequestItemsCss = 'menu_items';
  finacialItemsCss = 'menu_items_close';
  administratorItemsCss = 'menu_items_close';
  configItemsCss = 'menu_items_close';
  reportItemsCss = 'menu_items_close';

  activeAcompanyMain = 'active-company-block';
  menuHeader = 'menu_header';
  hideAutomaticaSeletiva = false;

  constructor() { }

  ngOnInit() {
    if (this.User.company.isRequestor) {
      this.customerRequestItemsCss = 'menu_items_close';
    }

    if (sessionStorage.getItem('whitelabel')) {
      this.activeAcompanyMain = this.activeAcompanyMain + sessionStorage.getItem('whitelabel');
      this.menuHeader = this.menuHeader + sessionStorage.getItem('whitelabel');

      this.hideAutomaticaSeletiva = true;
    }
  }

  menuItem(item) {
    this.hiddeAllMenu();
    switch (item) {
      case 'customerMenuDashBoardItemsCss':
        this.customerMenuDashBoardItemsCss = 'menu_items';
        break;
      case 'customerRequestItemsCss':
        this.customerRequestItemsCss = 'menu_items';
        break;
      case 'customerPreRequestItemsCss':
        this.customerPreRequestItemsCss = 'menu_items';
        break;
      case 'providerMenuDashBoardItemsCss':
        this.providerMenuDashBoardItemsCss = 'menu_items';
        break;
      case 'providerRequestItemsCss':
        this.providerRequestItemsCss = 'menu_items';
        break;
      case 'finacialItemsCss':
        this.finacialItemsCss = 'menu_items';
        break;
      case 'administratorItemsCss':
        this.administratorItemsCss = 'menu_items';
        break;
      case 'configItemsCss':
        this.configItemsCss = 'menu_items';
        break;
      case 'reportItemsCss':
        this.reportItemsCss = 'menu_items';
        break;
      case 'customerAutomaticRequestItemsCss':
        this.customerAutomaticRequestItemsCss = 'menu_items';
        break;
      case 'customerSchedulleRequestItemsCss':
        this.customerSchedulleRequestItemsCss = 'menu_items';
        break;
      case 'customerRequestWait':
        this.customerRequestWait = 'menu_items';
        break;
      case 'customerImportCss':
        this.customerImportCss = 'menu_items';
        break;
      case 'offerCss':
        this.offerCss = 'menu_items';
        break;

    }
  }

  hiddeAllMenu() {
    this.customerMenuDashBoardItemsCss = 'menu_items_close';
    this.customerImportCss = 'menu_items_close';
    this.customerRequestItemsCss = 'menu_items_close';
    this.customerPreRequestItemsCss = 'menu_items_close';
    this.providerMenuDashBoardItemsCss = 'menu_items_close';
    this.providerRequestItemsCss = 'menu_items_close';
    this.finacialItemsCss = 'menu_items_close';
    this.administratorItemsCss = 'menu_items_close';
    this.configItemsCss = 'menu_items_close';
    this.reportItemsCss = 'menu_items_close';
    this.customerAutomaticRequestItemsCss = 'menu_items_close';
    this.customerSchedulleRequestItemsCss = 'menu_items_close';
    this.customerRequestWait = 'menu_items_close';
    this.offerCss = 'menu_items_close';
  }

  /** caso mobile / fechar menu */
  menuClick() {
    if (window.innerWidth < 768) {
      this.onclickMenu.emit();
    }
  }

  openCampanySelectionModal() {
    this.onopenCampanySelectionModal.emit();
  }
}
