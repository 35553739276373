import { Company } from './Company';
import { Status } from './Status';
import { SubSegment } from './SubSegment';
import { ProductType } from './ProductType';
import { Address } from './Address';
import { RequestCompany } from './RequestCompany';
import { User } from './User';
import { RequestItem } from './RequestItem';
import { Proposal } from './Proposal';
import { ProposalHistory } from './ProposalHistory';


export class Request {
  requestId: number;
  companyId: number;
  statusId: number;
  userId: number;
  cancelReason: string;
  subSegmentId: number;
  productDescription: string;
  productTypeId: number;
  productId: number;
  bestDate: Date;
  closeDate: Date;
  addressId: number;
  deliveryInstructions: string;
  note: string;
  awnserDate: string;
  awnserHour: string;
  effectiveDate: string;
  delivery: boolean;
  company: Company;
  status: Status;
  quantity: number;
  subSegment: SubSegment;
  productType: ProductType;
  address: Address;
  requestCompanies: RequestCompany[];
  requestItems: RequestItem[];
  requestProducts: Array<any>;
  requestAtachments: Array<any>;
  user: User;
  measure: string;
  createAt: Date;
  noTaxes: boolean;
  automaticApprovedValue: number;
  spot: boolean;
  proposals: Proposal[];
  proposalHistories: ProposalHistory[]
  chats: any[];
  companyBusinessCode: any;
  companyWorkId?: number;
}
