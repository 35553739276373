import { Injectable } from '@angular/core';
import { Segment } from 'src/app/models/Segment';
import { Request } from 'src/app/models/Request';
import { RequestAtachmentApi } from 'src/app/core/http/entidades/RequestAtachmentApi';

@Injectable({
  providedIn: 'root'
})
export class RequestAttachmentService {

  constructor(private api: RequestAtachmentApi) {
    this.api.baseName = 'requestatachment';
  }
  /** buscar todas os segmentos */
  getall(): any {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** Salvar */
  save(request: any): any {
    console.log(request);
    return new Promise((resolve, reject) => {
      this.api.post(request)
        .then((data: any) => resolve(data))
        .catch(() => {
          reject();
        });
    });
  }
  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api.delete(id)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
}
