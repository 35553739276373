import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { SelectItem } from "primeng/api";
import { ActiveCompanyService } from "src/app/services/active-company.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SegmentService } from "src/app/services/segment/segment.service";
import { UserService } from "src/app/services/user/user.service";
import { UtilsService } from "src/app/services/core/utils.service";
import { Title } from "@angular/platform-browser";
import * as Highcharts from "highcharts";
import { RequestService } from "src/app/services/request/request.service";
import { Subscription } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { User } from "src/app/models/User";

declare var require: any;
const Boost = require("highcharts/modules/boost");
const noData = require("highcharts/modules/no-data-to-display");
const More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: "app-dash-board-customer-performace",
  templateUrl: "./dash-board-customer-performace.component.html",
  styleUrls: ["./dash-board-customer-performace.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class DashBoardCustomerPerformaceComponent implements OnInit, OnDestroy {
  optionsTop5 = {};
  filterFormSegmentIdOptionList: Array<SelectItem> = [];
  filterFormUserIdOptionList: Array<SelectItem> = [];
  form: FormGroup;

  pt = {
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sab"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Feveiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
  };

  TrustRequestorSendRequests = 0;
  NewRequestorSendRequests = 0;
  TotalSendRequests = 0;

  TrustRequestorReceivedProposal = 0;
  NewRequestorReceivedProposal = 0;
  TotalReceivedProposal = 0;

  TrustRequestorPurchasesMade = 0;
  NewRequestorPurchasesMade = 0;
  TotalPurchasesMade = 0;

  TrustRequestorValueInPurchases = 0;
  NewRequestorValueInPurchases = 0;
  TotalValueInPurchases = 0;

  QisarValueSaving = 0;
  QisarValueSavingDetails = [];

  ValueSaving = 0;
  ValueSavingDetails = [];

  loadProposalsFinish = false;
  loadSalesFinish = false;
  loadSavingFinish = false;
  loadSalesValueFinish = false;
  loadChatBestProvidersFinish = false;
  subscribeCompany: Subscription;
  userIsManager : boolean = false;
  currentUserId : number;
  currentUser: User;


  constructor(
    private activeCompany: ActiveCompanyService,
    private title: Title,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private segmentService: SegmentService,
    private ngxSmartModalService: NgxSmartModalService,
    private requestService: RequestService,
    private util: UtilsService
  ) {}
  ngOnDestroy(): void {
    if (this.subscribeCompany) {
      this.subscribeCompany.unsubscribe();
    }
  }

  async ngOnInit() {
    Highcharts.setOptions({
      lang: {
        drillUpText: "Carregar Todos",
        noData: "Nenhum dado encontrado",
        thousandsSep: ".",
        decimalPoint: ",",
      },
    });

    this.title.setTitle("DashBoard Performace| Qisar");
    // Troca de empresa
    this.subscribeCompany = this.activeCompany.onActiveCompanyChange.subscribe(() => {
      this.loadPage();
    });
    // Formulario de busca
    const initial = new Date(new Date().setMonth(new Date().getMonth() - 1));
    this.form = this.formBuilder.group({
      to: [initial],
      from: [new Date()],
      segmentId: [0],
      userId: [0],
    });
    this.loadSegments();
    
    await this.util.getUserFromSession()
      .then(user => {
        this.userIsManager = user.companyManager || false;
        this.currentUserId = user.userId;
        this.currentUser = user;
        this.loadPage();
      });

      await this.loadUsers();
  }

  validAllLoads() {
    this.util.running();
    if (
      this.loadProposalsFinish &&
      this.loadSalesFinish &&
      this.loadSalesValueFinish &&
      this.loadChatBestProvidersFinish &&
      this.loadSavingFinish
    ) {
      this.util.finish();
    }
  }

  loadProposals() {
    this.loadProposalsFinish = false;
    this.requestService
      .GetDashBoarbPerformaceCustromerProposalsGet(
        this.activeCompany.activeCompanyId,
        this.form.value.to,
        this.form.value.from,
        this.form.value.segmentId,
        this.userIsManager ?  this.form.value.userId : this.currentUserId
      )
      .then((data: any) => {
        this.TrustRequestorReceivedProposal = data.myCustomerRequests;
        this.NewRequestorReceivedProposal = data.nonCustomerRequests;
        this.TotalReceivedProposal = data.total;
        this.loadProposalsFinish = true;
        this.validAllLoads();
      });
  }

  loadSaving() {
    this.loadSavingFinish = false;
    this.requestService
      .GetDashBoarbPerformaceCustromerSavingValue(
        this.activeCompany.activeCompanyId,
        this.form.value.to,
        this.form.value.from,
        this.form.value.segmentId,
        this.userIsManager ?  this.form.value.userId : this.currentUserId
      )
      .then((data: any) => {
        console.log(data)
        this.QisarValueSaving = data.sum;
        this.QisarValueSavingDetails = data.sumDetails;
        this.ValueSaving = data.myCustomerRequestsSum;
        this.ValueSavingDetails = data.myCustomerRequestsSumDetails;
        this.loadSavingFinish = true;
        this.validAllLoads();
        console.log(data.sumDetails)
      });
  }

  loadSales() {
    this.loadSalesFinish = false;
    this.requestService
      .GetDashBoarbPerformaceCustromerSales(
        this.activeCompany.activeCompanyId,
        this.form.value.to,
        this.form.value.from,
        this.form.value.segmentId,
        this.userIsManager ?  this.form.value.userId : this.currentUserId
      )
      .then((data: any) => {
        this.TrustRequestorPurchasesMade = data.myCustomerRequests;
        this.NewRequestorPurchasesMade = data.nonCustomerRequests;
        this.TotalPurchasesMade = data.total;
        this.loadSalesFinish = true;
        this.validAllLoads();
      });
  }

  loadSalesValue() {
    this.loadSalesValueFinish = false;
    this.requestService
      .GetDashBoarbPerformaceCustromerSalesValue(
        this.activeCompany.activeCompanyId,
        this.form.value.to,
        this.form.value.from,
        this.form.value.segmentId,
        this.userIsManager ?  this.form.value.userId : this.currentUserId
      )
      .then((data: any) => {
        this.TrustRequestorValueInPurchases = data.myCustomerRequestsSum;
        this.NewRequestorValueInPurchases = data.nonCustomerRequestsSum;
        this.TotalValueInPurchases = data.sum;
        this.loadSalesValueFinish = true;
        this.validAllLoads();
      });
  }

  loadChatBestProviders() {
    this.loadChatBestProvidersFinish = false;
    this.requestService
      .GetDashBoarbPerformaceCustromerSalesTopRequestors(
        this.activeCompany.activeCompanyId,
        this.form.value.to,
        this.form.value.from,
        this.form.value.segmentId,
        this.userIsManager ?  this.form.value.userId : this.currentUserId
      )
      .then((data: any) => {
        this.loadChatBestProvidersFinish = true;
 
        const arrData = [];
        data.forEach((item) => {

          arrData.push({
            name: item.name,
            y: item.sum,
          });
        });

        this.optionsTop5 = {
          chart: {
            type: "column",
            height: 200,
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            series: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                format: "R$ {point.y:,.2f}",
              },
            },
          },
          tooltip: {
            pointFormat: "R$ {point.y:,.2f}",
          },
          yAxis: {
            title: {
              text: "",
            },
            labels: {
              format: "R$ {value:,.2f}",
            },
          },
          xAxis: {
            type: "category",
          },
          series: [
            {
              name: "Fornecedores",
              colorByPoint: true,
              data: arrData,
            },
          ],
        };
        Highcharts.chart("chart-providers", this.optionsTop5);
        this.validAllLoads();
      });
  }

  loadPage() {
    this.TrustRequestorSendRequests = 0;
    this.NewRequestorSendRequests = 0;
    this.TotalSendRequests = 0;

    this.TrustRequestorReceivedProposal = 0;
    this.NewRequestorReceivedProposal = 0;
    this.TotalReceivedProposal = 0;

    this.TrustRequestorPurchasesMade = 0;
    this.NewRequestorPurchasesMade = 0;
    this.TotalPurchasesMade = 0;

    this.TrustRequestorValueInPurchases = 0;
    this.NewRequestorValueInPurchases = 0;
    this.TotalValueInPurchases = 0;

    this.QisarValueSaving = 0;
    this.ValueSaving = 0;
    this.optionsTop5 = {
      chart: {
        type: "column",
        height: 200,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "R$ {point.y:,.2f}",
          },
        },
      },
      tooltip: {
        pointFormat: "R$ {point.y:,.2f}",
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          format: "R$ {value:,.2f}",
        },
      },
      xAxis: {
        type: "category",
      },
    };
    Highcharts.chart("chart-providers", this.optionsTop5);

    this.util.running();
    this.loadProposals();
    this.loadSales();
    this.loadSalesValue();
    this.loadChatBestProviders();
    this.loadSaving();
  }

  loadSegments() {
    this.filterFormSegmentIdOptionList = [];
    this.filterFormSegmentIdOptionList.push({
      label: "Todos",
      value: 0,
    });
    this.segmentService.getall().then((response) => {
      for (const segment of response) {
        this.filterFormSegmentIdOptionList.push({
          label: segment.name,
          value: segment.segmentId,
        });
      }
    });
  }

  async loadUsers() {
    this.filterFormUserIdOptionList = [];
    this.filterFormUserIdOptionList.push({
      label: "Todos",
      value: 0,
    });

    let userList: User[];
    userList = await this.userService.listWithOutSegments(this.currentUser.companyId);

    for (const user of userList) {
      this.filterFormUserIdOptionList.push({
        label: user.name,
        value: user.userId,
      });
    };
  }

  openSaving() {
    this.ngxSmartModalService.getModal('mdlSaving').open();
  }

  openSavingQisar() {
    this.ngxSmartModalService.getModal('mdlSavingQisar').open();
  }

  savingValue(bigcost : number, winnerCost: number){
    console.log(bigcost)
    console.log(winnerCost)
    return bigcost < winnerCost ? 0 : bigcost - winnerCost;
  }
}
