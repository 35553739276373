import { Injectable } from '@angular/core';
import { ProposalApi } from 'src/app/core/http/entidades/ProposalApi';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {

  constructor(private api: ProposalApi) {
    this.api.baseName = 'proposal';
  }

  totalReceived(companyId: number, segmentId: number, initialDate: any, endDate: any, userId: any): any {
    return new Promise((result, reject) => {
      this.api.get('/total/received/' + companyId + '/' + segmentId + '/' + initialDate.toISOString() + '/' + endDate.toISOString()+ '/' + userId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  totalSender(companyId: number, segmentId: number, initialDate: any, endDate: any): any {
    return new Promise((result, reject) => {
      this.api.get('/total/send/' + companyId + '/' + segmentId + '/' + initialDate.toISOString() + '/' + endDate.toISOString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  itensfromaapproval() {
    return this.api.get('/itensfromaapproval');
  }

  approval(proposalId: number, userId: number) {
    return this.api.post({ proposalId, userId }, '/approval');
  }

  decline(proposalId: number, userId: number, cancelReason: string) {
    return this.api.post({ proposalId, userId, cancelReason }, '/decline');
  }

  /** buscar propostas não lidas */
  getProposalsNotRead(): any {
    return new Promise((result, reject) => {
      this.api.get('/GetProposalsNotRead')
        .then(data => result(data))
        .catch(error => reject(error));
    });

  }

  /** marcar proposta como lida */
  readProposalsByRequest(requestId: any): any {
    return new Promise((resolve, reject) => {
      this.api.post(requestId, '/readProposalsByRequest')
        .then(() => resolve(null))
        .catch(() => {
          reject();
        });
    });
  }
}
