import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver, ViewChildren, ComponentRef } from '@angular/core';
import { User } from 'src/app/models/User';
import { Pagination } from 'src/app/models/pagination.model';
import { Title } from '@angular/platform-browser';
import { CompanySetupInfo } from 'src/app/models/CompanySetupInfo';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { CompanyPaymentDetailService } from 'src/app/services/companypaymentdetail/companypaymentdetail.service';
import { CompanyPaymentDetail } from 'src/app/models/CompanyPaymentDetail';
import { PaymentPlanAcceptMonthService } from 'src/app/services/paymentplanacceptmonth/paymentplanacceptmonth.service';
import { PaymentPlanAcceptMonth } from 'src/app/models/PaymentPlanAcceptMonth';
import { UtilsService } from 'src/app/services/core/utils.service';

@Component({
  selector: 'app-payment',
  templateUrl: './extract.component.html',
  styleUrls: ['./extract.component.css']
})
export class CompanyExtractComponent implements OnInit {
  private currentUser: User;
  formGroup: FormGroup;
  refenceDateList: Array<SelectItem> = [];
  companyPaymentDetailList: CompanyPaymentDetail[];
  companyPaymentPlanAcceptMonth: PaymentPlanAcceptMonth[]; 
  lastAcceptedPaymentPlan: PaymentPlanAcceptMonth;
  hasAccept: boolean = false;
  acceptedPlans = 0;
  limit = 0;
  planValue = 0;
  saleComissionTotal = 0;
  invoiceTotal = 0;

  public companySetupInformation: CompanySetupInfo;
  public InvoicesLateBillDays = 0;
  public Paginator: Pagination<Array<CompanyPaymentDetail>>;

  constructor(
    private formBuilder: FormBuilder,
    private title: Title,
    private companyPaymentDetailService: CompanyPaymentDetailService,
    private paymentPlanAcceptMonthService: PaymentPlanAcceptMonthService,
    private utils: UtilsService
    ) {
    this.Paginator = new Pagination();
    this.title.setTitle('Demonstrativo Financeiro | Qisar');
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      referenceId: new FormControl('')
    });

    this.utils.getUserFromSession().then(response => {
      this.currentUser = response;
    });

    let data = new Date();
    this.formGroup.controls.referenceId.setValue(('0' + (data.getMonth() +1).toString()).slice(-2) + '/' + '01' +'/' + data.getFullYear().toString());

    this.refenceDateArray();
    this.loadExtract(); 
  }

  refenceDateArray(){
    var today = new Date();  
    
    for(var i = 6; i > 0; i-=1){        
      var d = new Date(today.getFullYear(), today.getMonth() +1 -i, 1)

      this.refenceDateList.push({
        value: ('0' + (d.getMonth() +1).toString()).slice(-2) + '/' + '01' +'/' + d.getFullYear().toString(),
        label: ('0' + (d.getMonth() +1).toString()).slice(-2) + '/' + d.getFullYear().toString()
      })
    }
  }

  async loadExtract(){
    
    this.saleComissionTotal = 0;
    this.invoiceTotal = 0;
    this.planValue = 0;
    this.companyPaymentDetailList = CompanyPaymentDetail[''];
    this.lastAcceptedPaymentPlan = <PaymentPlanAcceptMonth>{};
    this.hasAccept = false;
    this.limit = 0;

    let initialDate = new Date(this.formGroup.controls.referenceId.value);    
    let finalDate   = new Date(initialDate.getFullYear(), initialDate.getMonth() +1, 0);

    this.companyPaymentDetailList = await this.companyPaymentDetailService.GetCompanyPaymentDetailByDate(initialDate, finalDate);
    
    await this.acceptPlansInfo();

    this.calcTotals();

  }

  async acceptPlansInfo(){
    let initialDate = new Date(this.formGroup.controls.referenceId.value);    
    let finalDate   = new Date(initialDate.getFullYear(), initialDate.getMonth() +1, 0);

    this.companyPaymentPlanAcceptMonth = await this.paymentPlanAcceptMonthService.getByCompanyidDateRange(initialDate, finalDate);   
    
    if(this.companyPaymentPlanAcceptMonth.length){
      this.acceptedPlans = Number(this.companyPaymentPlanAcceptMonth.length);
      this.lastAcceptedPaymentPlan = this.companyPaymentPlanAcceptMonth[0]; 
      this.hasAccept = true;
    }
    
    if(this.companyPaymentDetailList.length){
      this.limit = this.companyPaymentDetailList.length;
    }
  }

  calcTotals(){ 

    if(this.limit <=1)
        this.planValue = 9.99;
    else if(this.limit > 1 && this.limit <=5)
        this.planValue = 49.99;
    if(this.limit > 5 && this.limit <=10)
        this.planValue = 99.99;
    if(this.limit >10)
        this.planValue = 199.99;

    this.companyPaymentDetailList.forEach(item =>{
      this.saleComissionTotal += item.saleCommission;
    })

    this.invoiceTotal = this.planValue + this.saleComissionTotal;

  }
}