import { Component, OnInit } from "@angular/core";
import { OffersService } from "src/app/services/offers/offers.service";
import { UtilsService } from "src/app/services/core/utils.service";
import Swal from "sweetalert2";
import { requireConfirm } from "src/app/utils/decorators/requireConfirm";

@Component({
  selector: "app-offermybuies",
  templateUrl: "./offermybuies.component.html",
  styleUrls: ["./offermybuies.component.css"],
})
export class OffermybuiesComponent implements OnInit {
  offers = [];
  constructor(
    private offerServices: OffersService,
    private utils: UtilsService
  ) {}

  ngOnInit() {
    this.getItems();
  }

  async getItems() {
    const currentUser = await this.utils.getUserFromSession();
    let companyId = 0;
    if(currentUser.companyId){
      companyId = currentUser.companyId;
    }
    this.offerServices
      .shoppingByCompanyAndUser(companyId, currentUser.userId)
      .then((offers: []) => (this.offers = offers));
  }

  @requireConfirm(
    "ATENÇÃO",
    "<p>Confirma o cancelamento dessa compra?</p>",
    "Sim",
    "Não"
  )
  cancelBuy(Sale: any) {
    Swal.fire({
      title: "Qisar",
      text: "Informe o motivo do cancelamento",
      input: "textarea",
      preConfirm: (reason) => {
        if (reason != "") {
          Sale.cancelReason = reason;
          this.offerServices.cancelBuy(Sale)
          .then(() => this.getItems());
        }
      }
    });
  }
}
