import { Injectable } from '@angular/core';
import { CompanySegment } from 'src/app/models/CompanySegment';
import { CompanySegmentApi } from 'src/app/core/http/entidades/CompanySegmentApi';

@Injectable({
  providedIn: 'root'
})
export class CompanysegmentserviceService {

  constructor(private api: CompanySegmentApi) {
    this.api.baseName = 'companysegment';
  }

  /** buscar todas os endereços */
  getall(): any {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um endereço pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** Salvar */
  save(companySegment: CompanySegment): any {
    return new Promise((resolve, reject) => {
      // se empresa nova, insere
      if (companySegment.companySegmentId === 0) {
        this.api.post(companySegment)
          .then(data => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(companySegment)
          .then(data => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api.delete(id)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
}
