import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TermService } from 'src/app/services/term/term.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  form: FormGroup;
  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private terms: TermService
  ) {
    this.title.setTitle('Termos de Uso');
  }

  termId = 0;
  name = '';
  ngOnInit() {
    this.form = this.formBuilder.group({
      html: [null, Validators.compose([Validators.required])]
    });

    this.terms.useTerm()
      .then((data) => {
        this.form.controls.html.setValue(data.html);
        this.termId = data.termId,
          this.name = data.name;
      });
  }

  save() {
    if (this.form.valid) {
      const term = { termId: this.termId, name: this.name, html: this.form.value.html };
      this.terms.save(term)
        .then(() => {
          Swal.fire('Qisar', 'Termos Salvos com sucesso', 'success');
        });
    }
  }

}
