import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Townhouse } from 'src/app/models/Townhouse';
import { TownhouseCalendar } from 'src/app/models/TownhouseCalendar';
import { TownhouseService } from 'src/app/services/townhouse/townhouse.service';
import { TownhouseCalendarService } from 'src/app/services/townhousecalendar/townhousecalendar.service';
import { SelectItem } from 'primeng/api';
import Swal from 'sweetalert2';
import { requireConfirm } from 'src/app/utils/decorators/requireConfirm';

@Component({
  templateUrl: './covid-admin.component.html',
  styleUrls: ['./covid-admin.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CovidAdminComponent implements OnInit {
  townhouseId = 0;
  townhouses: Townhouse[];
  arrTownhouses: Array<SelectItem> = [];
  calendar: TownhouseCalendar[];

  constructor(
    private townHouseService: TownhouseService,
    private townHouseCalendarService: TownhouseCalendarService
  ) { }

  ngOnInit(): void {
    this
      .townHouseService
      .getAll()
      .then((data: Townhouse[]) => {
        this.townhouses = data;
        this.loadHouse(data);
      });
  }

  @requireConfirm('Qisar', 'Cancelar esse agendamento?')
  freeHour(calendar: TownhouseCalendar) {
    calendar.cpf = null;
    calendar.name = null;
    calendar.email = null;
    calendar.unidade = null;
    calendar.phone = null;
    this
      .townHouseCalendarService
      .update(calendar)
      .then(() => {
        Swal.fire('Calendario de Exames', 'Exame cancelado com sucesso', 'success')
          .then(() => {
            this.getCalendarByTownHouse();
          });
      })
      .catch(err => console.log(err));
  }

  download() {
    const headerLine = [
      'Transacao',
      'Id do Condominio',
      'Dia.',
      'Hora',
      'Email',
      'Nome',
      'Telefone',
      'CPF',
      'Unidade',
    ];
    const replacer = (key, value) => value === null ? '' : value;
    const header = Object.keys(this.calendar[0]);
    const csv = this.calendar.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
    csv.unshift(headerLine.join(';'));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob(['\ufeff', csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'relatorio.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();

  }
  loadHouse(data: Townhouse[]) {
    this.arrTownhouses = [];
    this.arrTownhouses.push({
      value: null,
      label: ''
    });
    data.forEach(element => {
      this
        .arrTownhouses
        .push({
          value: element.townhouseId,
          label: element.name
        });
    });
  }

  getCalendarByTownHouse() {
    this
      .townHouseCalendarService
      .getBybyTownhouse(this.townhouseId)
      .then((data: TownhouseCalendar[]) => this.calendar = data);

  }

}
