import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user/user.service';
import Swal from 'sweetalert2';
import { User } from 'src/app/models/User';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LostPasswordComponent implements OnInit {
  imageLogo = '/assets/images/logo';
  loginHeader = 'lost-password-header';
  btnPrimary = 'btn-primary';
  linkHome = "linkHome";
  cssPage = "page";
  opac = "opac";
  form: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private title: Title, private userService: UserService) {
    this.title.setTitle('Recuperar Senha | Qisar');
    if (sessionStorage.getItem('whitelabel')) {
      this.imageLogo += '-' + sessionStorage.getItem('whitelabel');
      this.loginHeader += sessionStorage.getItem('whitelabel');
      this.btnPrimary += sessionStorage.getItem('whitelabel');
      this.linkHome += sessionStorage.getItem('whitelabel');
      this.cssPage += '-' + sessionStorage.getItem('whitelabel');
      this.opac += '-' + sessionStorage.getItem('whitelabel');
    }
    this.imageLogo += '.png'

    this.form = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
  }

  send() {
    const user = new User();
    user.email = this.form.value.email;
    console.log(user);
    this.userService.lostPassword(user);
    Swal.fire('Qisar', 'Você recebera um email com sua nova senha em instantes', 'success')
      .then(() => {
        window.location.href = window.location.href.replace('?action=lost-password', '');
      });
  }
}
