import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenModel } from 'src/app/models/TokenModel';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { Pagination } from 'src/app/models/pagination.model';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class Api<T> {
  public apiEndPoint: string;
  private token = '';
  private httpHeaders: any;
  public baseName = '';
  /**
   * Construtor da Classe
   * @param http Cliente de HTTP
   */
  constructor(private http: HttpClient) {
    this.apiEndPoint = environment.apiUri + 'api/';
    this.token = '';
  }
  /**
   * Fazer uma chamada via GET
   * @param url URL da Chamada
   */
  get(url: string = '', manageHeaders: any = null): Promise<any> {
    return this.loadHeaders()
      .then(() => {
        if (manageHeaders) {
          this.httpHeaders = manageHeaders(this.httpHeaders.headers);
        }
        return this
          .http
          .get(this.apiEndPoint + this.baseName + url, this.httpHeaders)
          .toPromise();
      });
  }

    getAllPaging<C>(paginator: Pagination<C>, manageHeaders: Function = null, url: string = ''): Promise<Pagination<C>> {
        return this.loadHeaders()
            .then(() => {
                if(manageHeaders)
                    this.httpHeaders = manageHeaders(this.httpHeaders.headers);

                return this
                    .http
                    .get(`${this.apiEndPoint}${this.baseName}/${url != '' ? url : ''}all/paging/${paginator.itemsPerPage}/${paginator.currentPage}`, this.httpHeaders)
                    .toPromise();
            });
    }

  getPaging<C>(paginator: Pagination<C>, url: string, manageHeaders: any = null): Promise<Pagination<C>> {
    return this.loadHeaders()
      .then(() => {
        if (manageHeaders) {
          this.httpHeaders = manageHeaders(this.httpHeaders.headers);
        }
        return this
          .http
          .get(`${this.apiEndPoint}${this.baseName}/${url}/paging/${paginator.itemsPerPage}/${paginator.currentPage}`, this.httpHeaders)
          .toPromise();
      });
  }

    sendFiles( url: string, files: File[], uploadProgressEvent: Function = null) : Promise<boolean>{
        return this.loadHeaders()
            .then(() => {
                const formData = new FormData();
                let headers : HttpHeaders = this.httpHeaders.headers;

                headers = headers.delete('Content-Type');

                files.forEach(file => {
                    formData.append(file.name, file, file.name);
                });

                return this
                    .http
                    .post(`${this.apiEndPoint}${this.baseName}/${url}`, formData, {
                        headers: headers,
                        reportProgress: true,
                        observe: 'events'
                    })
                    .pipe(map((event) => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                if(uploadProgressEvent)
                                    uploadProgressEvent(event);
                                break;
                            case HttpEventType.Response:
                                return event;
                            default:
                                break;
                        }
                    }))
                    .toPromise();
            })
    }

    getFile(url: string = '', responseType : string, beforeStart: Function = null, onComplete: Function = null): void {
        if(beforeStart)
            beforeStart();

        from<any>(this.loadHeaders()).pipe(
            flatMap(x => {
                this.httpHeaders.headers.delete('Content-Type');
                this.httpHeaders.headers.append('Accept', responseType);

        return this.http.get<Blob>(this.apiEndPoint + this.baseName + url, {
          headers: this.httpHeaders.headers,
          observe: 'response',
          responseType: 'blob' as 'json'
        });
      })
    )
      .subscribe(response => {
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename: any;

            if((contentDisposition || '') === '')
                console.log('O header Content-Disposition não está presente na resposta do servidor, por este motivo não é possível recuperar o nome do arquivo, isso pode ser um probelma de CORS');
            else
            {
                var rgx = new RegExp(/filename=(.*);/);
                var rgxResult = rgx.exec(contentDisposition);

                if((rgxResult || []).length > 1)
                    filename = rgxResult[1];
                else
                    console.log('Não foi possível obter o nome do arquivo, pode ser que o header Content-Disposition esteja fora do padrão');

                saveAs(response.body, filename);
            }

            if(onComplete)
                onComplete();
        },
        (error) => {
            Swal.fire('Qisar', 'Não foi possível recuperar o arquivo, por favor, contate o suporte');

            if(onComplete)
                onComplete();
        });
    }

  /**
   * Buscar entidade
   * @param filter Filtro
   */
  getOne(filter: string, url: string = ''): Promise<any> {
    return this.loadHeaders()
      .then(() => {
        return this
          .http
          .get(this.apiEndPoint + this.baseName + url + '/' + filter, this.httpHeaders)
          .toPromise();
      });
  }
  /**
   * Adicionar um registo para a entidade
   * @param url URL da Chamada
   * @param entity Entidade para gravar
   */
  post(entity: any, url: string = ''): any {
    return this.loadHeaders().then(() => {
      const data = JSON.stringify(entity);
      return this
        .http
        .post<T>(this.apiEndPoint + this.baseName + url, data, this.httpHeaders)
        .toPromise();
    });
  }
  /**
   * Atualizar o registro
   * @param url URL da Chamada
   * @param entity Entidade para atualizar
   */
  put(entity: any, url: string = ''): any {
    return this.loadHeaders().then(() => {
      const data = JSON.stringify(entity);
      console.log(this.apiEndPoint + this.baseName + url)
      console.log(data)
      return this
        .http
        .put<T>(this.apiEndPoint + this.baseName + url, data, this.httpHeaders)
        .toPromise();
    });
  }
  /**
   * Excluir uma entidade
   * @param url url da chamada
   * @param id id da entidade
   */
  delete(id: number, url: string = ''): any {
    return this.loadHeaders().then(() => {
      return this
        .http
        .delete<T>(this.apiEndPoint + this.baseName + url + '/' + id, this.httpHeaders)
        .toPromise();
    });
  }
  /** header da chamada */
  loadHeaders(): any {
    return new Promise(Resolve => {
      if (sessionStorage.getItem('Token')) {
        const tokenObjectStr: string = sessionStorage.getItem('Token');
        if (tokenObjectStr) {
          const tokenModel: TokenModel = JSON.parse(tokenObjectStr);
          this.token = tokenModel.token;
        }
        this.httpHeaders = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Headers': '*',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.token
          })
        };

        Resolve(null);
      } else {
        this.httpHeaders = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Headers': '*',
            'Content-Type': 'application/json'
          })
        };

        Resolve(null);
      }
    });
  }
}
