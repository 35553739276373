import { OnInit, Component, ViewEncapsulation, OnDestroy } from "@angular/core";
import { ActiveCompanyService } from "src/app/services/active-company.service";
import { UtilsService } from "src/app/services/core/utils.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { SegmentService } from "src/app/services/segment/segment.service";
import { CompanyService } from "src/app/services/company/company.service";
import { User } from "src/app/models/User";
import { Company } from "src/app/models/Company";
import * as Highcharts from "highcharts";
import * as _ from "lodash";
import { Subscription } from 'rxjs';

declare var require: any;
const Boost = require("highcharts/modules/boost");
const noData = require("highcharts/modules/no-data-to-display");
const More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

interface CompaniesByState {
  name: string;
  y: number;
}

@Component({
  templateUrl: "./customer-trustsalers.component.html",
  styleUrls: ["./customer-trustsalers.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerTrustSalersComponent implements OnInit, OnDestroy {
  companyId = 0;
  companies = [];

  companyStates: CompaniesByState[] = [];

  companiesSearch = [];
  companiesTable = [];
  companiesWithPlan = [];
  companiesWithOutPlan = [];

  seeTable = false;
  form: FormGroup;
  filterFormSegmentIdOptionList: Array<SelectItem> = [];
  filterFormHavePlanOptionList: Array<SelectItem> = [];

  options = {};
  optionsUF = {};
  subscribeCompany: Subscription;

  constructor(
    private activeCompany: ActiveCompanyService,
    private utilsServices: UtilsService,
    private formBuilder: FormBuilder,
    private segmentService: SegmentService,
    private companyService: CompanyService
  ) {}
  ngOnDestroy(): void {
    if (this.subscribeCompany) {
      this.subscribeCompany.unsubscribe();
    }
  }

  loadSegments() {
    this.filterFormSegmentIdOptionList = [];
    this.filterFormSegmentIdOptionList.push({
      label: "Todos",
      value: null,
    });
    this.segmentService.getall().then((response) => {
      for (const segment of response) {
        this.filterFormSegmentIdOptionList.push({
          label: segment.name,
          value: segment.segmentId,
        });
      }
    });
  }

  loadChart() {
    const data = [];

    data.push({
      name: "Aderiram",
      y: this.companiesWithPlan.length,
      color: "#7dc80b",
    });

    data.push({
      name: "Não Aderiram",
      y: this.companiesWithOutPlan.length,
      color: "#b794f4",
    });

    this.options = {
      chart: {
        type: "pie",
        height: 276,

      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormat: "<b>{point.percentage:.1f}</b>%",
      },
      plotOptions: {
        pie: {
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "{point.name}",
          },
        },
      },
      series: [
        {
          innerSize: "50%",
          name: "Fornecedores",
          colorByPoint: true,
          data: data,
        },
      ],
    };

    Highcharts.chart("chart", this.options);
  }

  loadChartUf() {
    const data = this.companyStates;

    this.options = {
      chart: {
        height: 246,
        type: "pie",
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0
      }
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
        align: "center",
        verticalAlign: "middle",
        y: 60,
      },
      tooltip: {
        pointFormat: "<b>{point.percentage:.1f}</b>%",
      },
      plotOptions: {
        pie: {
          cursor: "pointer",
          innerSize: 80,
          depth: 45,
          dataLabels: {
            enabled: true,
            format: "{point.name}",
          },

       },
      },
      series: [
        {
          type: 'pie',
          name: "Estados",
          data: data,
        },
      ],
    };

    Highcharts.chart("chart-uf", this.options);
  }

  readUF(companies: Company[]) {
    this.companyStates = [];
    companies.forEach((company) => {
      const grp = _.mapValues(_.groupBy(company.addresses, "state"), (clist) =>
        clist.map(() => _.omit(company.addresses, "state"))
      );
      const UFs = Object.keys(grp);
      UFs.forEach((uf) => {
        this.populeUF(uf);
      });
    });

    this.loadChartUf();
  }

  populeUF(uf: string) {
    const idx = this.companyStates.findIndex((u) => u.name === uf);
    if (idx > -1) {
      const qtd = this.companyStates[idx].y;
      this.companyStates[idx].y = qtd + 1;
    } else {
      this.companyStates.push({
        name: uf,
        y: 1,
      });
    }
  }

  loadPlanSituation() {
    this.filterFormHavePlanOptionList = [];
    this.filterFormHavePlanOptionList.push({
      label: "Todos",
      value: null,
    });

    this.filterFormHavePlanOptionList.push({
      label: "Aderiu à Plataforma",
      value: 0,
    });

    this.filterFormHavePlanOptionList.push({
      label: "Não aderiu à Plataforma",
      value: 1,
    });
  }

  loadPage() {
    this.utilsServices.running();
    this.companyService
      .getSalers(this.companyId)
      .then((data: []) => {
        this.companies = data;
        this.readUF(data);
        this.searchCompanies();
        this.loadChart();
        this.utilsServices.finish();
      })
      .catch((err) => console.error(err));
  }

  getSegments(company: Company): string {
    let segments = "";
    if (company.companySegments) {
      for (let index = 0; index < company.companySegments.length; index++) {
        if (segments.length > 0) {
          if (index === company.companySegments.length - 1) {
            segments += " e ";
          } else {
            segments += ", ";
          }
        }
        segments += company.companySegments[index].segment.name;
      }
    }

    return segments;
  }

  getUF(company: Company): string {
    let UFs = "";
    if (company.addresses) {
      for (let index = 0; index < company.addresses.length; index++) {
        if (UFs.length > 0) {
          if (index === company.companySegments.length - 1) {
            UFs += " e ";
          } else {
            UFs += ", ";
          }
        }
        UFs += company.addresses[index].state;
      }
    }

    return UFs;
  }

  showTable(companies: Company[]) {
    this.seeTable = true;
    this.companiesTable = companies;
  }

  searchCompanies() {
    this.seeTable = false;
    this.companiesSearch = [];
    if (this.form.value.segmentId === null) {
      this.companiesSearch = this.companies;
      this.showTable(this.companiesSearch);
    } else {
      this.companies.forEach((company: Company) => {
        if (company.companySegments) {
          let contains = false;
          company.companySegments.forEach((segment) => {
            if (segment.segmentId === this.form.value.segmentId) {
              contains = true;
            }
          });
          if (contains) {
            this.companiesSearch.push(company);
          }
        }
      });
      this.showTable(this.companiesSearch);
    }
    this.companiesWithPlan = [];
    this.companiesWithOutPlan = [];

    this.companiesSearch.forEach((company: Company) => {
      if (company.paymentPlanId && company.paymentPlanId > 0) {
        this.companiesWithPlan.push(company);
      } else {
        this.companiesWithOutPlan.push(company);
      }
    });
    this.readUF(this.companiesSearch);
    this.loadChart();
  }

  ngOnInit(): void {
    Highcharts.setOptions({
      lang: {
        drillUpText: "Carregar Todos",
        noData: "Nenhum dado encontrado",
        thousandsSep: ".",
        decimalPoint: ",",
      },
    });
    this.loadSegments();
    this.loadPlanSituation();

    this.form = this.formBuilder.group({
      segmentId: [null],
      paymentPlanStatusId: [null],
    });

    this.utilsServices.getUserFromSession().then((user: User) => {
      this.companyId = user.companyId;
      this.loadPage();
    });

    this.subscribeCompany = this.activeCompany.onActiveCompanyChange.subscribe(() => {
      this.companyId = this.activeCompany.activeCompanyId;
      this.loadPage();
    });
  }
}
