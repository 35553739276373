import { Injectable, EventEmitter } from '@angular/core';
import isValidCnpj from '@brazilian-utils/is-valid-cnpj';
import { User } from 'src/app/models/User';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  onUserDataChange: EventEmitter<User> = new EventEmitter();

  constructor(
    private userService: UserService
  ) { }
  /** Mostrar o Load  */
  running() {
    const spinLoader = document.getElementById('spinLoader') as HTMLInputElement;
    spinLoader.className = 'qisarLoader';
  }

  /** Fechar com o Load */
  finish() {
    const spinLoader = document.getElementById('spinLoader') as HTMLInputElement;
    spinLoader.className = 'qisarLoader-close';
  }

  /** Abrir Modal */
  openModal(name: string) {
    const modal = document.getElementById(name) as HTMLInputElement;
    modal.style.display = 'block';
  }
  /** Fechar Modal */
  closeModal(name: string) {
    const modal = document.getElementById(name) as HTMLInputElement;
    modal.style.display = 'none';
  }
  /** busca o usuario logado */
  getUserFromSession(): Promise<User> {
    return new Promise(Resolve => {
      if (sessionStorage.getItem('Token')) {
        const tokenSessao = JSON.parse(sessionStorage.getItem('Token'));
        Resolve(tokenSessao.user);
      }
    });
  }

  urlPagSeguro(): boolean {
    return (window.location.href.indexOf('pagseguro') > 0)
  }

  updateSessionUser(): Promise<void> {
    return new Promise((resolve, reject) => {
      const tokenStr = sessionStorage.getItem('Token');
      if (tokenStr && tokenStr !== '') {
        const tokenSessao = JSON.parse(sessionStorage.getItem('Token'));
        const oldUser: User = tokenSessao.user;
        this.userService.get(oldUser.userId).then(newUser => {
          tokenSessao.user = newUser;
          sessionStorage.setItem('Token', JSON.stringify(tokenSessao));
          this.onUserDataChange.emit(newUser);
          resolve();
        });
      } else {
        reject();
      }
    });
  }

  getTokenData() {
    return JSON.parse(sessionStorage.getItem('Token'));
  }

  setTokenData(tokenData: any) {
    sessionStorage.setItem('Token', JSON.stringify(tokenData));
  }

  validCnpj(cnpj: string): boolean {
    return isValidCnpj(cnpj);
  }

  redirect(path: string): void {
    let url = window.location.protocol;
    url = url + '//' + window.location.hostname;
    if (window.location.port !== '80') {
      url = url + ':' + window.location.port;
    }
    url = url + path;
    window.location.replace(url);
  }
}
