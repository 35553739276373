import { Component, OnInit } from '@angular/core';
import { OffersService } from 'src/app/services/offers/offers.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { requireConfirm } from 'src/app/utils/decorators/requireConfirm';

@Component({
  selector: 'app-offeredit',
  templateUrl: './offeredit.component.html',
  styleUrls: ['./offeredit.component.css']
})
export class OffereditComponent implements OnInit {
  offer: any;
  form: FormGroup;

  constructor(
    private offerService: OffersService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.buildForm();
    this.loadOffer();

  }

  async loadOffer() {
    const id = this.activedRoute.snapshot.paramMap.get('offerId');
    this.offerService.get(Number(id))
      .then((offer) => {
        this.offer = offer;
        this.loadForm(offer);
      });
  }

  backToList() {
    this.router.navigateByUrl('/offer/negotiation');
  }

  @requireConfirm('Qisar', 'Confirma salvar os dados?')
  save() {
    if (this.form.valid) {
      this.offer.cost = this.form.value.cost;
      this.offer.quantity = this.form.value.quantity;
      this.offer.name = this.form.value.name;
      this.offer.description = this.form.value.description;
      this.offerService.save(this.offer)
        .then(() => this.backToList());
    }
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      quantity: [null, Validators.compose([Validators.required])],
      cost: [null, Validators.compose([Validators.required, Validators.min(0.01)])]
    });
  }

  loadForm(Offer: any) {
    this.form.controls['name'].setValue(Offer.name);
    this.form.controls['quantity'].setValue(Offer.quantity);
    this.form.controls['cost'].setValue(Offer.cost);
    this.form.controls['description'].setValue(Offer.description);
  }

  disabled() {
    if (!this.form.valid) {
      return 'disabled';
    }
    return '';
  }
}
