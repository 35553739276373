import { Injectable } from '@angular/core';
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service'
import { FileHelper } from '../utils/file-helper';
import * as UUID from 'uuid-js';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AzureStorageBlobHelperService {

  blobUri: string = "https://qisarfiles.blob.core.windows.net";
  storageAccount: string = "qisarfiles";

  //Funciona para o Request
  sasTokenForUpload = environment.azureBlobSasTokenForUpload;
  sasTokenForDownload = environment.azureBlobSasTokenForDownload

  constructor(
    private blobService: BlobService
  ) {

  }

  uploadFile(file: File, containerName: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (file) {
        const uploadParams: UploadParams = {
          sas: this.sasTokenForUpload,
          storageAccount: this.storageAccount,
          containerName: containerName
        }
        const fileNameParts: Array<string> = file.name.split(".");
        let extension: string = null;
        if (fileNameParts.length > 1) {
          extension = fileNameParts[fileNameParts.length - 1]
        }
        let fileName: string = UUID.create().toString();
        if (extension) {
          fileName += "." + extension;
        }
        const baseUrl = this.blobService.generateBlobUrl(uploadParams, fileName);
        const uploadConfig: UploadConfig = {
          baseUrl: baseUrl,
          sasToken: uploadParams.sas,
          blockSize: 1024 * 64,
          file: file,
          complete: () => {
            resolve(baseUrl);
          },
          error: (err) => {
            console.error(err);
            reject();
          },
          progress: (percent) => {

          }
        };
        this.blobService.upload(uploadConfig);
      }
    });
  }

  uploadDataUrl(dataUrl: string, containerName: string) {
    return this.uploadFile(FileHelper.convertDataUrlToFile(dataUrl, new Date().toISOString()), containerName)
  }

}
