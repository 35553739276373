import { Injectable } from '@angular/core';
import { UserPhoneApi } from 'src/app/core/http/entidades/UserPhoneApi';
import { UserPhone } from 'src/app/models/UserPhone';

@Injectable({
  providedIn: 'root'
})
export class UserphoneService {
  constructor(private api: UserPhoneApi) {
    this.api.baseName = 'userphone';
   }
  /** buscar um telefone de usuario pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** Salvar */
  save(userPhone: UserPhone): any {
    return new Promise((resolve, reject) => {
      if (userPhone.userPhoneId === 0) {
        this.api.post(userPhone)
          .then(data => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(userPhone)
          .then((data: UserPhone) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api.delete(id)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
}
