import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {

  constructor(private title: Title) {
    this.title.setTitle("Altere sua Senha | Qisar");
   }

  ngOnInit() {
  }

  save() {
    window.location.href = window.location.href.replace('?action=reset-password', '');
  }


}
