import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/core/utils.service';
import { Company } from 'src/app/models/Company';
import { Address } from 'src/app/models/Address';
import { Title } from '@angular/platform-browser';
import { Segment } from 'src/app/models/Segment';
import { ProducttypeService } from 'src/app/services/producttype/producttype.service';
import { Request } from 'src/app/models/Request';
import { Product } from 'src/app/models/Product';
import { User } from 'src/app/models/User';
import { ProductType } from 'src/app/models/ProductType';

@Component({
  selector: 'app-newrequest',
  templateUrl: './newrequest.component.html',
  styleUrls: ['./newrequest.component.css']
})
export class NewrequestComponent implements OnInit {
  user: User;
  company: Company;
  address: Address;
  segment: Segment;
  segments: Segment[];
  productType: ProductType;
  productsType: ProductType[];
  request: Request;
  product: Product;
  constructor(
    private utils: UtilsService,
    private title: Title,
    private productTypeService: ProducttypeService
  ) {
    this.title.setTitle('Nova Requisição | Qisar');
  }
  ngOnInit() {
    this.segments = new Array<Segment>();
    this.productsType = new Array<ProductType>();

    this.productTypeService.getall()
      .then((productsType: ProductType[]) => {
        this.productsType = productsType;
      });

    this.utils.running();
    this.utils.getUserFromSession()
      .then((user: User) => {
        this.request = new Request();
        this.request.requestId = 0;
        this.request.companyId = user.companyId;
        this.request.statusId = 1;
        this.request.cancelReason = '';
        this.request.subSegmentId = 0;
        this.request.productDescription = '';
        this.request.productTypeId = 0;
        this.request.addressId = 0;
        this.request.deliveryInstructions = '';
        this.request.awnserDate = '';
        this.request.awnserHour = '';
        this.request.delivery = false;
        this.user = user;
        this.company = user.company;
        user.userSegments.forEach(userSegment => {
          if (userSegment.isCustomer === true) {
            this.segments.push(userSegment.segment);
          }
        });
        this.segments = this.segments.sort(s => s.segmentId);
      });
    this.address = new Address();
    this.utils.finish();
  }
}
