import { Injectable } from '@angular/core';
import { TownhouseApi } from 'src/app/core/http/entidades/TownhouseApi';

@Injectable({
  providedIn: 'root'
})
export class TownhouseService {

  constructor(private api: TownhouseApi) {
    this.api.baseName = 'townhouse';
  }
  getAll() {
    return new Promise((result, reject) => {
      this.api.get('/')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

}
