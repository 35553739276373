import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UtilsService } from 'src/app/services/core/utils.service';
import { Router } from '@angular/router';
import { SegmentService } from 'src/app/services/segment/segment.service';
import Swal from 'sweetalert2';
import { Segment } from 'src/app/models/Segment';
import { SubSegment } from 'src/app/models/SubSegment';
import { SubSegmentService } from 'src/app/services/subsegment/subsegment.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-segment-register',
  templateUrl: './segment-register.component.html',
  styleUrls: ['./segment-register.component.css']
})
export class SegmentRegisterComponent implements OnInit {
  segments: Segment[];
  segment: Segment;
  subSegment: SubSegment;
  constructor(
    private title: Title,
    private router: Router,
    private ngxSmartModalService: NgxSmartModalService,
    private utils: UtilsService,
    private subSegmentService: SubSegmentService,
    private segmentService: SegmentService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.title.setTitle('Cadastro de Segmentos | Qisar');
    this.pageLoad();
  }
  /** carrega a pagina */
  pageLoad() {
    this.utils.running();
    this.cleanSegment();
    this.subSegment = new SubSegment();
    this.segmentService.list()
      .then((segments: []) => {
        this.segments = segments;
        this.utils.finish();
      })
      .catch(() => {
        this.utils.finish();
        Swal.fire('Qisar', 'Não foi possivel carregar os segmentos', 'error');
      });
  }
  ngOnInit() {
  }
  /** modal do  novo segmento */
  newSegment() {
    this.cleanSegment();
    this.ngxSmartModalService.getModal('modalSegment').open();
  }
  newSubSegment(segment: Segment) {
    this.cleanSubSegment(segment);
    this.ngxSmartModalService.getModal('modalSubSegment').open();
  }
  /** fechar modal */
  closeSegmentModal() {
    this.ngxSmartModalService.getModal('modalSegment').close();
    if (this.segment.segmentId > 0) {
      this.segmentService.get(this.segment.segmentId)
        .then((data: Segment) => {
          const idx = this.segments.findIndex(s => s.segmentId === this.segment.segmentId);
          this.segments[idx] = data;
          this.cleanSegment();
        })
        .catch(() => {
          Swal.fire('Qisar', 'Erro ao buscar o segmento', 'error');
        });
    }
  }
  closeSubSegmentModal() {
    this.ngxSmartModalService.getModal('modalSubSegment').close();
  }
  /** Editar uma Segmento */
  editSegment(segment: Segment) {
    this.segment = segment;
    this.ngxSmartModalService.getModal('modalSegment').open();
  }
  editSubSegment(subsegment: SubSegment) {
    this.subSegment = subsegment;
    this.ngxSmartModalService.getModal('modalSubSegment').open();
  }

  /** Editar uma Segmento */
  deleteSegment(segment: Segment) {
    Swal.fire({
      title: 'Qisar',
      type: 'question',
      html:
        'Confirma a exclusão do segmento?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmo'
    }).then((data) => {
      if (data.value) {
        this.segmentService.delete(segment.segmentId)
          .then(() => {
            const idx = this.segments.findIndex(s => s.segmentId === this.segment.segmentId);
            this.segments.splice(idx, 1);
          });
      }
    });
  }
  /** segmento zerado */
  cleanSegment() {
    this.segment = new Segment();
    this.segment.segmentId = 0;
    this.segment.name = '';
    this.segment.description = '';
  }
  /** segmento zerado */
  cleanSubSegment(segment: Segment) {
    this.subSegment = new SubSegment();
    this.subSegment.segmentId = segment.segmentId;
    this.subSegment.name = '';
    this.subSegment.description = '';
    this.subSegment.subSegmentId = 0;
  }
  /** salvar o segmento */
  saveSegment() {
    this.ngxSmartModalService.getModal('modalSegment').close();
    this.utils.running();
    this.segmentService.save(this.segment)
      .then((data: Segment) => {
        if (this.segment.segmentId === 0) {
          this.segments.push(data);
        }
        Swal.fire('Qisar', 'Segmento cadastrado com sucesso', 'success');
        this.utils.finish();
      })
      .catch(() => {
        Swal.fire('Qisar', 'Erro ao salvar o segmento', 'error');
        this.utils.finish();
      });
  }
  saveSubSegment() {
    this.ngxSmartModalService.getModal('modalSubSegment').open();
    this.utils.running();
    this.subSegmentService.save(this.subSegment)
      .then((data: SubSegment) => {
        if (this.subSegment.subSegmentId === 0) {
          const segment = this.segments.find(c => c.segmentId === this.subSegment.segmentId);
          segment.subSegments.push(data);
          this.utils.finish();
        }
        Swal.fire('Qisar', 'Segmento cadastrado com sucesso', 'success');
        this.utils.finish();
      })
      .catch(() => {
        Swal.fire('Qisar', 'Erro ao salvar o segmento', 'error');
        this.utils.finish();
      });
  }
  /**
   * Habilita ou some a Linha
   * @param id Id da Mensagem
   */
  toggleLine(area: string, id: number) {
    const line = document.getElementById(area + id);
    if (line.className === 'disable-line') {
      line.className = '';
    } else {
      line.className = 'disable-line';
    }
  }
}
