import { Injectable } from '@angular/core';
import { TermApi } from 'src/app/core/http/entidades/TermApi';

@Injectable({
  providedIn: 'root'
})
export class TermService {

  constructor(private api: TermApi) {
    this.api.baseName = 'term';
  }
  useTerm(): any {
    return new Promise((result, reject) => {
      this.api.get('/UseTerm')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  save(term: any): any {
    return new Promise((result, reject) => {
      this.api.put(term)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
}
