import { Segment } from './Segment';
import { User } from './User';
import { Company } from './Company';

export class UserSegment {
  public userSegmentId: number;
  public userId: number;
  public segmentId: number;
  public companyId: number;
  public maxValue: number;
  public isCustomer: boolean;
  public isRequestor: boolean;
  public canCreateRequests: boolean;
  public segment: Segment;
  public user: User;
  public company: Company;

}
