import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { RequestService } from "src/app/services/request/request.service";
import { Request } from "src/app/models/Request";
import { UtilsService } from "src/app/services/core/utils.service";
import { SelectItem } from "primeng/api";
import { OverlayPanel } from "primeng/overlaypanel";
import { InputMask } from "primeng/inputmask";
import { ObjectMap } from "src/app/utils/object-map";
import { DialogHelperService } from "src/app/services/dialog-helper.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SegmentService } from "src/app/services/segment/segment.service";
import * as moment from "moment";
import { Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { ActiveCompanyService } from "src/app/services/active-company.service";
import { User } from "src/app/models/User";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/services/user/user.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { QisarService } from "src/app/services/hubs/qisar.service";
import { NotificationService } from "src/app/services/notification.service";
import { CompanyService } from "src/app/services/company/company.service";
import { Subscription } from "rxjs";
import { CompanyWorkService } from 'src/app/services/companywork/companywork.service';

@Component({
  selector: "app-customer-request-list-page",
  templateUrl: "./customer-request-list-page.component.html",
  styleUrls: ["./customer-request-list-page.component.css"],
})
export class CustomerRequestListPageComponent implements OnInit, OnDestroy {
  @ViewChild("dateTimeEditOverlayPanel") dateTimeEditOverlayPanel: OverlayPanel;
  @ViewChild("addressOverlayPanel") addressOverlayPanel: OverlayPanel;
  @ViewChild("productOverlayPanel") productOverlayPanel: OverlayPanel;
  @ViewChild("dateTimeEditInputMask") dateTimeEditInputMask: InputMask;
  @ViewChild("atachmentsOverlayPanel") atachmentsOverlayPanel: OverlayPanel;

  requestList: Array<Request>;
  toAproved = false;
  requestMap: ObjectMap<Request> = new ObjectMap("requestId");
  isRequestApiBusy = false;
  hasImportFile = false;
  hasHasCompanyWork = false;
  isMaster = false;
  filterForm: FormGroup;
  companyId: number;
  seeStatusFilter = true;
  icon = "fas fa-list";
  pageTitle = "Todas as Requisições Enviadas";
  declines = [];
  customers = [];
  companyWorks = [];
  requestIdFilterCurrentValue : string = '';
  evenUser = false;
  cssPage = 'app-page-body';

  filterFormDateRangeOptionList: Array<SelectItem> = [
    {
      label: "Hoje",
      value: "today",
    },
    {
      label: "Última semana",
      value: "last-7-days",
    },
    {
      label: "Últimos 15 dias",
      value: "last-15-days",
    },
    {
      label: "Último mês",
      value: "last-30-days",
    },
    {
      label: "Desde o início",
      value: "all",
    },
  ];
  filterFormSegmentIdOptionList: Array<SelectItem> = [];
  filterFormUserIdOptionList: Array<SelectItem> = [];
  filterFormStatusIdOptionList: Array<SelectItem> = [
    {
      label: "Todos",
      value: null,
    },
    {
      label: "Em andamento",
      value: 1,
    },
    {
      label: "Encerrada",
      value: 3,
    },
    {
      label: "Aguardando Aprovação",
      value: 5,
    },
    {
      label: "Cancelada",
      value: 2,
    },
  ];

  statusColorMap = {
    1: "#ffc107",
    2: "#b71c1c",
    3: "#64dd17",
    5: "#1976d2",
    6: "#836699",
  };

  dateTimeEditValue = "";
  selectedRequestIdToDateTimeEdit: number = null;
  selectedRequestToShowAddress: Request = null;
  selectedRequestToShowProduct: Request = null;
  selectedRequestToShowAtachments: Request = null;
  manager = false;
  userId = 0;
  cssFilters = "col-12 col-md-9";
  automatic = false;
  schedulle = false;
  btnPrimary = 'btn-primary';
  subscribeCompany: Subscription;
  currentUser: User;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private customerService: CompanyService,
    private requestService: RequestService,
    private segmentService: SegmentService,
    private ngxSmartModalService: NgxSmartModalService,
    private dialogHelperService: DialogHelperService,
    private notificationService: NotificationService,
    private utils: UtilsService,
    private title: Title,
    private hub: QisarService,
    private userService: UserService,
    private activeCompanyService: ActiveCompanyService,
    private companyWorkService: CompanyWorkService,
    private router : Router
  ) {
    this.utils.running();
  }
  ngOnDestroy(): void {
    if (this.subscribeCompany) {
      this.subscribeCompany.unsubscribe();
    }
  }

  

  openDecline(chats: any) {
    this.declines = [];
    chats.forEach((chat) => {
      const decline = {
        trust: chat.trustCompany,
        company: chat.company.name,
        cancelReasor: chat.cancelReasor,
        user: chat.user.name,
      };
      this.declines.push(decline);
    });
    if (this.declines.length > 0) {
      this.ngxSmartModalService.getModal("customer-declines").open();
    }
  }
  async ngOnInit() {
    if (sessionStorage.getItem('whitelabel')) {
      this.btnPrimary += sessionStorage.getItem('whitelabel');
      this.cssPage += '-' + sessionStorage.getItem('whitelabel');
    }

    this.customers = [];
    this.customers.push({
      label: "Todos",
      value: null,
    });
    this.customerService.getallcustomers().then((customers) => {
      customers.forEach((customer) => {
        this.customers.push({
          label: customer.name,
          value: customer.companyId,
        });
      });
    });
    this.hub.onMessageNewProposal.subscribe(() => {
      this.notificationService.onProposalReload();
    });

    this.hub.onDeclineRequest.subscribe(() => {
      this.notificationService.onDeclineReload();
    });

    await this.utils.getUserFromSession().then((user: User) => {
      this.companyId = this.activeCompanyService.activeCompanyId;
      this.evenUser = (environment.evenId === this.companyId);
      this.userId = user.userId;
      this.hasImportFile = user.company.hasImportFile;
      this.hasHasCompanyWork = user.company.hasCompanyWork;
      this.currentUser = user;

      if(this.hasHasCompanyWork) {
        this
          .companyWorkService
          .getActived(this.companyId)
          .then((data: any[]) => {
            this.companyWorks.push({
              label: "Todos",
              value: null,
            });
            data.forEach(companyWork => {
              this.companyWorks.push({
                label: companyWork.name,
                value: companyWork.companyWorkId,
              });
            });

            this.filterForm.controls.companyWorkId.setValue(null);
            
          })
      }

      if (user.companyManager) {
        this.manager = user.companyManager;
        this.cssFilters = "col-12 col-md-12";
      }
      if (user.master) {
        this.manager = user.companyManager;
        this.cssFilters = "col-12 col-md-12";
      }
      if (user.master || user.operator) {
        this.isMaster = true;
      }
    });
    let statusId = null;

    if (window.location.href.indexOf("customer/automatic/request") > 0) {
      this.automatic = true;
      this.pageTitle = "Todas as Requisições com Compra Automatizada Enviadas";
    }
    if (window.location.href.indexOf("customer/schedulle/request") > 0) {
      this.schedulle = true;
      this.pageTitle = "Todas as Requisições com Compra Agendadas Enviadas";
    }
    if (window.location.href.indexOf("customer/request/forapproved") > 0) {
      this.toAproved = true;
      this.pageTitle = "Aprovação de Compras";
      this.icon = "fas fa-list";
      this.seeStatusFilter = false;
    }

    if (this.route.snapshot.paramMap.get("status")) {
      this.seeStatusFilter = false;
      switch (this.route.snapshot.paramMap.get("status")) {
        case "open":
          statusId = 1;
          this.icon = "fas fa-lock-open";
          this.pageTitle = "Requisições Em Andamento";
          if (this.automatic) {
            this.pageTitle = "Requisições com Compra Automatizada Em Andamento";
          }
          if (this.schedulle) {
            this.pageTitle = "Requisições com Compra Agendada Em Andamento";
          }
          break;
        case "cancel":
          statusId = 2;
          this.icon = "fas fa-ban";
          this.pageTitle = "Requisições Canceladas";
          if (this.automatic) {
            this.pageTitle = "Requisições com Compra Automatizada Canceladas";
          }
          if (this.schedulle) {
            this.pageTitle = "Requisições com Compra Agendada Canceladas";
          }
          break;
        case "close":
          statusId = 3;
          this.icon = "fas fa-lock";
          this.pageTitle = "Requisições Encerradas";
          if (this.automatic) {
            this.pageTitle = "Requisições com Compra Automatizada Encerradas";
          }
          if (this.schedulle) {
            this.pageTitle = "Requisições com Compra Agendada Encerradas";
          }
          break;
        case "wait":
          statusId = 5;
          this.icon = "fas fa-clock";
          this.pageTitle = "Requisições Aguardando Aprovação";
          break;

        case "draft":
          statusId = 6;
          this.icon = "fab fa-firstdraft";
          this.pageTitle = "Requisições Aguardando Aprovação";
          break;
      }
    }
    this.title.setTitle(this.pageTitle + "| " + environment.appTitle);
    this.filterForm = this.formBuilder.group({
      segmentId: [null, Validators.compose([])],
      dateRange: ["last-7-days", Validators.compose([])],
      statusId: [statusId, Validators.compose([])],
      awnserDate: [null, Validators.compose([])],
      requestId: [null, Validators.compose([])],
      userId: [null, Validators.compose([])],
      customerId: [null, Validators.compose([])],
      companyBusinessCode: ['', Validators.compose([])],
      companyWorkId: [null, Validators.compose([])],
    });

    this.loadAuxData().then(() => {
      this.loadList().then(() => {
        this.utils.finish();
      });
    });

    this.subscribeCompany = this.activeCompanyService.onActiveCompanyChange.subscribe(
      () => {
        this.companyId = this.activeCompanyService.activeCompanyId;
        this.evenUser = (environment.evenId === this.companyId);
        this.loadList().then(() => {
          this.utils.finish();
        });
      }
    );
    
    await this.loadUserList();
  }

  getDeclines(chats: any): number {
    return chats.length;
  }
  reloadRequest(requestId: any) {
    let queryUserid = this.userId;
    if (this.manager) {
      queryUserid = this.filterForm.value.userId;
      if (!queryUserid) {
        queryUserid = 0;
      }
    }
    if (this.seeStatusFilter) {
      if (!this.toAproved) {
        this.requestService
          .searchList(
            "customer",
            queryUserid,
            this.manager,
            this.isMaster
              ? this.filterForm.value.customerId
              : this.activeCompanyService.activeCompanyId,
            this.filterForm.value.segmentId,
            this.filterForm.value.statusId,
            null,
            null,
            this.filterForm.value.awnserDate,
            requestId,
            this.automatic,
            this.schedulle,
            false,
            this.filterForm.value.companyBusinessCode ? this.filterForm.value.companyBusinessCode : '',
            this.filterForm.value.companyWorkId
          )
          .then((response: Array<Request>) => {
            this.utils.running();
            const idx = this.requestList.findIndex(
              (r) => r.requestId === requestId
            );
            this.requestList[idx] = response[0];
            this.utils.finish();
          });
      } else {
        this.requestService
          .GetForAproved(
            this.activeCompanyService.activeCompanyId,
            this.filterForm.value.segmentId,
            this.filterForm.value.requestId,
            this.filterForm.value.userId,
            this.filterForm.value.companyBusinessCode ? this.filterForm.value.companyBusinessCode : '',
            this.filterForm.value.companyWorkId,            
            null,
            null
          )
          .then((response: Array<Request>) => {
            this.utils.running();
            const idx = this.requestList.findIndex(
              (r) => r.requestId === requestId
            );
            this.requestList[idx] = response[0];
            this.utils.finish();
          });
      }
    } else {
      this.loadList();
    }
  }

  loadList(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.utils.running();
      this.requestList = [];
      this.isRequestApiBusy = true;
      let rangeDateStart: Date = null;
      let rangeDateEnd: Date = null;

      const filterFormDateRange: string = this.filterForm.value.dateRange;
      if (filterFormDateRange === "today") {
        rangeDateStart = new Date(moment().format("YYYY-MM-DD") + " 00:00:00");
        rangeDateEnd = new Date(moment().format("YYYY-MM-DD") + " 23:59:59");
      }
      if (filterFormDateRange === "last-7-days") {
        rangeDateStart = new Date(
          moment().subtract(7, "days").format("YYYY-MM-DD") + " 00:00:00"
        );
        rangeDateEnd = new Date(moment().format("YYYY-MM-DD") + " 23:59:59");
      }
      if (filterFormDateRange === "last-15-days") {
        rangeDateStart = new Date(
          moment().subtract(15, "days").format("YYYY-MM-DD") + " 00:00:00"
        );
        rangeDateEnd = new Date(moment().format("YYYY-MM-DD") + " 23:59:59");
      }
      if (filterFormDateRange === "last-30-days") {
        rangeDateStart = new Date(
          moment().subtract(30, "days").format("YYYY-MM-DD") + " 00:00:00"
        );
        rangeDateEnd = new Date(moment().format("YYYY-MM-DD") + " 23:59:59");
      }

      let queryUserid = this.userId;
      if (this.manager) {
        queryUserid = this.filterForm.value.userId;
        if (!queryUserid) {
          queryUserid = 0;
        }
      }
      if (!this.toAproved) {
        
        this.requestService
          .searchList(
            "customer",
            queryUserid,
            this.manager,
            this.isMaster
              ? this.filterForm.value.customerId
              : this.activeCompanyService.activeCompanyId,
            this.filterForm.value.segmentId,
            this.filterForm.value.statusId,
            rangeDateStart,
            rangeDateEnd,
            this.filterForm.value.awnserDate,
            this.filterForm.value.requestId,
            this.automatic,
            this.schedulle,
            this.router.url == '/customer/request/list',
            this.filterForm.value.companyBusinessCode ? this.filterForm.value.companyBusinessCode : '',
            this.filterForm.value.companyWorkId
          )
          .then((response: Array<Request>) => {
            this.requestList = response;
            this.requestMap.addList(response);
            this.isRequestApiBusy = false;
            this.utils.finish();
            resolve();
          });
      } else {
        this.requestService
          .GetForAproved(
            this.activeCompanyService.activeCompanyId,
            this.filterForm.value.segmentId,
            this.filterForm.value.requestId,
            this.filterForm.value.userId,
            this.filterForm.value.companyBusinessCode ? this.filterForm.value.companyBusinessCode : '',
            this.filterForm.value.companyWorkId,
            null,
            null
          )
          .then((response: Array<Request>) => {
            this.requestList = response;
            this.requestMap.addList(response);
            this.isRequestApiBusy = false;
            this.utils.finish();
            resolve();
          });
      }
    });
  }

  loadAuxData(): Promise<void> {
    return new Promise((resolve, reject) => {
      const promiseList: Array<Promise<any>> = [];
      promiseList.push(
        new Promise((res, rj) => {
          this.segmentService.getall().then((response) => {
            this.filterFormSegmentIdOptionList.push({
              label: "Todos",
              value: null,
            });
            for (const segment of response) {
              if(this.activeCompanyService.activeCompanyId === environment.evenId){
                this.filterFormSegmentIdOptionList.push({
                  label: segment.segmentEven ? segment.segmentEven: segment.name, 
                  value: segment.segmentId,
                });
              }
              else{
                this.filterFormSegmentIdOptionList.push({
                  label: segment.name,
                  value: segment.segmentId,
                });
              }           
            }
            res(null);
          });
        })
      );
      Promise.all(promiseList).then(() => {
        resolve();
      });
    });
  }

  async loadUserList() {
    this.filterFormUserIdOptionList = [];
    this.filterFormUserIdOptionList.push({
      label: "Todos",
      value: 0,
    });

    let userList: User[];
    userList = await this.userService.listWithOutSegments(this.currentUser.companyId);

    for (const user of userList) {
      this.filterFormUserIdOptionList.push({
        label: user.name,
        value: user.userId,
      });
    };

  }

  getDisplayItemsList(request: Request): string {
    let str = "";
    let index = 0;
    request.requestItems.forEach((item) => {
      if (str.length > 0) {
        if (index === request.requestItems.length - 1) {
          str += " e ";
        } else {
          str += ", ";
        }
      }
      str += item.description + " (" + item.quantity + " " + item.measure + ")";
      index++;
    });
    return str;
  }

  getDisplayProductList(request: Request): string {
    let str = "";
    let index = 0;
    request.requestProducts.forEach((product) => {
      if (str.length > 0) {
        if (index === request.requestProducts.length - 1) {
          str += " e ";
        } else {
          str += ", ";
        }
      }
      str +=
        product.product.name + " (" + product.qtd + " " + product.measure + ")";
      index++;
    });
    return str;
  }

  getDisplayProductText(request: any): string {
    if (request.requestProducts) {
      if (request.requestProducts.length > 0) {
        return this.getDisplayProductList(request);
      }
    }
    if (request.requestItems) {
      if (request.requestItems.length > 0) {
        return this.getDisplayItemsList(request);
      }
    }
    if (request.productDescription) {
      return (
        request.productDescription +
        " (" +
        request.quantity +
        " " +
        request.measure +
        ")"
      );
    } else {
      return "";
    }
  }

  getDisplayProductTotal(request: any): string {
    if (request.requestProducts) {
      if (request.requestProducts.length > 0) {
        return "[" + request.requestProducts.length + " itens ]";
      }
    }
    if (request.requestItems) {
      if (request.requestItems.length > 0) {
        return "[" + request.requestItems.length + " itens ]";
      }
    }
    if (request.productDescription) {
      return "[1 item]";
    } else {
      return "";
    }
  }

  openDateTimeEditOverlayPanel(event: any, request: Request) {
    this.selectedRequestIdToDateTimeEdit = request.requestId;
    setTimeout(() => {
      this.dateTimeEditOverlayPanel.show(event);
      setTimeout(() => {
        this.dateTimeEditInputMask.writeValue("");
        this.dateTimeEditInputMask.focus();
      }, 500);
    }, 250);
  }

  openAdressOverlayPanel(event: any, request: Request) {
    this.selectedRequestToShowAddress = request;
    if (this.addressOverlayPanel.visible) {
      this.addressOverlayPanel.hide();
    }
    setTimeout(() => {
      this.addressOverlayPanel.show(event);
    }, 250);
  }

  openProductOverlayPanel(event: any, request: Request) {
    this.selectedRequestToShowProduct = request;
    if (this.productOverlayPanel.visible) {
      this.productOverlayPanel.hide();
    }
    setTimeout(() => {
      this.productOverlayPanel.show(event);
    }, 250);
  }

  /**
   * Altera a Data limite da requisição
   */
  onDateTimeEditInputMaskComplete() {
    const inputCustomer = this.dateTimeEditInputMask.value;
    const dateAmericanString =
      inputCustomer.substr(3, 2) +
      "/" +
      inputCustomer.substr(0, 2) +
      "/" +
      inputCustomer.substr(6, 4) +
      " " +
      inputCustomer.substr(11, 6);

    const inputDateCustomer = new Date(dateAmericanString);
    if (inputDateCustomer.getDate()) {
      const today = new Date();
      if (today > inputDateCustomer) {
        this.dialogHelperService.showErrorAlert(
          "A data não pode ser no passado",
          "Prazo de Recebimento"
        );
      } else {
        const request = this.requestMap.get(
          this.selectedRequestIdToDateTimeEdit.toString()
        );
        this.dialogHelperService
          .askConfirmation(
            "Alterar data e hora limite da requisição " +
              "#" +
              request.requestId +
              " para " +
              inputCustomer +
              "."
          )
          .then((confirmation) => {
            this.dateTimeEditOverlayPanel.hide();
            if (confirmation) {
              const valueParts = inputCustomer.split(" ");
              request.awnserDate = valueParts[0];
              request.awnserHour = valueParts[1];
              this.requestService.save(request).then(() => {
                this.dialogHelperService.showSuccessNotification(
                  "Data limite alterada com sucesso!"
                );
                this.loadList().then(() => {
                  this.utils.finish();
                });
              });
            }
          });
      }
    } else {
      this.dialogHelperService.showErrorAlert(
        "Informe uma data/hora Válida",
        "Prazo de Recebimento"
      );
    }
  }

  openAtachmentsOverlayPanel(event: any, request: Request) {
    this.selectedRequestToShowAtachments = request;
    if (this.atachmentsOverlayPanel.visible) {
      this.atachmentsOverlayPanel.hide();
    }
    setTimeout(() => {
      this.atachmentsOverlayPanel.show(event);
    }, 250);
  }

  requestIdFilterChanged(e: any){
    var value = e.target.value;

    if(this.requestIdFilterCurrentValue !== value)
    {
      this.requestIdFilterCurrentValue = value;
      this.loadList();
    }
  }
}