import { Injectable, EventEmitter } from '@angular/core';
import { Company } from '../models/Company';
import { ObjectMap } from '../utils/object-map';
import { ArrayHelper } from '../utils/array-helper';

@Injectable({
  providedIn: 'root'
})
export class ActiveCompanyService {

  companyMap: ObjectMap<Company> = new ObjectMap('companyId');
  companyIdList: Array<number>;
  activeCompanyId: number;

  onActiveCompanyChange: EventEmitter<number> = new EventEmitter();

  constructor() { }

  setOptionList(companyList: Array<Company>) {
    this.companyIdList = ArrayHelper.getPropertyList(companyList, 'companyId');
    this.companyMap.addList(companyList);
    if (companyList.length > 0) {
      this.activeCompanyId = companyList[0].companyId;
    }
    this.onActiveCompanyChange.emit(this.activeCompanyId);
  }

  selectCompany(companyId: number) {
    this.activeCompanyId = companyId;
    this.onActiveCompanyChange.emit(companyId);
  }

}
