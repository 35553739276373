import { Component, OnInit } from '@angular/core';
import { ShippingtypeService } from 'src/app/services/shippingtype/shippingtype.service';

@Component({
  selector: 'app-shippingtype',
  templateUrl: './shippingtype.component.html',
  styleUrls: ['./shippingtype.component.css']
})
export class ShippingtypeComponent implements OnInit {
  types: [];
  constructor(private shippingTypeServices: ShippingtypeService) { }

  ngOnInit() {
    this.shippingTypeServices.getall()
      .then((data: []) => this.types = data);
  }

}
