import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user/user.service';
import { UtilsService } from 'src/app/services/core/utils.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { User } from 'src/app/models/User';
import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/Company';
import { Segment } from 'src/app/models/Segment';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { UserSegment } from 'src/app/models/UserSegment';
import { UsersegmentService } from 'src/app/services/usersegment/usersegment.service';
import { ActiveCompanyService } from 'src/app/services/active-company.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subscription } from 'rxjs';
import { Console } from 'console';
import { UserCompany } from 'src/app/models/UserCompany';
import { UserCompanyService } from 'src/app/services/usercompany/usercompany.service';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.css']
})
export class UserRegisterComponent implements OnInit, OnDestroy {
  users: User[];
  companies: Company[] = null;
  user: User;
  userSegment: UserSegment;
  userCompany: UserCompany;
  segments: Segment[];
	companySegments: Segment[];						 
  loginUser: User;
  companyFilter: any;
  showLimite = false;
  subscriptionCompany: Subscription;
  filterForm: FormGroup;

  constructor(
    private title: Title,
    private userService: UserService,
    private ngxSmartModalService: NgxSmartModalService,
    private companyService: CompanyService,
    private segmentService: SegmentService,
    private userSegmentService: UsersegmentService,
    private userCompanyService: UserCompanyService,
    private router: Router,
    private activeCompany: ActiveCompanyService,
    private formBuilder: FormBuilder,
    private utils: UtilsService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.title.setTitle('Cadastro de Usuários | Qisar');
    this.loadPage();
    this.userSegment = new UserSegment();
    this.userCompany = new UserCompany();
    this.subscriptionCompany = this.activeCompany.onActiveCompanyChange.subscribe(() => {
      this.companyFilter = this.activeCompany.activeCompanyId;
      this.loadTable();
    });
  }
  ngOnDestroy(): void {
    if(this.subscriptionCompany){
      this.subscriptionCompany.unsubscribe();
    }
  }

  unlock(user: any) {
    Swal.fire({
      title: 'Qisar',
      type: 'question',
      html: 'Confirma o desbloqueio do usuario?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Confirmo'
    })
      .then((res) => {
        if (res.value) {
          user.loginErrosCount = 0;
          this.userService.save(user);
        }
      });
  }
  /** carrega a pagina */
  loadPage() {
    this.utils.running();
    this.segments = Array<Segment>();
    this.loadLoggedUser()
      .then(async () => {
        this.companyFilter = this.loginUser.companyId;
        this.cleanUser();
        if (this.loginUser.master || this.loginUser.operator) {
          this.loadCompanyList();
        } else {
          this.utils.finish();
          this.loadTable(); 
        }
      });
      
  }
  /** carregar usuario logado */
  loadLoggedUser(): any {
    return this.utils
      .getUserFromSession()
      .then((user: User) => {
        this.loginUser = user;
      });
  }
  /** carregar tabela */
  async loadTable() {
    this.users = [];    
    this.utils.running();
    await this.userService.listWithOutSegments(this.companyFilter)
      .then((users: []) => {
        this.users = users;
        this.utils.finish();
      })
      .catch(() => {
        this.utils.finish();
        Swal.fire('Qisar', 'Não foi carregar a lista de usuários', 'error');
      });
  }
  /** carregar lista de empresas */
  loadCompanyList() {
    this.companyService.list()
      .then(companies => {
        this.companies = companies;
        this.companyFilter = companies[0].companyId;
        this.loadTable();
      });
  }
  ngOnInit() {
    this.filterForm = this.formBuilder.group({
      nickName: null,
      cnpj: null,
      email: null});
  }
  /* Novo Usuário */
  newUser() {
    this.cleanUser();
    this.ngxSmartModalService.getModal('modalUser').open();
  }
  /** Nova Associação de Seguimento */
  newSegmentUser(userId: number) {
    this.segments = [];
    this.clearUserSegment(userId);
    this.segmentService.getNotUserFromUser(userId, this.companyFilter)
      .then((segments: Segment[]) => {

        const segmentAll: Segment = {
          name: "Todos",
          description: "",
          segmentEven: "",
          subSegments: null,
          companySegments: null,
          userSegments: null,
          products: null,
          segmentId: 0
        };

        this.companySegments = segments;
        this.segments = segments;
        if(this.segments[0] != null)
        {
          this.segments.push(segmentAll);
        }       
        
      });
    this.ngxSmartModalService.getModal('modalSegmentUser').open();
  }
  newCompanyUser(userId: number) {
    //this.companies = [];
    // this.clearUserSegment(userId);
    // this.segmentService.getNotUserFromUser(userId, this.companyFilter)
    //   .then((segments: Segment[]) => {		 
    //     this.segments = segments;
    //   });
    this.ngxSmartModalService.getModal('modalCompanyUser').open();
  }
  /** edita um usuario */
  editUser(user: User) {
    this.user = user;
    this.ngxSmartModalService.getModal('modalUser').open();
  }
  /** edita um segmento */
  editSegment(userSegment: UserSegment) {
    this.segments = Array<Segment>();
    this.userSegment = userSegment;
    this.ngxSmartModalService.getModal('modalSegmentUser').open();
  }
  /* Fecha Modal */
  closeUserModal() {
    this.utils.running();
    if (this.user.userId !== 0) {
      this.userService.get(this.user.userId)
        .then((data) => {
          if (data !== this.user) {
            const i = this.users.findIndex(u => u.userId === this.user.userId);
            this.users[i] = data;
          }
          this.utils.finish();
          this.cleanUser();
          this.ngxSmartModalService.getModal('modalUser').close();
        });
    } else {
      this.ngxSmartModalService.getModal('modalUser').close();
      this.utils.finish();
    }
  }
  /* Fecha Modal */
  closeSegmentModal() {
    this.ngxSmartModalService.getModal('modalSegmentUser').close();
  }
  /* Salvar Usuario */
  saveUserModal() {
    this.utils.running();
    this.ngxSmartModalService.getModal('modalUser').close();
    this.userService.save(this.user)
      .then((data: User) => {
        if (this.user.userId === 0) {
          this.users.push(data);
        }
        Swal.fire('Qisar', 'Usuário cadastrado com sucesso', 'success');
        this.utils.finish();
      })
      .catch(() => {
        Swal.fire('Qisar', 'Erro ao cadastrar o usuário', 'error');
        this.utils.finish();
      });
  }
  /* Salvar novo seguimento */
  async saveSegmentModal() {
    this.utils.running();
    this.ngxSmartModalService.getModal('modalSegmentUser').close();

    if(this.userSegment.segmentId === 0){
      for(const segment of this.companySegments) {   
        this.userSegment.userSegmentId = 0;
        this.userSegment.userId = this.userSegment.userId;
        this.userSegment.segmentId = segment.segmentId;
        this.userSegment.companyId = this.userSegment.companyId;
        this.userSegment.maxValue = this.userSegment.maxValue;
        this.userSegment.isCustomer = this.userSegment.isCustomer;
        this.userSegment.isRequestor = this.userSegment.isRequestor;
        this.userSegment.canCreateRequests = this.userSegment.canCreateRequests;
        this.userSegment.segment = null;
        this.userSegment.user = null;
        this.userSegment.company = null;

        await this.userSegmentService.save(this.userSegment);
      }      
      this.utils.finish();
      Swal.fire('Qisar', 'Segmento cadastrado com sucesso', 'success');
      
      window.location.reload();      
    }
    else{
      this.userSegmentService.save(this.userSegment)
      .then((data: UserSegment) => {
        const user = this.users.find(u => u.userId === this.userSegment.userId);
        if (user) {
          user.userSegments = [];
          this.segmentService
            .getFromUser(user.userId)
            .then((segments) => {
              user.userSegments = segments;
              Swal.fire('Qisar', 'Segmento cadastrado com sucesso', 'success');
              this.utils.finish();
            });
        }
      })
      .catch(() => {
        this.loadTable();
        this.utils.finish();
      });
   }
   
  }
  applyCompanyListFilter(): void {
    
    this.utils.running();
    const companyListToDisplay: Array<Company> = [];
    let cnpj = "";
    if (this.filterForm.value.cnpj) {
      cnpj = this.filterForm.value.cnpj
        .toLowerCase()
        .replace(".", "")
        .replace(".", "")
        .replace("/", "")
        .replace("-", "");
    }
    for (const company of this.companies) {
      let addToDisplayList = false;
      if (this.filterForm.value.nickName) {
        if (
          company.nickName
            .toLowerCase()
            .indexOf(this.filterForm.value.nickName.toLowerCase()) >= 0
        ) {
          addToDisplayList = true;
        }
      } else if (this.filterForm.value.email) {
        addToDisplayList = false;
        for (const user of company.users) {
          if (user.email.indexOf(this.filterForm.value.email) >= 0) {
            addToDisplayList = true;
          }
        }
      } else if (cnpj !== "") {
        if (company.cnpj.toLowerCase().indexOf(cnpj) >= 0) {
          addToDisplayList = true;
        } 
      } else {
        addToDisplayList = true;
      }    
      if (addToDisplayList) {
        if (this.filterForm.value.profileId) {
          const profileId = this.filterForm.value.profileId;
          if (profileId === 1 && company.isCustomer) {
            companyListToDisplay.push(company);
          } else if (profileId === 2 && company.isRequestor) {
            companyListToDisplay.push(company);
          }
        } else {
          companyListToDisplay.push(company);
        }
      }
    }
    this.setSelectedCompanyFilter(companyListToDisplay[0].companyId);
    //this.companyListToDisplay = companyListToDisplay;
    this.utils.finish();
  }
  async saveCompanyModal() {
    this.utils.running();
    this.ngxSmartModalService.getModal('modalCompanyUser').close();
    this.userCompanyService.save(this.userCompany)
    .then((data: UserCompany) => {
      const user = this.users.find(u => u.userId === this.userCompany.userId);
      if (user) {
        user.userCompanies = [];
        this.userCompanyService
          .getByUserId(user.userId)
          .then((companies) => {
            user.userCompanies = companies;
            Swal.fire('Qisar', 'Empresa cadastrada com sucesso', 'success');
            this.utils.finish();
          });
      }
    })
    .catch(() => {
      this.loadTable();
      this.utils.finish();
    });
   
  }
  /** usuario novo */
  cleanUser() {
    this.user = new User();
    this.user.userId = 0;
    this.user.actived = true;
    this.user.avatar = '';
    this.user.creationDate = new Date();
    this.user.email = '';
    this.user.master = false;
    this.user.operator = false;
    this.user.password = '';
    this.user.useTerms = false;
    this.user.name = '';
    this.user.companyId = this.companyFilter;

  }

  setSelectedCompany(companyId: any) {
    this.user.companyId = companyId;
    this.companyFilter = companyId;
  }

  setSelectedCompanyFilter(companyId: any) {
    this.user.companyId = companyId;
    this.companyFilter = companyId;
    this.loadTable();
  }

  /** associação nova */
  clearUserSegment(userId: number) {
    this.userSegment = new UserSegment();
    this.userSegment.userSegmentId = 0;
    this.userSegment.segmentId = 0;
    this.userSegment.maxValue = 0;
    this.userSegment.isCustomer = false;
    this.userSegment.isRequestor = false;
    this.userSegment.canCreateRequests = false;
    this.userSegment.userId = userId;
    this.userSegment.companyId = this.companyFilter;
  }
  /** remove o segmento do usuario */
  removeUserSegment(userSegment: UserSegment) {
    Swal.fire({
      title: 'Qisar',
      type: 'question',
      html:
        'Confirma a exclusão da associação?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmo'
    })
      .then((result) => {
        if (result.value) {
          const userId = userSegment.userId;
          this.utils.running();
          this.userSegmentService.delete(userSegment.userSegmentId)
            .then(() => {
              const user = this.users.find(u => u.userId === userId);
              if (user) {
                user.userSegments = [];
                this.segmentService
                  .getFromUser(user.userId)
                  .then((segments) => {
                    user.userSegments = segments;
                  });
              }
              this.utils.finish();
            })
            .catch(() => {
              this.loadPage();
              this.utils.finish();
            });
        }
      });
  }
  /** remove a company do usuario */
  removeUserCompany(userCompany: UserCompany) {
    Swal.fire({
      title: 'Qisar',
      type: 'question',
      html:
        'Confirma a exclusão da associação?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmo'
    })
      .then((result) => {
        if (result.value) {
          const userId = userCompany.userId;
          this.utils.running();
          this.userCompanyService.delete(userCompany.userCompanyId)
            .then(() => {
              const user = this.users.find(u => u.userId === userId);
              if (user) {
                user.userCompanies = [];
                this.userCompanyService
                  .getByUserId(user.userId)
                  .then((_userCompanies) => {
                    user.userCompanies = _userCompanies;
                  });
              }
              this.utils.finish();
            })
            .catch(() => {
              this.loadPage();
              this.utils.finish();
            });
        }
      });
  }
  validEmail() {
    // tslint:disable-next-line: max-line-length
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!regexp.test(this.user.email)) {
      Swal.fire('Qisar', 'Ops, E-mail Inválido', 'error');
      this.user.email = '';
    }
  }
  /** mostra linda de segmentos */
  toggleLine(user: User) {
    const line = document.getElementById('segments-' + user.userId);
    if (line.className === 'disable-line') {
      line.className = '';
    } else {
      line.className = 'disable-line';
    }

    this.utils.running();
    this.segmentService.getFromUser(user.userId)
      .then(res => {
        user.userSegments = res;
        return this.segmentService.getNotUserFromUser(user.userId, this.companyFilter);
      })
      .then((segments: Segment[]) => {
        this.segments = segments;
        this.utils.finish();
      });
  }

  resetPassword(user: User) {
    Swal.fire({
      title: 'Qisar',
      type: 'question',
      html:
        'Deseja resetar a senha do usuário ' + user.name + '?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar'
    })
      .then((reset) => {
        if (reset.value) {
          this.userService.lostPassword(user);
          Swal.fire({
            title: 'Qisar',
            type: 'info',
            html:
              'A nova senha foi enviada para o email ' + user.email,
          });
        }
      });
  }
  editCompanyUser(user: User){
    const line = document.getElementById('companies-' + user.userId);
    if (line.className === 'disable-line') {
      line.className = '';
    } else {
      line.className = 'disable-line';
    }

    this.utils.running();
    this.userCompany.userId = user.userId;
    this.userCompanyService.getByUserId(user.userId)
      .then(res => {
        user.userCompanies = res;
        //return this.segmentService.getNotUserFromUser(user.userId, this.companyFilter);
      })
      .then((companies: Company[]) => {
        //this.companies = companies;
        this.utils.finish();
      });  
  }
}


