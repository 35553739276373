import { CompanySegment } from './CompanySegment';
import { SubSegment } from './SubSegment';
import { UserSegment } from './UserSegment';
import { Product } from './Product';

export class Segment {
    public segmentId: number;
    public name: string;
    public description: string;
    public segmentEven: string;
    public subSegments: SubSegment[];
    public companySegments: CompanySegment[];
    public userSegments: UserSegment[];
    public products: Product[];
}
