import { Injectable } from '@angular/core';
import { RequestItemApi } from 'src/app/core/http/entidades/RequestItemApi';
import { RequestItem } from 'src/app/models/RequestItem';

@Injectable({
  providedIn: 'root'
})
export class RequestitemService {

  constructor(private api: RequestItemApi) {
    this.api.baseName = 'requestitem';
   }

   save(item: RequestItem): any {
    return new Promise((resolve, reject) => {
      // se segmento novo, insere
      if (item.requestItemId === 0) {
        this.api.post(item)
          .then((data: RequestItem) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(item)
          .then((data: RequestItem) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
}
