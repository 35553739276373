import { AbstractControl } from "@angular/forms";
import * as cpf_cnpj from "cpf_cnpj";
import * as moment from 'moment';

export class CustomValidators {

  public static cnpj(control: AbstractControl) {
    if (!control.value || control.value == "") {
      return null
    }
    if (!cpf_cnpj.CNPJ.isValid(control.value)) {
      return {
        cnpj: true
      }
    }
    return null
  }

  public static cpf(control: AbstractControl) {
    if (!control.value || control.value == "") {
      return null
    }
    if (!cpf_cnpj.CPF.isValid(control.value)) {
      return {
        cpf: true
      }
    }
    return null
  }

  public static maxDate(maxDate: Date) {
    return (control: AbstractControl) => {
      if (!maxDate) {
        return null;
      }
      if (control.value > maxDate) {
        return {
          maxDate: maxDate
        }
      }
      return null;
    }
  }

  public static minDate(minDate: Date) {
    return (control: AbstractControl) => {
      if (!minDate) {
        return null;
      }
      let controlDate: Date = new Date(moment(control.value).format("YYYY-MM-DD")+" 00:00:00");
      minDate = new Date(moment(minDate).format("YYYY-MM-DD")+" 00:00:00");
      if (controlDate < minDate) {
        return {
          minDate: minDate
        }
      }
      return null;
    }
  }

  public static emailOrNull(control: AbstractControl) {
    if (control.value && control.value != "") {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(control.value).toLowerCase())) {
        return {
          email: true
        }
      }
    }
    return null
  }

}