import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-picker-and-cropper',
  templateUrl: './image-picker-and-cropper.component.html',
  styleUrls: ['./image-picker-and-cropper.component.css']
})
export class ImagePickerAndCropperComponent implements OnInit {

  @ViewChild("input") input: ElementRef;

  @Output() onImageChange: EventEmitter<string> = new EventEmitter();

  @Input() initialImageUrl: string = null;
  @Input() aspectRatio: number = 1;
  @Input() showInline: boolean = true;

  imageChangedEvent: any;
  croppedImage: string;
  finalImage: string;

  format: string = "png";


  constructor(
    private ngxSmartModalService: NgxSmartModalService,
  ) { }

  ngOnInit() {
  }

  selectImage() {
    this.input.nativeElement.click();
  }

  onImageSelection($event) {
    this.imageChangedEvent = $event;
    this.ngxSmartModalService.getModal("app-image-picker-and-cropper-cropper").open();
  }

  onImageCropped($event: ImageCroppedEvent) {
    this.croppedImage = $event.base64;
  }

  onComplete() {
    this.ngxSmartModalService.getModal("app-image-picker-and-cropper-cropper").close();
    this.finalImage = this.croppedImage;
    this.onImageChange.emit(this.croppedImage);
  }

  openImageModal(): void {
    this.ngxSmartModalService.getModal("app-image-picker-and-cropper-preview").open();
  }

}
