import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TownhouseService } from 'src/app/services/townhouse/townhouse.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Townhouse } from 'src/app/models/Townhouse';
import { SelectItem } from 'primeng/api';
import { TownhouseCalendarService } from 'src/app/services/townhousecalendar/townhousecalendar.service';
import { TownhouseCalendar } from 'src/app/models/TownhouseCalendar';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  templateUrl: './covid.component.html',
  styleUrls: ['./covid.component.css'],
  selector: 'app-covid',
  encapsulation: ViewEncapsulation.None
})
export class CovidComponent implements OnInit {
  form: FormGroup;
  calendar: TownhouseCalendar[];
  t: TownhouseCalendar[];
  townhouses: Array<SelectItem> = [];
  days: Array<SelectItem> = [];
  hours: Array<SelectItem> = [];

  constructor(
    private townhouseService: TownhouseService,
    private townhouseCalendarService: TownhouseCalendarService,
    private formBuilder: FormBuilder,
    private router: Router,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      townhouseId: [null, Validators.compose([Validators.required])],
      name: [null, Validators.compose([Validators.required])],
      day: [null, Validators.compose([Validators.required])],
      hour: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required])],
      cpf: [null, Validators.compose([Validators.required])],
      phone: [null, Validators.compose([Validators.required])],
      unidade: [null],
    });

    this
      .townhouseService
      .getAll()
      .then((data: Townhouse[]) => {
        data.forEach(townhouse => {
          if (townhouse.actived) {
            this.townhouses.push({
              label: townhouse.name,
              value: townhouse.townhouseId
            });
          }
        });
      })
      .catch(err => console.log(err));
  }

  save() {
    const form = this.form.value;
    this.update(form);
  }

  getCalendarFromTownhouse() {
    const id = this.form.value.townhouseId;
    this
      .townhouseCalendarService
      .getBybyTownhouse(id)
      .then((data: TownhouseCalendar[]) => {
        this.t = data;
        this.calendar = data;
        this.formatCalendar(data);
        this.getHours();
      });
  }

  formatCalendar(data: TownhouseCalendar[]) {
    this.days = [];
    this.hours = [];
    this.days.push({
      value: null,
      label: ''
    });

    data.forEach(item => {
      if (this.days.findIndex(element => element.value === item.day) === -1) {
        this.days.push({
          value: item.day,
          label: this.datepipe.transform(item.day, 'dd/MM/yyyy')
        });
      }
    });
  }

  getHours() {
    this.hours = [];
    this.hours.push({
      value: null,
      label: ''
    });
    this.t.forEach(item => {
      if (!item.email) {
        if (item.day === this.form.value.day) {
          this.hours.push({
            value: item.hour,
            label: item.hour
          });
        }
      }
    });
  }


  update(calendar: any) {
    this.t.forEach(item => {
      if (item.townhouseId === calendar.townhouseId) {
        if (item.day === calendar.day) {
          if (item.hour === calendar.hour) {
            if (!item.email) {
              calendar.townhouseCalendarId = item.townhouseCalendarId;
              this
                .townhouseCalendarService
                .update(calendar)
                .then(() => {
                  Swal.fire('Calendario de Exames', 'Seu exame foi agendado com sucesso', 'success')
                    .then(() => {
                      window.location.href = '/';
                    });
                })
                .catch(err => console.log(err));
            }
          }
        }
      }
    });
  }
}
