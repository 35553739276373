import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CompanyService } from 'src/app/services/company/company.service';

@Component({
  selector: 'app-summit',
  templateUrl: './summit.component.html',
  styleUrls: ['./summit.component.css']
})
export class SummitComponent implements OnInit {

  constructor(private companyService: CompanyService, private title: Title) {
    this.title.setTitle('Fornecedores Summit | Qisar');
  }
  companies: any;
  ngOnInit() {
    this.companyService.getSummit()
    .then((data) => { this.companies = data; });
  }

}
