import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NotificationService } from 'src/app/services/notification.service';
import { Lead } from 'src/app/models/Lead';
import {LeadService} from 'src/app/services/lead/lead.service'
import { User } from 'src/app/models/User';
import { UtilsService } from 'src/app/services/core/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {
  @Input() User: any;
  @Input() IsOnline: any;
  @Output() ontoogleMenu: EventEmitter<void> = new EventEmitter();
  @Output() onlogout: EventEmitter<void> = new EventEmitter();

  form: FormGroup;

  qisarTop = 'qisarTop'
  colorTitle = 'color-title';
  imagemLogo = '/assets/images/logo';
  buttonPrimary = 'btn-primary';
  currentUser: User;

  seeButtonLead = false;

  modalCss = '';


  @ViewChild('topMenu')
  private topMenu: ElementRef;

  @ViewChild('topNotification')
  private topNotification: ElementRef;

  @ViewChild('topProposals')
  private topProposals: ElementRef;

  @ViewChild('topRequests')
  private topRequests: ElementRef;

  @ViewChild('topSales')
  private topSales: ElementRef;

  @ViewChild('topCancelRequests')
  private topCancelRequests: ElementRef;

  @ViewChild('topDeclineRequests')
  private topDeclineRequests: ElementRef;

  constructor(
    public notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private ngxSmartModalService: NgxSmartModalService,
    private utils: UtilsService,
    private LeadService: LeadService
  ) {
    this.notificationService.start();

    if (sessionStorage.getItem('whitelabel')) {
      this.qisarTop = this.qisarTop + sessionStorage.getItem('whitelabel');
      this.colorTitle = this.colorTitle + sessionStorage.getItem('whitelabel');
      this.imagemLogo = this.imagemLogo + '-' + sessionStorage.getItem('whitelabel') + '.png';
      this.buttonPrimary += sessionStorage.getItem('whitelabel');
      this.modalCss = 'modal-' + sessionStorage.getItem('whitelabel');

      if (sessionStorage.getItem('whitelabel') == 'pagseguro') {
        this.seeButtonLead = true;
      }

    } else {
      this.imagemLogo = this.imagemLogo + '.png';
    }
  }

  lead() {
    this.ngxSmartModalService.getModal("modalSeller").open();
  }

  saveLead() {
    if(this.form.valid) {

      const lead: Lead = {
        leadId: 0,
        userId: this.currentUser.userId,
        email: this.form.controls.email.value
      }

      console.log(lead);

      this
      .LeadService
      .save(lead)
      .then(() => {
          Swal.fire('Qisar', 'Indicação criada com sucesso', 'info');
      })
      .catch(() => Swal.fire('Qisar', 'Erro ao criar indicação', 'error'));

      this.form.reset();
      this.ngxSmartModalService.getModal("modalSeller").close();
    }
  }

  /** mostras notificações */
  showDecline() {
    this.hideUserMenu();
    this.hideProposals();
    this.hideRequests();
    this.hideSales();
    this.hideCancelRequest();
    this.hideNotifications();
    this.topDeclineRequests.nativeElement.className = 'top-notify-active';
  }

  /** mostras notificações */
  showNotifications() {
    this.hideUserMenu();
    this.hideProposals();
    this.hideRequests();
    this.hideSales();
    this.hideCancelRequest();
    this.hideDeclineRequest();
    this.topNotification.nativeElement.className = 'top-notify-active';
  }

  openWhats() {
    window.open('https://api.whatsapp.com/send?phone=5511967702962', '_blank');
  }

  /** mostras notificações */
  showProposals() {
    this.hideUserMenu();
    this.hideNotifications();
    this.hideRequests();
    this.hideSales();
    this.hideCancelRequest();
    this.hideDeclineRequest();
    this.topProposals.nativeElement.className = 'top-notify-active';
  }

  /** mostras notificações */
  showRequests() {
    this.hideUserMenu();
    this.hideNotifications();
    this.hideProposals();
    this.hideSales();
    this.hideCancelRequest();
    this.hideDeclineRequest();
    this.topRequests.nativeElement.className = 'top-notify-active';
  }

  /** mostras notificações */
  showCancelRequests() {
    this.hideUserMenu();
    this.hideNotifications();
    this.hideRequests();
    this.hideProposals();
    this.hideSales();
    this.hideDeclineRequest();
    this.topCancelRequests.nativeElement.className = 'top-notify-active';
  }

  /** Ocultar itens do menu notificação */
  hideDeclineRequest() {
    this.topDeclineRequests.nativeElement.className = 'top-notify';
  }

  /** Ocultar itens do menu notificação */
  hideCancelRequest() {
    this.topCancelRequests.nativeElement.className = 'top-notify';
  }
  /** Ocultar itens do menu notificação */
  hideNotifications() {
    this.topNotification.nativeElement.className = 'top-notify';
  }

  /** Ocultar itens do menu notificação */
  hideProposals() {
    this.topProposals.nativeElement.className = 'top-notify';
  }

  /** Ocultar itens do menu notificação */
  hideRequests() {
    this.topRequests.nativeElement.className = 'top-notify';
  }

  /** Ocultar itens do menu notificação */
  hideSales() {
    this.topSales.nativeElement.className = 'top-notify';
  }

  /** sair do sistema */
  logout() {
    this.onlogout.emit();
  }

  toogleMenu() {
    this.ontoogleMenu.emit();
  }
  /** Mostrar itens do menu usuario */
  showUserMenu() {
    this.hideNotifications();
    this.hideProposals();
    this.hideSales();
    this.hideRequests();
    this.hideCancelRequest();
    this.hideDeclineRequest();
    this.topMenu.nativeElement.className = 'top-menu-active';
  }
  showSales() {
    this.hideUserMenu();
    this.hideNotifications();
    this.hideProposals();
    this.hideRequests();
    this.hideCancelRequest();
    this.hideDeclineRequest();
    this.topSales.nativeElement.className = 'top-notify-active';
  }
  /** Ocultar itens do menu usuario */
  hideUserMenu() {
    this.topMenu.nativeElement.className = 'top-menu';
  }

  /** Fechar todos os menus */
  hideAllMenus() {
    this.hideUserMenu();
    this.hideNotifications();
    this.hideProposals();
    this.hideRequests();
    this.hideSales();
    this.hideCancelRequest();
    this.hideDeclineRequest();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: [null, Validators.compose([
        Validators.email,
        Validators.required
      ])]
    })

    this.utils.getUserFromSession().then((user: User) => {
      this.currentUser = user;
    })
  }

}
