import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company/company.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { UtilsService } from 'src/app/services/core/utils.service';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit {

  filterForm: FormGroup;

  constructor(
    private customerService: CompanyService,
    private title: Title,
    private util: UtilsService,
    private formBuilder: FormBuilder
  ) { }
  customers = [];
  companyPlans = null;

  ngOnInit() {
    this.title.setTitle('Fornecedores | Qisar');
    this.filterForm = this.formBuilder.group({
      customerId: null
    });

    this.loadCustomers();
  }
  applyCompanyListFilter() {
    this.util.running();
    this.customerService
      .getCompanyPlansByCustomerId(this.filterForm.value.customerId)
      .then((plans) => {
        if (plans.length > 0) {
          this.companyPlans = plans;
        } else {
          this.companyPlans = null;
        }
        this.util.finish();
      }).catch(() => this.companyPlans = null);
  }

  download() {
    this.util.running();
    const headerLine = [
      'Controle Interno',
      'Id do Fornecedor',
      'Nome do Fornecedor',
      'Id do Comprador',
      'Nome do Comprador',
      'Data do Plano',
      'Valor'
    ];
    const replacer = (key, value) => value === null ? '' : value;
    const header = Object.keys(this.companyPlans[0]);
    const csv = this.companyPlans.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
    csv.unshift(headerLine.join(';'));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob(['\ufeff', csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'relatorio.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.util.finish();
  }
  loadCustomers() {
    this.customers = [];
    this.customerService.getallcustomers()
      .then((customers) => {
        customers.forEach(customer => {
          this.customers.push({
            label: customer.name,
            value: customer.companyId
          });
        });
      });
  }
}
