import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Request } from 'src/app/models/Request';
import { User } from 'src/app/models/User';
import { UtilsService } from 'src/app/services/core/utils.service';
import { RequestService } from 'src/app/services/request/request.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogHelperService } from 'src/app/services/dialog-helper.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { OverlayPanel } from 'primeng/overlaypanel';
import { InputMask } from 'primeng/inputmask';
import { ObjectMap } from 'src/app/utils/object-map';
import { ActiveCompanyService } from 'src/app/services/active-company.service';

@Component({
  selector: 'app-request-page',
  templateUrl: './request-page.component.html',
  styleUrls: ['./request-page.component.css']
})
export class RequestPageComponent implements OnInit, OnDestroy {

  @ViewChild('dateTimeEditOverlayPanel') dateTimeEditOverlayPanel: OverlayPanel;
  @ViewChild('addressOverlayPanel') addressOverlayPanel: OverlayPanel;
  @ViewChild('productOverlayPanel') productOverlayPanel: OverlayPanel;
  @ViewChild('dateTimeEditInputMask') dateTimeEditInputMask: InputMask;
  @ViewChild('atachmentsOverlayPanel') atachmentsOverlayPanel: OverlayPanel;

  currentUser: User;

  requestId: number;
  companyId: number;
  request: any;
  requestList: Array<Request>;
  expandedRowKeys: any = {};

  requestMap: ObjectMap<Request> = new ObjectMap('requestId');

  subscription: Subscription;
  subscriptionCompany: Subscription;

  dateTimeEditValue = '';
  selectedRequestIdToDateTimeEdit: number = null;
  selectedRequestToShowAddress: Request = null;
  selectedRequestToShowAtachments: Request = null;
  selectedRequestToShowProduct: Request = null;

  statusColorMap = {
    1: '#ffc107',
    2: '#b71c1c',
    3: '#64dd17',
  };

  constructor(
    private utilsService: UtilsService,
    private requestService: RequestService,
    private activatedRoute: ActivatedRoute,
    private dialogHelperService: DialogHelperService,
    private title: Title,
    private activeCompanyService: ActiveCompanyService
  ) { }

  ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe(params => {
      this.requestId = params.requestId;
      this.title.setTitle('Requisição #' + this.requestId + ' | ' + environment.appTitle);
      this.utilsService.getUserFromSession().then(user => {
        this.currentUser = user;
        this.loadRequest().then(() => {

        });
      });
    });
    this.subscriptionCompany = this.activeCompanyService.onActiveCompanyChange.subscribe(() => {
      this.companyId = this.activeCompanyService.activeCompanyId;
      this.loadRequest().then(() => {
      });
    });
  }

  loadRequest(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.companyId = this.activeCompanyService.activeCompanyId;
      this.requestService.get(this.requestId).then(request => {
        this.request = request;
        this.expandedRowKeys[this.requestId] = true;
        this.requestList = [];
        this.requestList.push(request);
        resolve();
      });
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if(this.subscriptionCompany){
      this.subscriptionCompany.unsubscribe();
    }
  }

  getDisplayProductList(request: Request): string {
    let str = '';
    let index = 0;
    request.requestProducts.forEach(product => {
      if (str.length > 0) {
        if (index === request.requestProducts.length - 1) {
          str += ' e ';
        } else {
          str += ', ';
        }
      }
      str += product.product.name + ' (' + product.qtd + ' ' + product.measure + ')';
      index++;
    });
    return str;
  }

  openDateTimeEditOverlayPanel(event: any, request: Request) {
    this.selectedRequestIdToDateTimeEdit = request.requestId;
    if (this.dateTimeEditOverlayPanel.visible) {
      this.dateTimeEditOverlayPanel.hide();
      setTimeout(() => {
        this.dateTimeEditOverlayPanel.show(event);
        setTimeout(() => {
          this.dateTimeEditInputMask.writeValue('');
          this.dateTimeEditInputMask.focus();
        }, 500);
      }, 250);
    }
  }

  openAdressOverlayPanel(event: any, request: Request) {
    this.selectedRequestToShowAddress = request;
    if (this.addressOverlayPanel.visible) {
      this.addressOverlayPanel.hide();
    }
    setTimeout(() => {
      this.addressOverlayPanel.show(event);
    }, 250);
  }


  onDateTimeEditInputMaskComplete() {
    const inputCustomer = this.dateTimeEditInputMask.value;
    const dateAmericanString = inputCustomer.substr(3, 2) + '/' +
      inputCustomer.substr(0, 2) + '/' +
      inputCustomer.substr(6, 4) + ' ' +
      inputCustomer.substr(11, 6);

    const inputDateCustomer = new Date(dateAmericanString);
    if (inputDateCustomer.getDate()) {
      const today = new Date();
      if (today > inputDateCustomer) {
        this.dialogHelperService.showErrorAlert('A data não pode ser no passado', 'Prazo de Recebimento');
      } else {
        const request = this.requestMap.get(this.selectedRequestIdToDateTimeEdit.toString());
        this.dialogHelperService.askConfirmation('Alterar data e hora limite da requisição '
          + '#' + request.requestId
          + ' para ' + inputCustomer + '.').then(confirmation => {
            this.dateTimeEditOverlayPanel.hide();
            if (confirmation) {
              const valueParts = inputCustomer.split(' ');
              request.awnserDate = valueParts[0];
              request.awnserHour = valueParts[1];
              this.requestService.save(request).then(() => {
                this.dialogHelperService.showSuccessNotification('Data limite alterada com sucesso!');
                this.loadRequest().then();
              });
            }
          });
      }
    } else {
      this.dialogHelperService.showErrorAlert('Informe uma data/hora Válida', 'Prazo de Recebimento');
    }
  }

  openAtachmentsOverlayPanel(event: any, request: Request) {
    this.selectedRequestToShowAtachments = request;

    if (this.atachmentsOverlayPanel.visible) {
      this.atachmentsOverlayPanel.hide();
    }
    setTimeout(() => {
      this.atachmentsOverlayPanel.show(event);
    }, 250);
  }

  openProductOverlayPanel(event: any, request: Request) {
    this.selectedRequestToShowProduct = request;
    if (this.productOverlayPanel.visible) {
      this.productOverlayPanel.hide();
    }
    setTimeout(() => {
      this.productOverlayPanel.show(event);
    }, 250);
  }

  getDisplayProductText(request: Request): string {
    if (request.requestProducts) {
      if (request.requestProducts.length > 0) {
        return this.getDisplayProductList(request);
      }
    }
    if (request.requestItems) {
      if (request.requestItems.length > 0) {
        return this.getDisplayItemsList(request);
      }
    }
    if (request.productDescription) {
      return request.productDescription + ' (' + request.quantity + ' ' + request.measure + ')';
    } else {
      return '';
    }
  }

  getDisplayItemsList(request: Request): string {
    let str = '';
    let index = 0;
    request.requestItems.forEach(item => {
      if (str.length > 0) {
        if (index === request.requestItems.length - 1) {
          str += ' e ';
        } else {
          str += ', ';
        }
      }
      str += item.description + ' (' + item.quantity + ' ' + item.measure + ')';
      index++;
    });
    return str;
  }

}
