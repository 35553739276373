import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UtilsService } from 'src/app/services/core/utils.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DialogHelperService } from 'src/app/services/dialog-helper.service';
import { ActiveCompanyService } from 'src/app/services/active-company.service';
import * as moment from 'moment';
import { PrepoposalService } from 'src/app/services/prerequest/prepoposal.service';
import { PrepoposalrequestitemService } from 'src/app/services/prerequest/prepoposalrequestitem.service';
import { SelectItem } from 'primeng/api';
import { ObjectMap } from 'src/app/utils/object-map';
import { ArrayHelper } from 'src/app/utils/array-helper';
import { AddressService } from 'src/app/services/address/address.service';
import { PrerequestService } from 'src/app/services/prerequest/prerequest.service';

@Component({
  selector: 'app-customer-prerequest-datail-view',
  templateUrl: './customer-prerequest-datail-view.component.html',
  styleUrls: ['./customer-prerequest-datail-view.component.css']
})
export class CustomerPrerequestDatailViewComponent implements OnInit {

  @Input() request: any;
  @Input() requestId: number;
  @Input() requestUserId: number;

  @Input() requestNoTaxes = false;
  @Input() creator = false;

  @Output() onbuyProposal = new EventEmitter<number>();

  proposalForm: FormGroup;
  form: FormGroup;
  isProposalSaving = false;
  totalProposalSaveSteps: number;
  completedProposalSaveSteps: number;
  itemsCost = 0;
  haveItems = false;
  requestDateLimit: Date;
  proposalModal: any;
  currentUser: any;
  minDate = new Date();
  addressOptionList: Array<SelectItem> = [];
  addressMap: ObjectMap<any> = new ObjectMap('addressId');

  timeOptionList: Array<SelectItem> = [
    {
      label: '',
      value: null
    },
    {
      label: '00:00',
      value: '00:00:00'
    },
    {
      label: '01:00',
      value: '01:00:00'
    },
    {
      label: '02:00',
      value: '02:00:00'
    },
    {
      label: '03:00',
      value: '03:00:00'
    },
    {
      label: '04:00',
      value: '04:00:00'
    },
    {
      label: '05:00',
      value: '05:00:00'
    },
    {
      label: '06:00',
      value: '06:00:00'
    },
    {
      label: '07:00',
      value: '07:00:00'
    },
    {
      label: '08:00',
      value: '08:00:00'
    },
    {
      label: '09:00',
      value: '09:00:00'
    },
    {
      label: '10:00',
      value: '10:00:00'
    },
    {
      label: '11:00',
      value: '11:00:00'
    },
    {
      label: '12:00',
      value: '12:00:00'
    },
    {
      label: '13:00',
      value: '13:00:00'
    },
    {
      label: '14:00',
      value: '14:00:00'
    },
    {
      label: '15:00',
      value: '15:00:00'
    },
    {
      label: '16:00',
      value: '16:00:00'
    },
    {
      label: '17:00',
      value: '17:00:00'
    },
    {
      label: '18:00',
      value: '18:00:00'
    },
    {
      label: '19:00',
      value: '19:00:00'
    },
    {
      label: '20:00',
      value: '20:00:00'
    },
    {
      label: '21:00',
      value: '21:00:00'
    },
    {
      label: '22:00',
      value: '22:00:00'
    },
    {
      label: '23:00',
      value: '23:00:00'
    },
  ];

  pt = {
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'eg', 'ter', 'qua', 'qui', 'sex', 'sab'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Feveiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
  };


  constructor(
    private utils: UtilsService,
    private ngxSmartModalService: NgxSmartModalService,
    private addressService: AddressService,
    private formBuilder: FormBuilder,
    private dialogHelperService: DialogHelperService,
    private requestService: PrerequestService,
    private proposalService: PrepoposalService,
    private proposalRequestItemService: PrepoposalrequestitemService,
    private activeCompanyService: ActiveCompanyService
  ) { }


  reloadRequest() {
    this.requestService.get(this.requestId)
      .then(request => {
        this.request = request;
        this.loadPage();
      });
  }

  getNow(): Date {
    return new Date();
  }

  loadPage() {
    this.addressService.findByCompany(this.activeCompanyService.activeCompanyId).then(response => {
      this.addressOptionList = ArrayHelper.toOptionList(response, 'addressId', 'name');
      this.addressMap.addList(response);
    });

    this.form = this.formBuilder.group({
      awnserDate: [null, Validators.compose([Validators.required])],
      awnserHour: [null, Validators.compose([Validators.required])]
    });
    if (!this.requestNoTaxes) {
      this.requestNoTaxes = false;
    }
    if (this.request.preRequestItems) {
      if (this.request.preRequestItems.length > 0) {
        this.haveItems = true;
      }
    }

    if (this.haveItems) {

      this.proposalForm = this.formBuilder.group({
        addressId: [null, Validators.compose([Validators.required])],
        conditions: [null, Validators.compose([Validators.required])]
      });
      this.request.preRequestItems.forEach(item => {
        this.proposalForm.addControl('un_' + item.preRequestItemId.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])));
      });
    } else {
      this.proposalForm = this.formBuilder.group({
        addressId: [null, Validators.compose([Validators.required])],
        conditions: [null, Validators.compose([Validators.required])],
        quantity: [null, Validators.compose([Validators.required, Validators.min(0.01)])]
      });
    }


    this.requestDateLimit = moment(this.request.awnserDate + ' ' + this.request.awnserHour, 'DD/MM/YYYY HH:mm:ss').toDate();
    this.utils.getUserFromSession().then(response => {
      this.currentUser = response;
    });
  }

  onFormAddressIdChange() {
    this.proposalForm.controls.conditions.setValue(null);
    const addressId = this.proposalForm.value.addressId;
    if (addressId) {
      const address = this.addressMap.get(addressId.toString());
      this.proposalForm.controls.conditions.setValue(address.deliveryInstruction);
    }
  }

  companyPrincipalItemValue(requestItemId, items): any {
    if (items) {
      const item = items.find(p => p.preRequestItemId === requestItemId);
      if (item) {
        return item.quantity;
      } else {
        return '';
      }
    }
  }

  companyItemValue(requestItemId, proposal): any {
    if (proposal) {
      const item = proposal.find(p => p.preRequestItemId === requestItemId);
      if (item) {
        return item.quantity;
      } else {
        return '';
      }
    }
  }


  openRequestModal() {
    this.form.reset();
    this.ngxSmartModalService.getModal('provider-torequest').open();
  }

  ToRequest() {
    if (this.form.valid) {
      this.dialogHelperService.askConfirmation('Deseja criar a requisicao?')
        .then(confirm => {
          if (confirm) {
            this.ngxSmartModalService.close('provider-torequest');
            this.utils.running();
            this.request.awnserDate = moment(this.form.value.awnserDate).format('DD/MM/YYYY');
            this.request.awnserHour = this.form.value.awnserHour;
            this.requestService.ToRequest(this.request).
              then((data: any) => {
                this.dialogHelperService.showSuccessNotification('Requisição ' + data.requestId + ' criada com sucesso');
                this.onbuyProposal.emit(this.request.preRequestId);
                this.utils.finish();
              });
          }
        });
    }
  }

  inValidRequest(): boolean {
    return !this.form.valid;
  }

  ngOnInit() {
    this.loadPage();
  }
  openProposalModal() {
    this.proposalForm.reset();
    this.ngxSmartModalService.getModal('provider-request-detail-view-proposal').open();
  }
  saveProposal() {
    if (this.proposalForm.valid) {
      this.dialogHelperService.askConfirmation('Você quer mesmo participar dessa compra coletiva?')
        .then(confirmation => {
          if (confirmation) {

            const proposal = {
              preProposalId: 0,
              companyId: this.request.companyId,
              preRequestId: this.requestId,
              preChatId: null,
              quantity: this.haveItems ? 0 : this.proposalForm.value.quantity,
              conditions: this.proposalForm.value.conditions,
              date: new Date(),
              addressId: this.proposalForm.value.addressId
            };
            if (this.activeCompanyService.activeCompanyId) {
              proposal.companyId = this.activeCompanyService.activeCompanyId;
            }
            this.proposalService.save(proposal)
              .then((response: any) => {
                const proposalId = response.preProposalId;
                proposal.preProposalId = response.preProposalId;
                const itemListAddPromiseList: Array<Promise<any>> = [];
                if (this.request.preRequestItems) {
                  if (this.request.preRequestItems.length > 0) {
                    for (const item of this.request.preRequestItems) {
                      itemListAddPromiseList.push(
                        new Promise((resolve, reject) => {
                          const itemValues = {
                            preProposalRequestItemId: 0,
                            preProposalId: proposalId,
                            preRequestItemId: item.preRequestItemId,
                            quantity: this.proposalForm.value['un_' + item.preRequestItemId],
                          };
                          this.proposalRequestItemService.save(itemValues)
                            .then(() => { resolve(); });
                        })
                      );
                    }
                  }
                }
                Promise.all(itemListAddPromiseList).then(() => {
                  this.ngxSmartModalService.getModal('provider-request-detail-view-proposal').close();
                  this.dialogHelperService.showSuccessNotification('Dados enviados com sucesso.');
                  this.reloadRequest();
                });
              });
          }
        });
    } else {
      const formKeyList: Array<string> = Object.keys(this.proposalForm.controls);
      for (const key of formKeyList) {
        this.proposalForm.controls[key].markAsTouched();
      }
      this.dialogHelperService.showSuccessNotification('Verifique os campos obrigatórios');
    }
  }
  isInvalidProposalForm(): boolean {
    return !this.proposalForm.valid;
  }
}
