import { Injectable } from '@angular/core';
import { AzureStorageBlobHelperService } from './azure-storage-blob-helper.service';
import * as UUID from 'uuid-js';
import { ObjectMap } from '../utils/object-map';
import Swal from 'sweetalert2';

export interface AzureBlobUploadStatus {
  id: string;
  status: number;
  url?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AzureBlobUploaderService {

  private containerName = 'requestfiles';

  statusMap: ObjectMap<AzureBlobUploadStatus> = new ObjectMap('id');

  constructor(
    private azureStorageBlobHelperService: AzureStorageBlobHelperService
  ) { }

  uploadFile(file: File): string {
    const fileNameParts: Array<string> = file.name.split(".");
    let extension: string = null;
    if (fileNameParts.length > 1) {
      extension = fileNameParts[fileNameParts.length - 1]
    }
    const uploadId: string = UUID.create().toString();
    const status: AzureBlobUploadStatus = {
      id: uploadId,
      status: 1
    };
    this.statusMap.add(status);
    this.azureStorageBlobHelperService.uploadFile(file, this.containerName).then(url => {
      status.url = url;
      status.status = 2;
      this.statusMap.add(status);
    });
    return uploadId;
  }

  getStatus(id: string): AzureBlobUploadStatus {
    const status = this.statusMap.get(id);
    if (!status) {
      return {
        id,
        status: 0,
      };
    }
    return status;
  }

  checkUploadIsComplete(uploadIdList: Array<string>): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      if (uploadIdList.length < 1) {
        resolve([]);
      }
      const interval = setInterval(() => {
        const urlList: Array<string> = [];
        for (const uploadId of uploadIdList) {
          if (this.statusMap.get(uploadId).status !== 2) {
            return false;
          } else {
            urlList.push(this.statusMap.get(uploadId).url);
          }
        }
        resolve(urlList);
        clearInterval(interval);
      }, 100);
    });
  }

}
