import { ProposalRequestItem } from './ProposalRequestItem';
import { Sale } from './Sale';

export class Proposal {
  public proposalId: number;
  public plots: number;
  public plotCost: number;
  public cost: number;
  public shipping: number;
  public icms: number;
  public icmsPercent: number;
  public pisCofins: number;
  public pisCofinsPercent: number;
  public ipi: number;
  public ipiPercent: number;
  public iss: number;
  public issPercent: number;
  public date: any;
  public companyId: number;
  public requestId: number;
  public chatId: any;
  public createdAt: any;
  public conditions: string;
  public shippingTypeId: number;
  public shippingEstimate: string;
  public sales: Sale[];
  public minPurchase: number;
  public proposalRequestItems? : ProposalRequestItem[];
}
