import { Injectable, EventEmitter } from '@angular/core';
import { UserApi } from '../core/http/entidades/UserApi';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  onAuthChange: EventEmitter<boolean> = new EventEmitter()

  isReady: boolean = false;
  token: string;
  user: User;

  constructor(
    private userApi: UserApi
  ) { }

  waitAuth(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      resolve();
      if (this.user) {
        resolve(true);
      } else {
        const intervalId = setInterval(() => {
          if (this.user) {
            clearInterval(intervalId)
            resolve(true);
          }
        }, 500)
      }
    })
  }

  init(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const token = sessionStorage.getItem('Token');
      if (token) {
        this.loadUser().then(() => {
          if (this.user) {
            this.token = token;
            this.onAuthChange.emit(true);
            resolve();
          } else {
            this.onAuthChange.emit(false);
            resolve();
          }
        }).catch(error => {
          this.signOut();
          resolve();
        });
      } else {
        this.onAuthChange.emit(false)
        resolve();
      }
    });
  }

  signIn(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const data = {
        email,
        password,
      };
      this.userApi.post(data, '/login').then(data => {
        this.token = data.token;
        this.user = data.user;
        if (this.token) {
          sessionStorage.setItem('Token', this.token);
          this.onAuthChange.emit(true);
        }
        resolve();
      }).catch(() => {
        reject();
      });
    });
  }

  signOut() {
    this.token = null;
    this.user = null;
    sessionStorage.removeItem('Token');
    this.onAuthChange.emit(false);
  }

  loadUser(): any {
    return new Promise((resolve, reject) => {
      this.userApi.get('/userfromtoken').then(data => {
        let user: User;
        if (data) {
          if (data.userId) {
            user = data;
          }
        }
        this.user = user;
        resolve();
      }).catch(error => {
        reject(error)
      });
    });
  }


}
