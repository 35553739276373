import { Injectable } from '@angular/core';
import { PreRequestAtachmentApi } from 'src/app/core/http/entidades/PreRequestAtachmentApi';

@Injectable({
  providedIn: 'root'
})
export class PrerequestatachmentService {

  constructor(private api: PreRequestAtachmentApi) {
    this.api.baseName = 'prerequestatachment';
  }
  getall(): any {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** Salvar */
  save(request: any): any {
    return new Promise((resolve, reject) => {
      // se segmento novo, insere
      if (!request.requestAttachmentId) {
        this.api.post(request)
          .then((data: any) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(request)
          .then((data: any) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
}
