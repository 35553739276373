import * as moment from 'moment';

export {}

declare global{
    interface Date{
        toBeginDate() : Date;
        toEndDate() : Date;
        toISOUTCBrasiliaString() : string;
    }
}

Date.prototype.toBeginDate = function(){
    let val = this as Date;
    return new Date(val.getFullYear(), val.getMonth(), val.getDate(), 0, 0, 0, 0);
}

Date.prototype.toEndDate = function(){
    let val = this as Date;
    return new Date(val.getFullYear(), val.getMonth(), val.getDate(), 23, 59, 59, 0);
}

Date.prototype.toISOUTCBrasiliaString = function(){
    let date = this as Date;

    function pad(number) {
        if (number < 10) {
          return '0' + number;
        }
        return number;
    }

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      '.' + (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
      '-03:00';
}