import { Injectable } from '@angular/core';
import { ShippingTypeApi } from 'src/app/core/http/entidades/ShippingTypeApi';

@Injectable({
  providedIn: 'root'
})
export class ShippingtypeService {

  constructor(private api: ShippingTypeApi) {
    this.api.baseName = 'shippingtype';
   }
   getall(): any {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  getAllActived(): any {
    return new Promise((resolve, reject) => {
      this.api.get('/GetAllActived')
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  /** buscar um registro pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** Salvar */
  save(entity: any): any {
    return new Promise((resolve, reject) => {
      // se empresa nova, insere
      if (entity.statusId === 0) {
        this.api.post(entity)
          .then(data => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(entity)
          .then(data => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
}
