import { Component, OnInit } from '@angular/core';
import { Pagination } from 'src/app/models/pagination.model';
import { ShippingFileInfo } from 'src/app/models/ShippingFileInfo';
import { ShippingFileService } from 'src/app/services/shippingfile/shippingfile.service';
import { UtilsService } from 'src/app/services/core/utils.service';

@Component({
  selector: 'app-shippingfiles-export',
  templateUrl: './shippingfiles-export.component.html',
  styleUrls: ['./shippingfiles-export.component.css']
})
export class ShippingFilesExportComponent implements OnInit {
  constructor(private shippingFileService : ShippingFileService,
              private utils : UtilsService) {
    this.Paginator = new Pagination();
  }

  public Paginator: Pagination<ShippingFileInfo[]>;

  ngOnInit() {
    this.buildPaginator();
    this.load();
  }

  buildPaginator() {
    this.Paginator.itemsPerPage = 10;
    this.Paginator.currentPage = 1;
    this.Paginator.totalRecords = 0;
  }

  paginate(event) {
    this.Paginator.itemsPerPage = 10;
    this.Paginator.currentPage = (event.page + 1);
    this.load();
  }

  load(){
    this.utils.running();
    
    this.shippingFileService.getOutgoingFilesPaging(this.Paginator)
      .then((data => {
        this.Paginator = data;
        this.utils.finish();
      }))
      .catch(error => {
        console.log(error);
        this.utils.finish();
      });
  }

  downloadFile(fileId : number){
    this.shippingFileService.downloadOutgoingFile(fileId,
      () => this.utils.running(),
      () => {
        this.utils.finish();
        this.load();
      });
  }
}
