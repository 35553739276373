import { Injectable } from '@angular/core';
import { TownhouseCalendarApi } from 'src/app/core/http/entidades/TownhouseCalendarApi';

@Injectable({
  providedIn: 'root'
})
export class TownhouseCalendarService {

  constructor(private api: TownhouseCalendarApi) {
    this.api.baseName = 'townhousecalendar';
  }
  getBybyTownhouse(id: number) {
    return new Promise((result, reject) => {
      this.api.get('/bytownhouse/' + id)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  update(calendar: any) {
    return new Promise((result, reject) => {
      this.api.put(calendar)
        .then(data => result(data))
        .catch(error => reject(error));
    });

  }

}
