import { Component, OnInit } from "@angular/core";
import { OffersService } from "src/app/services/offers/offers.service";
import { UtilsService } from "src/app/services/core/utils.service";
import Swal from "sweetalert2";
import { requireConfirm } from "src/app/utils/decorators/requireConfirm";

@Component({
  selector: "app-offermysalers",
  templateUrl: "./offermysalers.component.html",
  styleUrls: ["./offermysalers.component.css"],
})
export class OffermysalersComponent implements OnInit {
  offers = [];
  constructor(
    private offerServices: OffersService,
    private utils: UtilsService
  ) {}

  @requireConfirm(
    "ATENÇÃO",
    "<p>Confirma o cancelamento dessa venda?</p>",
    "Sim",
    "Não"
  )
  cancelSale(Sale: any) {
    Swal.fire({
      title: "Qisar",
      text: "Informe o motivo do cancelamento",
      input: "textarea",
      preConfirm: (reason) => {
        if (reason != "") {
          Sale.cancelReason = reason;
          this.offerServices.cancelSale(Sale).then(() => this.getItems());
        }
      },
    });
  }

  formatCNPJ(cnpj: string): string {
    let formated = "";
    formated =
      cnpj.substring(0, 2) +
      "." +
      cnpj.substring(2, 5) +
      "." +
      cnpj.substring(5, 8) +
      "/" +
      cnpj.substring(8, 12) +
      "-" +
      cnpj.substring(12, 14);

    return formated;
  }
  formatCep(cep: string): string {
    let formated = "";
    formated = cep.substring(0, 3) + "-" + cep.substring(3);

    return formated;
  }

  details(Sale: any) {
    let msg = Sale.user.name + "[" + Sale.user.email + "]";
    if (Sale.companyId) {
      msg +=
        "<br/> Empresa: " +
        this.formatCNPJ(Sale.company.cnpj) +
        " " +
        Sale.company.name;

      if (Sale.company.addresses) {
        Sale.company.addresses.forEach((address) => {
          msg +=
            "<br/> <br/>" +
            address.street +
            ", " +
            address.number +
            " " +
            address.neighBorHood +
            ", " +
            address.city +
            "-" +
            address.state +
            " " +
            this.formatCep(address.zipCode);
        });
      }
    } else {
      if (Sale.user.person.addresses) {
        Sale.user.person.addresses.forEach((address) => {
          msg +=
            "<br/> <br/>" +
            address.street +
            ", " +
            address.number +
            " " +
            address.neighBorHood +
            ", " +
            address.city +
            "-" +
            address.state +
            " " +
            this.formatCep(address.zipCode);
        });
      }
    }

    Swal.fire({
      title: "Detalhe da venda #" + Sale.offerId,
      html: msg,
      type: "info",
    });
  }
  ngOnInit() {
    this.getItems();
  }

  async getItems() {
    const currentUser = await this.utils.getUserFromSession();
    this.offerServices
      .salesByCompanyAndUser(currentUser.companyId, currentUser.userId)
      .then((offers: []) => (this.offers = offers));
  }
}
