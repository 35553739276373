import { Segment } from './Segment';
import { Company } from './Company';

export class CompanySegment {
  public companySegmentId: number;
  public companyId: number;
  public segmentId: number;
  public isCustomer: boolean;
  public isRequestor: boolean;
  public actived: boolean;
  public company: Company;
  public segment: Segment;
}
