import { Injectable } from '@angular/core';
import { SystemConfigurationApi } from 'src/app/core/http/entidades/SystemConfiguration';
import { SystemConfiguration } from 'src/app/models/SystemConfiguration';
import { Pagination } from 'src/app/models/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class SystemConfigurationService {

  constructor(private api: SystemConfigurationApi) {
    this.api.baseName = 'systemConfiguration';
  }
  
  getall(pagination : Pagination<SystemConfiguration[]>): Promise<Pagination<SystemConfiguration[]>> {
    return new Promise((resolve, reject) => {
      this.api.getAllPaging<SystemConfiguration[]>(pagination)
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  update(systemConfiguration : SystemConfiguration) : Promise<SystemConfiguration>{
    return new Promise((resolve, reject) => {
      this.api.put(systemConfiguration)
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
}
