import { SelectItem } from "primeng/api";

export class ArrayHelper {

  public static toOptionList(array: Array<any>, valueKey: string, labelKey: string): Array<SelectItem> {
    let optionList: Array<SelectItem> = [];
    optionList.push({
      label: "",
      value: null
    })
    for (const item of array) {
      optionList.push({
        label: item[labelKey],
        value: item[valueKey]
      })
    }
    return optionList;
  }

  public static toObjectOptionList(array: Array<any>, labelKey: string): Array<SelectItem> {
    let optionList: Array<SelectItem> = [];
    optionList.push({
      label: "",
      value: {}
    })
    for (const item of array) {
      optionList.push({
        label: item[labelKey],
        value: item
      })
    }
    return optionList;
  }

  public static getPropertyList(array: Array<any>, propertyKey: string): Array<any> {
    const list = [];
    for (const item of array) {
      list.push(item[propertyKey]);
    }
    return list;
  }

  public static getPropertyToPropertyMap(array: Array<any>, propertyKey: string, propertyValue: string): any {
    let map: any = {};
    for (const item of array) {
      map[item[propertyKey]] = item[propertyValue]
    }
    return map;
  }

  public static orderArrayByProperty(array: Array<any>, propertyKey: string) {
    const propertyKeyFirstChar: string = propertyKey[0];
    if (propertyKeyFirstChar === "-") {
      propertyKey = propertyKey.substr(1);
    }
    function compare(a, b) {
      if (a[propertyKey] < b[propertyKey]) {
        if (propertyKeyFirstChar === "-") {
          return 1
        }
        return -1;
      }
      if (a[propertyKey] > b[propertyKey]) {
        if (propertyKeyFirstChar === "-") {
          return -1
        }
        return 1;
      }
      return 0;
    }
    array.sort(compare);
    return array;
  }

}
