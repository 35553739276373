import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UtilsService } from 'src/app/services/core/utils.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Company } from 'src/app/models/Company';
import { User } from 'src/app/models/User';
import { CompanySegment } from 'src/app/models/CompanySegment';
import { UserSegment } from 'src/app/models/UserSegment';
import { CompanyService } from 'src/app/services/company/company.service';
import { UserService } from 'src/app/services/user/user.service';
import { Address } from 'src/app/models/Address';
import { AddressService } from 'src/app/services/address/address.service';
import { UsersegmentService } from 'src/app/services/usersegment/usersegment.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-customer-first-add-page',
  templateUrl: './customer-first-add-page.component.html',
  styleUrls: ['./customer-first-add-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerFirstAddPageComponent implements OnInit {

  form: FormGroup;
  company: any;
  user: any;
  address: any;
  segments = [];
  dropdownList = [];
  dropdownSettings = {};
  partnerId = 0;
  isConasi = false;
  isRadarDigital = false;
  isHabitacional = false;
  imageLogo = '/assets/images/logoBig.png';
  btnPrimary = 'btn-primary';
  companyName = 'Qisar';
  PagCompras = false;

  arrSelectedItemsBuy = [];
  arrSelectedItemsSale = [];
  constructor(
    private formBuilder: FormBuilder,
    private title: Title,
    private utils: UtilsService,
    private http: HttpClient,
    private companyService: CompanyService,
    private userService: UserService,
    private addressService: AddressService,
    private userSegmentsService: UsersegmentService,
    private segmentsService: SegmentService
  ) {
    if (sessionStorage.getItem('whitelabel')) {
      this.imageLogo = '/assets/images/logo-' + sessionStorage.getItem('whitelabel') + '.png';
      this.btnPrimary += sessionStorage.getItem('whitelabel');
      this.companyName = sessionStorage.getItem('whitelabel');
      if (sessionStorage.getItem('whitelabel') === 'pagseguro') {
        this.companyName = 'Pagseguro Compras';
      }

      if(sessionStorage.getItem('whitelabel') === "pagseguro") {
        this.PagCompras = true;
      }
    }
    this.title.setTitle('Bem vindo | Qisar');
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'segmentId',
      textField: 'name',
      selectAllText: 'Selecione Tudo',
      unSelectAllText: 'Desmarque Tudo',
      allowSearchFilter: false

    };

    this.utils.running();
    this.segmentsService.getall()
      .then((s: any) => {
        this.dropdownList = s;
        this.utils.finish();
      })
      .catch(() => {
        this.utils.finish();
      });
  }

  isInvalidForm(): boolean {
    return !this.form.valid;
  }

  onItemSelectBuy(item: any) {
    this.arrSelectedItemsBuy.push(item);
  }
  onSelectAllBuy(items: any) {
    this.arrSelectedItemsBuy = items;
  }
  onDeSelectBuy(item: any) {
    const idx = this.arrSelectedItemsBuy.findIndex(s => s.segmentId === item.segmentId);
    this.arrSelectedItemsBuy.splice(idx, 1);
  }

  onDeSelectAllBuy(item: any) {
    this.arrSelectedItemsBuy = [];
  }


  onItemSelectSale(item: any) {
    this.arrSelectedItemsSale.push(item);
  }
  onSelectAllSale(items: any) {
    this.arrSelectedItemsSale = items;
  }

  onDeSelectSale(item: any) {
    const idx = this.arrSelectedItemsSale.findIndex(s => s.segmentId === item.segmentId);
    this.arrSelectedItemsSale.splice(idx, 1);
  }

  onDeSelectAllSale(item: any) {
    this.arrSelectedItemsSale = [];
  }

  ngOnInit() {
    if ((window.location.href.indexOf('conasi/customer/add') > 0)) {
      this.partnerId = 924;
      this.isConasi = true;
    }
    if ((window.location.href.indexOf('radarindustrial/customer/add') > 0)) {
      this.partnerId = 1772;
      this.isRadarDigital = true;
    }
    if ((window.location.href.indexOf('habitacional/customer/add') > 0)) {
      this.partnerId = 5473;
      this.isHabitacional = true;
    }

    this.form = this.formBuilder.group({
      companyName: [null, Validators.compose([Validators.required])],
      companyCNPJ: [null, Validators.compose([Validators.required])],
      stateCode: [null],
      companyCEP: [null, Validators.compose([Validators.required])],
      companyStreet: [null, Validators.compose([Validators.required])],
      companyNumber: [null, Validators.compose([Validators.required])],
      companyNeighBorHood: [null, Validators.compose([Validators.required])],
      companyCMPL: [null, Validators.compose([])],
      companyCity: [null, Validators.compose([Validators.required])],
      companyState: [null, Validators.compose([Validators.required])],
      companyPhone: [null, Validators.compose([Validators.required])],
      userEmail: [null, Validators.compose([Validators.required])],
      userName: [null, Validators.compose([Validators.required])],
      selectedItemsBuy: [null, Validators.compose([])],
      selectedItemsSales: [null, Validators.compose([])],
    });
  }

  login() {
    window.location.href = '/';
  }
  /** busca o cep */
  searchCep(): void {
    if (this.form.value.companyCEP) {
      if (this.form.value.companyCEP !== '') {
        this.http.get('https://viacep.com.br/ws/' + this.form.value.companyCEP + '/json')
          .toPromise()
          .then((data: any) => {
            this.form.controls.companyStreet.setValue(data.logradouro);
            this.form.controls.companyNeighBorHood.setValue(data.bairro);
            this.form.controls.companyCity.setValue(data.localidade);
            this.form.controls.companyState.setValue(data.uf);
          });
      }
    }
  }
  validCNPJ(): void {
    if (this.form.value.companyCNPJ) {
      if (this.form.value.companyCNPJ !== '') {
        if (!this.utils.validCnpj(this.form.value.companyCNPJ)) {
          Swal.fire('Qisar', 'Ops, CNPJ invalido', 'error');
          this.form.controls.companyCNPJ.setValue('');
        } else {
          this.companyService.getByCNPJ(this.form.value.companyCNPJ)
            .then((data) => {
              if (data) {
                Swal.fire('Qisar', 'Ops, CNPJ já cadastrado', 'error');
                this.form.controls.companyCNPJ.setValue('');
              }
            });
        }
      }
    }
  }
  validEmail(): void {
    if (this.form.value.userEmail) {
      if (this.form.value.userEmail !== '') {
        // tslint:disable-next-line: max-line-length
        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (!regexp.test(this.form.value.userEmail)) {
          Swal.fire('Qisar', 'Ops, E-mail Inválido', 'error');
          this.form.controls.userEmail.setValue('');
        }
      }
    }
  }

  save() {
    if (this.form.valid) {
      this.utils.running();
      const a = '';
      const user = new User();
      user.email = 'integration';
      user.password = a;
      this.userService.login(user)
        .then((u) => {
          sessionStorage.setItem('Token', JSON.stringify(u));
          this.company = new Company();
          this.company.companyId = 0;
          this.company.partnerId = this.partnerId;
          this.company.name = this.form.value.companyName;
          this.company.nickName = this.form.value.companyName;
          this.company.phoneOffice = this.form.value.companyPhone;
          this.company.webSite = '';
          this.company.monthlyPaymentDays = 30;
          this.company.monthlyPayment = 0;
          this.company.cnpj = this.form.value.companyCNPJ;
          this.company.stateCode = this.form.value.stateCode;
          this.company.isRequestor = (this.arrSelectedItemsSale.length > 0);
          this.company.isCustomer = (this.arrSelectedItemsBuy.length > 0);
          this.company.termsStatus = 0;
          this.company.effectiveDate = '';
          this.company.active = true;
          this.company.pagCompras = this.PagCompras;
          this.address = new Address();
          this.address.actived = true;
          this.address.city = this.form.value.companyCity;
          this.address.cmpl = this.form.value.companyCMPL;
          this.address.neighBorHood = this.form.value.companyNeighBorHood;
          this.address.default = true;
          this.address.deliveryInstruction = '';
          this.address.name = 'Principal';
          this.address.number = Number(this.form.value.companyNumber);
          this.address.state = this.form.value.companyState;
          this.address.street = this.form.value.companyStreet;
          this.address.zipCode = this.form.value.companyCEP;
          this.companyService.save(this.company)
            .then((company) => {
              this.address.addressId = 0;
              this.address.companyId = company.companyId;
              this.addressService.save(this.address);
              this.arrSelectedItemsSale.forEach((s) => {
                const companySegment = new CompanySegment();
                companySegment.companySegmentId = 0;
                companySegment.actived = true;
                companySegment.companyId = company.companyId;
                companySegment.isRequestor = true;
                companySegment.isCustomer = false;
                companySegment.segmentId = s.segmentId;
                this.companyService.addSegmentToCompany(companySegment);
              });
              this.arrSelectedItemsBuy.forEach((s) => {
                const companySegment = new CompanySegment();
                companySegment.companySegmentId = 0;
                companySegment.actived = true;
                companySegment.companyId = company.companyId;
                companySegment.isRequestor = false;
                companySegment.isCustomer = true;
                companySegment.segmentId = s.segmentId;
                this.companyService.addSegmentToCompany(companySegment);
              });
              this.user = new User();
              this.user.userId = 0;
              this.user.creationDate = new Date();
              this.user.companyMaster = true;
              this.user.email = this.form.value.userEmail;
              this.user.password = '';
              this.user.master = false;
              this.user.name = this.form.value.userName;
              this.user.useTerms = false;
              this.user.operator = false;
              this.user.companyId = company.companyId;
              this.user.actived = true;
              this.user.avatar = '';
              this.user.tokenChat = '';
              if(this.company.pagCompras)
              {
                this.user.requireStrongPassword = true;
              }               
              this.userService.save(this.user)
                .then((us) => {
                  this.arrSelectedItemsSale.forEach((sSales) => {
                    const userSegment = new UserSegment();
                    userSegment.userSegmentId = 0;
                    userSegment.maxValue = 0;
                    userSegment.userId = us.userId;
                    userSegment.isRequestor = true;
                    userSegment.isCustomer = false;
                    userSegment.canCreateRequests = true;
                    userSegment.segmentId = sSales.segmentId;
                    userSegment.companyId = us.companyId;
                    this.userSegmentsService.save(userSegment);
                  });
                  this.arrSelectedItemsBuy.forEach((sBuy) => {
                    const userSegment = new UserSegment();
                    userSegment.userSegmentId = 0;
                    userSegment.maxValue = 0;
                    userSegment.userId = us.userId;
                    userSegment.isRequestor = false;
                    userSegment.isCustomer = true;
                    userSegment.segmentId = sBuy.segmentId;
                    userSegment.companyId = us.companyId;
                    userSegment.canCreateRequests = true;
                    this.userSegmentsService.save(userSegment);
                  });
                  sessionStorage.clear();
                  this.utils.finish();
                  Swal.fire('Qisar',
                    'Bem vindo à qisar, em instantes você recebera um email com sua senha de acesso',
                    'success').then(() => {
                      this.login();
                    });
                });
            });
        });
    }
  }
}
