import { Component, OnInit } from '@angular/core';
import { SystemConfiguration } from 'src/app/models/SystemConfiguration';
import { Pagination } from 'src/app/models/pagination.model';
import { SystemConfigurationService } from 'src/app/services/systemConfiguration/systemConfiguration.service';
import * as lodash from 'lodash'
import Swal from 'sweetalert2';
import { UtilsService } from 'src/app/services/core/utils.service';

@Component({
  selector: 'app-system-configuration',
  templateUrl: './configuration-system.component.html',
  styleUrls: ['./configuration-system.component.css']
})
export class ConfigurationSystemComponent implements OnInit {
  public Paginator : Pagination<SystemConfiguration[]>;
  public editing : boolean;
  public editingKey : string;
  public keyValues: SystemConfiguration[];
  public keyValuesOriginal : SystemConfiguration[];

  constructor(
    private systemConfigurationService : SystemConfigurationService,
    private utils: UtilsService
  ){
      this.Paginator = new Pagination();
  }

  ngOnInit() {
    this.buildPaginator();
    this.load();
  }

  applyFilter(event: any){
    if(event.target.value.length === 0)
      this.keyValues = lodash.cloneDeep(this.keyValuesOriginal);
    else
      this.keyValues = this.keyValuesOriginal.filter(item => item.key.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1)
  }

  buildPaginator(){
    this.Paginator.itemsPerPage = 10;
    this.Paginator.currentPage = 1;
    this.Paginator.totalRecords = 0;
  }

  paginate(event){
    this.Paginator.itemsPerPage = event.rows;
    this.Paginator.currentPage = (event.page + 1);
    this.load();
  }

  load(){
    this.utils.running();

    this.systemConfigurationService.getall(this.Paginator)
      .then(data => { 
        this.keyValuesOriginal = data.data;
        this.keyValues = lodash.cloneDeep(this.keyValuesOriginal);
        this.utils.finish();
      })
      .catch(error => {
        console.log(error);
        this.utils.finish();
      });
  }

  update(systemConfiguration : SystemConfiguration){
    this.utils.running();
    
    this.systemConfigurationService.update(systemConfiguration)
      .then(data => { 
        let originalItem = this.keyValuesOriginal.filter(item => item.key === systemConfiguration.key)[0];
        originalItem.value = systemConfiguration.value;

        Swal.fire('Qisar', `Chave ${systemConfiguration.key} atualizada com sucesso!`, 'success');
        this.editing = false;
        this.editingKey = '';

        this.utils.finish();
       })
      .catch(error => {
        Swal.fire('Qisar', 'Erro ao atualizar chave de configuração', 'error');
        console.log(error);

        this.editing = false;
        this.editingKey = '';
        this.utils.finish();
      });
  }

  resetList(){
    this.keyValues.forEach(item => {
      var original = this.keyValuesOriginal.filter(originalItem => originalItem.key === item.key)[0];
      item.value = original.value;
    });
  }

  edit(key: string){
    this.editing = true;
    this.editingKey = key;
    this.resetList();
  }

  confirm(key : string){
    let item = this.keyValues.filter(item => item.key === key)[0];
    this.update(item);
  }

  cancel(){
    this.editing = false;
    this.editingKey = '';
    this.resetList();
  }
}
