export class TownhouseCalendar {
  public townhouseCalendarId: number;
  public townhouseId: number;
  public day: any;
  public hour: any;
  public email: string;
  public name: string;
  public phone: string;
  public cpf: string;
  public unidade: string;
}
