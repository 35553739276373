import { Injectable } from '@angular/core';
import { CompanyWorkApi } from 'src/app/core/http/entidades/CompanyWorkApi';

@Injectable({
    providedIn: "root",
})
export class CompanyWorkService {
    constructor(
        private api: CompanyWorkApi,
    ) {
        this.api.baseName = "companywork/";
    }

    getActived(companyid: number) {
        return new Promise((resolve, reject) => {
            this.api
                .get(companyid.toString())
                .then((data) => resolve(data))
                .catch((error) => reject(error));
        });
    }
}