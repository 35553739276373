import { Injectable } from '@angular/core';
import { ProposalShippingApi } from 'src/app/core/http/entidades/ProposalShippingApi';

@Injectable({
  providedIn: 'root'
})
export class ProposalshippingService {

  constructor(private api: ProposalShippingApi) {
    this.api.baseName = 'ProposalShipping';
  }


  save(item: any): any {
    return new Promise((resolve, reject) => {
      this.api.post(item)
        .then((data: any) => resolve(data))
        .catch(() => {
          reject();
        });
    });
  }

}
