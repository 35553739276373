import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UtilsService } from 'src/app/services/core/utils.service';
import { User } from 'src/app/models/User';
import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/Company';

@Component({
  selector: 'app-terms-of-use-view',
  templateUrl: './terms-of-use-view.component.html',
  styleUrls: ['./terms-of-use-view.component.css']
})
export class TermsOfUseViewComponent implements OnInit {

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onAccept = new EventEmitter<Company>();

  @Input() isTrustyCompany: boolean;


  basicPlan_9_99__id = 10;

  // Somente favoritos
  isTerm1Visible = false;
  term1Confirmation = false;
  termPla1 = false;
  termPla2 = false;
  termPla3 = false;
  termPla4 = false;

  // Somente não favoritos
  isTerm2Visible = false;
  term2Confirmation = false;

  // Somente não favoritos
  isTermSummitVisible = false;
  termSummitConfirmation = false;

  isReady = false;

  currentUser: User;
  msgTerms = 'Grátis por 30 dias partir da data de adesão.';
  cost = 0;
  constructor(
    private utilsService: UtilsService,
    public ngxSmartModalService: NgxSmartModalService,
    private companyService: CompanyService
  ) { }

  changePlan(plan: number) {
    this.termPla1 = false;
    this.termPla2 = false;
    this.termPla3 = false;
    this.termPla4 = false;
    switch (plan) {
      case 1:
        this.termPla1 = true;
        break;
      case 2:
        this.termPla2 = true;
        break;
      case 3:
        this.termPla3 = true;
        break;
      case 4:
        this.termPla4 = true;
        break;
    }

    this.term1Confirmation = (this.termPla1 || this.termPla2 || this.termPla3 || this.termPla4);
  }

  ngOnInit(): void {
    this.utilsService.getUserFromSession().then(response => {
      this.currentUser = response;
      if (this.currentUser.company.monthlyPayment) {
        this.cost = this.currentUser.company.monthlyPayment;
      }

      if (this.currentUser.company.monthlyPaymentDays) {
        this.msgTerms = 'Grátis por ' + this.currentUser.company.monthlyPaymentDays + ' dias  à partir da data de adesão';
      } else {
        if (this.isTrustyCompany) {
          this.msgTerms = 'Grátis por 30 dias à partir da data de adesão';
        }
      }

      if (this.currentUser.company.termsStatus === 0) {
        if (this.isTrustyCompany) {
          this.isTerm1Visible = true;
        } else {
          this.isTerm1Visible = true;
          this.isTerm2Visible = true;
        }
      }
      if (this.currentUser.company.termsStatus === 1) {
        if (!this.isTrustyCompany) {
          this.isTerm2Visible = true;
        }
      }
      if (this.currentUser.company.termsStatus === 2) {
        if (this.isTrustyCompany) {
          this.isTerm2Visible = false;
        }
      }
      if (!this.isTerm1Visible && !this.isTerm2Visible && !this.isTermSummitVisible) {
        this.onAccept.emit(this.currentUser.company);
      } else {
        this.isReady = true;
      }

    });
  }

  accept(): void {
    this.term1Confirmation = (this.termPla1 || this.termPla2 || this.termPla3 || this.termPla4);
    const company = this.currentUser.company;
    const termsStatus = company.termsStatus;
    const planAccept = company.paymentPlanId;
    let Plan = 0 + planAccept;
    if (this.termPla1) {
      Plan = 1;
    }
    if (this.termPla2) {
      Plan = 2;
    }
    if (this.termPla3) {
      Plan = 3;
    }
    if (this.termPla4) {
      Plan = 4;
    }
    if (this.isTerm1Visible && !this.isTerm2Visible) {
      company.termsStatus = termsStatus + 1;
    }
    if (!this.isTerm1Visible && this.isTerm2Visible) {
      company.termsStatus = termsStatus + 2;
    }
    if (this.isTerm1Visible && this.isTerm2Visible) {
      company.termsStatus = termsStatus + 3;
    }
    company.paymentPlanId = Plan;
    company.enableToSaleAcceptDate = new Date().toISOString();
    this.companyService.save(company).then(() => {
      const tokenData = this.utilsService.getTokenData();
      tokenData.user.company = company;
      this.utilsService.setTokenData(tokenData);
      this.onAccept.emit(company);
    });
  }

  checkUserConfirmation(): boolean {
    if (this.isTerm1Visible) {
      if (!this.term1Confirmation) {
        return false;
      }
    }
    if (this.isTerm2Visible) {
      if (!this.term2Confirmation) {
        return false;
      }
    }
    return true;
  }

}
