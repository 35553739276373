import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { OffersService } from "src/app/services/offers/offers.service";
import { UtilsService } from "src/app/services/core/utils.service";
import { User } from "src/app/models/User";
import { requireConfirm } from "src/app/utils/decorators/requireConfirm";

@Component({
  selector: "app-offerlist",
  templateUrl: "./offerlist.component.html",
  styleUrls: ["./offerlist.component.css"],
})
export class OfferlistComponent implements OnInit {
  offers = [];
  user: User;

  constructor(
    private title: Title,
    private router: Router,
    private utils: UtilsService,
    private offerService: OffersService
  ) {}

  @requireConfirm(
    "Qisar",
    "<p>Será cobrada uma comissão de <strong>3.5%</strong> sobre o valor bruto de cada venda que você realizar no portal</p>",
    "Concordo",
    "Discordo"
  )
  newProduct() {
    this.router.navigateByUrl("/offer/new");
  }

  details(offerId: number) {
    this.router.navigateByUrl("/offer/details/" + offerId);
  }

  itemStyle(statusId: number) {
    switch (statusId) {
      case 1:
        return "text-primary";
      case 2:
        return "text-danger";
      case 3:
        return "text-success";
    }
  }

  ngOnInit() {
    this.title.setTitle("Oportunidades | Qisar");
    const tokenData = this.utils.getTokenData();
    this.user = tokenData.user;
    this.utils.running();
    this.offerService.getOffersList().then((data: []) => {
      this.offers = data;
      this.utils.finish();
    });
  }

  formatDescription(description: string) {
    return this.formatText(description, 100);
  }

  formatTitle(title: string) {
    return this.formatText(title, 60);
  }

  formatText(text: string, characters: number) {
    if (text.length > characters) {
      return text.substr(0, characters) + "...";
    } else {
      return text;
    }
  }
}
