import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver, ViewChildren, ComponentRef } from '@angular/core';
import * as moment from 'moment';
import { CompanyInvoice } from 'src/app/models/companyInvoice.model';
import { PaymentState } from 'src/app/models/paymentState.enum.model';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { UtilsService } from 'src/app/services/core/utils.service';
import { User } from 'src/app/models/User';
import { Pagination } from 'src/app/models/pagination.model';
import { InvoiceDetailComponent } from './detail/invoicedetail.component';
import { InvoiceState } from 'src/app/models/invoiceState.enum.model';
import { saveAs } from 'file-saver';
import { Title } from '@angular/platform-browser';
import { CompanySetupInfo } from 'src/app/models/CompanySetupInfo';

@Component({
  selector: 'app-payment',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class CompanyInvoicesComponent implements OnInit {
  private currentUser: User;

  public companySetupInformation: CompanySetupInfo;
  public InvoicesLateBillDays = 0;
  public Paginator: Pagination<Array<CompanyInvoice>>;
  @ViewChild(InvoiceDetailComponent) invoiceDetail: InvoiceDetailComponent;

  constructor(
    private paymentService: PaymentService,
    private title: Title,
    private utilsService: UtilsService,
    private resolver: ComponentFactoryResolver) {
    this.Paginator = new Pagination();
    this.title.setTitle('Faturas | Qisar');
  }

  ngOnInit() {
    this.buildPaginator();
    this.getInvoices();
  }

  getInvoices() {
    this.utilsService.getUserFromSession()
      .then((user: User) => {
        this.currentUser = user;
        this.paymentService.getCompanyInvoicesPaging(user.companyId, this.Paginator.itemsPerPage, this.Paginator.currentPage)
          .then((result: Pagination<Array<CompanyInvoice>>) => {
            result.data.forEach(item => {
              const referenceDate = moment(item.referenceDate);
              item.paymentDate = this.getPaymentDate(item);
              item.referenceMonth = referenceDate.format('MM');
              item.referenceYear = referenceDate.format('YYYY');
            });
            this.InvoicesLateBillDays = this.getInvoicesLateBillDays(result.data);
            this.getCompanySetupInfo();
            this.Paginator = result;
          });
      });
  }

  getCompanySetupInfo() {
    this.paymentService.getCompanySetupInformation(this.currentUser.companyId)
      .then((companySetupInfo: CompanySetupInfo) => {
        this.companySetupInformation = companySetupInfo;
      });
  }

  buildPaginator() {
    this.Paginator.itemsPerPage = 10;
    this.Paginator.currentPage = 1;
    this.Paginator.totalRecords = 0;
  }

  paginate(event) {
    this.Paginator.itemsPerPage = event.rows;
    this.Paginator.currentPage = (event.page + 1);
    this.getInvoices();
  }

  getPaymentDate(companyInvoice: CompanyInvoice): Date {
    if (companyInvoice.payments) {
      const lastPayment = companyInvoice.payments.filter(item => item.isCurrent);
      if (lastPayment) {
        if (lastPayment.length > 0 && lastPayment[0].state === PaymentState.success) {
          return lastPayment[0].paymentDate;
        }
      }
    }

    return null;
  }

  getInvoicesLateBillDays(invoices: Array<CompanyInvoice>): number {
    const lateBillInvoices = invoices.filter(i => i.state === InvoiceState.lateBill);
    if (lateBillInvoices) {
      if (lateBillInvoices.length > 0) {
        const orderedInvoices = lateBillInvoices.sort((a, b) => {
          if (a.dueDate > b.dueDate) {
            return 1;
          } else if (a.dueDate < b.dueDate) {
            return -1;
          } else {
            return 0;
          }
        });

        const invoice = orderedInvoices[0];
        const now = moment(new Date());
        const invoiceDueDate = moment(invoice.dueDate);
        return now.diff(invoiceDueDate, 'days');
      }
    }
    return 0;
  }

  showInvoiceDetail(invoiceId: number) {
    this.invoiceDetail.loadInvoice(invoiceId);
  }

  downloadBankBillet(invoiceId: number) {
        this.paymentService
         .getInvoicePaymentFile(invoiceId)
         .then((data: Blob) => {
           saveAs(data);
         });
  }
}
