import { UserPhone } from './UserPhone';
import { UserSegment } from './UserSegment';
import { Company } from './Company';
import { CompanyAcceptDetail } from './CompanyAcceptDetail';

export class User {
  public userId: number;
  public email: string;
  public password: string;
  public name: string;
  public avatar: string;
  public useTerms: boolean;
  public useTermsIP: string;
  public companyId: number;
  public companyMaster: boolean;
  public LastLoginIp: string;
  public actived: boolean;
  public creationDate: any;
  public lastLogin: any;
  public master: boolean;
  public operator: boolean;
  public userPhones: UserPhone[];
  public userSegments: UserSegment[];
  public userCompanies: Array<any>;
  public company: Company;
  public companyManager: boolean;
  public toReceiveAllMails: boolean;
  public lastSystemInteration: any;
  public requireStrongPassword: boolean;
  public loginErrosCount: number;
  public enableOffers: boolean;
  public companyAcceptDetail: CompanyAcceptDetail;
}
