import { Injectable } from '@angular/core';
import { PaymentApi } from 'src/app/core/http/entidades/PaymentApi';
import { CompanyInvoice } from 'src/app/models/companyInvoice.model';
import { Pagination } from 'src/app/models/pagination.model';
import { saveAs } from 'file-saver'
import { ChartCompanyInvoicesStatus } from 'src/app/models/ChartCompanyInvoiceStatus';
import { InvoiceState } from 'src/app/models/invoiceState.enum.model';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { CompanySetupInfo } from 'src/app/models/CompanySetupInfo';
import { CompanyPaymentItem } from 'src/app/models/CompanyPaymentItem';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private api: PaymentApi) {
    this.api.baseName = 'payment';
  }

  getCompanyInvoices(companyId: number): Promise<Array<CompanyInvoice>> {
    return new Promise((result, reject) => {
      this.api.get(`/invoice/company/${companyId}/all`)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  getCompanyInvoicesPaging(companyId: number, itemsPerPage: number, currentPage: number): Promise<Pagination<Array<CompanyInvoice>>> {
    return new Promise((result, reject) => {
      this.api.get(`/invoice/company/${companyId}/all/paging/${itemsPerPage}/${currentPage}`)
        .then(data => result(data))
        .catch(error => {
          if (error.status === 404) {
            const data: any = new Pagination<CompanyInvoice[]>();
            data.data = new Array<CompanyInvoice>();
            data.currentPage = 1;
            data.itemsPerPage = 1;
            data.totalRecords = 1;

            result(data);
          }
          else
            reject(error);
        });
    })
  }

  getCompanyInvoicesPagingByStatus(state: InvoiceState, itemsPerPage: number, currentPage: number, beginDate: Date, endDate: Date): Promise<Pagination<Array<CompanyPaymentItem>>> {
    return new Promise((result, reject) => {
      var url = `/invoice/state/${state}/all/paging/${itemsPerPage}/${currentPage}`;

      if (beginDate && endDate) {
        var bDate = `${beginDate.getFullYear()}-${(beginDate.getMonth() + 1).toString().padStart(2, '0')}`;
        var eDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}`;
        url += `?beginDate=${bDate}&endDate=${eDate}`;
      }

      this.api.get(url)
        .then(data => result(data))
        .catch(error => {
          if (error.status === 404) {
            let data: any = new Pagination<CompanyPaymentItem[]>();
            data.data = new Array<CompanyPaymentItem>();
            data.currentPage = 1;
            data.itemsPerPage = 1;
            data.totalRecords = 1;

            result(data);
          }
          else
            reject(error);
        });
    })
  }

  getInvoiceDetail(invoiceId: number): Promise<CompanyInvoice> {
    return new Promise((result, reject) => {
      this.api.get(`/invoice/${invoiceId}/detail`)
        .then(data => result(data))
        .catch(error => reject(error));
    })
  }

  getInvoicePaymentFile(invoiceId: number): any {
    return this.api.getFile(`/invoice/${invoiceId}/file`, 'application/pdf');
  }

  getInvoicePaymentOverview(initialDate: Date, endDate: Date, type?: string): Promise<Array<ChartCompanyInvoicesStatus>> {
    return new Promise((result, reject) => {
      var url = '/invoice/overview';

      if (initialDate != null && endDate != null) {
        var iDate = `${initialDate.getFullYear()}-${(initialDate.getMonth() + 1).toString().padStart(2, '0')}`;
        var eDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}`;
        url += `?beginDate=${iDate}&endDate=${eDate}`;
      }

      if(type)
        url += `${ url.indexOf('?') >= 0 ? '&' : '?' }type=${type}`;

      this.api.get(url)
        .then(data => result(data))
        .catch(error => {
          if (error.status === 404)
            result([] as Array<ChartCompanyInvoicesStatus>);
          else
            reject(error);
        });
    });
  }

  getCompanySetupInformation(companyId: number) : Promise<CompanySetupInfo>{
    return new Promise((result, reject) => {
      var url = `/company/${companyId}/setup`;

      this.api.get(url)
        .then(data => result(data))
        .catch(error => {
          if (error.status === 404)
            result(null);
          else
            reject(error);
        });
    });
  }
}
