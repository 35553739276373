import { Segment } from './Segment';
import { Product } from './Product';

export class SubSegment {
    public subSegmentId: number;
    public name: string;
    public measure: string;
    public segmentId: number;
    public description: string;
    public segment: Segment;
    public requestHasManyProducts: boolean;
    public products: Product[];
}
