import { Injectable } from '@angular/core';
import { ChatMessageApi } from 'src/app/core/http/entidades/ChatMessageApi';

@Injectable({
  providedIn: 'root'
})
export class ChatmessageService {

  constructor(private api: ChatMessageApi) {
    this.api.baseName = 'ChatMessage';
  }

  /** cria novo mensagem */
  create(chatMessage: any): any {
    return new Promise((resolve, reject) => {
      this.api.post(chatMessage)
        .then((data: any) => resolve(data))
        .catch(() => {
          reject();
        });
    });
  }

  /** retorna lista de mensagens não lidas */
  unreadMessages(UserId: number): any {
    return new Promise((resolve, reject) => {
      this.api.get('/unreadMessages/' + UserId.toString())
        .then((data: any) => resolve(data))
        .catch(() => {
          reject();
        });
    });
  }

  /** marca mensagem como lida */
  setMessageRead(chatMessage: any): any {
    return new Promise((resolve, reject) => {
      this.api.post(chatMessage, '/SetMessageRead')
        .then((data: any) => resolve(data))
        .catch(() => {
          reject();
        });
    });
  }

  /** marca mensagens da requisição como lidas */
  SetMessagesRead(userId: any, requestId: any): any {
    const userChat = { userId, requestId };
    return new Promise((resolve, reject) => {
      this.api.post(userChat, '/SetMessagesRead')
        .then(() => resolve())
        .catch(() => {
          reject();
        });
    });
  }
}
