import { Injectable } from '@angular/core';
import { CompanyPaymentConfigurationServiceApi } from 'src/app/core/http/entidades/CompanyPaymentConfigurationService';
import { CompanyPaymentConfiguration } from 'src/app/models/CompanyPaymentConfiguration'
import { Pagination } from 'src/app/models/pagination.model';
import { reject } from 'q';

@Injectable({
  providedIn: 'root'
})
export class CompanyPaymentConfigurationService {

  constructor(private api: CompanyPaymentConfigurationServiceApi) {
    this.api.baseName = 'company';
  }

  getById(Id: number) : Promise<CompanyPaymentConfiguration>{
    return new Promise((result, reject) => {
      this.api.get(`${Id}/payment/configuration`)
      .then(data => result(data))
      .catch(error => reject(error));
    });
  }

  getPaging(pagination: Pagination<CompanyPaymentConfiguration[]>, companyNameFilter: string = null) : Promise<Pagination<CompanyPaymentConfiguration[]>> {
    let url = '';
    
    if(companyNameFilter == null)
      url = 'payment/configuration';
    else
      url = `${companyNameFilter}/payment/configuration`

    return new Promise((result, reject) => {
      this.api.getPaging(pagination, url)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  update(config: CompanyPaymentConfiguration): Promise<boolean>{
    return new Promise((result, reject) => {
      this.api.put(config, `/payment/configuration`)
        .then(data => result(true))
        .catch(error => reject(error));
    });
  }

  add(config : CompanyPaymentConfiguration) : Promise<boolean>{
    return new Promise((result, reject) => {
      this.api.post(config, '/payment/configuration')
        .then(data => result(true))
        .catch(error => reject(error));
    });
  }
}
