import { Injectable } from '@angular/core';
import { PreRequestItemApi } from 'src/app/core/http/entidades/PreRequestItemApi';

@Injectable({
  providedIn: 'root'
})
export class PrerequestitemService {

  constructor(private api: PreRequestItemApi) {
    this.api.baseName = 'prerequestitem';
  }
  save(item: any): any {
    return new Promise((resolve, reject) => {
      if (item.preRequestItemId === 0) {
        this.api.post(item)
          .then((data: any) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(item)
          .then((data: any) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
}
