import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company/company.service';
import { UtilsService } from 'src/app/services/core/utils.service';

@Component({
  selector: 'app-reportbysegments',
  templateUrl: './reportbysegments.component.html',
  styleUrls: ['./reportbysegments.component.css']
})
export class ReportbysegmentsComponent implements OnInit {
  list = [];

  constructor(private companyService: CompanyService, private utilsService: UtilsService) { }

  ngOnInit() {
    this.load();
  }

  load() {
    this.utilsService.running();
    this.companyService.ReportSegmentRequestors()
      .then((data) => {
        this.list = data;
        this.utilsService.finish();
      });
  }

}
