import { Injectable } from '@angular/core';
import { Segment } from 'src/app/models/Segment';
import { SaleApi } from 'src/app/core/http/entidades/SaleApi';
import { Sale } from 'src/app/models/Sale';

@Injectable({
  providedIn: 'root'
})
export class SaleService {

  constructor(private api: SaleApi) {
    this.api.baseName = 'sale';
  }
  /** buscar todas os segmentos */
  getall(): any {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um segmento pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** Salvar */
  save(sale: Sale): any {
    return new Promise((resolve, reject) => {
      // se segmento novo, insere
      if (sale.saleId === 0 || !sale.saleId) {
        this.api.post(sale)
          .then((data: Sale) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(sale)
          .then((data: Sale) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api.delete(id)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  getFromSegment(segmentId: number): any {
    return new Promise((result, reject) => {
      this.api.get('/fromsegment/' + segmentId.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  getFromSubSegment(subSegmentId: number): any {
    return new Promise((result, reject) => {
      this.api.get('/fromsubsegment/' + subSegmentId.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  TotalSale(companyid: number, segmentId: number, initialDate: any, endDate: any): any {
    return new Promise((result, reject) => {
      this.api.get('/TotalSale/' + companyid + '/' + segmentId + '/' + initialDate.toISOString() + '/' + endDate.toISOString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  TotalBuy(companyid: number, segmentId: number, initialDate: any, endDate: any, userId: any): any {
    console.log(companyid)
    console.log(segmentId)
    console.log(initialDate.toISOString())
    console.log(endDate.toISOString())
    console.log(userId)
    return new Promise((result, reject) => {
      this.api.get('/TotalBuy/' + companyid + '/' + segmentId + '/' + initialDate.toISOString() + '/' + endDate.toISOString() + '/'+ userId)
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** buscar vendas não lidas */
  getNotReadSalesByUserId(): any {
    return new Promise((result, reject) => {
      this.api.get('/GetNotReadSalesByUserId')
        .then(data => result(data))
        .catch(error => reject(error));
    });

  }

  /** marcar proposta como lida */
  readSaleByRequest(requestId: any): any {
    return new Promise((resolve, reject) => {
      this.api.post(requestId, '/ReadSaleByRequest')
        .then(() => resolve())
        .catch(() => reject());
    });
  }
}
