import { Injectable } from '@angular/core';
import { Segment } from 'src/app/models/Segment';
import { SegmentApi } from 'src/app/core/http/entidades/SegmentApi';
import { ProductApi } from 'src/app/core/http/entidades/ProductApi';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private api: ProductApi) {
    this.api.baseName = 'product';
   }
  /** buscar todas os segmentos */
  getall(): any {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um segmento pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** Salvar */
  save(segment: Segment): any {
    return new Promise((resolve, reject) => {
      // se segmento novo, insere
      if (segment.segmentId === 0) {
        this.api.post(segment)
          .then((data: Segment) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(segment)
          .then((data: Segment) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api.delete(id)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  getFromSegment(segmentId: number): any {
    return new Promise((result, reject) => {
      this.api.get('/fromsegment/' + segmentId.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  getFromSubSegment(subSegmentId: number): any {
    return new Promise((result, reject) => {
      this.api.get('/fromsubsegment/' + subSegmentId.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
}
