import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { QisarService } from 'src/app/services/hubs/qisar.service';

@Component({
  selector: 'app-users-active',
  templateUrl: './users-active.component.html',
  styleUrls: ['./users-active.component.css']
})
export class UsersActiveComponent implements OnInit {

  users = [];
  now = '';
  constructor(
    private qisarService: QisarService,
    private title: Title) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.title.setTitle('Usuarios Logados ');
    this.users = this.qisarService.onlineUsers;

    this.qisarService.onChangeUsers.subscribe((users: []) => {
      this.users = this.qisarService.onlineUsers;
    });

  }
}
