import Swal from 'sweetalert2';

export function requireConfirm(title = 'Qisar', message = 'Confirma essa operação', confirmText = 'Sim', declineText = 'Não') {
  return function (target: any, key: any, descriptor: PropertyDescriptor) {

    const originCode = descriptor.value;

    descriptor.value = function (...args: any[]) {
      let ret: any;
      Swal.fire({
        title: title,
        html: message,
        type: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: declineText,
        confirmButtonText: confirmText
      })
        .then((confirm) => {
          if (confirm.value) {
            ret = originCode.apply(this, args);
          }
        });

      return ret;
    }

    return descriptor;
  }
}
