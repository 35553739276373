import { Injectable } from '@angular/core';
import { Segment } from 'src/app/models/Segment';
import { AddressApi } from 'src/app/core/http/entidades/AddressApi';
import { Address } from 'src/app/models/Address';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private api: AddressApi) {
    this.api.baseName = 'address';
  }
  /** buscar todas os segmentos */
  getall(): any {
    return new Promise((resolve, reject) => {
      this.api.get()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um segmento pelo Id */
  get(id: number): any {
    return new Promise((result, reject) => {
      this.api.getOne(id.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }
  /** buscar um segmento pelo Id */
  findByCompany(companyId: number): any {
    return new Promise((result, reject) => {
      this.api.get('/findByCompany/' + companyId.toString())
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** buscar um segmento pelo Id */
  getForRequest(): any {
    return new Promise((result, reject) => {
      this.api.get('/forrequest')
        .then(data => result(data))
        .catch(error => reject(error));
    });
  }

  /** Salvar */
  save(address: Address): any {
    return new Promise((resolve, reject) => {
      // se segmento novo, insere
      if (address.addressId === 0) {
        this.api.post(address)
          .then((data: Segment) => resolve(data))
          .catch(() => {
            reject();
          });
      } else { // senão atualiza
        this.api.put(address)
          .then((data: Segment) => resolve(data))
          .catch(() => {
            reject();
          });
      }
    });
  }
  /** excluir */
  delete(id: number): any {
    return new Promise((resolve, reject) => {
      this.api.delete(id)
        .then(() => {
          resolve(null);
        })
        .catch(() => {
          reject();
        });
    });
  }
}
